export const TOGGLE_STEP = "TOGGLE_STEP";
export const RESET_WIZARD = "RESET_WIZARD";

export const toggleStep = (step) => async (dispatch) => {
  dispatch({
    type: TOGGLE_STEP,
    payload: {
      step,
    },
  });
};

export const resetWizard = () => async (dispatch) => {
  dispatch({
    type: RESET_WIZARD,
  });
};
