import { updateClasses } from "./middlewares/settings.middleware.js";
import { updateTheme } from "./middlewares/themes.middleware.js";
import { initialState } from "./reducers/app.initial.state";
import configuration from "../configs/config.json";

const { storage } = configuration.app;

const browserStorage = sessionStorage || localStorage;

/**
 * Use this function if you need to call a middleware
 * when setting the initial state.
 */
const callMiddlewares = (state) => {
  // Since a middlwrare is requried to modify the DOM in this case
  // for settings and themes, is necessary to call middleware
  // directly to set the initial state loaded from storage
  updateClasses(state);
  updateTheme(state);
};

/* Use an IIFE to export the persisted state in a variable */
export const persistedState = (() => {
  try {
    const rawState = browserStorage.getItem(storage.key);
    if (rawState === null) return undefined;
    const state = JSON.parse(rawState);
    if (state.version !== storage.version) {
      return undefined;
    } else {
      delete state.version;
    }
    callMiddlewares(state);
    return state;
  } catch (err) {
    return undefined;
  }
})();

/**
 * Export a method to save state on each store update
 */
export const saveState = (state) => {
  try {
    let stateFilter = JSON.parse(JSON.stringify(state)); // deep clone
    ["offsidebarOpen", "asideToggled", "horizontal"] // states which we don't want to persist.
      .forEach((item) => delete stateFilter.settings[item]);
    [].forEach((item) => delete stateFilter[item]); // states which we don't want to persist.
    stateFilter.version = storage.version;
    const rawState = JSON.stringify(stateFilter);
    browserStorage.setItem(storage.key, rawState);
  } catch (err) {
    // Ignore write errors.
  }
};

/**
 * Export a method to save state on each store
 */
export const resetState = () => {
  try {
    const rawState = JSON.stringify(initialState);
    browserStorage.setItem(storage.key, rawState);
  } catch (err) {
    browserStorage.removeItem(storage.key);
  }
};

/**
 * Export a method to save state on each store
 */
export const readState = () => {
  try {
    const rawState = browserStorage.getItem(storage.key);
    return JSON.parse(rawState);
  } catch (err) {
    return null;
  }
};
