import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../store/actions/actions";

class UserAutosense extends Component {
  componentDidMount() {
    const { user, loading, actions } = this.props;
    if (!user && loading.authenticating === 0) {
      actions.autosense();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAutosense);
