import React from "react";

const Logo = props => {
  const font = {
    fontFamily: "Montserrat-SemiBold",
    unitsPerEm: 1000,
    underlinePosition: -75,
    underlineThickness: 50
  };
  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 196.34 45.07"
      style={{ enableBackground: "new 0 0 196.34 45.07" }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
    >
      <style type="text/css">
        {
          ".st0 {fill:#FFFFFF;}	.st1{display:none;}	.st2{display:inline;fill:#1AB39B;}	.st3{fill:#22AC9B;}	.st4{opacity:0.1;clip-path:url(#SVGID_2_);fill:#FFFFFF;}	.st5{fill:none;}	.st6{opacity:0.15;clip-path:url(#SVGID_4_);fill:#BCB5B3;}	.st7{display:none;fill:#FFFFFF;}	.st8{font-family:'Montserrat', sans-serif;font-weight:600}	.st9{font-size:27.8367px;}"
        }
      </style>
      <font horizAdvX="1000">
        <font-face {...font} />
        <missing-glyph
          horizAdvX="587"
          d="M40,700l507,0l0,-700l-507,0M467,70l0,560l-347,0l0,-560z"
        />
        <glyph
          unicode="
"
          horizAdvX="276"
        />
        <glyph unicode=" " horizAdvX="276" />
        <glyph
          unicode="A"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306z"
        />
        <glyph
          unicode="&#xC1;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M425,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x102;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M201,894l82,0C284,871 293,853 310,840C327,826 349,819 374,819C399,819 421,826 438,840C455,853 464,871 465,894l82,0C545,850 528,815 496,789C463,763 423,750 374,750C325,750 285,763 253,789C220,815 203,850 201,894z"
        />
        <glyph
          unicode="&#x1EAE;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M201,864l80,0C283,849 293,838 310,829C327,820 348,815 374,815C400,815 422,820 439,829C456,838 465,849 467,864l80,0C544,829 526,801 495,781C463,760 423,750 374,750C325,750 285,760 254,781C222,801 204,829 201,864M411,1008l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EB6;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M314,-144C314,-127 320,-112 331,-100C342,-89 357,-83 374,-83C391,-83 406,-89 417,-100C428,-112 434,-127 434,-144C434,-162 428,-176 417,-187C406,-198 391,-203 374,-203C357,-203 342,-198 331,-187C320,-176 314,-162 314,-144M201,894l82,0C284,871 293,853 310,840C327,826 349,819 374,819C399,819 421,826 438,840C455,853 464,871 465,894l82,0C545,850 528,815 496,789C463,763 423,750 374,750C325,750 285,763 253,789C220,815 203,850 201,894z"
        />
        <glyph
          unicode="&#x1EB0;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M201,864l80,0C283,849 293,838 310,829C327,820 348,815 374,815C400,815 422,820 439,829C456,838 465,849 467,864l80,0C544,829 526,801 495,781C463,760 423,750 374,750C325,750 285,760 254,781C222,801 204,829 201,864M204,1008l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EB2;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M201,864l80,0C283,849 293,838 310,829C327,820 348,815 374,815C400,815 422,820 439,829C456,838 465,849 467,864l80,0C544,829 526,801 495,781C463,760 423,750 374,750C325,750 285,760 254,781C222,801 204,829 201,864M362,897C388,910 401,924 401,941C401,950 398,958 391,964C384,969 376,972 365,972C350,972 336,968 321,960l-22,42C320,1016 345,1023 376,1023C402,1023 423,1016 438,1003C453,989 460,971 460,948C460,929 454,912 442,897C430,882 414,871 394,863z"
        />
        <glyph
          unicode="&#x1EB4;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M201,864l80,0C283,849 293,838 310,829C327,820 348,815 374,815C400,815 422,820 439,829C456,838 465,849 467,864l80,0C544,829 526,801 495,781C463,760 423,750 374,750C325,750 285,760 254,781C222,801 204,829 201,864M355,926C344,933 336,938 329,941C322,944 316,946 309,946C281,946 266,930 265,898l-68,0C198,934 208,962 227,982C246,1002 271,1012 303,1012C320,1012 335,1009 348,1004C361,998 376,990 391,980C403,973 413,967 420,964C427,961 434,959 441,959C454,959 465,963 472,972C479,980 484,991 485,1006l66,0C550,971 541,943 522,923C503,902 478,892 447,892C429,892 413,895 400,901C386,907 371,915 355,926z"
        />
        <glyph
          unicode="&#x1CD;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M565,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xC2;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M462,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EA4;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M461,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M597,966l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EAC;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M314,-144C314,-127 320,-112 331,-100C342,-89 357,-83 374,-83C391,-83 406,-89 417,-100C428,-112 434,-127 434,-144C434,-162 428,-176 417,-187C406,-198 391,-203 374,-203C357,-203 342,-198 331,-187C320,-176 314,-162 314,-144M462,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EA6;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M461,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M390,966l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EA8;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M461,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M503,864C529,877 542,891 542,908C542,917 539,925 532,931C525,936 517,939 506,939C491,939 477,935 462,927l-22,42C461,983 486,990 517,990C543,990 564,983 579,970C594,956 601,938 601,915C601,896 595,879 583,864C571,849 555,838 535,830z"
        />
        <glyph
          unicode="&#x1EAA;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M461,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M355,932C344,939 336,944 329,947C322,950 316,952 309,952C281,952 266,936 265,904l-68,0C198,940 208,968 227,988C246,1008 271,1018 303,1018C320,1018 335,1015 348,1010C361,1004 376,996 391,986C403,979 413,973 420,970C427,967 434,965 441,965C454,965 465,969 472,978C479,986 484,997 485,1012l66,0C550,977 541,949 522,929C503,908 478,898 447,898C429,898 413,901 400,907C386,913 371,921 355,932z"
        />
        <glyph
          unicode="&#x200;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M124,894l125,0l80,-137l-98,0M311,894l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xC4;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M217,828C217,846 223,861 234,872C245,883 260,889 277,889C294,889 309,883 320,872C331,861 337,846 337,828C337,811 331,796 320,785C309,774 294,768 277,768C260,768 245,774 234,785C223,796 217,811 217,828M411,828C411,846 417,861 428,872C439,883 454,889 471,889C488,889 503,883 514,872C525,861 531,846 531,828C531,811 525,796 514,785C503,774 488,768 471,768C454,768 439,774 428,785C417,796 411,811 411,828z"
        />
        <glyph
          unicode="&#x1EA0;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M314,-144C314,-127 320,-112 331,-100C342,-89 357,-83 374,-83C391,-83 406,-89 417,-100C428,-112 434,-127 434,-144C434,-162 428,-176 417,-187C406,-198 391,-203 374,-203C357,-203 342,-198 331,-187C320,-176 314,-162 314,-144z"
        />
        <glyph
          unicode="&#xC0;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M176,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EA2;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M358,793C377,802 391,811 400,822C408,832 412,844 412,857C412,870 407,880 398,889C389,897 377,901 362,901C341,901 321,895 302,882l-27,55C288,947 304,955 321,960C338,965 356,968 375,968C407,968 433,959 454,941C475,922 485,898 485,868C485,814 456,774 399,747z"
        />
        <glyph
          unicode="&#x202;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M374,901C423,901 463,888 496,862C528,836 545,801 547,757l-82,0C464,780 455,799 438,813C421,826 399,833 374,833C349,833 327,826 310,813C293,799 284,780 283,757l-82,0C203,801 220,836 253,862C285,888 325,901 374,901z"
        />
        <glyph
          unicode="&#x100;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M201,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x104;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M552,-121C552,-90 564,-61 588,-33C612,-6 649,18 699,37l55,-37C712,-19 682,-37 664,-54C645,-72 636,-91 636,-111C636,-126 641,-138 652,-147C663,-156 677,-160 695,-160C718,-160 739,-155 756,-144l21,-61C750,-220 718,-228 682,-228C641,-228 610,-218 587,-198C564,-179 552,-153 552,-121z"
        />
        <glyph
          unicode="&#xC5;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M257,873C257,905 268,932 291,955C313,978 340,989 373,989C406,989 434,978 457,955C479,932 490,905 490,873C490,841 479,814 457,792C434,770 406,759 373,759C340,759 313,770 291,792C268,814 257,841 257,873M437,873C437,892 431,907 419,920C406,932 391,938 373,938C355,938 340,932 328,920C316,907 310,892 310,873C310,855 316,840 328,828C340,816 355,810 373,810C392,810 407,816 419,828C431,840 437,855 437,873z"
        />
        <glyph
          unicode="&#x1FA;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M472,936C484,917 490,896 490,873C490,841 479,814 457,792C434,770 406,759 373,759C340,759 313,770 291,792C268,814 257,841 257,873C257,905 268,932 291,955C313,978 340,989 373,989C392,989 410,985 426,977l85,87l64,-50M437,873C437,892 431,907 419,920C406,932 391,938 373,938C355,938 340,932 328,920C316,907 310,892 310,873C310,855 316,840 328,828C340,816 355,810 373,810C392,810 407,816 419,828C431,840 437,855 437,873z"
        />
        <glyph
          unicode="&#xC3;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M357,797C346,806 338,812 331,817C324,821 317,823 310,823C296,823 285,818 276,807C267,796 262,782 261,763l-67,0C195,804 205,836 225,861C244,885 270,897 302,897C319,897 333,894 346,887C358,880 373,870 391,857C402,848 411,842 418,838C425,833 431,831 438,831C452,831 464,836 473,846C482,856 486,870 487,888l67,0C553,849 542,817 523,793C504,769 478,757 446,757C429,757 415,760 403,767C390,774 375,784 357,797z"
        />
        <glyph
          unicode="&#xC6;"
          horizAdvX="1063"
          d="M1012,109l0,-109l-525,0l0,162l-266,0l-92,-162l-134,0l406,700l597,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M487,264l0,327l-22,0l-186,-327z"
        />
        <glyph
          unicode="&#x1FC;"
          horizAdvX="1063"
          d="M1012,109l0,-109l-525,0l0,162l-266,0l-92,-162l-134,0l406,700l597,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M487,264l0,327l-22,0l-186,-327M602,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="B"
          horizAdvX="761"
          d="M716,190C716,129 693,83 646,50C599,17 531,0 442,0l-348,0l0,700l328,0C505,700 568,684 613,652C658,619 680,575 680,518C680,483 672,453 656,426C640,399 618,379 590,364C629,351 659,330 682,301C705,271 716,234 716,190M224,598l0,-193l184,0C453,405 488,413 513,430C537,446 549,470 549,501C549,533 537,557 513,574C488,590 453,598 408,598M585,203C585,270 535,304 434,304l-210,0l0,-202l210,0C535,102 585,136 585,203z"
        />
        <glyph
          unicode="C"
          horizAdvX="727"
          d="M44,350C44,419 60,480 93,535C126,590 171,633 228,664C285,695 348,710 419,710C476,710 529,700 576,680C623,660 663,631 696,593l-84,-79C561,569 499,596 425,596C377,596 334,586 296,565C258,544 228,514 207,477C186,440 175,397 175,350C175,303 186,260 207,223C228,186 258,157 296,136C334,115 377,104 425,104C499,104 561,132 612,187l84,-80C663,69 623,40 576,20C528,0 475,-10 418,-10C347,-10 284,6 227,37C170,68 125,110 93,165C60,220 44,281 44,350z"
        />
        <glyph
          unicode="&#x106;"
          horizAdvX="727"
          d="M44,350C44,419 60,480 93,535C126,590 171,633 228,664C285,695 348,710 419,710C476,710 529,700 576,680C623,660 663,631 696,593l-84,-79C561,569 499,596 425,596C377,596 334,586 296,565C258,544 228,514 207,477C186,440 175,397 175,350C175,303 186,260 207,223C228,186 258,157 296,136C334,115 377,104 425,104C499,104 561,132 612,187l84,-80C663,69 623,40 576,20C528,0 475,-10 418,-10C347,-10 284,6 227,37C170,68 125,110 93,165C60,220 44,281 44,350M464,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x10C;"
          horizAdvX="727"
          d="M44,350C44,419 60,480 93,535C126,590 171,633 228,664C285,695 348,710 419,710C476,710 529,700 576,680C623,660 663,631 696,593l-84,-79C561,569 499,596 425,596C377,596 334,586 296,565C258,544 228,514 207,477C186,440 175,397 175,350C175,303 186,260 207,223C228,186 258,157 296,136C334,115 377,104 425,104C499,104 561,132 612,187l84,-80C663,69 623,40 576,20C528,0 475,-10 418,-10C347,-10 284,6 227,37C170,68 125,110 93,165C60,220 44,281 44,350M604,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xC7;"
          horizAdvX="727"
          d="M44,350C44,419 60,480 93,535C126,590 171,633 228,664C285,695 348,710 419,710C476,710 529,700 576,680C623,660 663,631 696,593l-84,-79C561,569 499,596 425,596C377,596 334,586 296,565C258,544 228,514 207,477C186,440 175,397 175,350C175,303 186,260 207,223C228,186 258,157 296,136C334,115 377,104 425,104C499,104 561,132 612,187l84,-80C663,69 623,40 576,20C528,0 475,-10 418,-10C347,-10 284,6 227,37C170,68 125,110 93,165C60,220 44,281 44,350M279,-207l23,59C323,-159 345,-164 370,-164C405,-164 423,-152 423,-128C423,-118 419,-110 410,-104C401,-98 388,-95 370,-95l-31,0l27,102l68,0l-14,-56C449,-52 470,-61 485,-76C500,-91 507,-110 507,-133C507,-162 495,-185 470,-202C445,-219 412,-228 371,-228C335,-228 304,-221 279,-207z"
        />
        <glyph
          unicode="&#x1E08;"
          horizAdvX="727"
          d="M44,350C44,419 60,480 93,535C126,590 171,633 228,664C285,695 348,710 419,710C476,710 529,700 576,680C623,660 663,631 696,593l-84,-79C561,569 499,596 425,596C377,596 334,586 296,565C258,544 228,514 207,477C186,440 175,397 175,350C175,303 186,260 207,223C228,186 258,157 296,136C334,115 377,104 425,104C499,104 561,132 612,187l84,-80C663,69 623,40 576,20C528,0 475,-10 418,-10C347,-10 284,6 227,37C170,68 125,110 93,165C60,220 44,281 44,350M279,-207l23,59C323,-159 345,-164 370,-164C405,-164 423,-152 423,-128C423,-118 419,-110 410,-104C401,-98 388,-95 370,-95l-31,0l27,102l68,0l-14,-56C449,-52 470,-61 485,-76C500,-91 507,-110 507,-133C507,-162 495,-185 470,-202C445,-219 412,-228 371,-228C335,-228 304,-221 279,-207M464,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x108;"
          horizAdvX="727"
          d="M44,350C44,419 60,480 93,535C126,590 171,633 228,664C285,695 348,710 419,710C476,710 529,700 576,680C623,660 663,631 696,593l-84,-79C561,569 499,596 425,596C377,596 334,586 296,565C258,544 228,514 207,477C186,440 175,397 175,350C175,303 186,260 207,223C228,186 258,157 296,136C334,115 377,104 425,104C499,104 561,132 612,187l84,-80C663,69 623,40 576,20C528,0 475,-10 418,-10C347,-10 284,6 227,37C170,68 125,110 93,165C60,220 44,281 44,350M501,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x10A;"
          horizAdvX="727"
          d="M44,350C44,419 60,480 93,535C126,590 171,633 228,664C285,695 348,710 419,710C476,710 529,700 576,680C623,660 663,631 696,593l-84,-79C561,569 499,596 425,596C377,596 334,586 296,565C258,544 228,514 207,477C186,440 175,397 175,350C175,303 186,260 207,223C228,186 258,157 296,136C334,115 377,104 425,104C499,104 561,132 612,187l84,-80C663,69 623,40 576,20C528,0 475,-10 418,-10C347,-10 284,6 227,37C170,68 125,110 93,165C60,220 44,281 44,350M342,840C342,861 349,878 362,891C375,904 392,911 413,911C434,911 451,904 464,891C477,878 484,861 484,840C484,819 477,802 464,789C451,776 434,769 413,769C392,769 375,776 362,789C349,802 342,819 342,840z"
        />
        <glyph
          unicode="D"
          horizAdvX="826"
          d="M94,700l306,0C475,700 541,686 599,657C657,628 702,587 734,534C766,481 782,419 782,350C782,281 766,220 734,167C702,114 657,73 599,44C541,15 475,0 400,0l-306,0M394,110C445,110 491,120 530,140C569,159 599,187 620,224C641,260 651,302 651,350C651,398 641,440 620,477C599,513 569,541 530,561C491,580 445,590 394,590l-170,0l0,-480z"
        />
        <glyph
          unicode="&#x1C4;"
          horizAdvX="1452"
          d="M94,700l306,0C475,700 541,686 599,657C657,628 702,587 734,534C766,481 782,419 782,350C782,281 766,220 734,167C702,114 657,73 599,44C541,15 475,0 400,0l-306,0M394,110C445,110 491,120 530,140C569,159 599,187 620,224C641,260 651,302 651,350C651,398 641,440 620,477C599,513 569,541 530,561C491,580 445,590 394,590l-170,0l0,-480M1422,110l0,-110l-597,0l0,87l412,503l-405,0l0,110l577,0l0,-87l-412,-503M1315,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xD0;"
          horizAdvX="836"
          d="M104,700l306,0C485,700 551,686 609,657C667,628 712,587 744,534C776,481 792,419 792,350C792,281 776,220 744,167C712,114 667,73 609,44C551,15 485,0 410,0l-306,0M404,110C455,110 501,120 540,140C579,159 609,187 630,224C651,260 661,302 661,350C661,398 651,440 630,477C609,513 579,541 540,561C501,580 455,590 404,590l-170,0l0,-480M5,404l416,0l0,-96l-416,0z"
        />
        <glyph
          unicode="&#x10E;"
          horizAdvX="826"
          d="M94,700l306,0C475,700 541,686 599,657C657,628 702,587 734,534C766,481 782,419 782,350C782,281 766,220 734,167C702,114 657,73 599,44C541,15 475,0 400,0l-306,0M394,110C445,110 491,120 530,140C569,159 599,187 620,224C641,260 651,302 651,350C651,398 641,440 620,477C599,513 569,541 530,561C491,580 445,590 394,590l-170,0l0,-480M566,893l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x110;"
          horizAdvX="836"
          d="M104,700l306,0C485,700 551,686 609,657C667,628 712,587 744,534C776,481 792,419 792,350C792,281 776,220 744,167C712,114 667,73 609,44C551,15 485,0 410,0l-306,0M404,110C455,110 501,120 540,140C579,159 609,187 630,224C651,260 661,302 661,350C661,398 651,440 630,477C609,513 579,541 540,561C501,580 455,590 404,590l-170,0l0,-480M5,404l416,0l0,-96l-416,0z"
        />
        <glyph
          unicode="&#x1E0C;"
          horizAdvX="826"
          d="M94,700l306,0C475,700 541,686 599,657C657,628 702,587 734,534C766,481 782,419 782,350C782,281 766,220 734,167C702,114 657,73 599,44C541,15 475,0 400,0l-306,0M394,110C445,110 491,120 530,140C569,159 599,187 620,224C641,260 651,302 651,350C651,398 641,440 620,477C599,513 569,541 530,561C491,580 445,590 394,590l-170,0l0,-480M318,-144C318,-127 324,-112 335,-100C346,-89 361,-83 378,-83C395,-83 410,-89 421,-100C432,-112 438,-127 438,-144C438,-162 432,-176 421,-187C410,-198 395,-203 378,-203C361,-203 346,-198 335,-187C324,-176 318,-162 318,-144z"
        />
        <glyph
          unicode="&#x1E0E;"
          horizAdvX="826"
          d="M94,700l306,0C475,700 541,686 599,657C657,628 702,587 734,534C766,481 782,419 782,350C782,281 766,220 734,167C702,114 657,73 599,44C541,15 475,0 400,0l-306,0M394,110C445,110 491,120 530,140C569,159 599,187 620,224C641,260 651,302 651,350C651,398 641,440 620,477C599,513 569,541 530,561C491,580 445,590 394,590l-170,0l0,-480M205,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="&#x1C5;"
          horizAdvX="1358"
          d="M94,700l306,0C475,700 541,686 599,657C657,628 702,587 734,534C766,481 782,419 782,350C782,281 766,220 734,167C702,114 657,73 599,44C541,15 475,0 400,0l-306,0M394,110C445,110 491,120 530,140C569,159 599,187 620,224C641,260 651,302 651,350C651,398 641,440 620,477C599,513 569,541 530,561C491,580 445,590 394,590l-170,0l0,-480M1325,100l0,-100l-461,0l0,79l293,355l-287,0l0,100l447,0l0,-79l-294,-355M1285,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="E"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193z"
        />
        <glyph
          unicode="&#xC9;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M402,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x114;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M178,894l82,0C261,871 270,853 287,840C304,826 326,819 351,819C376,819 398,826 415,840C432,853 441,871 442,894l82,0C522,850 505,815 473,789C440,763 400,750 351,750C302,750 262,763 230,789C197,815 180,850 178,894z"
        />
        <glyph
          unicode="&#x11A;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M542,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x1E1C;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M238,-207l23,59C282,-159 304,-164 329,-164C364,-164 382,-152 382,-128C382,-118 378,-110 369,-104C360,-98 347,-95 329,-95l-31,0l27,102l68,0l-14,-56C408,-52 429,-61 444,-76C459,-91 466,-110 466,-133C466,-162 454,-185 429,-202C404,-219 371,-228 330,-228C294,-228 263,-221 238,-207M178,894l82,0C261,871 270,853 287,840C304,826 326,819 351,819C376,819 398,826 415,840C432,853 441,871 442,894l82,0C522,850 505,815 473,789C440,763 400,750 351,750C302,750 262,763 230,789C197,815 180,850 178,894z"
        />
        <glyph
          unicode="&#xCA;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M439,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EBE;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M438,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M574,966l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EC6;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M299,-144C299,-127 305,-112 316,-100C327,-89 342,-83 359,-83C376,-83 391,-89 402,-100C413,-112 419,-127 419,-144C419,-162 413,-176 402,-187C391,-198 376,-203 359,-203C342,-203 327,-198 316,-187C305,-176 299,-162 299,-144M439,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EC0;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M438,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M367,966l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EC2;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M438,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M480,864C506,877 519,891 519,908C519,917 516,925 509,931C502,936 494,939 483,939C468,939 454,935 439,927l-22,42C438,983 463,990 494,990C520,990 541,983 556,970C571,956 578,938 578,915C578,896 572,879 560,864C548,849 532,838 512,830z"
        />
        <glyph
          unicode="&#x1EC4;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M438,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M332,932C321,939 313,944 306,947C299,950 293,952 286,952C258,952 243,936 242,904l-68,0C175,940 185,968 204,988C223,1008 248,1018 280,1018C297,1018 312,1015 325,1010C338,1004 353,996 368,986C380,979 390,973 397,970C404,967 411,965 418,965C431,965 442,969 449,978C456,986 461,997 462,1012l66,0C527,977 518,949 499,929C480,908 455,898 424,898C406,898 390,901 377,907C363,913 348,921 332,932z"
        />
        <glyph
          unicode="&#x204;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M101,894l125,0l80,-137l-98,0M288,894l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xCB;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M194,828C194,846 200,861 211,872C222,883 237,889 254,889C271,889 286,883 297,872C308,861 314,846 314,828C314,811 308,796 297,785C286,774 271,768 254,768C237,768 222,774 211,785C200,796 194,811 194,828M388,828C388,846 394,861 405,872C416,883 431,889 448,889C465,889 480,883 491,872C502,861 508,846 508,828C508,811 502,796 491,785C480,774 465,768 448,768C431,768 416,774 405,785C394,796 388,811 388,828z"
        />
        <glyph
          unicode="&#x116;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M280,840C280,861 287,878 300,891C313,904 330,911 351,911C372,911 389,904 402,891C415,878 422,861 422,840C422,819 415,802 402,789C389,776 372,769 351,769C330,769 313,776 300,789C287,802 280,819 280,840z"
        />
        <glyph
          unicode="&#x1EB8;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M299,-144C299,-127 305,-112 316,-100C327,-89 342,-83 359,-83C376,-83 391,-89 402,-100C413,-112 419,-127 419,-144C419,-162 413,-176 402,-187C391,-198 376,-203 359,-203C342,-203 327,-198 316,-187C305,-176 299,-162 299,-144z"
        />
        <glyph
          unicode="&#xC8;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M153,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EBA;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M335,793C354,802 368,811 377,822C385,832 389,844 389,857C389,870 384,880 375,889C366,897 354,901 339,901C318,901 298,895 279,882l-27,55C265,947 281,955 298,960C315,965 333,968 352,968C384,968 410,959 431,941C452,922 462,898 462,868C462,814 433,774 376,747z"
        />
        <glyph
          unicode="&#x206;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M351,901C400,901 440,888 473,862C505,836 522,801 524,757l-82,0C441,780 432,799 415,813C398,826 376,833 351,833C326,833 304,826 287,813C270,799 261,780 260,757l-82,0C180,801 197,836 230,862C262,888 302,901 351,901z"
        />
        <glyph
          unicode="&#x112;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M178,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E16;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M178,846l346,0l0,-70l-346,0M388,1026l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1E14;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M178,846l346,0l0,-70l-346,0M181,1026l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x118;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M417,-121C417,-90 429,-61 453,-33C477,-6 514,18 564,37l55,-37C577,-19 547,-37 529,-54C510,-72 501,-91 501,-111C501,-126 506,-138 517,-147C528,-156 542,-160 560,-160C583,-160 604,-155 621,-144l21,-61C615,-220 583,-228 547,-228C506,-228 475,-218 452,-198C429,-179 417,-153 417,-121z"
        />
        <glyph
          unicode="&#x1EBC;"
          horizAdvX="670"
          d="M619,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M334,797C323,806 315,812 308,817C301,821 294,823 287,823C273,823 262,818 253,807C244,796 239,782 238,763l-67,0C172,804 182,836 202,861C221,885 247,897 279,897C296,897 310,894 323,887C335,880 350,870 368,857C379,848 388,842 395,838C402,833 408,831 415,831C429,831 441,836 450,846C459,856 463,870 464,888l67,0C530,849 519,817 500,793C481,769 455,757 423,757C406,757 392,760 380,767C367,774 352,784 334,797z"
        />
        <glyph
          unicode="&#x1B7;"
          horizAdvX="631"
          d="M588,210C588,169 577,132 556,99C535,66 502,39 459,20C416,0 362,-10 299,-10C246,-10 195,-3 147,11C98,25 59,44 29,69l46,102C102,150 135,133 174,120C213,107 253,101 295,101C346,101 385,111 414,130C443,149 457,176 457,210C457,243 443,269 416,288C389,306 347,315 292,315l-66,0l0,87l175,191l-344,0l0,107l497,0l0,-87l-185,-202C440,404 495,382 532,347C569,311 588,265 588,210z"
        />
        <glyph
          unicode="&#x1EE;"
          horizAdvX="631"
          d="M588,210C588,169 577,132 556,99C535,66 502,39 459,20C416,0 362,-10 299,-10C246,-10 195,-3 147,11C98,25 59,44 29,69l46,102C102,150 135,133 174,120C213,107 253,101 295,101C346,101 385,111 414,130C443,149 457,176 457,210C457,243 443,269 416,288C389,306 347,315 292,315l-66,0l0,87l175,191l-344,0l0,107l497,0l0,-87l-185,-202C440,404 495,382 532,347C569,311 588,265 588,210M498,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="F"
          horizAdvX="637"
          d="M224,591l0,-213l338,0l0,-110l-338,0l0,-268l-130,0l0,700l511,0l0,-109z"
        />
        <glyph
          unicode="G"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140z"
        />
        <glyph
          unicode="&#x11E;"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140M241,894l82,0C324,871 333,853 350,840C367,826 389,819 414,819C439,819 461,826 478,840C495,853 504,871 505,894l82,0C585,850 568,815 536,789C503,763 463,750 414,750C365,750 325,763 293,789C260,815 243,850 241,894z"
        />
        <glyph
          unicode="&#x1E6;"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140M605,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x11C;"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140M502,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x122;"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140M466,-136C466,-155 461,-174 450,-195l-32,-70l-61,0l24,81C358,-177 346,-161 346,-136C346,-120 352,-107 363,-97C374,-88 388,-83 406,-83C424,-83 439,-88 450,-98C461,-108 466,-121 466,-136z"
        />
        <glyph
          unicode="&#x120;"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140M343,840C343,861 350,878 363,891C376,904 393,911 414,911C435,911 452,904 465,891C478,878 485,861 485,840C485,819 478,802 465,789C452,776 435,769 414,769C393,769 376,776 363,789C350,802 343,819 343,840z"
        />
        <glyph
          unicode="&#x1E20;"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140M241,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E4;"
          horizAdvX="809"
          d="M572,358l123,0l0,-279C659,50 617,28 569,13C521,-2 472,-10 421,-10C350,-10 285,6 228,37C171,68 126,110 93,165C60,220 44,281 44,350C44,419 60,480 93,535C126,590 171,633 229,664C286,695 351,710 423,710C482,710 535,700 583,681C631,662 671,633 704,596l-82,-80C569,569 504,596 429,596C380,596 336,586 298,565C259,544 229,515 208,478C186,441 175,398 175,350C175,303 186,261 208,224C229,187 259,157 298,136C336,115 379,104 428,104C483,104 531,116 572,140M396,290l391,0l0,-96l-391,0z"
        />
        <glyph
          unicode="H"
          horizAdvX="810"
          d="M716,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289z"
        />
        <glyph
          unicode="&#x126;"
          horizAdvX="830"
          d="M726,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289M5,607l819,0l0,-96l-819,0z"
        />
        <glyph
          unicode="&#x1E2A;"
          horizAdvX="810"
          d="M716,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289M232,-77l78,0C310,-98 319,-115 337,-128C355,-141 378,-148 405,-148C432,-148 455,-141 473,-128C491,-115 500,-98 500,-77l78,0C576,-118 559,-150 527,-175C494,-200 454,-213 405,-213C356,-213 316,-200 284,-175C251,-150 234,-118 232,-77z"
        />
        <glyph
          unicode="&#x21E;"
          horizAdvX="810"
          d="M716,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289M596,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x124;"
          horizAdvX="810"
          d="M716,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289M493,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1E24;"
          horizAdvX="810"
          d="M716,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289M345,-144C345,-127 351,-112 362,-100C373,-89 388,-83 405,-83C422,-83 437,-89 448,-100C459,-112 465,-127 465,-144C465,-162 459,-176 448,-187C437,-198 422,-203 405,-203C388,-203 373,-198 362,-187C351,-176 345,-162 345,-144z"
        />
        <glyph unicode="I" horizAdvX="319" d="M94,700l130,0l0,-700l-130,0z" />
        <glyph
          unicode="&#x132;"
          horizAdvX="722"
          d="M75,64l49,101C154,145 186,130 221,119C256,108 291,103 327,103C445,103 504,170 504,304l0,396l130,0l0,-389C634,242 621,184 596,136C571,88 535,52 489,27C443,2 389,-10 327,-10C278,-10 232,-3 189,10C146,23 108,41 75,64M88,700l130,0l0,-385l-130,0z"
        />
        <glyph
          unicode="&#xCD;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M210,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x12C;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M-14,894l82,0C69,871 78,853 95,840C112,826 134,819 159,819C184,819 206,826 223,840C240,853 249,871 250,894l82,0C330,850 313,815 281,789C248,763 208,750 159,750C110,750 70,763 38,789C5,815 -12,850 -14,894z"
        />
        <glyph
          unicode="&#xCE;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M247,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x208;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M-91,894l125,0l80,-137l-98,0M96,894l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xCF;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M2,828C2,846 8,861 19,872C30,883 45,889 62,889C79,889 94,883 105,872C116,861 122,846 122,828C122,811 116,796 105,785C94,774 79,768 62,768C45,768 30,774 19,785C8,796 2,811 2,828M196,828C196,846 202,861 213,872C224,883 239,889 256,889C273,889 288,883 299,872C310,861 316,846 316,828C316,811 310,796 299,785C288,774 273,768 256,768C239,768 224,774 213,785C202,796 196,811 196,828z"
        />
        <glyph
          unicode="&#x1E2E;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M7,826C7,843 13,857 24,868C35,879 49,884 65,884C82,884 96,879 107,868C118,857 123,843 123,826C123,809 118,796 107,785C96,774 82,768 65,768C49,768 35,774 24,785C13,796 7,809 7,826M196,826C196,843 202,857 213,868C224,879 238,884 254,884C270,884 284,878 295,867C306,856 312,842 312,826C312,809 306,796 295,785C284,774 270,768 254,768C238,768 224,774 213,785C202,796 196,809 196,826M196,1026l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x130;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M88,840C88,861 95,878 108,891C121,904 138,911 159,911C180,911 197,904 210,891C223,878 230,861 230,840C230,819 223,802 210,789C197,776 180,769 159,769C138,769 121,776 108,789C95,802 88,819 88,840z"
        />
        <glyph
          unicode="&#x1ECA;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M99,-144C99,-127 105,-112 116,-100C127,-89 142,-83 159,-83C176,-83 191,-89 202,-100C213,-112 219,-127 219,-144C219,-162 213,-176 202,-187C191,-198 176,-203 159,-203C142,-203 127,-198 116,-187C105,-176 99,-162 99,-144z"
        />
        <glyph
          unicode="&#xCC;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M-39,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EC8;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M143,793C162,802 176,811 185,822C193,832 197,844 197,857C197,870 192,880 183,889C174,897 162,901 147,901C126,901 106,895 87,882l-27,55C73,947 89,955 106,960C123,965 141,968 160,968C192,968 218,959 239,941C260,922 270,898 270,868C270,814 241,774 184,747z"
        />
        <glyph
          unicode="&#x20A;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M159,901C208,901 248,888 281,862C313,836 330,801 332,757l-82,0C249,780 240,799 223,813C206,826 184,833 159,833C134,833 112,826 95,813C78,799 69,780 68,757l-82,0C-12,801 5,836 38,862C70,888 110,901 159,901z"
        />
        <glyph
          unicode="&#x12A;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M18,863l282,0l0,-75l-282,0z"
        />
        <glyph
          unicode="&#x12E;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M79,-124C79,-63 113,-9 181,36l43,-36C201,-21 185,-40 176,-57C167,-75 162,-93 162,-111C162,-126 166,-137 175,-146C183,-155 193,-160 206,-160C217,-160 228,-157 239,-150l19,-58C241,-221 218,-228 189,-228C156,-228 129,-218 109,-199C89,-180 79,-155 79,-124z"
        />
        <glyph
          unicode="&#x128;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M142,797C131,806 123,812 116,817C109,821 102,823 95,823C81,823 70,818 61,807C52,796 47,782 46,763l-67,0C-20,804 -10,836 10,861C29,885 55,897 87,897C104,897 118,894 131,887C143,880 158,870 176,857C187,848 196,842 203,838C210,833 216,831 223,831C237,831 249,836 258,846C267,856 271,870 272,888l67,0C338,849 327,817 308,793C289,769 263,757 231,757C214,757 200,760 188,767C175,774 160,784 142,797z"
        />
        <glyph
          unicode="J"
          horizAdvX="527"
          d="M-13,91l74,89C100,127 146,100 200,100C273,100 309,143 309,229l0,362l-249,0l0,109l379,0l0,-464C439,154 419,93 379,52C339,11 280,-10 203,-10C158,-10 117,-1 80,17C42,34 11,59 -13,91z"
        />
        <glyph
          unicode="&#x134;"
          horizAdvX="527"
          d="M-13,91l74,89C100,127 146,100 200,100C273,100 309,143 309,229l0,362l-249,0l0,109l379,0l0,-464C439,154 419,93 379,52C339,11 280,-10 203,-10C158,-10 117,-1 80,17C42,34 11,59 -13,91M338,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="K"
          horizAdvX="729"
          d="M335,293l-111,-114l0,-179l-130,0l0,700l130,0l0,-359l346,359l146,0l-294,-313l312,-387l-152,0z"
        />
        <glyph
          unicode="&#x1E8;"
          horizAdvX="729"
          d="M335,293l-111,-114l0,-179l-130,0l0,700l130,0l0,-359l346,359l146,0l-294,-313l312,-387l-152,0M568,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x136;"
          horizAdvX="729"
          d="M335,293l-111,-114l0,-179l-130,0l0,700l130,0l0,-359l346,359l146,0l-294,-313l312,-387l-152,0M437,-136C437,-155 432,-174 421,-195l-32,-70l-61,0l24,81C329,-177 317,-161 317,-136C317,-120 323,-107 334,-97C345,-88 359,-83 377,-83C395,-83 410,-88 421,-98C432,-108 437,-121 437,-136z"
        />
        <glyph
          unicode="L"
          horizAdvX="599"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0z"
        />
        <glyph
          unicode="&#x1C7;"
          horizAdvX="1126"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M586,91l74,89C699,127 745,100 799,100C872,100 908,143 908,229l0,362l-249,0l0,109l379,0l0,-464C1038,154 1018,93 978,52C938,11 879,-10 802,-10C757,-10 716,-1 679,17C641,34 610,59 586,91z"
        />
        <glyph
          unicode="&#x139;"
          horizAdvX="599"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M210,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x13D;"
          horizAdvX="599"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M434,737l99,0l-16,-197l-83,0z"
        />
        <glyph
          unicode="&#x13B;"
          horizAdvX="599"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M414,-136C414,-155 409,-174 398,-195l-32,-70l-61,0l24,81C306,-177 294,-161 294,-136C294,-120 300,-107 311,-97C322,-88 336,-83 354,-83C372,-83 387,-88 398,-98C409,-108 414,-121 414,-136z"
        />
        <glyph
          unicode="&#x13F;"
          horizAdvX="599"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M386,404C386,425 393,443 407,458C421,472 438,479 459,479C480,479 497,472 511,458C524,443 531,425 531,404C531,382 524,364 511,350C497,336 480,329 459,329C438,329 421,336 407,350C393,364 386,382 386,404z"
        />
        <glyph
          unicode="&#x1E36;"
          horizAdvX="599"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M294,-144C294,-127 300,-112 311,-100C322,-89 337,-83 354,-83C371,-83 386,-89 397,-100C408,-112 414,-127 414,-144C414,-162 408,-176 397,-187C386,-198 371,-203 354,-203C337,-203 322,-198 311,-187C300,-176 294,-162 294,-144z"
        />
        <glyph
          unicode="&#x1C8;"
          horizAdvX="894"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M506,-170l36,93C561,-91 585,-98 615,-98C638,-98 656,-91 669,-76C681,-62 687,-41 687,-13l0,547l125,0l0,-544C812,-69 796,-116 763,-150C730,-184 685,-201 626,-201C573,-201 533,-191 506,-170M669,697C669,718 677,736 692,751C707,765 726,772 749,772C772,772 791,765 806,752C821,738 829,721 829,700C829,678 822,660 807,645C792,630 772,622 749,622C726,622 707,629 692,644C677,658 669,676 669,697z"
        />
        <glyph
          unicode="&#x1E3A;"
          horizAdvX="599"
          d="M94,700l130,0l0,-590l366,0l0,-110l-496,0M181,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="&#x141;"
          horizAdvX="609"
          d="M104,700l130,0l0,-590l366,0l0,-110l-496,0M-4,304l345,261l53,-68l-345,-262z"
        />
        <glyph
          unicode="M"
          horizAdvX="955"
          d="M737,0l-1,464l-230,-384l-58,0l-230,378l0,-458l-124,0l0,700l107,0l278,-464l273,464l107,0l1,-700z"
        />
        <glyph
          unicode="&#x1E42;"
          horizAdvX="955"
          d="M737,0l-1,464l-230,-384l-58,0l-230,378l0,-458l-124,0l0,700l107,0l278,-464l273,464l107,0l1,-700M417,-144C417,-127 423,-112 434,-100C445,-89 460,-83 477,-83C494,-83 509,-89 520,-100C531,-112 537,-127 537,-144C537,-162 531,-176 520,-187C509,-198 494,-203 477,-203C460,-203 445,-198 434,-187C423,-176 417,-162 417,-144z"
        />
        <glyph
          unicode="N"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474z"
        />
        <glyph
          unicode="&#x1CA;"
          horizAdvX="1337"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M797,91l74,89C910,127 956,100 1010,100C1083,100 1119,143 1119,229l0,362l-249,0l0,109l379,0l0,-464C1249,154 1229,93 1189,52C1149,11 1090,-10 1013,-10C968,-10 927,-1 890,17C852,34 821,59 797,91z"
        />
        <glyph
          unicode="&#x143;"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M456,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x147;"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M596,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x145;"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M465,-136C465,-155 460,-174 449,-195l-32,-70l-61,0l24,81C357,-177 345,-161 345,-136C345,-120 351,-107 362,-97C373,-88 387,-83 405,-83C423,-83 438,-88 449,-98C460,-108 465,-121 465,-136z"
        />
        <glyph
          unicode="&#x1E44;"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M334,840C334,861 341,878 354,891C367,904 384,911 405,911C426,911 443,904 456,891C469,878 476,861 476,840C476,819 469,802 456,789C443,776 426,769 405,769C384,769 367,776 354,789C341,802 334,819 334,840z"
        />
        <glyph
          unicode="&#x1E46;"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M345,-144C345,-127 351,-112 362,-100C373,-89 388,-83 405,-83C422,-83 437,-89 448,-100C459,-112 465,-127 465,-144C465,-162 459,-176 448,-187C437,-198 422,-203 405,-203C388,-203 373,-198 362,-187C351,-176 345,-162 345,-144z"
        />
        <glyph
          unicode="&#x14A;"
          horizAdvX="810"
          d="M716,700l0,-643C716,-30 697,-95 658,-137C619,-180 560,-201 483,-201C447,-201 413,-195 382,-182C350,-170 323,-152 300,-129l62,93C393,-73 433,-91 480,-91C549,-91 584,-52 587,27l-364,447l0,-474l-129,0l0,700l107,0l386,-474l0,474z"
        />
        <glyph
          unicode="&#x1CB;"
          horizAdvX="1105"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M717,-170l36,93C772,-91 796,-98 826,-98C849,-98 867,-91 880,-76C892,-62 898,-41 898,-13l0,547l125,0l0,-544C1023,-69 1007,-116 974,-150C941,-184 896,-201 837,-201C784,-201 744,-191 717,-170M880,697C880,718 888,736 903,751C918,765 937,772 960,772C983,772 1002,765 1017,752C1032,738 1040,721 1040,700C1040,678 1033,660 1018,645C1003,630 983,622 960,622C937,622 918,629 903,644C888,658 880,676 880,697z"
        />
        <glyph
          unicode="&#x1E48;"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M232,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="&#xD1;"
          horizAdvX="810"
          d="M716,700l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474l0,474M388,797C377,806 369,812 362,817C355,821 348,823 341,823C327,823 316,818 307,807C298,796 293,782 292,763l-67,0C226,804 236,836 256,861C275,885 301,897 333,897C350,897 364,894 377,887C389,880 404,870 422,857C433,848 442,842 449,838C456,833 462,831 469,831C483,831 495,836 504,846C513,856 517,870 518,888l67,0C584,849 573,817 554,793C535,769 509,757 477,757C460,757 446,760 434,767C421,774 406,784 388,797z"
        />
        <glyph
          unicode="O"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350z"
        />
        <glyph
          unicode="&#xD3;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M472,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x14E;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M248,894l82,0C331,871 340,853 357,840C374,826 396,819 421,819C446,819 468,826 485,840C502,853 511,871 512,894l82,0C592,850 575,815 543,789C510,763 470,750 421,750C372,750 332,763 300,789C267,815 250,850 248,894z"
        />
        <glyph
          unicode="&#xD4;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M509,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1ED0;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M508,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M644,966l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1ED8;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M361,-144C361,-127 367,-112 378,-100C389,-89 404,-83 421,-83C438,-83 453,-89 464,-100C475,-112 481,-127 481,-144C481,-162 475,-176 464,-187C453,-198 438,-203 421,-203C404,-203 389,-198 378,-187C367,-176 361,-162 361,-144M509,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1ED2;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M508,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M437,966l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1ED4;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M508,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M550,864C576,877 589,891 589,908C589,917 586,925 579,931C572,936 564,939 553,939C538,939 524,935 509,927l-22,42C508,983 533,990 564,990C590,990 611,983 626,970C641,956 648,938 648,915C648,896 642,879 630,864C618,849 602,838 582,830z"
        />
        <glyph
          unicode="&#x1ED6;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M508,757l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M402,932C391,939 383,944 376,947C369,950 363,952 356,952C328,952 313,936 312,904l-68,0C245,940 255,968 274,988C293,1008 318,1018 350,1018C367,1018 382,1015 395,1010C408,1004 423,996 438,986C450,979 460,973 467,970C474,967 481,965 488,965C501,965 512,969 519,978C526,986 531,997 532,1012l66,0C597,977 588,949 569,929C550,908 525,898 494,898C476,898 460,901 447,907C433,913 418,921 402,932z"
        />
        <glyph
          unicode="&#x20C;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M171,894l125,0l80,-137l-98,0M358,894l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xD6;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M264,828C264,846 270,861 281,872C292,883 307,889 324,889C341,889 356,883 367,872C378,861 384,846 384,828C384,811 378,796 367,785C356,774 341,768 324,768C307,768 292,774 281,785C270,796 264,811 264,828M458,828C458,846 464,861 475,872C486,883 501,889 518,889C535,889 550,883 561,872C572,861 578,846 578,828C578,811 572,796 561,785C550,774 535,768 518,768C501,768 486,774 475,785C464,796 458,811 458,828z"
        />
        <glyph
          unicode="&#x22A;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M269,826C269,843 275,857 286,868C297,879 311,884 327,884C344,884 358,879 369,868C380,857 385,843 385,826C385,809 380,796 369,785C358,774 344,768 327,768C311,768 297,774 286,785C275,796 269,809 269,826M458,826C458,843 464,857 475,868C486,879 500,884 516,884C532,884 546,878 557,867C568,856 574,842 574,826C574,809 568,796 557,785C546,774 532,768 516,768C500,768 486,774 475,785C464,796 458,809 458,826M248,998l346,0l0,-70l-346,0z"
        />
        <glyph
          unicode="&#x230;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M361,827C361,845 367,860 378,871C389,882 404,887 421,887C438,887 453,882 464,871C475,860 481,845 481,827C481,809 475,795 464,784C453,773 438,767 421,767C404,767 389,773 378,784C367,795 361,809 361,827M248,1002l346,0l0,-70l-346,0z"
        />
        <glyph
          unicode="&#x1ECC;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M361,-144C361,-127 367,-112 378,-100C389,-89 404,-83 421,-83C438,-83 453,-89 464,-100C475,-112 481,-127 481,-144C481,-162 475,-176 464,-187C453,-198 438,-203 421,-203C404,-203 389,-198 378,-187C367,-176 361,-162 361,-144z"
        />
        <glyph
          unicode="&#xD2;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M223,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1ECE;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M405,793C424,802 438,811 447,822C455,832 459,844 459,857C459,870 454,880 445,889C436,897 424,901 409,901C388,901 368,895 349,882l-27,55C335,947 351,955 368,960C385,965 403,968 422,968C454,968 480,959 501,941C522,922 532,898 532,868C532,814 503,774 446,747z"
        />
        <glyph
          unicode="&#x1A0;"
          horizAdvX="842"
          d="M798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350C44,419 61,480 94,535C127,590 172,633 230,664C288,695 353,710 426,710C457,710 494,707 538,702C573,698 598,696 613,696C642,696 664,702 679,714C694,725 702,742 702,765C702,786 695,806 682,827l76,28C778,827 788,796 788,761C788,724 778,694 758,671C737,648 708,632 671,624C710,593 741,553 764,506C787,458 798,406 798,350M667,350C667,397 656,439 635,476C614,513 584,543 547,564C510,585 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,187 635,224C656,261 667,303 667,350z"
        />
        <glyph
          unicode="&#x1EDA;"
          horizAdvX="842"
          d="M798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350C44,419 61,480 94,535C127,590 172,633 230,664C288,695 353,710 426,710C457,710 494,707 538,702C573,698 598,696 613,696C642,696 664,702 679,714C694,725 702,742 702,765C702,786 695,806 682,827l76,28C778,827 788,796 788,761C788,724 778,694 758,671C737,648 708,632 671,624C710,593 741,553 764,506C787,458 798,406 798,350M667,350C667,397 656,439 635,476C614,513 584,543 547,564C510,585 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,187 635,224C656,261 667,303 667,350M472,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EE2;"
          horizAdvX="842"
          d="M798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350C44,419 61,480 94,535C127,590 172,633 230,664C288,695 353,710 426,710C457,710 494,707 538,702C573,698 598,696 613,696C642,696 664,702 679,714C694,725 702,742 702,765C702,786 695,806 682,827l76,28C778,827 788,796 788,761C788,724 778,694 758,671C737,648 708,632 671,624C710,593 741,553 764,506C787,458 798,406 798,350M667,350C667,397 656,439 635,476C614,513 584,543 547,564C510,585 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,187 635,224C656,261 667,303 667,350M361,-144C361,-127 367,-112 378,-100C389,-89 404,-83 421,-83C438,-83 453,-89 464,-100C475,-112 481,-127 481,-144C481,-162 475,-176 464,-187C453,-198 438,-203 421,-203C404,-203 389,-198 378,-187C367,-176 361,-162 361,-144z"
        />
        <glyph
          unicode="&#x1EDC;"
          horizAdvX="842"
          d="M798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350C44,419 61,480 94,535C127,590 172,633 230,664C288,695 353,710 426,710C457,710 494,707 538,702C573,698 598,696 613,696C642,696 664,702 679,714C694,725 702,742 702,765C702,786 695,806 682,827l76,28C778,827 788,796 788,761C788,724 778,694 758,671C737,648 708,632 671,624C710,593 741,553 764,506C787,458 798,406 798,350M667,350C667,397 656,439 635,476C614,513 584,543 547,564C510,585 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,187 635,224C656,261 667,303 667,350M223,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EDE;"
          horizAdvX="842"
          d="M798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350C44,419 61,480 94,535C127,590 172,633 230,664C288,695 353,710 426,710C457,710 494,707 538,702C573,698 598,696 613,696C642,696 664,702 679,714C694,725 702,742 702,765C702,786 695,806 682,827l76,28C778,827 788,796 788,761C788,724 778,694 758,671C737,648 708,632 671,624C710,593 741,553 764,506C787,458 798,406 798,350M667,350C667,397 656,439 635,476C614,513 584,543 547,564C510,585 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,187 635,224C656,261 667,303 667,350M405,793C424,802 438,811 447,822C455,832 459,844 459,857C459,870 454,880 445,889C436,897 424,901 409,901C388,901 368,895 349,882l-27,55C335,947 351,955 368,960C385,965 403,968 422,968C454,968 480,959 501,941C522,922 532,898 532,868C532,814 503,774 446,747z"
        />
        <glyph
          unicode="&#x1EE0;"
          horizAdvX="842"
          d="M798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350C44,419 61,480 94,535C127,590 172,633 230,664C288,695 353,710 426,710C457,710 494,707 538,702C573,698 598,696 613,696C642,696 664,702 679,714C694,725 702,742 702,765C702,786 695,806 682,827l76,28C778,827 788,796 788,761C788,724 778,694 758,671C737,648 708,632 671,624C710,593 741,553 764,506C787,458 798,406 798,350M667,350C667,397 656,439 635,476C614,513 584,543 547,564C510,585 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,187 635,224C656,261 667,303 667,350M404,797C393,806 385,812 378,817C371,821 364,823 357,823C343,823 332,818 323,807C314,796 309,782 308,763l-67,0C242,804 252,836 272,861C291,885 317,897 349,897C366,897 380,894 393,887C405,880 420,870 438,857C449,848 458,842 465,838C472,833 478,831 485,831C499,831 511,836 520,846C529,856 533,870 534,888l67,0C600,849 589,817 570,793C551,769 525,757 493,757C476,757 462,760 450,767C437,774 422,784 404,797z"
        />
        <glyph
          unicode="&#x150;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M359,894l125,0l-108,-137l-98,0M546,894l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#x20E;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M421,901C470,901 510,888 543,862C575,836 592,801 594,757l-82,0C511,780 502,799 485,813C468,826 446,833 421,833C396,833 374,826 357,813C340,799 331,780 330,757l-82,0C250,801 267,836 300,862C332,888 372,901 421,901z"
        />
        <glyph
          unicode="&#x14C;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M248,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E52;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M248,846l346,0l0,-70l-346,0M458,1026l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1E50;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M248,846l346,0l0,-70l-346,0M251,1026l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EA;"
          horizAdvX="842"
          d="M798,350C798,293 786,240 763,193C740,146 708,106 668,74C628,42 583,19 533,6C488,-7 453,-23 428,-44C403,-65 390,-87 390,-111C390,-126 395,-138 406,-147C417,-156 431,-160 449,-160C472,-160 493,-155 510,-144l21,-61C504,-220 472,-228 435,-228C395,-228 364,-218 341,-198C318,-179 306,-153 306,-121C306,-76 327,-38 370,-7C307,0 251,20 202,53C152,85 113,127 86,179C58,230 44,287 44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350z"
        />
        <glyph
          unicode="&#xD8;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M675,770l93,0l-600,-840l-93,0z"
        />
        <glyph
          unicode="&#x1FE;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M675,770l93,0l-600,-840l-93,0M472,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#xD5;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M404,797C393,806 385,812 378,817C371,821 364,823 357,823C343,823 332,818 323,807C314,796 309,782 308,763l-67,0C242,804 252,836 272,861C291,885 317,897 349,897C366,897 380,894 393,887C405,880 420,870 438,857C449,848 458,842 465,838C472,833 478,831 485,831C499,831 511,836 520,846C529,856 533,870 534,888l67,0C600,849 589,817 570,793C551,769 525,757 493,757C476,757 462,760 450,767C437,774 422,784 404,797z"
        />
        <glyph
          unicode="&#x1E4C;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M402,792C391,799 383,804 376,807C369,810 363,812 356,812C328,812 313,796 312,764l-68,0C245,800 255,828 274,848C293,868 318,878 350,878C367,878 382,875 395,870C408,864 423,856 438,846C450,839 460,833 467,830C474,827 481,825 488,825C501,825 512,829 519,838C526,846 531,857 532,872l66,0C597,837 588,809 569,789C550,768 525,758 494,758C476,758 460,761 447,767C433,773 418,781 402,792M458,1026l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1E4E;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M402,792C391,799 383,804 376,807C369,810 363,812 356,812C328,812 313,796 312,764l-68,0C245,800 255,828 274,848C293,868 318,878 350,878C367,878 382,875 395,870C408,864 423,856 438,846C450,839 460,833 467,830C474,827 481,825 488,825C501,825 512,829 519,838C526,846 531,857 532,872l66,0C597,837 588,809 569,789C550,768 525,758 494,758C476,758 460,761 447,767C433,773 418,781 402,792M269,978C269,995 275,1009 286,1020C297,1031 311,1036 327,1036C344,1036 358,1031 369,1020C380,1009 385,995 385,978C385,961 380,948 369,937C358,926 344,920 327,920C311,920 297,926 286,937C275,948 269,961 269,978M458,978C458,995 464,1009 475,1020C486,1031 500,1036 516,1036C532,1036 546,1030 557,1019C568,1008 574,994 574,978C574,961 568,948 557,937C546,926 532,920 516,920C500,920 486,926 475,937C464,948 458,961 458,978z"
        />
        <glyph
          unicode="&#x22C;"
          horizAdvX="842"
          d="M44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,281 782,220 749,165C716,110 671,68 614,37C557,6 492,-10 421,-10C350,-10 285,6 228,37C171,68 126,111 93,166C60,221 44,282 44,350M667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350M402,792C391,799 383,804 376,807C369,810 363,812 356,812C328,812 313,796 312,764l-68,0C245,800 255,828 274,848C293,868 318,878 350,878C367,878 382,875 395,870C408,864 423,856 438,846C450,839 460,833 467,830C474,827 481,825 488,825C501,825 512,829 519,838C526,846 531,857 532,872l66,0C597,837 588,809 569,789C550,768 525,758 494,758C476,758 460,761 447,767C433,773 418,781 402,792M248,998l346,0l0,-70l-346,0z"
        />
        <glyph
          unicode="&#x152;"
          horizAdvX="1131"
          d="M1079,109l0,-109l-653,0C351,0 285,15 227,44C169,73 124,114 92,167C60,220 44,280 44,349C44,418 60,480 92,533C124,586 169,627 227,656C284,685 351,700 426,700l639,0l0,-109l-381,0l0,-182l339,0l0,-107l-339,0l0,-193M555,110l0,480l-123,0C381,590 336,580 297,560C258,540 228,512 207,476C186,439 175,397 175,349C175,302 186,260 207,224C228,187 258,159 297,140C336,120 381,110 432,110z"
        />
        <glyph
          unicode="P"
          horizAdvX="726"
          d="M680,451C680,400 668,356 644,319C620,281 586,252 541,232C496,212 443,202 382,202l-158,0l0,-202l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451M549,451C549,496 534,530 505,554C476,578 433,590 376,590l-152,0l0,-278l152,0C433,312 476,324 505,348C534,372 549,406 549,451z"
        />
        <glyph
          unicode="&#xDE;"
          horizAdvX="726"
          d="M680,369C680,318 668,274 644,237C620,199 586,170 541,150C496,130 443,120 382,120l-158,0l0,-120l-130,0l0,700l130,0l0,-82l158,0C443,618 496,608 541,588C586,568 620,539 644,502C668,464 680,420 680,369M549,369C549,414 534,448 505,472C476,496 433,508 376,508l-152,0l0,-278l152,0C433,230 476,242 505,266C534,290 549,324 549,369z"
        />
        <glyph
          unicode="Q"
          horizAdvX="842"
          d="M833,-70C810,-97 783,-118 752,-132C721,-146 687,-153 650,-153C602,-153 558,-142 518,-120C478,-99 432,-61 381,-8C316,-3 258,16 207,49C156,81 116,123 87,176C58,228 44,286 44,350C44,418 60,480 93,535C126,590 171,633 228,664C285,695 350,710 421,710C492,710 557,695 614,664C671,633 716,590 749,535C782,480 798,419 798,350C798,295 787,244 765,197C743,150 712,110 673,77C634,44 588,20 536,5C555,-16 574,-31 593,-40C612,-49 632,-54 653,-54C699,-54 739,-35 773,2M421,104C468,104 510,115 547,136C584,157 614,186 635,224C656,261 667,303 667,350C667,397 656,439 635,477C614,514 584,544 547,565C510,586 468,596 421,596C374,596 332,586 295,565C258,544 228,514 207,477C186,439 175,397 175,350C175,303 186,261 207,224C228,186 258,157 295,136C332,115 374,104 421,104z"
        />
        <glyph
          unicode="R"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590z"
        />
        <glyph
          unicode="&#x154;"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590M424,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x158;"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590M564,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x156;"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590M433,-136C433,-155 428,-174 417,-195l-32,-70l-61,0l24,81C325,-177 313,-161 313,-136C313,-120 319,-107 330,-97C341,-88 355,-83 373,-83C391,-83 406,-88 417,-98C428,-108 433,-121 433,-136z"
        />
        <glyph
          unicode="&#x210;"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590M123,894l125,0l80,-137l-98,0M310,894l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#x1E5A;"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590M313,-144C313,-127 319,-112 330,-100C341,-89 356,-83 373,-83C390,-83 405,-89 416,-100C427,-112 433,-127 433,-144C433,-162 427,-176 416,-187C405,-198 390,-203 373,-203C356,-203 341,-198 330,-187C319,-176 313,-162 313,-144z"
        />
        <glyph
          unicode="&#x212;"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590M373,901C422,901 462,888 495,862C527,836 544,801 546,757l-82,0C463,780 454,799 437,813C420,826 398,833 373,833C348,833 326,826 309,813C292,799 283,780 282,757l-82,0C202,801 219,836 252,862C284,888 324,901 373,901z"
        />
        <glyph
          unicode="&#x1E5E;"
          horizAdvX="731"
          d="M552,0l-143,205C403,204 394,204 382,204l-158,0l0,-204l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451C680,398 667,353 642,315C616,277 579,249 531,230l161,-230M376,590l-152,0l0,-279l152,0C433,311 476,323 505,348C534,372 549,406 549,451C549,496 534,530 505,554C476,578 433,590 376,590M200,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="S"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74z"
        />
        <glyph
          unicode="&#x15A;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M375,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x1E64;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M361,874l134,0l-152,-117l-96,0M264,979C264,997 270,1012 281,1023C292,1034 307,1039 324,1039C341,1039 356,1034 367,1023C378,1012 384,997 384,979C384,961 378,947 367,936C356,925 341,919 324,919C307,919 292,925 281,936C270,947 264,961 264,979z"
        />
        <glyph
          unicode="&#xA78B;"
          horizAdvX="277"
          d="M74,700l129,0l-17,-403l-112,0z"
        />
        <glyph
          unicode="&#x160;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M515,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x1E66;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M515,864l-135,-107l-112,0l-135,107l104,0l87,-55l87,55M264,956C264,974 270,989 281,1000C292,1011 307,1016 324,1016C341,1016 356,1011 367,1000C378,989 384,974 384,956C384,938 378,924 367,913C356,902 341,896 324,896C307,896 292,902 281,913C270,924 264,938 264,956z"
        />
        <glyph
          unicode="&#x15E;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M203,-207l23,59C247,-159 269,-164 294,-164C329,-164 347,-152 347,-128C347,-118 343,-110 334,-104C325,-98 312,-95 294,-95l-31,0l27,102l68,0l-14,-56C373,-52 394,-61 409,-76C424,-91 431,-110 431,-133C431,-162 419,-185 394,-202C369,-219 336,-228 295,-228C259,-228 228,-221 203,-207z"
        />
        <glyph
          unicode="&#x15C;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M412,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x218;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M384,-136C384,-155 379,-174 368,-195l-32,-70l-61,0l24,81C276,-177 264,-161 264,-136C264,-120 270,-107 281,-97C292,-88 306,-83 324,-83C342,-83 357,-88 368,-98C379,-108 384,-121 384,-136z"
        />
        <glyph
          unicode="&#x1E60;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M253,840C253,861 260,878 273,891C286,904 303,911 324,911C345,911 362,904 375,891C388,878 395,861 395,840C395,819 388,802 375,789C362,776 345,769 324,769C303,769 286,776 273,789C260,802 253,819 253,840z"
        />
        <glyph
          unicode="&#x1E62;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M264,-144C264,-127 270,-112 281,-100C292,-89 307,-83 324,-83C341,-83 356,-89 367,-100C378,-112 384,-127 384,-144C384,-162 378,-176 367,-187C356,-198 341,-203 324,-203C307,-203 292,-198 281,-187C270,-176 264,-162 264,-144z"
        />
        <glyph
          unicode="&#x1E68;"
          horizAdvX="629"
          d="M35,74l45,101C109,152 144,134 186,120C227,105 269,98 311,98C363,98 402,106 428,123C453,140 466,162 466,189C466,209 459,226 445,239C430,252 412,262 390,269C368,276 338,285 300,294C247,307 204,319 171,332C138,345 109,365 86,392C62,419 50,455 50,501C50,540 61,575 82,607C103,638 134,663 177,682C219,701 271,710 332,710C375,710 417,705 458,694C499,683 535,668 565,648l-41,-101C493,565 461,579 428,588C395,597 362,602 331,602C280,602 242,593 217,576C192,559 179,536 179,507C179,487 186,471 201,458C215,445 233,435 255,428C277,421 307,412 345,403C397,391 440,379 473,366C506,353 535,333 559,306C582,279 594,243 594,198C594,159 584,124 563,93C542,62 510,37 467,18C424,-1 372,-10 311,-10C257,-10 205,-2 155,13C104,28 64,49 35,74M264,-144C264,-127 270,-112 281,-100C292,-89 307,-83 324,-83C341,-83 356,-89 367,-100C378,-112 384,-127 384,-144C384,-162 378,-176 367,-187C356,-198 341,-203 324,-203C307,-203 292,-198 281,-187C270,-176 264,-162 264,-144M253,840C253,861 260,878 273,891C286,904 303,911 324,911C345,911 362,904 375,891C388,878 395,861 395,840C395,819 388,802 375,789C362,776 345,769 324,769C303,769 286,776 273,789C260,802 253,819 253,840z"
        />
        <glyph
          unicode="&#x1E9E;"
          horizAdvX="771"
          d="M726,204C726,159 715,121 694,89C672,56 642,32 605,15C567,-2 524,-10 477,-10C418,-10 368,-2 328,15l20,105C381,105 417,98 458,98C501,98 535,107 559,126C582,145 594,172 594,209C594,245 582,273 558,292C534,311 501,321 458,321C429,321 402,317 379,310l-23,59l185,213C502,597 461,604 417,604C353,604 304,586 270,550C235,514 218,462 218,395l0,-395l-130,0l0,398C88,463 101,519 127,566C152,613 190,649 239,674C288,699 346,712 414,712C459,712 504,706 548,695C591,683 628,666 658,644l0,-82l-129,-146C588,408 635,386 672,349C708,312 726,263 726,204z"
        />
        <glyph
          unicode="&#x18F;"
          horizAdvX="817"
          d="M773,351C773,282 757,221 726,166C694,111 650,68 595,37C540,6 478,-10 409,-10C340,-10 277,6 222,37C166,68 122,112 91,168C60,223 44,286 44,356l0,32l596,0C635,428 622,464 600,496C577,527 549,552 514,570C479,587 442,596 401,596C360,596 321,589 284,575C246,561 214,541 188,514l-78,88C143,635 186,662 237,681C288,700 343,710 402,710C473,710 536,695 593,664C650,633 694,590 726,535C757,480 773,419 773,351M637,286l-458,0C190,228 216,182 257,149C298,116 348,99 409,99C470,99 521,116 562,150C602,183 627,229 637,286z"
        />
        <glyph
          unicode="T"
          horizAdvX="602"
          d="M236,590l-232,0l0,110l594,0l0,-110l-232,0l0,-590l-130,0z"
        />
        <glyph
          unicode="&#x166;"
          horizAdvX="602"
          d="M236,590l-232,0l0,110l594,0l0,-110l-232,0l0,-590l-130,0M93,381l416,0l0,-96l-416,0z"
        />
        <glyph
          unicode="&#x164;"
          horizAdvX="602"
          d="M236,590l-232,0l0,110l594,0l0,-110l-232,0l0,-590l-130,0M492,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x162;"
          horizAdvX="602"
          d="M236,590l-232,0l0,110l594,0l0,-110l-232,0l0,-590l-130,0M180,-207l23,59C224,-159 246,-164 271,-164C306,-164 324,-152 324,-128C324,-118 320,-110 311,-104C302,-98 289,-95 271,-95l-31,0l27,102l68,0l-14,-56C350,-52 371,-61 386,-76C401,-91 408,-110 408,-133C408,-162 396,-185 371,-202C346,-219 313,-228 272,-228C236,-228 205,-221 180,-207z"
        />
        <glyph
          unicode="&#x21A;"
          horizAdvX="602"
          d="M236,590l-232,0l0,110l594,0l0,-110l-232,0l0,-590l-130,0M361,-136C361,-155 356,-174 345,-195l-32,-70l-61,0l24,81C253,-177 241,-161 241,-136C241,-120 247,-107 258,-97C269,-88 283,-83 301,-83C319,-83 334,-88 345,-98C356,-108 361,-121 361,-136z"
        />
        <glyph
          unicode="&#x1E6C;"
          horizAdvX="602"
          d="M236,590l-232,0l0,110l594,0l0,-110l-232,0l0,-590l-130,0M241,-144C241,-127 247,-112 258,-100C269,-89 284,-83 301,-83C318,-83 333,-89 344,-100C355,-112 361,-127 361,-144C361,-162 355,-176 344,-187C333,-198 318,-203 301,-203C284,-203 269,-198 258,-187C247,-176 241,-162 241,-144z"
        />
        <glyph
          unicode="&#x1E6E;"
          horizAdvX="602"
          d="M236,590l-232,0l0,110l594,0l0,-110l-232,0l0,-590l-130,0M128,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="U"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305z"
        />
        <glyph
          unicode="&#xDA;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M447,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x16C;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M223,894l82,0C306,871 315,853 332,840C349,826 371,819 396,819C421,819 443,826 460,840C477,853 486,871 487,894l82,0C567,850 550,815 518,789C485,763 445,750 396,750C347,750 307,763 275,789C242,815 225,850 223,894z"
        />
        <glyph
          unicode="&#x1D3;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M587,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xDB;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M484,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x214;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M146,894l125,0l80,-137l-98,0M333,894l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xDC;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M239,828C239,846 245,861 256,872C267,883 282,889 299,889C316,889 331,883 342,872C353,861 359,846 359,828C359,811 353,796 342,785C331,774 316,768 299,768C282,768 267,774 256,785C245,796 239,811 239,828M433,828C433,846 439,861 450,872C461,883 476,889 493,889C510,889 525,883 536,872C547,861 553,846 553,828C553,811 547,796 536,785C525,774 510,768 493,768C476,768 461,774 450,785C439,796 433,811 433,828z"
        />
        <glyph
          unicode="&#x1EE4;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M336,-144C336,-127 342,-112 353,-100C364,-89 379,-83 396,-83C413,-83 428,-89 439,-100C450,-112 456,-127 456,-144C456,-162 450,-176 439,-187C428,-198 413,-203 396,-203C379,-203 364,-198 353,-187C342,-176 336,-162 336,-144z"
        />
        <glyph
          unicode="&#xD9;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M198,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EE6;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M380,793C399,802 413,811 422,822C430,832 434,844 434,857C434,870 429,880 420,889C411,897 399,901 384,901C363,901 343,895 324,882l-27,55C310,947 326,955 343,960C360,965 378,968 397,968C429,968 455,959 476,941C497,922 507,898 507,868C507,814 478,774 421,747z"
        />
        <glyph
          unicode="&#x1AF;"
          horizAdvX="799"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M636,700l21,0C676,700 692,706 703,718C714,729 719,745 719,764C719,787 712,808 699,826l77,28C796,829 806,797 806,760C806,714 793,678 766,651C739,624 703,611 656,611l-20,0z"
        />
        <glyph
          unicode="&#x1EE8;"
          horizAdvX="799"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M636,700l21,0C676,700 692,706 703,718C714,729 719,745 719,764C719,787 712,808 699,826l77,28C796,829 806,797 806,760C806,714 793,678 766,651C739,624 703,611 656,611l-20,0M447,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EF0;"
          horizAdvX="799"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M636,700l21,0C676,700 692,706 703,718C714,729 719,745 719,764C719,787 712,808 699,826l77,28C796,829 806,797 806,760C806,714 793,678 766,651C739,624 703,611 656,611l-20,0M336,-144C336,-127 342,-112 353,-100C364,-89 379,-83 396,-83C413,-83 428,-89 439,-100C450,-112 456,-127 456,-144C456,-162 450,-176 439,-187C428,-198 413,-203 396,-203C379,-203 364,-198 353,-187C342,-176 336,-162 336,-144z"
        />
        <glyph
          unicode="&#x1EEA;"
          horizAdvX="799"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M636,700l21,0C676,700 692,706 703,718C714,729 719,745 719,764C719,787 712,808 699,826l77,28C796,829 806,797 806,760C806,714 793,678 766,651C739,624 703,611 656,611l-20,0M198,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EEC;"
          horizAdvX="799"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M636,700l21,0C676,700 692,706 703,718C714,729 719,745 719,764C719,787 712,808 699,826l77,28C796,829 806,797 806,760C806,714 793,678 766,651C739,624 703,611 656,611l-20,0M380,793C399,802 413,811 422,822C430,832 434,844 434,857C434,870 429,880 420,889C411,897 399,901 384,901C363,901 343,895 324,882l-27,55C310,947 326,955 343,960C360,965 378,968 397,968C429,968 455,959 476,941C497,922 507,898 507,868C507,814 478,774 421,747z"
        />
        <glyph
          unicode="&#x1EEE;"
          horizAdvX="799"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M636,700l21,0C676,700 692,706 703,718C714,729 719,745 719,764C719,787 712,808 699,826l77,28C796,829 806,797 806,760C806,714 793,678 766,651C739,624 703,611 656,611l-20,0M379,797C368,806 360,812 353,817C346,821 339,823 332,823C318,823 307,818 298,807C289,796 284,782 283,763l-67,0C217,804 227,836 247,861C266,885 292,897 324,897C341,897 355,894 368,887C380,880 395,870 413,857C424,848 433,842 440,838C447,833 453,831 460,831C474,831 486,836 495,846C504,856 508,870 509,888l67,0C575,849 564,817 545,793C526,769 500,757 468,757C451,757 437,760 425,767C412,774 397,784 379,797z"
        />
        <glyph
          unicode="&#x170;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M334,894l125,0l-108,-137l-98,0M521,894l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#x216;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M396,901C445,901 485,888 518,862C550,836 567,801 569,757l-82,0C486,780 477,799 460,813C443,826 421,833 396,833C371,833 349,826 332,813C315,799 306,780 305,757l-82,0C225,801 242,836 275,862C307,888 347,901 396,901z"
        />
        <glyph
          unicode="&#x16A;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M223,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E7A;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M223,847l346,0l0,-70l-346,0M244,979C244,996 250,1010 261,1021C272,1032 286,1037 302,1037C319,1037 333,1032 344,1021C355,1010 360,996 360,979C360,962 355,949 344,938C333,927 319,921 302,921C286,921 272,927 261,938C250,949 244,962 244,979M433,979C433,996 439,1010 450,1021C461,1032 475,1037 491,1037C507,1037 521,1031 532,1020C543,1009 549,995 549,979C549,962 543,949 532,938C521,927 507,921 491,921C475,921 461,927 450,938C439,949 433,962 433,979z"
        />
        <glyph
          unicode="&#x172;"
          horizAdvX="789"
          d="M701,700l0,-395C701,226 685,162 653,113C621,64 576,29 517,8C423,-27 376,-66 376,-111C376,-126 381,-138 392,-147C403,-156 417,-160 435,-160C446,-160 457,-158 468,-155C479,-152 488,-149 496,-144l21,-61C504,-212 490,-218 473,-222C456,-226 438,-228 421,-228C381,-228 350,-218 327,-198C304,-179 292,-153 292,-121C292,-78 313,-41 355,-8C270,-1 205,30 158,84C111,137 88,211 88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390z"
        />
        <glyph
          unicode="&#x16E;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M279,873C279,905 290,932 313,955C335,978 362,989 395,989C428,989 456,978 479,955C501,932 512,905 512,873C512,841 501,814 479,792C456,770 428,759 395,759C362,759 335,770 313,792C290,814 279,841 279,873M459,873C459,892 453,907 441,920C428,932 413,938 395,938C377,938 362,932 350,920C338,907 332,892 332,873C332,855 338,840 350,828C362,816 377,810 395,810C414,810 429,816 441,828C453,840 459,855 459,873z"
        />
        <glyph
          unicode="&#x168;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M379,797C368,806 360,812 353,817C346,821 339,823 332,823C318,823 307,818 298,807C289,796 284,782 283,763l-67,0C217,804 227,836 247,861C266,885 292,897 324,897C341,897 355,894 368,887C380,880 395,870 413,857C424,848 433,842 440,838C447,833 453,831 460,831C474,831 486,836 495,846C504,856 508,870 509,888l67,0C575,849 564,817 545,793C526,769 500,757 468,757C451,757 437,760 425,767C412,774 397,784 379,797z"
        />
        <glyph
          unicode="&#x1E78;"
          horizAdvX="789"
          d="M88,305l0,395l130,0l0,-390C218,173 277,104 396,104C514,104 573,173 573,310l0,390l128,0l0,-395C701,204 674,126 621,72C567,17 492,-10 395,-10C298,-10 223,17 169,72C115,126 88,204 88,305M377,792C366,799 358,804 351,807C344,810 338,812 331,812C303,812 288,796 287,764l-68,0C220,800 230,828 249,848C268,868 293,878 325,878C342,878 357,875 370,870C383,864 398,856 413,846C425,839 435,833 442,830C449,827 456,825 463,825C476,825 487,829 494,838C501,846 506,857 507,872l66,0C572,837 563,809 544,789C525,768 500,758 469,758C451,758 435,761 422,767C408,773 393,781 377,792M433,1026l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="V"
          horizAdvX="729"
          d="M734,700l-305,-700l-128,0l-306,700l141,0l233,-540l235,540z"
        />
        <glyph
          unicode="W"
          horizAdvX="1144"
          d="M1117,700l-232,-700l-138,0l-173,518l-176,-518l-139,0l-232,700l135,0l174,-532l181,532l120,0l177,-535l179,535z"
        />
        <glyph
          unicode="&#x1E82;"
          horizAdvX="1144"
          d="M1117,700l-232,-700l-138,0l-173,518l-176,-518l-139,0l-232,700l135,0l174,-532l181,532l120,0l177,-535l179,535M624,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x174;"
          horizAdvX="1144"
          d="M1117,700l-232,-700l-138,0l-173,518l-176,-518l-139,0l-232,700l135,0l174,-532l181,532l120,0l177,-535l179,535M661,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1E84;"
          horizAdvX="1144"
          d="M1117,700l-232,-700l-138,0l-173,518l-176,-518l-139,0l-232,700l135,0l174,-532l181,532l120,0l177,-535l179,535M416,828C416,846 422,861 433,872C444,883 459,889 476,889C493,889 508,883 519,872C530,861 536,846 536,828C536,811 530,796 519,785C508,774 493,768 476,768C459,768 444,774 433,785C422,796 416,811 416,828M610,828C610,846 616,861 627,872C638,883 653,889 670,889C687,889 702,883 713,872C724,861 730,846 730,828C730,811 724,796 713,785C702,774 687,768 670,768C653,768 638,774 627,785C616,796 610,811 610,828z"
        />
        <glyph
          unicode="&#x1E80;"
          horizAdvX="1144"
          d="M1117,700l-232,-700l-138,0l-173,518l-176,-518l-139,0l-232,700l135,0l174,-532l181,532l120,0l177,-535l179,535M375,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="X"
          horizAdvX="693"
          d="M535,0l-190,266l-188,-266l-148,0l260,357l-246,343l147,0l179,-249l177,249l141,0l-245,-338l263,-362z"
        />
        <glyph
          unicode="Y"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0z"
        />
        <glyph
          unicode="&#xDD;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M382,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x176;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M419,757l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x178;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M174,828C174,846 180,861 191,872C202,883 217,889 234,889C251,889 266,883 277,872C288,861 294,846 294,828C294,811 288,796 277,785C266,774 251,768 234,768C217,768 202,774 191,785C180,796 174,811 174,828M368,828C368,846 374,861 385,872C396,883 411,889 428,889C445,889 460,883 471,872C482,861 488,846 488,828C488,811 482,796 471,785C460,774 445,768 428,768C411,768 396,774 385,785C374,796 368,811 368,828z"
        />
        <glyph
          unicode="&#x1E8E;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M260,840C260,861 267,878 280,891C293,904 310,911 331,911C352,911 369,904 382,891C395,878 402,861 402,840C402,819 395,802 382,789C369,776 352,769 331,769C310,769 293,776 280,789C267,802 260,819 260,840z"
        />
        <glyph
          unicode="&#x1EF4;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M271,-144C271,-127 277,-112 288,-100C299,-89 314,-83 331,-83C348,-83 363,-89 374,-100C385,-112 391,-127 391,-144C391,-162 385,-176 374,-187C363,-198 348,-203 331,-203C314,-203 299,-198 288,-187C277,-176 271,-162 271,-144z"
        />
        <glyph
          unicode="&#x1EF2;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M133,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EF6;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M315,793C334,802 348,811 357,822C365,832 369,844 369,857C369,870 364,880 355,889C346,897 334,901 319,901C298,901 278,895 259,882l-27,55C245,947 261,955 278,960C295,965 313,968 332,968C364,968 390,959 411,941C432,922 442,898 442,868C442,814 413,774 356,747z"
        />
        <glyph
          unicode="&#x232;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M158,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1EF8;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M314,797C303,806 295,812 288,817C281,821 274,823 267,823C253,823 242,818 233,807C224,796 219,782 218,763l-67,0C152,804 162,836 182,861C201,885 227,897 259,897C276,897 290,894 303,887C315,880 330,870 348,857C359,848 368,842 375,838C382,833 388,831 395,831C409,831 421,836 430,846C439,856 443,870 444,888l67,0C510,849 499,817 480,793C461,769 435,757 403,757C386,757 372,760 360,767C347,774 332,784 314,797z"
        />
        <glyph
          unicode="Z"
          horizAdvX="663"
          d="M638,110l0,-110l-597,0l0,87l412,503l-405,0l0,110l577,0l0,-87l-412,-503z"
        />
        <glyph
          unicode="&#x179;"
          horizAdvX="663"
          d="M638,110l0,-110l-597,0l0,87l412,503l-405,0l0,110l577,0l0,-87l-412,-503M391,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x17D;"
          horizAdvX="663"
          d="M638,110l0,-110l-597,0l0,87l412,503l-405,0l0,110l577,0l0,-87l-412,-503M531,894l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x17B;"
          horizAdvX="663"
          d="M638,110l0,-110l-597,0l0,87l412,503l-405,0l0,110l577,0l0,-87l-412,-503M269,840C269,861 276,878 289,891C302,904 319,911 340,911C361,911 378,904 391,891C404,878 411,861 411,840C411,819 404,802 391,789C378,776 361,769 340,769C319,769 302,776 289,789C276,802 269,819 269,840z"
        />
        <glyph
          unicode="&#x1E92;"
          horizAdvX="663"
          d="M638,110l0,-110l-597,0l0,87l412,503l-405,0l0,110l577,0l0,-87l-412,-503M284,-144C284,-127 290,-112 301,-100C312,-89 327,-83 344,-83C361,-83 376,-89 387,-100C398,-112 404,-127 404,-144C404,-162 398,-176 387,-187C376,-198 361,-203 344,-203C327,-203 312,-198 301,-187C290,-176 284,-162 284,-144z"
        />
        <glyph
          unicode="a"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167z"
        />
        <glyph
          unicode="&#xE1;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M348,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x103;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M124,744l82,0C207,721 216,703 233,690C250,676 272,669 297,669C322,669 344,676 361,690C378,703 387,721 388,744l82,0C468,700 451,665 419,639C386,613 346,600 297,600C248,600 208,613 176,639C143,665 126,700 124,744z"
        />
        <glyph
          unicode="&#x1EAF;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M124,714l80,0C206,699 216,688 233,679C250,670 271,665 297,665C323,665 345,670 362,679C379,688 388,699 390,714l80,0C467,679 449,651 418,631C386,610 346,600 297,600C248,600 208,610 177,631C145,651 127,679 124,714M334,858l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EB7;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M231,-144C231,-127 237,-112 248,-100C259,-89 274,-83 291,-83C308,-83 323,-89 334,-100C345,-112 351,-127 351,-144C351,-162 345,-176 334,-187C323,-198 308,-203 291,-203C274,-203 259,-198 248,-187C237,-176 231,-162 231,-144M124,744l82,0C207,721 216,703 233,690C250,676 272,669 297,669C322,669 344,676 361,690C378,703 387,721 388,744l82,0C468,700 451,665 419,639C386,613 346,600 297,600C248,600 208,613 176,639C143,665 126,700 124,744z"
        />
        <glyph
          unicode="&#x1EB1;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M124,714l80,0C206,699 216,688 233,679C250,670 271,665 297,665C323,665 345,670 362,679C379,688 388,699 390,714l80,0C467,679 449,651 418,631C386,610 346,600 297,600C248,600 208,610 177,631C145,651 127,679 124,714M127,858l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EB3;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M124,714l80,0C206,699 216,688 233,679C250,670 271,665 297,665C323,665 345,670 362,679C379,688 388,699 390,714l80,0C467,679 449,651 418,631C386,610 346,600 297,600C248,600 208,610 177,631C145,651 127,679 124,714M285,747C311,760 324,774 324,791C324,800 321,808 314,814C307,819 299,822 288,822C273,822 259,818 244,810l-22,42C243,866 268,873 299,873C325,873 346,866 361,853C376,839 383,821 383,798C383,779 377,762 365,747C353,732 337,721 317,713z"
        />
        <glyph
          unicode="&#x1EB5;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M124,714l80,0C206,699 216,688 233,679C250,670 271,665 297,665C323,665 345,670 362,679C379,688 388,699 390,714l80,0C467,679 449,651 418,631C386,610 346,600 297,600C248,600 208,610 177,631C145,651 127,679 124,714M278,776C267,783 259,788 252,791C245,794 239,796 232,796C204,796 189,780 188,748l-68,0C121,784 131,812 150,832C169,852 194,862 226,862C243,862 258,859 271,854C284,848 299,840 314,830C326,823 336,817 343,814C350,811 357,809 364,809C377,809 388,813 395,822C402,830 407,841 408,856l66,0C473,821 464,793 445,773C426,752 401,742 370,742C352,742 336,745 323,751C309,757 294,765 278,776z"
        />
        <glyph
          unicode="&#x1CE;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M488,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xE2;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M385,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EA5;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M384,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M520,816l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EAD;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M231,-144C231,-127 237,-112 248,-100C259,-89 274,-83 291,-83C308,-83 323,-89 334,-100C345,-112 351,-127 351,-144C351,-162 345,-176 334,-187C323,-198 308,-203 291,-203C274,-203 259,-198 248,-187C237,-176 231,-162 231,-144M385,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EA7;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M384,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M313,816l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EA9;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M384,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M426,714C452,727 465,741 465,758C465,767 462,775 455,781C448,786 440,789 429,789C414,789 400,785 385,777l-22,42C384,833 409,840 440,840C466,840 487,833 502,820C517,806 524,788 524,765C524,746 518,729 506,714C494,699 478,688 458,680z"
        />
        <glyph
          unicode="&#x1EAB;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M384,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M278,782C267,789 259,794 252,797C245,800 239,802 232,802C204,802 189,786 188,754l-68,0C121,790 131,818 150,838C169,858 194,868 226,868C243,868 258,865 271,860C284,854 299,846 314,836C326,829 336,823 343,820C350,817 357,815 364,815C377,815 388,819 395,828C402,836 407,847 408,862l66,0C473,827 464,799 445,779C426,758 401,748 370,748C352,748 336,751 323,757C309,763 294,771 278,782z"
        />
        <glyph
          unicode="&#x201;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M47,744l125,0l80,-137l-98,0M234,744l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xE4;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M140,678C140,696 146,711 157,722C168,733 183,739 200,739C217,739 232,733 243,722C254,711 260,696 260,678C260,661 254,646 243,635C232,624 217,618 200,618C183,618 168,624 157,635C146,646 140,661 140,678M334,678C334,696 340,711 351,722C362,733 377,739 394,739C411,739 426,733 437,722C448,711 454,696 454,678C454,661 448,646 437,635C426,624 411,618 394,618C377,618 362,624 351,635C340,646 334,661 334,678z"
        />
        <glyph
          unicode="&#x1EA1;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M231,-144C231,-127 237,-112 248,-100C259,-89 274,-83 291,-83C308,-83 323,-89 334,-100C345,-112 351,-127 351,-144C351,-162 345,-176 334,-187C323,-198 308,-203 291,-203C274,-203 259,-198 248,-187C237,-176 231,-162 231,-144z"
        />
        <glyph
          unicode="&#xE0;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M99,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EA3;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M281,643C300,652 314,661 323,672C331,682 335,694 335,707C335,720 330,730 321,739C312,747 300,751 285,751C264,751 244,745 225,732l-27,55C211,797 227,805 244,810C261,815 279,818 298,818C330,818 356,809 377,791C398,772 408,748 408,718C408,664 379,624 322,597z"
        />
        <glyph
          unicode="&#x203;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M297,751C346,751 386,738 419,712C451,686 468,651 470,607l-82,0C387,630 378,649 361,663C344,676 322,683 297,683C272,683 250,676 233,663C216,649 207,630 206,607l-82,0C126,651 143,686 176,712C208,738 248,751 297,751z"
        />
        <glyph
          unicode="&#x101;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M124,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x105;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M327,-121C327,-90 339,-61 363,-33C387,-6 424,18 474,37l55,-37C487,-19 457,-37 439,-54C420,-72 411,-91 411,-111C411,-126 416,-138 427,-147C438,-156 452,-160 470,-160C493,-160 514,-155 531,-144l21,-61C525,-220 493,-228 457,-228C416,-228 385,-218 362,-198C339,-179 327,-153 327,-121z"
        />
        <glyph
          unicode="&#xE5;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M180,707C180,739 191,766 214,789C236,812 263,823 296,823C329,823 357,812 380,789C402,766 413,739 413,707C413,675 402,648 380,626C357,604 329,593 296,593C263,593 236,604 214,626C191,648 180,675 180,707M360,707C360,726 354,741 342,754C329,766 314,772 296,772C278,772 263,766 251,754C239,741 233,726 233,707C233,689 239,674 251,662C263,650 278,644 296,644C315,644 330,650 342,662C354,674 360,689 360,707z"
        />
        <glyph
          unicode="&#x1FB;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M395,770C407,751 413,730 413,707C413,675 402,648 380,626C357,604 329,593 296,593C263,593 236,604 214,626C191,648 180,675 180,707C180,739 191,766 214,789C236,812 263,823 296,823C315,823 333,819 349,811l85,87l64,-50M360,707C360,726 354,741 342,754C329,766 314,772 296,772C278,772 263,766 251,754C239,741 233,726 233,707C233,689 239,674 251,662C263,650 278,644 296,644C315,644 330,650 342,662C354,674 360,689 360,707z"
        />
        <glyph
          unicode="&#xE3;"
          horizAdvX="607"
          d="M529,313l0,-313l-118,0l0,65C396,42 374,24 346,12C317,-1 283,-7 243,-7C203,-7 168,0 138,14C108,27 85,46 69,71C52,95 44,122 44,153C44,201 62,240 98,269C133,298 189,312 266,312l138,0l0,8C404,357 393,386 371,406C348,426 315,436 271,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 286,540C365,540 425,521 467,484C508,446 529,389 529,313M404,167l0,62l-129,0C203,229 167,205 167,158C167,135 176,117 194,104C212,91 237,84 269,84C300,84 328,91 353,106C377,120 394,140 404,167M280,647C269,656 261,662 254,667C247,671 240,673 233,673C219,673 208,668 199,657C190,646 185,632 184,613l-67,0C118,654 128,686 148,711C167,735 193,747 225,747C242,747 256,744 269,737C281,730 296,720 314,707C325,698 334,692 341,688C348,683 354,681 361,681C375,681 387,686 396,696C405,706 409,720 410,738l67,0C476,699 465,667 446,643C427,619 401,607 369,607C352,607 338,610 326,617C313,624 298,634 280,647z"
        />
        <glyph
          unicode="&#xE6;"
          horizAdvX="993"
          d="M952,229l-421,0C538,189 556,157 587,134C618,111 657,99 704,99C761,99 810,118 849,157l68,-77C892,51 861,30 825,15C788,0 747,-7 701,-7C652,-7 608,1 569,17C529,33 495,58 468,91C447,56 418,31 381,16C344,1 304,-7 260,-7C193,-7 141,8 102,39C63,69 44,109 44,158C44,203 62,240 98,269C134,297 190,311 266,311l138,0l0,9C404,357 393,386 371,406C348,426 315,436 272,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 285,540C379,540 445,513 484,458C508,484 537,504 572,519C606,533 643,540 683,540C734,540 781,529 822,506C863,483 896,451 920,410C943,369 955,322 955,269C955,254 954,240 952,229M530,311l302,0C827,352 810,383 781,406C752,429 719,440 682,440C645,440 612,429 583,406C554,383 536,352 530,311M404,201l0,28l-129,0C203,229 167,206 167,160C167,136 176,117 195,104C213,91 238,84 271,84C312,84 345,95 369,116C392,137 404,166 404,201z"
        />
        <glyph
          unicode="&#x1FD;"
          horizAdvX="993"
          d="M952,229l-421,0C538,189 556,157 587,134C618,111 657,99 704,99C761,99 810,118 849,157l68,-77C892,51 861,30 825,15C788,0 747,-7 701,-7C652,-7 608,1 569,17C529,33 495,58 468,91C447,56 418,31 381,16C344,1 304,-7 260,-7C193,-7 141,8 102,39C63,69 44,109 44,158C44,203 62,240 98,269C134,297 190,311 266,311l138,0l0,9C404,357 393,386 371,406C348,426 315,436 272,436C241,436 212,431 183,422C154,413 129,400 109,383l-49,91C88,495 122,512 161,523C200,534 242,540 285,540C379,540 445,513 484,458C508,484 537,504 572,519C606,533 643,540 683,540C734,540 781,529 822,506C863,483 896,451 920,410C943,369 955,322 955,269C955,254 954,240 952,229M530,311l302,0C827,352 810,383 781,406C752,429 719,440 682,440C645,440 612,429 583,406C554,383 536,352 530,311M404,201l0,28l-129,0C203,229 167,206 167,160C167,136 176,117 195,104C213,91 238,84 271,84C312,84 345,95 369,116C392,137 404,166 404,201M518,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="b"
          horizAdvX="686"
          d="M649,267C649,213 637,165 614,124C591,82 559,50 518,27C477,4 430,-7 378,-7C341,-7 308,-1 278,12C247,25 222,44 201,69l0,-69l-119,0l0,742l125,0l0,-273C228,492 254,510 283,522C312,534 343,540 378,540C430,540 477,529 518,506C559,483 591,451 614,410C637,369 649,321 649,267M522,267C522,317 507,357 478,388C448,419 410,434 364,434C334,434 307,427 283,414C259,400 240,380 226,355C212,330 205,300 205,267C205,234 212,204 226,179C240,154 259,134 283,121C307,107 334,100 364,100C410,100 448,115 478,146C507,177 522,217 522,267z"
        />
        <glyph
          unicode="c"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267z"
        />
        <glyph
          unicode="&#x107;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267M358,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x10D;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267M498,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xE7;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267M183,-207l23,59C227,-159 249,-164 274,-164C309,-164 327,-152 327,-128C327,-118 323,-110 314,-104C305,-98 292,-95 274,-95l-31,0l27,102l68,0l-14,-56C353,-52 374,-61 389,-76C404,-91 411,-110 411,-133C411,-162 399,-185 374,-202C349,-219 316,-228 275,-228C239,-228 208,-221 183,-207z"
        />
        <glyph
          unicode="&#x1E09;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267M183,-207l23,59C227,-159 249,-164 274,-164C309,-164 327,-152 327,-128C327,-118 323,-110 314,-104C305,-98 292,-95 274,-95l-31,0l27,102l68,0l-14,-56C353,-52 374,-61 389,-76C404,-91 411,-110 411,-133C411,-162 399,-185 374,-202C349,-219 316,-228 275,-228C239,-228 208,-221 183,-207M358,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x109;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267M395,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x10B;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267M236,690C236,711 243,728 256,741C269,754 286,761 307,761C328,761 345,754 358,741C371,728 378,711 378,690C378,669 371,652 358,639C345,626 328,619 307,619C286,619 269,626 256,639C243,652 236,669 236,690z"
        />
        <glyph
          unicode="d"
          horizAdvX="687"
          d="M605,742l0,-742l-120,0l0,69C464,44 439,25 409,12C378,-1 345,-7 308,-7C257,-7 211,4 170,27C129,50 96,82 73,124C50,165 38,213 38,267C38,321 50,369 73,410C96,451 129,483 170,506C211,529 257,540 308,540C343,540 376,534 405,522C434,510 459,492 480,468l0,274M482,267C482,300 475,330 461,355C447,380 428,400 404,414C380,427 353,434 323,434C293,434 266,427 242,414C218,400 199,380 185,355C171,330 164,300 164,267C164,234 171,204 185,179C199,154 218,134 242,121C266,107 293,100 323,100C353,100 380,107 404,121C428,134 447,154 461,179C475,204 482,234 482,267z"
        />
        <glyph
          unicode="&#xF0;"
          horizAdvX="648"
          d="M611,343C611,270 599,208 574,155C549,102 513,61 466,33C419,4 363,-10 298,-10C249,-10 204,-1 165,18C126,37 95,63 72,98C49,133 38,173 38,218C38,263 49,302 72,336C94,370 125,396 164,415C203,434 246,443 293,443C334,443 371,436 404,421C436,406 462,384 483,356l0,15C483,460 460,527 415,572l-261,-103l-30,74l203,80C303,630 276,633 246,633C195,633 148,625 105,610l-19,102C137,727 191,734 248,734C332,734 404,715 463,676l89,35l29,-74l-53,-21C583,548 611,457 611,343M458,216C458,252 444,282 417,306C389,329 354,341 311,341C266,341 231,330 204,307C177,284 164,254 164,216C164,179 177,149 204,126C230,103 264,92 307,92C337,92 364,98 387,109C410,120 427,136 440,155C452,174 458,194 458,216z"
        />
        <glyph
          unicode="&#x10F;"
          horizAdvX="687"
          d="M605,742l0,-742l-120,0l0,69C464,44 439,25 409,12C378,-1 345,-7 308,-7C257,-7 211,4 170,27C129,50 96,82 73,124C50,165 38,213 38,267C38,321 50,369 73,410C96,451 129,483 170,506C211,529 257,540 308,540C343,540 376,534 405,522C434,510 459,492 480,468l0,274M482,267C482,300 475,330 461,355C447,380 428,400 404,414C380,427 353,434 323,434C293,434 266,427 242,414C218,400 199,380 185,355C171,330 164,300 164,267C164,234 171,204 185,179C199,154 218,134 242,121C266,107 293,100 323,100C353,100 380,107 404,121C428,134 447,154 461,179C475,204 482,234 482,267M691,779l99,0l-16,-197l-83,0z"
        />
        <glyph
          unicode="&#x111;"
          horizAdvX="687"
          d="M605,742l0,-742l-120,0l0,69C464,44 439,25 409,12C378,-1 345,-7 308,-7C257,-7 211,4 170,27C129,50 96,82 73,124C50,165 38,213 38,267C38,321 50,369 73,410C96,451 129,483 170,506C211,529 257,540 308,540C343,540 376,534 405,522C434,510 459,492 480,468l0,274M482,267C482,300 475,330 461,355C447,380 428,400 404,414C380,427 353,434 323,434C293,434 266,427 242,414C218,400 199,380 185,355C171,330 164,300 164,267C164,234 171,204 185,179C199,154 218,134 242,121C266,107 293,100 323,100C353,100 380,107 404,121C428,134 447,154 461,179C475,204 482,234 482,267M283,678l404,0l0,-82l-404,0z"
        />
        <glyph
          unicode="&#x1E0D;"
          horizAdvX="687"
          d="M605,742l0,-742l-120,0l0,69C464,44 439,25 409,12C378,-1 345,-7 308,-7C257,-7 211,4 170,27C129,50 96,82 73,124C50,165 38,213 38,267C38,321 50,369 73,410C96,451 129,483 170,506C211,529 257,540 308,540C343,540 376,534 405,522C434,510 459,492 480,468l0,274M482,267C482,300 475,330 461,355C447,380 428,400 404,414C380,427 353,434 323,434C293,434 266,427 242,414C218,400 199,380 185,355C171,330 164,300 164,267C164,234 171,204 185,179C199,154 218,134 242,121C266,107 293,100 323,100C353,100 380,107 404,121C428,134 447,154 461,179C475,204 482,234 482,267M294,-144C294,-127 300,-112 311,-100C322,-89 337,-83 354,-83C371,-83 386,-89 397,-100C408,-112 414,-127 414,-144C414,-162 408,-176 397,-187C386,-198 371,-203 354,-203C337,-203 322,-198 311,-187C300,-176 294,-162 294,-144z"
        />
        <glyph
          unicode="&#x1E0F;"
          horizAdvX="687"
          d="M605,742l0,-742l-120,0l0,69C464,44 439,25 409,12C378,-1 345,-7 308,-7C257,-7 211,4 170,27C129,50 96,82 73,124C50,165 38,213 38,267C38,321 50,369 73,410C96,451 129,483 170,506C211,529 257,540 308,540C343,540 376,534 405,522C434,510 459,492 480,468l0,274M482,267C482,300 475,330 461,355C447,380 428,400 404,414C380,427 353,434 323,434C293,434 266,427 242,414C218,400 199,380 185,355C171,330 164,300 164,267C164,234 171,204 185,179C199,154 218,134 242,121C266,107 293,100 323,100C353,100 380,107 404,121C428,134 447,154 461,179C475,204 482,234 482,267M181,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="&#x1C6;"
          horizAdvX="1221"
          d="M605,742l0,-742l-120,0l0,69C464,44 439,25 409,12C378,-1 345,-7 308,-7C257,-7 211,4 170,27C129,50 96,82 73,124C50,165 38,213 38,267C38,321 50,369 73,410C96,451 129,483 170,506C211,529 257,540 308,540C343,540 376,534 405,522C434,510 459,492 480,468l0,274M482,267C482,300 475,330 461,355C447,380 428,400 404,414C380,427 353,434 323,434C293,434 266,427 242,414C218,400 199,380 185,355C171,330 164,300 164,267C164,234 171,204 185,179C199,154 218,134 242,121C266,107 293,100 323,100C353,100 380,107 404,121C428,134 447,154 461,179C475,204 482,234 482,267M1180,100l0,-100l-461,0l0,79l293,355l-287,0l0,100l447,0l0,-79l-294,-355M1140,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="e"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310z"
        />
        <glyph
          unicode="&#xE9;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M362,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x115;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M138,744l82,0C221,721 230,703 247,690C264,676 286,669 311,669C336,669 358,676 375,690C392,703 401,721 402,744l82,0C482,700 465,665 433,639C400,613 360,600 311,600C262,600 222,613 190,639C157,665 140,700 138,744z"
        />
        <glyph
          unicode="&#x11B;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M502,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x1E1D;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M190,-207l23,59C234,-159 256,-164 281,-164C316,-164 334,-152 334,-128C334,-118 330,-110 321,-104C312,-98 299,-95 281,-95l-31,0l27,102l68,0l-14,-56C360,-52 381,-61 396,-76C411,-91 418,-110 418,-133C418,-162 406,-185 381,-202C356,-219 323,-228 282,-228C246,-228 215,-221 190,-207M138,744l82,0C221,721 230,703 247,690C264,676 286,669 311,669C336,669 358,676 375,690C392,703 401,721 402,744l82,0C482,700 465,665 433,639C400,613 360,600 311,600C262,600 222,613 190,639C157,665 140,700 138,744z"
        />
        <glyph
          unicode="&#xEA;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M399,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EBF;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M398,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M534,816l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EC7;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M251,-144C251,-127 257,-112 268,-100C279,-89 294,-83 311,-83C328,-83 343,-89 354,-100C365,-112 371,-127 371,-144C371,-162 365,-176 354,-187C343,-198 328,-203 311,-203C294,-203 279,-198 268,-187C257,-176 251,-162 251,-144M399,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1EC1;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M398,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M327,816l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EC3;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M398,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M440,714C466,727 479,741 479,758C479,767 476,775 469,781C462,786 454,789 443,789C428,789 414,785 399,777l-22,42C398,833 423,840 454,840C480,840 501,833 516,820C531,806 538,788 538,765C538,746 532,729 520,714C508,699 492,688 472,680z"
        />
        <glyph
          unicode="&#x1EC5;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M398,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M292,782C281,789 273,794 266,797C259,800 253,802 246,802C218,802 203,786 202,754l-68,0C135,790 145,818 164,838C183,858 208,868 240,868C257,868 272,865 285,860C298,854 313,846 328,836C340,829 350,823 357,820C364,817 371,815 378,815C391,815 402,819 409,828C416,836 421,847 422,862l66,0C487,827 478,799 459,779C440,758 415,748 384,748C366,748 350,751 337,757C323,763 308,771 292,782z"
        />
        <glyph
          unicode="&#x205;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M61,744l125,0l80,-137l-98,0M248,744l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xEB;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M154,678C154,696 160,711 171,722C182,733 197,739 214,739C231,739 246,733 257,722C268,711 274,696 274,678C274,661 268,646 257,635C246,624 231,618 214,618C197,618 182,624 171,635C160,646 154,661 154,678M348,678C348,696 354,711 365,722C376,733 391,739 408,739C425,739 440,733 451,722C462,711 468,696 468,678C468,661 462,646 451,635C440,624 425,618 408,618C391,618 376,624 365,635C354,646 348,661 348,678z"
        />
        <glyph
          unicode="&#x117;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M240,690C240,711 247,728 260,741C273,754 290,761 311,761C332,761 349,754 362,741C375,728 382,711 382,690C382,669 375,652 362,639C349,626 332,619 311,619C290,619 273,626 260,639C247,652 240,669 240,690z"
        />
        <glyph
          unicode="&#x1EB9;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M251,-144C251,-127 257,-112 268,-100C279,-89 294,-83 311,-83C328,-83 343,-89 354,-100C365,-112 371,-127 371,-144C371,-162 365,-176 354,-187C343,-198 328,-203 311,-203C294,-203 279,-198 268,-187C257,-176 251,-162 251,-144z"
        />
        <glyph
          unicode="&#xE8;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M113,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EBB;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M295,643C314,652 328,661 337,672C345,682 349,694 349,707C349,720 344,730 335,739C326,747 314,751 299,751C278,751 258,745 239,732l-27,55C225,797 241,805 258,810C275,815 293,818 312,818C344,818 370,809 391,791C412,772 422,748 422,718C422,664 393,624 336,597z"
        />
        <glyph
          unicode="&#x207;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M311,751C360,751 400,738 433,712C465,686 482,651 484,607l-82,0C401,630 392,649 375,663C358,676 336,683 311,683C286,683 264,676 247,663C230,649 221,630 220,607l-82,0C140,651 157,686 190,712C222,738 262,751 311,751z"
        />
        <glyph
          unicode="&#x113;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M138,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E17;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M138,696l346,0l0,-70l-346,0M348,876l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1E15;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M138,696l346,0l0,-70l-346,0M141,876l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x119;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C537,67 524,55 509,44C468,11 439,-17 422,-40C405,-64 397,-86 397,-107C397,-124 402,-137 413,-146C424,-155 438,-160 456,-160C467,-160 478,-158 489,-155C500,-152 509,-149 517,-144l21,-61C525,-212 511,-218 494,-222C477,-226 459,-228 442,-228C402,-228 371,-218 348,-198C325,-178 313,-151 313,-117C313,-98 317,-79 325,-60C333,-41 345,-23 360,-6C354,-7 345,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310z"
        />
        <glyph
          unicode="&#x1EBD;"
          horizAdvX="622"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M294,647C283,656 275,662 268,667C261,671 254,673 247,673C233,673 222,668 213,657C204,646 199,632 198,613l-67,0C132,654 142,686 162,711C181,735 207,747 239,747C256,747 270,744 283,737C295,730 310,720 328,707C339,698 348,692 355,688C362,683 368,681 375,681C389,681 401,686 410,696C419,706 423,720 424,738l67,0C490,699 479,667 460,643C441,619 415,607 383,607C366,607 352,610 340,617C327,624 312,634 294,647z"
        />
        <glyph
          unicode="&#x259;"
          horizAdvX="622"
          d="M40,307l419,0C452,346 433,378 402,401C371,424 332,435 286,435C227,435 179,416 141,377l-67,77C98,483 128,504 165,519C202,534 243,541 289,541C348,541 399,529 444,506C489,483 523,450 548,409C572,367 584,320 584,267C584,215 572,168 549,127C525,85 492,52 450,29C408,6 361,-6 308,-6C256,-6 210,6 169,29C128,52 96,84 73,126C50,167 38,215 38,270C38,279 39,291 40,307M460,224l-303,0C162,185 179,154 206,130C233,106 267,94 308,94C348,94 382,106 410,130C437,153 454,185 460,224z"
        />
        <glyph
          unicode="&#x292;"
          horizAdvX="541"
          d="M512,25C512,-17 502,-55 482,-89C461,-124 431,-151 390,-171C349,-191 298,-201 238,-201C187,-201 139,-194 94,-179C48,-164 10,-144 -19,-119l46,103C52,-39 82,-56 119,-69C155,-82 193,-89 233,-89C281,-89 318,-79 344,-59C370,-39 383,-11 383,25C383,60 371,88 346,108C321,127 282,137 231,137l-65,0l0,87l165,202l-323,0l0,108l472,0l0,-88l-174,-213C373,226 424,204 459,167C494,130 512,82 512,25z"
        />
        <glyph
          unicode="&#x1EF;"
          horizAdvX="541"
          d="M512,25C512,-17 502,-55 482,-89C461,-124 431,-151 390,-171C349,-191 298,-201 238,-201C187,-201 139,-194 94,-179C48,-164 10,-144 -19,-119l46,103C52,-39 82,-56 119,-69C155,-82 193,-89 233,-89C281,-89 318,-79 344,-59C370,-39 383,-11 383,25C383,60 371,88 346,108C321,127 282,137 231,137l-65,0l0,87l165,202l-323,0l0,108l472,0l0,-88l-174,-213C373,226 424,204 459,167C494,130 512,82 512,25M425,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="f"
          horizAdvX="369"
          d="M220,562l0,-32l147,0l0,-100l-143,0l0,-430l-125,0l0,430l-88,0l0,100l88,0l0,34C99,621 116,667 149,700C182,733 229,749 290,749C338,749 376,739 403,720l-35,-94C347,641 324,648 299,648C246,648 220,619 220,562z"
        />
        <glyph
          unicode="g"
          horizAdvX="694"
          d="M612,534l0,-453C612,-107 516,-201 324,-201C273,-201 224,-194 178,-181C132,-168 94,-150 64,-125l56,94C143,-50 173,-66 209,-77C244,-89 280,-95 317,-95C376,-95 419,-82 446,-55C473,-28 487,12 487,67l0,28C466,72 440,54 409,42C378,30 345,24 308,24C257,24 212,35 171,57C130,78 97,109 74,148C50,187 38,232 38,283C38,334 50,379 74,418C97,457 130,487 171,508C212,529 257,540 308,540C347,540 382,534 414,521C445,508 472,489 493,463l0,71M489,283C489,328 474,364 444,392C413,420 374,434 327,434C279,434 240,420 210,392C179,364 164,328 164,283C164,238 179,201 210,173C240,144 279,130 327,130C374,130 413,144 444,173C474,201 489,238 489,283z"
        />
        <glyph
          unicode="&#x11F;"
          horizAdvX="694"
          d="M612,534l0,-453C612,-107 516,-201 324,-201C273,-201 224,-194 178,-181C132,-168 94,-150 64,-125l56,94C143,-50 173,-66 209,-77C244,-89 280,-95 317,-95C376,-95 419,-82 446,-55C473,-28 487,12 487,67l0,28C466,72 440,54 409,42C378,30 345,24 308,24C257,24 212,35 171,57C130,78 97,109 74,148C50,187 38,232 38,283C38,334 50,379 74,418C97,457 130,487 171,508C212,529 257,540 308,540C347,540 382,534 414,521C445,508 472,489 493,463l0,71M489,283C489,328 474,364 444,392C413,420 374,434 327,434C279,434 240,420 210,392C179,364 164,328 164,283C164,238 179,201 210,173C240,144 279,130 327,130C374,130 413,144 444,173C474,201 489,238 489,283M162,744l82,0C245,721 254,703 271,690C288,676 310,669 335,669C360,669 382,676 399,690C416,703 425,721 426,744l82,0C506,700 489,665 457,639C424,613 384,600 335,600C286,600 246,613 214,639C181,665 164,700 162,744z"
        />
        <glyph
          unicode="&#x1E7;"
          horizAdvX="694"
          d="M612,534l0,-453C612,-107 516,-201 324,-201C273,-201 224,-194 178,-181C132,-168 94,-150 64,-125l56,94C143,-50 173,-66 209,-77C244,-89 280,-95 317,-95C376,-95 419,-82 446,-55C473,-28 487,12 487,67l0,28C466,72 440,54 409,42C378,30 345,24 308,24C257,24 212,35 171,57C130,78 97,109 74,148C50,187 38,232 38,283C38,334 50,379 74,418C97,457 130,487 171,508C212,529 257,540 308,540C347,540 382,534 414,521C445,508 472,489 493,463l0,71M489,283C489,328 474,364 444,392C413,420 374,434 327,434C279,434 240,420 210,392C179,364 164,328 164,283C164,238 179,201 210,173C240,144 279,130 327,130C374,130 413,144 444,173C474,201 489,238 489,283M526,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x11D;"
          horizAdvX="694"
          d="M612,534l0,-453C612,-107 516,-201 324,-201C273,-201 224,-194 178,-181C132,-168 94,-150 64,-125l56,94C143,-50 173,-66 209,-77C244,-89 280,-95 317,-95C376,-95 419,-82 446,-55C473,-28 487,12 487,67l0,28C466,72 440,54 409,42C378,30 345,24 308,24C257,24 212,35 171,57C130,78 97,109 74,148C50,187 38,232 38,283C38,334 50,379 74,418C97,457 130,487 171,508C212,529 257,540 308,540C347,540 382,534 414,521C445,508 472,489 493,463l0,71M489,283C489,328 474,364 444,392C413,420 374,434 327,434C279,434 240,420 210,392C179,364 164,328 164,283C164,238 179,201 210,173C240,144 279,130 327,130C374,130 413,144 444,173C474,201 489,238 489,283M423,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x123;"
          horizAdvX="694"
          d="M612,534l0,-453C612,-107 516,-201 324,-201C273,-201 224,-194 178,-181C132,-168 94,-150 64,-125l56,94C143,-50 173,-66 209,-77C244,-89 280,-95 317,-95C376,-95 419,-82 446,-55C473,-28 487,12 487,67l0,28C466,72 440,54 409,42C378,30 345,24 308,24C257,24 212,35 171,57C130,78 97,109 74,148C50,187 38,232 38,283C38,334 50,379 74,418C97,457 130,487 171,508C212,529 257,540 308,540C347,540 382,534 414,521C445,508 472,489 493,463l0,71M489,283C489,328 474,364 444,392C413,420 374,434 327,434C279,434 240,420 210,392C179,364 164,328 164,283C164,238 179,201 210,173C240,144 279,130 327,130C374,130 413,144 444,173C474,201 489,238 489,283M402,653C402,637 396,624 385,615C373,605 356,600 335,600C314,600 297,605 286,615C274,625 268,638 268,653C268,669 274,688 286,710l41,92l69,0l-32,-101C389,694 402,678 402,653z"
        />
        <glyph
          unicode="&#x121;"
          horizAdvX="694"
          d="M612,534l0,-453C612,-107 516,-201 324,-201C273,-201 224,-194 178,-181C132,-168 94,-150 64,-125l56,94C143,-50 173,-66 209,-77C244,-89 280,-95 317,-95C376,-95 419,-82 446,-55C473,-28 487,12 487,67l0,28C466,72 440,54 409,42C378,30 345,24 308,24C257,24 212,35 171,57C130,78 97,109 74,148C50,187 38,232 38,283C38,334 50,379 74,418C97,457 130,487 171,508C212,529 257,540 308,540C347,540 382,534 414,521C445,508 472,489 493,463l0,71M489,283C489,328 474,364 444,392C413,420 374,434 327,434C279,434 240,420 210,392C179,364 164,328 164,283C164,238 179,201 210,173C240,144 279,130 327,130C374,130 413,144 444,173C474,201 489,238 489,283M264,690C264,711 271,728 284,741C297,754 314,761 335,761C356,761 373,754 386,741C399,728 406,711 406,690C406,669 399,652 386,639C373,626 356,619 335,619C314,619 297,626 284,639C271,652 264,669 264,690z"
        />
        <glyph
          unicode="&#x1E21;"
          horizAdvX="694"
          d="M612,534l0,-453C612,-107 516,-201 324,-201C273,-201 224,-194 178,-181C132,-168 94,-150 64,-125l56,94C143,-50 173,-66 209,-77C244,-89 280,-95 317,-95C376,-95 419,-82 446,-55C473,-28 487,12 487,67l0,28C466,72 440,54 409,42C378,30 345,24 308,24C257,24 212,35 171,57C130,78 97,109 74,148C50,187 38,232 38,283C38,334 50,379 74,418C97,457 130,487 171,508C212,529 257,540 308,540C347,540 382,534 414,521C445,508 472,489 493,463l0,71M489,283C489,328 474,364 444,392C413,420 374,434 327,434C279,434 240,420 210,392C179,364 164,328 164,283C164,238 179,201 210,173C240,144 279,130 327,130C374,130 413,144 444,173C474,201 489,238 489,283M162,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E5;"
          horizAdvX="704"
          d="M676,-50l-85,0C554,-151 464,-201 323,-201C272,-201 224,-194 178,-181C131,-168 93,-150 64,-125l56,93C144,-52 174,-68 209,-79C244,-91 280,-97 317,-97C379,-97 424,-81 452,-50l-173,0l0,75l205,1C486,38 487,51 487,65l0,57C466,99 440,82 409,71C378,60 344,54 307,54C256,54 210,64 169,85C128,106 96,135 73,172C50,209 38,251 38,298C38,345 50,387 73,424C96,461 128,489 169,510C210,530 256,540 307,540C346,540 381,534 413,522C445,509 472,491 493,466l0,68l119,0l0,-453C612,62 611,43 609,26l67,0M489,298C489,341 474,375 444,402C414,429 375,442 327,442C279,442 240,429 210,402C179,375 164,341 164,298C164,255 179,220 210,193C240,166 279,153 327,153C375,153 414,166 444,193C474,220 489,255 489,298z"
        />
        <glyph
          unicode="h"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,742l125,0l0,-270C228,494 253,511 284,523C314,534 348,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306z"
        />
        <glyph
          unicode="&#x127;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,742l125,0l0,-270C228,494 253,511 284,523C314,534 348,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M0,678l404,0l0,-82l-404,0z"
        />
        <glyph
          unicode="&#x1E2B;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,742l125,0l0,-270C228,494 253,511 284,523C314,534 348,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M172,-77l78,0C250,-98 259,-115 277,-128C295,-141 318,-148 345,-148C372,-148 395,-141 413,-128C431,-115 440,-98 440,-77l78,0C516,-118 499,-150 467,-175C434,-200 394,-213 345,-213C296,-213 256,-200 224,-175C191,-150 174,-118 172,-77z"
        />
        <glyph
          unicode="&#x21F;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,742l125,0l0,-270C228,494 253,511 284,523C314,534 348,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M336,952l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x125;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,742l125,0l0,-270C228,494 253,511 284,523C314,534 348,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M233,799l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1E25;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,742l125,0l0,-270C228,494 253,511 284,523C314,534 348,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M285,-144C285,-127 291,-112 302,-100C313,-89 328,-83 345,-83C362,-83 377,-89 388,-100C399,-112 405,-127 405,-144C405,-162 399,-176 388,-187C377,-198 362,-203 345,-203C328,-203 313,-198 302,-187C291,-176 285,-162 285,-144z"
        />
        <glyph
          unicode="i"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M65,697C65,718 73,736 88,751C103,765 122,772 145,772C168,772 187,765 202,752C217,738 225,721 225,700C225,678 218,660 203,645C188,630 168,622 145,622C122,622 103,629 88,644C73,658 65,676 65,697z"
        />
        <glyph
          unicode="&#x131;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0z"
        />
        <glyph
          unicode="&#xED;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M196,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x12D;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M-3,744l77,0C74,721 80,702 93,689C106,675 123,668 145,668C167,668 184,675 197,689C210,702 216,721 216,744l77,0C292,700 278,665 251,639C223,613 188,600 145,600C102,600 67,613 40,639C12,665 -2,700 -3,744z"
        />
        <glyph
          unicode="&#xEE;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M213,607l-68,70l-68,-70l-98,0l110,137l112,0l110,-137z"
        />
        <glyph
          unicode="&#x209;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M-105,744l125,0l80,-137l-98,0M82,744l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xEF;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M11,678C11,695 17,710 28,721C39,732 52,738 69,738C85,738 99,732 110,721C121,710 126,695 126,678C126,661 121,647 110,636C99,625 85,619 69,619C52,619 39,625 28,636C17,647 11,661 11,678M164,678C164,695 170,710 181,721C192,732 205,738 222,738C239,738 252,732 263,721C274,710 279,695 279,678C279,661 274,647 263,636C252,625 239,619 222,619C205,619 192,625 181,636C170,647 164,661 164,678z"
        />
        <glyph
          unicode="&#x1E2F;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M-7,676C-7,693 -1,707 10,718C21,729 35,734 51,734C68,734 82,729 93,718C104,707 109,693 109,676C109,659 104,646 93,635C82,624 68,618 51,618C35,618 21,624 10,635C-1,646 -7,659 -7,676M182,676C182,693 188,707 199,718C210,729 224,734 240,734C256,734 270,728 281,717C292,706 298,692 298,676C298,659 292,646 281,635C270,624 256,618 240,618C224,618 210,624 199,635C188,646 182,659 182,676M182,876l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1ECB;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M65,697C65,718 73,736 88,751C103,765 122,772 145,772C168,772 187,765 202,752C217,738 225,721 225,700C225,678 218,660 203,645C188,630 168,622 145,622C122,622 103,629 88,644C73,658 65,676 65,697M85,-144C85,-127 91,-112 102,-100C113,-89 128,-83 145,-83C162,-83 177,-89 188,-100C199,-112 205,-127 205,-144C205,-162 199,-176 188,-187C177,-198 162,-203 145,-203C128,-203 113,-198 102,-187C91,-176 85,-162 85,-144z"
        />
        <glyph
          unicode="&#xEC;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M-53,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EC9;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M129,643C148,652 162,661 171,672C179,682 183,694 183,707C183,720 178,730 169,739C160,747 148,751 133,751C112,751 92,745 73,732l-27,55C59,797 75,805 92,810C109,815 127,818 146,818C178,818 204,809 225,791C246,772 256,748 256,718C256,664 227,624 170,597z"
        />
        <glyph
          unicode="&#x20B;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M145,751C188,751 223,738 251,712C278,686 292,651 293,607l-77,0C216,630 210,649 197,663C184,676 167,683 145,683C123,683 106,676 93,663C80,649 74,630 74,607l-77,0C-2,651 12,686 40,712C67,738 102,751 145,751z"
        />
        <glyph
          unicode="&#x133;"
          horizAdvX="584"
          d="M82,534l125,0l0,-534l-125,0M65,697C65,718 73,736 88,751C103,765 122,772 145,772C168,772 187,765 202,752C217,738 225,721 225,700C225,678 218,660 203,645C188,630 168,622 145,622C122,622 103,629 88,644C73,658 65,676 65,697M196,-170l36,93C251,-91 275,-98 305,-98C328,-98 346,-91 359,-76C371,-62 377,-41 377,-13l0,547l125,0l0,-544C502,-69 486,-116 453,-150C420,-184 375,-201 316,-201C263,-201 223,-191 196,-170M359,697C359,718 367,736 382,751C397,765 416,772 439,772C462,772 481,765 496,752C511,738 519,721 519,700C519,678 512,660 497,645C482,630 462,622 439,622C416,622 397,629 382,644C367,658 359,676 359,697z"
        />
        <glyph
          unicode="&#x12B;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M4,713l282,0l0,-75l-282,0z"
        />
        <glyph
          unicode="&#x12F;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M65,697C65,718 73,736 88,751C103,765 122,772 145,772C168,772 187,765 202,752C217,738 225,721 225,700C225,678 218,660 203,645C188,630 168,622 145,622C122,622 103,629 88,644C73,658 65,676 65,697M62,-124C62,-63 96,-9 164,36l43,-36C184,-21 168,-40 159,-57C150,-75 145,-93 145,-111C145,-126 149,-137 158,-146C166,-155 176,-160 189,-160C200,-160 211,-157 222,-150l19,-58C224,-221 201,-228 172,-228C139,-228 112,-218 92,-199C72,-180 62,-155 62,-124z"
        />
        <glyph
          unicode="&#x129;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M127,649C118,657 111,663 106,667C100,671 94,673 89,673C78,673 70,668 65,658C60,647 57,632 57,613l-66,0C-9,656 -1,690 15,713C31,736 53,747 82,747C98,747 112,743 124,736C136,729 149,718 163,705C172,697 179,691 185,687C190,683 196,681 201,681C212,681 220,686 225,696C230,706 233,720 233,738l66,0C299,696 291,664 275,641C259,618 237,607 208,607C192,607 178,611 166,618C154,625 141,636 127,649z"
        />
        <glyph
          unicode="j"
          horizAdvX="295"
          d="M-93,-170l36,93C-38,-91 -14,-98 16,-98C39,-98 57,-91 70,-76C82,-62 88,-41 88,-13l0,547l125,0l0,-544C213,-69 197,-116 164,-150C131,-184 86,-201 27,-201C-26,-201 -66,-191 -93,-170M70,697C70,718 78,736 93,751C108,765 127,772 150,772C173,772 192,765 207,752C222,738 230,721 230,700C230,678 223,660 208,645C193,630 173,622 150,622C127,622 108,629 93,644C78,658 70,676 70,697z"
        />
        <glyph
          unicode="&#x237;"
          horizAdvX="295"
          d="M-93,-170l36,93C-38,-91 -14,-98 16,-98C39,-98 57,-91 70,-76C82,-62 88,-41 88,-13l0,547l125,0l0,-544C213,-69 197,-116 164,-150C131,-184 86,-201 27,-201C-26,-201 -66,-191 -93,-170z"
        />
        <glyph
          unicode="&#x135;"
          horizAdvX="295"
          d="M-93,-170l36,93C-38,-91 -14,-98 16,-98C39,-98 57,-91 70,-76C82,-62 88,-41 88,-13l0,547l125,0l0,-544C213,-69 197,-116 164,-150C131,-184 86,-201 27,-201C-26,-201 -66,-191 -93,-170M218,607l-68,70l-68,-70l-98,0l110,137l112,0l110,-137z"
        />
        <glyph
          unicode="k"
          horizAdvX="636"
          d="M300,229l-93,-88l0,-141l-125,0l0,742l125,0l0,-448l259,240l150,0l-223,-224l244,-310l-152,0z"
        />
        <glyph
          unicode="&#x1E9;"
          horizAdvX="636"
          d="M300,229l-93,-88l0,-141l-125,0l0,742l125,0l0,-448l259,240l150,0l-223,-224l244,-310l-152,0M336,952l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x137;"
          horizAdvX="636"
          d="M300,229l-93,-88l0,-141l-125,0l0,742l125,0l0,-448l259,240l150,0l-223,-224l244,-310l-152,0M375,-136C375,-155 370,-174 359,-195l-32,-70l-61,0l24,81C267,-177 255,-161 255,-136C255,-120 261,-107 272,-97C283,-88 297,-83 315,-83C333,-83 348,-88 359,-98C370,-108 375,-121 375,-136z"
        />
        <glyph
          unicode="&#x138;"
          horizAdvX="625"
          d="M300,229l-93,-88l0,-141l-125,0l0,534l125,0l0,-240l259,240l150,0l-223,-224l244,-310l-152,0z"
        />
        <glyph unicode="l" horizAdvX="289" d="M82,742l125,0l0,-742l-125,0z" />
        <glyph
          unicode="&#x13A;"
          horizAdvX="289"
          d="M82,742l125,0l0,-742l-125,0M196,952l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x13E;"
          horizAdvX="289"
          d="M82,742l125,0l0,-742l-125,0M291,779l99,0l-16,-197l-83,0z"
        />
        <glyph
          unicode="&#x13C;"
          horizAdvX="289"
          d="M82,742l125,0l0,-742l-125,0M205,-136C205,-155 200,-174 189,-195l-32,-70l-61,0l24,81C97,-177 85,-161 85,-136C85,-120 91,-107 102,-97C113,-88 127,-83 145,-83C163,-83 178,-88 189,-98C200,-108 205,-121 205,-136z"
        />
        <glyph
          unicode="&#x140;"
          horizAdvX="381"
          d="M82,742l125,0l0,-742l-125,0M267,360C267,379 274,396 287,409C300,422 316,428 335,428C355,428 372,422 385,409C398,396 404,379 404,360C404,341 398,325 385,312C372,299 355,292 335,292C316,292 300,299 287,312C274,325 267,341 267,360z"
        />
        <glyph
          unicode="&#x1E37;"
          horizAdvX="289"
          d="M82,742l125,0l0,-742l-125,0M85,-144C85,-127 91,-112 102,-100C113,-89 128,-83 145,-83C162,-83 177,-89 188,-100C199,-112 205,-127 205,-144C205,-162 199,-176 188,-187C177,-198 162,-203 145,-203C128,-203 113,-198 102,-187C91,-176 85,-162 85,-144z"
        />
        <glyph
          unicode="&#x1C9;"
          horizAdvX="584"
          d="M82,742l125,0l0,-742l-125,0M196,-170l36,93C251,-91 275,-98 305,-98C328,-98 346,-91 359,-76C371,-62 377,-41 377,-13l0,547l125,0l0,-544C502,-69 486,-116 453,-150C420,-184 375,-201 316,-201C263,-201 223,-191 196,-170M359,697C359,718 367,736 382,751C397,765 416,772 439,772C462,772 481,765 496,752C511,738 519,721 519,700C519,678 512,660 497,645C482,630 462,622 439,622C416,622 397,629 382,644C367,658 359,676 359,697z"
        />
        <glyph
          unicode="&#x1E3B;"
          horizAdvX="289"
          d="M82,742l125,0l0,-742l-125,0M-28,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="&#x142;"
          horizAdvX="309"
          d="M92,742l125,0l0,-742l-125,0M-18,284l294,223l51,-71l-293,-222z"
        />
        <glyph
          unicode="m"
          horizAdvX="1053"
          d="M975,306l0,-306l-125,0l0,290C850,337 840,372 819,396C798,419 769,431 730,431C688,431 654,417 629,390C604,362 591,322 591,271l0,-271l-125,0l0,290C466,337 456,372 435,396C414,419 385,431 346,431C303,431 270,417 245,390C220,363 207,323 207,271l0,-271l-125,0l0,534l119,0l0,-68C221,490 246,508 276,521C306,534 339,540 376,540C416,540 452,533 483,518C514,503 538,480 556,451C578,479 606,501 641,517C676,532 714,540 756,540C823,540 877,521 916,482C955,443 975,384 975,306z"
        />
        <glyph
          unicode="&#x1E43;"
          horizAdvX="1053"
          d="M975,306l0,-306l-125,0l0,290C850,337 840,372 819,396C798,419 769,431 730,431C688,431 654,417 629,390C604,362 591,322 591,271l0,-271l-125,0l0,290C466,337 456,372 435,396C414,419 385,431 346,431C303,431 270,417 245,390C220,363 207,323 207,271l0,-271l-125,0l0,534l119,0l0,-68C221,490 246,508 276,521C306,534 339,540 376,540C416,540 452,533 483,518C514,503 538,480 556,451C578,479 606,501 641,517C676,532 714,540 756,540C823,540 877,521 916,482C955,443 975,384 975,306M468,-144C468,-127 474,-112 485,-100C496,-89 511,-83 528,-83C545,-83 560,-89 571,-100C582,-112 588,-127 588,-144C588,-162 582,-176 571,-187C560,-198 545,-203 528,-203C511,-203 496,-198 485,-187C474,-176 468,-162 468,-144z"
        />
        <glyph
          unicode="n"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306z"
        />
        <glyph
          unicode="&#x144;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M396,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x149;"
          horizAdvX="795"
          d="M50,700l83,0l-19,-261l-67,0M717,306l0,-306l-125,0l0,290C592,337 581,372 559,396C537,419 506,431 465,431C419,431 383,417 356,390C329,362 316,322 316,270l0,-270l-125,0l0,534l119,0l0,-69C331,490 357,508 388,521C419,534 455,540 494,540C561,540 615,520 656,481C697,442 717,383 717,306z"
        />
        <glyph
          unicode="&#x148;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M536,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x146;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M405,-136C405,-155 400,-174 389,-195l-32,-70l-61,0l24,81C297,-177 285,-161 285,-136C285,-120 291,-107 302,-97C313,-88 327,-83 345,-83C363,-83 378,-88 389,-98C400,-108 405,-121 405,-136z"
        />
        <glyph
          unicode="&#x1E45;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M274,690C274,711 281,728 294,741C307,754 324,761 345,761C366,761 383,754 396,741C409,728 416,711 416,690C416,669 409,652 396,639C383,626 366,619 345,619C324,619 307,626 294,639C281,652 274,669 274,690z"
        />
        <glyph
          unicode="&#x1E47;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M285,-144C285,-127 291,-112 302,-100C313,-89 328,-83 345,-83C362,-83 377,-89 388,-100C399,-112 405,-127 405,-144C405,-162 399,-176 388,-187C377,-198 362,-203 345,-203C328,-203 313,-198 302,-187C291,-176 285,-162 285,-144z"
        />
        <glyph
          unicode="&#x14B;"
          horizAdvX="685"
          d="M608,306l0,-316C608,-69 592,-116 560,-150C527,-184 481,-201 422,-201C369,-201 329,-191 302,-170l37,93C357,-91 381,-98 411,-98C434,-98 452,-91 465,-76C477,-62 483,-41 483,-13l0,303C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306z"
        />
        <glyph
          unicode="&#x1CC;"
          horizAdvX="980"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M592,-170l36,93C647,-91 671,-98 701,-98C724,-98 742,-91 755,-76C767,-62 773,-41 773,-13l0,547l125,0l0,-544C898,-69 882,-116 849,-150C816,-184 771,-201 712,-201C659,-201 619,-191 592,-170M755,697C755,718 763,736 778,751C793,765 812,772 835,772C858,772 877,765 892,752C907,738 915,721 915,700C915,678 908,660 893,645C878,630 858,622 835,622C812,622 793,629 778,644C763,658 755,676 755,697z"
        />
        <glyph
          unicode="&#x1E49;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M172,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="&#xF1;"
          horizAdvX="685"
          d="M608,306l0,-306l-125,0l0,290C483,337 472,372 450,396C428,419 397,431 356,431C310,431 274,417 247,390C220,362 207,322 207,270l0,-270l-125,0l0,534l119,0l0,-69C222,490 248,508 279,521C310,534 346,540 385,540C452,540 506,520 547,481C588,442 608,383 608,306M328,647C317,656 309,662 302,667C295,671 288,673 281,673C267,673 256,668 247,657C238,646 233,632 232,613l-67,0C166,654 176,686 196,711C215,735 241,747 273,747C290,747 304,744 317,737C329,730 344,720 362,707C373,698 382,692 389,688C396,683 402,681 409,681C423,681 435,686 444,696C453,706 457,720 458,738l67,0C524,699 513,667 494,643C475,619 449,607 417,607C400,607 386,610 374,617C361,624 346,634 328,647z"
        />
        <glyph
          unicode="o"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267z"
        />
        <glyph
          unicode="&#xF3;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M372,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x14F;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M148,744l82,0C231,721 240,703 257,690C274,676 296,669 321,669C346,669 368,676 385,690C402,703 411,721 412,744l82,0C492,700 475,665 443,639C410,613 370,600 321,600C272,600 232,613 200,639C167,665 150,700 148,744z"
        />
        <glyph
          unicode="&#xF4;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M409,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1ED1;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M408,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M544,816l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1ED9;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M261,-144C261,-127 267,-112 278,-100C289,-89 304,-83 321,-83C338,-83 353,-89 364,-100C375,-112 381,-127 381,-144C381,-162 375,-176 364,-187C353,-198 338,-203 321,-203C304,-203 289,-198 278,-187C267,-176 261,-162 261,-144M409,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1ED3;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M408,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M337,816l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1ED5;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M408,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M450,714C476,727 489,741 489,758C489,767 486,775 479,781C472,786 464,789 453,789C438,789 424,785 409,777l-22,42C408,833 433,840 464,840C490,840 511,833 526,820C541,806 548,788 548,765C548,746 542,729 530,714C518,699 502,688 482,680z"
        />
        <glyph
          unicode="&#x1ED7;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M408,607l-87,55l-87,-55l-104,0l135,107l112,0l135,-107M302,782C291,789 283,794 276,797C269,800 263,802 256,802C228,802 213,786 212,754l-68,0C145,790 155,818 174,838C193,858 218,868 250,868C267,868 282,865 295,860C308,854 323,846 338,836C350,829 360,823 367,820C374,817 381,815 388,815C401,815 412,819 419,828C426,836 431,847 432,862l66,0C497,827 488,799 469,779C450,758 425,748 394,748C376,748 360,751 347,757C333,763 318,771 302,782z"
        />
        <glyph
          unicode="&#x20D;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M71,744l125,0l80,-137l-98,0M258,744l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xF6;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M164,678C164,696 170,711 181,722C192,733 207,739 224,739C241,739 256,733 267,722C278,711 284,696 284,678C284,661 278,646 267,635C256,624 241,618 224,618C207,618 192,624 181,635C170,646 164,661 164,678M358,678C358,696 364,711 375,722C386,733 401,739 418,739C435,739 450,733 461,722C472,711 478,696 478,678C478,661 472,646 461,635C450,624 435,618 418,618C401,618 386,624 375,635C364,646 358,661 358,678z"
        />
        <glyph
          unicode="&#x22B;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M169,676C169,693 175,707 186,718C197,729 211,734 227,734C244,734 258,729 269,718C280,707 285,693 285,676C285,659 280,646 269,635C258,624 244,618 227,618C211,618 197,624 186,635C175,646 169,659 169,676M358,676C358,693 364,707 375,718C386,729 400,734 416,734C432,734 446,728 457,717C468,706 474,692 474,676C474,659 468,646 457,635C446,624 432,618 416,618C400,618 386,624 375,635C364,646 358,659 358,676M148,848l346,0l0,-70l-346,0z"
        />
        <glyph
          unicode="&#x231;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M261,677C261,695 267,710 278,721C289,732 304,737 321,737C338,737 353,732 364,721C375,710 381,695 381,677C381,659 375,645 364,634C353,623 338,617 321,617C304,617 289,623 278,634C267,645 261,659 261,677M148,852l346,0l0,-70l-346,0z"
        />
        <glyph
          unicode="&#x1ECD;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M261,-144C261,-127 267,-112 278,-100C289,-89 304,-83 321,-83C338,-83 353,-89 364,-100C375,-112 381,-127 381,-144C381,-162 375,-176 364,-187C353,-198 338,-203 321,-203C304,-203 289,-198 278,-187C267,-176 261,-162 261,-144z"
        />
        <glyph
          unicode="&#xF2;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M123,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1ECF;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M305,643C324,652 338,661 347,672C355,682 359,694 359,707C359,720 354,730 345,739C336,747 324,751 309,751C288,751 268,745 249,732l-27,55C235,797 251,805 268,810C285,815 303,818 322,818C354,818 380,809 401,791C422,772 432,748 432,718C432,664 403,624 346,597z"
        />
        <glyph
          unicode="&#x1A1;"
          horizAdvX="645"
          d="M607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267C38,320 50,367 75,408C100,449 134,482 177,505C220,528 269,540 324,540C343,540 366,539 391,536C396,535 404,535 415,534C425,533 434,533 443,533C466,533 483,539 495,552C507,564 513,580 513,599C513,620 506,641 492,661l76,28C589,660 599,629 599,595C599,566 592,540 578,519C564,498 544,481 519,470C547,445 569,416 584,381C599,346 607,308 607,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267z"
        />
        <glyph
          unicode="&#x1EDB;"
          horizAdvX="645"
          d="M607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267C38,320 50,367 75,408C100,449 134,482 177,505C220,528 269,540 324,540C343,540 366,539 391,536C396,535 404,535 415,534C425,533 434,533 443,533C466,533 483,539 495,552C507,564 513,580 513,599C513,620 506,641 492,661l76,28C589,660 599,629 599,595C599,566 592,540 578,519C564,498 544,481 519,470C547,445 569,416 584,381C599,346 607,308 607,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M373,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EE3;"
          horizAdvX="645"
          d="M607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267C38,320 50,367 75,408C100,449 134,482 177,505C220,528 269,540 324,540C343,540 366,539 391,536C396,535 404,535 415,534C425,533 434,533 443,533C466,533 483,539 495,552C507,564 513,580 513,599C513,620 506,641 492,661l76,28C589,660 599,629 599,595C599,566 592,540 578,519C564,498 544,481 519,470C547,445 569,416 584,381C599,346 607,308 607,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M262,-144C262,-127 268,-112 279,-100C290,-89 305,-83 322,-83C339,-83 354,-89 365,-100C376,-112 382,-127 382,-144C382,-162 376,-176 365,-187C354,-198 339,-203 322,-203C305,-203 290,-198 279,-187C268,-176 262,-162 262,-144z"
        />
        <glyph
          unicode="&#x1EDD;"
          horizAdvX="645"
          d="M607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267C38,320 50,367 75,408C100,449 134,482 177,505C220,528 269,540 324,540C343,540 366,539 391,536C396,535 404,535 415,534C425,533 434,533 443,533C466,533 483,539 495,552C507,564 513,580 513,599C513,620 506,641 492,661l76,28C589,660 599,629 599,595C599,566 592,540 578,519C564,498 544,481 519,470C547,445 569,416 584,381C599,346 607,308 607,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M124,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EDF;"
          horizAdvX="645"
          d="M607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267C38,320 50,367 75,408C100,449 134,482 177,505C220,528 269,540 324,540C343,540 366,539 391,536C396,535 404,535 415,534C425,533 434,533 443,533C466,533 483,539 495,552C507,564 513,580 513,599C513,620 506,641 492,661l76,28C589,660 599,629 599,595C599,566 592,540 578,519C564,498 544,481 519,470C547,445 569,416 584,381C599,346 607,308 607,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M306,643C325,652 339,661 348,672C356,682 360,694 360,707C360,720 355,730 346,739C337,747 325,751 310,751C289,751 269,745 250,732l-27,55C236,797 252,805 269,810C286,815 304,818 323,818C355,818 381,809 402,791C423,772 433,748 433,718C433,664 404,624 347,597z"
        />
        <glyph
          unicode="&#x1EE1;"
          horizAdvX="648"
          d="M607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267C38,320 50,367 75,408C100,449 134,482 177,505C220,528 269,540 324,540C343,540 366,539 391,536C396,535 404,535 415,534C425,533 434,533 443,533C466,533 483,539 495,552C507,564 513,580 513,599C513,620 506,641 492,661l76,28C589,660 599,629 599,595C599,566 592,540 578,519C564,498 544,481 519,470C547,445 569,416 584,381C599,346 607,308 607,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M303,647C294,656 286,662 280,667C273,671 267,673 261,673C248,673 238,668 230,657C222,646 218,632 217,613l-60,0C158,654 167,686 185,711C202,735 225,747 254,747C269,747 282,744 293,737C304,730 318,720 334,707C343,698 351,692 358,688C364,683 370,681 376,681C389,681 399,686 407,696C415,706 419,720 420,738l60,0C479,699 469,667 452,643C435,619 412,607 383,607C368,607 356,610 345,617C334,624 320,634 303,647z"
        />
        <glyph
          unicode="&#x151;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M259,744l125,0l-108,-137l-98,0M446,744l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#x20F;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M321,751C370,751 410,738 443,712C475,686 492,651 494,607l-82,0C411,630 402,649 385,663C368,676 346,683 321,683C296,683 274,676 257,663C240,649 231,630 230,607l-82,0C150,651 167,686 200,712C232,738 272,751 321,751z"
        />
        <glyph
          unicode="&#x14D;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M148,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E53;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M148,696l346,0l0,-70l-346,0M358,876l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1E51;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M148,696l346,0l0,-70l-346,0M151,876l134,0l114,-117l-96,0z"
        />
        <glyph
          unicode="&#x1EB;"
          horizAdvX="645"
          d="M307,-110C307,-126 312,-138 323,-147C334,-156 348,-160 366,-160C377,-160 388,-158 399,-155C410,-152 419,-149 427,-144l20,-61C435,-212 421,-218 404,-222C387,-226 369,-228 352,-228C312,-228 281,-218 258,-199C235,-180 223,-154 223,-123C223,-77 245,-38 288,-5C240,0 197,14 159,39C121,63 91,95 70,135C49,174 38,218 38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,209 592,158 563,114C533,70 492,37 440,14C391,-7 356,-27 337,-47C317,-68 307,-89 307,-110M322,100C368,100 406,115 436,146C466,177 481,217 481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100z"
        />
        <glyph
          unicode="&#xF8;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M506,601l70,0l-440,-672l-70,0z"
        />
        <glyph
          unicode="&#x1FF;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M506,601l70,0l-440,-672l-70,0M370,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#xF5;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M304,647C293,656 285,662 278,667C271,671 264,673 257,673C243,673 232,668 223,657C214,646 209,632 208,613l-67,0C142,654 152,686 172,711C191,735 217,747 249,747C266,747 280,744 293,737C305,730 320,720 338,707C349,698 358,692 365,688C372,683 378,681 385,681C399,681 411,686 420,696C429,706 433,720 434,738l67,0C500,699 489,667 470,643C451,619 425,607 393,607C376,607 362,610 350,617C337,624 322,634 304,647z"
        />
        <glyph
          unicode="&#x1E4D;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M302,642C291,649 283,654 276,657C269,660 263,662 256,662C228,662 213,646 212,614l-68,0C145,650 155,678 174,698C193,718 218,728 250,728C267,728 282,725 295,720C308,714 323,706 338,696C350,689 360,683 367,680C374,677 381,675 388,675C401,675 412,679 419,688C426,696 431,707 432,722l66,0C497,687 488,659 469,639C450,618 425,608 394,608C376,608 360,611 347,617C333,623 318,631 302,642M358,876l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="&#x1E4F;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M302,642C291,649 283,654 276,657C269,660 263,662 256,662C228,662 213,646 212,614l-68,0C145,650 155,678 174,698C193,718 218,728 250,728C267,728 282,725 295,720C308,714 323,706 338,696C350,689 360,683 367,680C374,677 381,675 388,675C401,675 412,679 419,688C426,696 431,707 432,722l66,0C497,687 488,659 469,639C450,618 425,608 394,608C376,608 360,611 347,617C333,623 318,631 302,642M169,828C169,845 175,859 186,870C197,881 211,886 227,886C244,886 258,881 269,870C280,859 285,845 285,828C285,811 280,798 269,787C258,776 244,770 227,770C211,770 197,776 186,787C175,798 169,811 169,828M358,828C358,845 364,859 375,870C386,881 400,886 416,886C432,886 446,880 457,869C468,858 474,844 474,828C474,811 468,798 457,787C446,776 432,770 416,770C400,770 386,776 375,787C364,798 358,811 358,828z"
        />
        <glyph
          unicode="&#x22D;"
          horizAdvX="645"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M302,642C291,649 283,654 276,657C269,660 263,662 256,662C228,662 213,646 212,614l-68,0C145,650 155,678 174,698C193,718 218,728 250,728C267,728 282,725 295,720C308,714 323,706 338,696C350,689 360,683 367,680C374,677 381,675 388,675C401,675 412,679 419,688C426,696 431,707 432,722l66,0C497,687 488,659 469,639C450,618 425,608 394,608C376,608 360,611 347,617C333,623 318,631 302,642M148,848l346,0l0,-70l-346,0z"
        />
        <glyph
          unicode="&#x153;"
          horizAdvX="1065"
          d="M1026,227l-420,0C613,188 633,157 664,134C695,111 733,99 780,99C838,99 886,118 924,157l68,-77C967,51 937,30 900,15C863,0 822,-7 777,-7C726,-7 680,2 641,21C601,39 568,65 543,98C519,65 488,39 450,21C412,2 369,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C369,540 412,531 450,513C487,495 518,470 542,437C566,470 597,495 634,513C671,531 712,540 757,540C809,540 856,529 897,506C938,483 970,450 993,409C1016,367 1028,319 1028,264C1028,255 1027,243 1026,227M605,310l303,0C903,349 886,380 859,404C832,428 798,440 757,440C717,440 683,428 656,405C628,381 611,349 605,310M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267z"
        />
        <glyph
          unicode="p"
          horizAdvX="686"
          d="M649,267C649,213 637,165 614,124C591,82 559,50 518,27C477,4 430,-7 378,-7C306,-7 249,17 207,65l0,-259l-125,0l0,728l119,0l0,-70C222,489 247,508 278,521C308,534 341,540 378,540C430,540 477,529 518,506C559,483 591,451 614,410C637,369 649,321 649,267M522,267C522,317 507,357 478,388C448,419 410,434 364,434C334,434 307,427 283,414C259,400 240,380 226,355C212,330 205,300 205,267C205,234 212,204 226,179C240,154 259,134 283,121C307,107 334,100 364,100C410,100 448,115 478,146C507,177 522,217 522,267z"
        />
        <glyph
          unicode="&#xFE;"
          horizAdvX="686"
          d="M649,267C649,213 637,165 614,124C591,82 559,50 518,27C477,4 430,-7 378,-7C306,-7 249,17 207,65l0,-259l-125,0l0,936l125,0l0,-271C249,517 306,540 378,540C430,540 477,529 518,506C559,483 591,451 614,410C637,369 649,321 649,267M522,267C522,317 507,357 478,388C448,419 410,434 364,434C334,434 307,427 283,414C259,400 240,380 226,355C212,330 205,300 205,267C205,234 212,204 226,179C240,154 259,134 283,121C307,107 334,100 364,100C410,100 448,115 478,146C507,177 522,217 522,267z"
        />
        <glyph
          unicode="q"
          horizAdvX="686"
          d="M604,534l0,-728l-125,0l0,259C437,17 380,-7 308,-7C256,-7 210,4 169,27C128,50 96,82 73,123C50,164 38,212 38,267C38,321 50,369 73,410C96,451 128,483 169,506C210,529 256,540 308,540C345,540 378,534 409,521C439,508 464,489 485,464l0,70M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267z"
        />
        <glyph
          unicode="r"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540z"
        />
        <glyph
          unicode="&#x155;"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540M287,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x159;"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540M427,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x157;"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540M205,-136C205,-155 200,-174 189,-195l-32,-70l-61,0l24,81C97,-177 85,-161 85,-136C85,-120 91,-107 102,-97C113,-88 127,-83 145,-83C163,-83 178,-88 189,-98C200,-108 205,-121 205,-136z"
        />
        <glyph
          unicode="&#x211;"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540M-14,744l125,0l80,-137l-98,0M173,744l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#x1E5B;"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540M85,-144C85,-127 91,-112 102,-100C113,-89 128,-83 145,-83C162,-83 177,-89 188,-100C199,-112 205,-127 205,-144C205,-162 199,-176 188,-187C177,-198 162,-203 145,-203C128,-203 113,-198 102,-187C91,-176 85,-162 85,-144z"
        />
        <glyph
          unicode="&#x213;"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540M236,751C285,751 325,738 358,712C390,686 407,651 409,607l-82,0C326,630 317,649 300,663C283,676 261,683 236,683C211,683 189,676 172,663C155,649 146,630 145,607l-82,0C65,651 82,686 115,712C147,738 187,751 236,751z"
        />
        <glyph
          unicode="&#x1E5F;"
          horizAdvX="420"
          d="M391,540l0,-119C380,423 371,424 362,424C313,424 275,410 248,382C221,353 207,312 207,259l0,-259l-125,0l0,534l119,0l0,-78C237,512 300,540 391,540M-28,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="s"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53z"
        />
        <glyph
          unicode="&#x15B;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M305,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x1E65;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M291,724l134,0l-152,-117l-96,0M194,829C194,847 200,862 211,873C222,884 237,889 254,889C271,889 286,884 297,873C308,862 314,847 314,829C314,811 308,797 297,786C286,775 271,769 254,769C237,769 222,775 211,786C200,797 194,811 194,829z"
        />
        <glyph
          unicode="&#xA78C;"
          horizAdvX="273"
          d="M75,671l124,0l-16,-374l-109,0z"
        />
        <glyph
          unicode="&#x161;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M445,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x1E67;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M445,714l-135,-107l-112,0l-135,107l104,0l87,-55l87,55M194,806C194,824 200,839 211,850C222,861 237,866 254,866C271,866 286,861 297,850C308,839 314,824 314,806C314,788 308,774 297,763C286,752 271,746 254,746C237,746 222,752 211,763C200,774 194,788 194,806z"
        />
        <glyph
          unicode="&#x15F;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M133,-207l23,59C177,-159 199,-164 224,-164C259,-164 277,-152 277,-128C277,-118 273,-110 264,-104C255,-98 242,-95 224,-95l-31,0l27,102l68,0l-14,-56C303,-52 324,-61 339,-76C354,-91 361,-110 361,-133C361,-162 349,-185 324,-202C299,-219 266,-228 225,-228C189,-228 158,-221 133,-207z"
        />
        <glyph
          unicode="&#x15D;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M342,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x219;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M314,-136C314,-155 309,-174 298,-195l-32,-70l-61,0l24,81C206,-177 194,-161 194,-136C194,-120 200,-107 211,-97C222,-88 236,-83 254,-83C272,-83 287,-88 298,-98C309,-108 314,-121 314,-136z"
        />
        <glyph
          unicode="&#x1E61;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M183,690C183,711 190,728 203,741C216,754 233,761 254,761C275,761 292,754 305,741C318,728 325,711 325,690C325,669 318,652 305,639C292,626 275,619 254,619C233,619 216,626 203,639C190,652 183,669 183,690z"
        />
        <glyph
          unicode="&#x1E63;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M194,-144C194,-127 200,-112 211,-100C222,-89 237,-83 254,-83C271,-83 286,-89 297,-100C308,-112 314,-127 314,-144C314,-162 308,-176 297,-187C286,-198 271,-203 254,-203C237,-203 222,-198 211,-187C200,-176 194,-162 194,-144z"
        />
        <glyph
          unicode="&#x1E69;"
          horizAdvX="515"
          d="M21,53l48,95C93,132 122,119 156,110C189,100 222,95 255,95C330,95 367,115 367,154C367,173 358,186 339,193C320,200 289,207 247,214C203,221 167,228 140,237C112,246 88,261 68,283C47,304 37,334 37,373C37,424 58,464 101,495C143,525 200,540 272,540C309,540 345,536 382,528C419,519 449,508 472,494l-48,-95C379,426 328,439 271,439C234,439 207,434 188,423C169,412 159,397 159,379C159,359 169,345 190,337C210,328 241,320 284,313C327,306 362,299 389,290C416,281 440,267 460,246C479,225 489,196 489,158C489,108 467,68 424,38C381,8 322,-7 247,-7C204,-7 161,-1 120,10C79,21 46,36 21,53M194,-144C194,-127 200,-112 211,-100C222,-89 237,-83 254,-83C271,-83 286,-89 297,-100C308,-112 314,-127 314,-144C314,-162 308,-176 297,-187C286,-198 271,-203 254,-203C237,-203 222,-198 211,-187C200,-176 194,-162 194,-144M183,690C183,711 190,728 203,741C216,754 233,761 254,761C275,761 292,754 305,741C318,728 325,711 325,690C325,669 318,652 305,639C292,626 275,619 254,619C233,619 216,626 203,639C190,652 183,669 183,690z"
        />
        <glyph
          unicode="&#xDF;"
          horizAdvX="684"
          d="M647,204C647,139 624,87 579,50C534,12 474,-7 400,-7C380,-7 359,-5 338,-2C317,1 298,4 283,9l17,103C325,103 356,99 392,99C432,99 464,109 487,128C510,147 521,173 521,208C521,242 508,268 483,287C458,306 423,315 379,315l-55,0l0,103C367,419 402,430 428,452C453,473 466,502 466,538C466,571 455,596 434,615C413,634 383,643 344,643C301,643 267,631 243,607C219,582 207,547 207,501l0,-501l-125,0l0,489C82,545 93,593 116,632C139,671 170,700 210,720C249,739 294,749 344,749C393,749 436,740 473,723C510,705 538,681 557,650C576,619 586,584 586,545C586,512 579,482 564,456C549,429 530,407 505,390C549,377 584,355 609,323C634,290 647,251 647,204z"
        />
        <glyph
          unicode="&#x17F;"
          horizAdvX="319"
          d="M413,720l-35,-94C358,641 335,648 310,648C259,648 234,619 234,562l0,-562l-125,0l0,430l-87,0l0,100l87,0l0,34C109,621 126,666 159,699C192,732 240,749 301,749C348,749 386,739 413,720z"
        />
        <glyph
          unicode="t"
          horizAdvX="424"
          d="M403,29C388,17 371,8 350,2C329,-4 306,-7 283,-7C224,-7 179,8 147,39C115,70 99,114 99,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C224,150 230,130 243,117C256,103 274,96 298,96C326,96 349,103 368,118z"
        />
        <glyph
          unicode="&#x167;"
          horizAdvX="434"
          d="M408,29C393,17 376,8 355,2C334,-4 311,-7 288,-7C229,-7 184,8 152,39C120,70 104,114 104,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C229,150 235,130 248,117C261,103 279,96 303,96C331,96 354,103 373,118M29,322l331,0l0,-81l-331,0z"
        />
        <glyph
          unicode="&#x165;"
          horizAdvX="424"
          d="M403,29C388,17 371,8 350,2C329,-4 306,-7 283,-7C224,-7 179,8 147,39C115,70 99,114 99,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C224,150 230,130 243,117C256,103 274,96 298,96C326,96 349,103 368,118M299,767l94,0l-13,-176l-81,0z"
        />
        <glyph
          unicode="&#x163;"
          horizAdvX="424"
          d="M403,29C388,17 371,8 350,2C329,-4 306,-7 283,-7C224,-7 179,8 147,39C115,70 99,114 99,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C224,150 230,130 243,117C256,103 274,96 298,96C326,96 349,103 368,118M136,-207l23,59C180,-159 202,-164 227,-164C262,-164 280,-152 280,-128C280,-118 276,-110 267,-104C258,-98 245,-95 227,-95l-31,0l27,102l68,0l-14,-56C306,-52 327,-61 342,-76C357,-91 364,-110 364,-133C364,-162 352,-185 327,-202C302,-219 269,-228 228,-228C192,-228 161,-221 136,-207z"
        />
        <glyph
          unicode="&#x21B;"
          horizAdvX="424"
          d="M403,29C388,17 371,8 350,2C329,-4 306,-7 283,-7C224,-7 179,8 147,39C115,70 99,114 99,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C224,150 230,130 243,117C256,103 274,96 298,96C326,96 349,103 368,118M317,-136C317,-155 312,-174 301,-195l-32,-70l-61,0l24,81C209,-177 197,-161 197,-136C197,-120 203,-107 214,-97C225,-88 239,-83 257,-83C275,-83 290,-88 301,-98C312,-108 317,-121 317,-136z"
        />
        <glyph
          unicode="&#x1E97;"
          horizAdvX="424"
          d="M403,29C388,17 371,8 350,2C329,-4 306,-7 283,-7C224,-7 179,8 147,39C115,70 99,114 99,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C224,150 230,130 243,117C256,103 274,96 298,96C326,96 349,103 368,118M4,796C4,814 10,829 21,840C32,851 47,857 64,857C81,857 96,851 107,840C118,829 124,814 124,796C124,779 118,764 107,753C96,742 81,736 64,736C47,736 32,742 21,753C10,764 4,779 4,796M198,796C198,814 204,829 215,840C226,851 241,857 258,857C275,857 290,851 301,840C312,829 318,814 318,796C318,779 312,764 301,753C290,742 275,736 258,736C241,736 226,742 215,753C204,764 198,779 198,796z"
        />
        <glyph
          unicode="&#x1E6D;"
          horizAdvX="424"
          d="M403,29C388,17 371,8 350,2C329,-4 306,-7 283,-7C224,-7 179,8 147,39C115,70 99,114 99,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C224,150 230,130 243,117C256,103 274,96 298,96C326,96 349,103 368,118M197,-144C197,-127 203,-112 214,-100C225,-89 240,-83 257,-83C274,-83 289,-89 300,-100C311,-112 317,-127 317,-144C317,-162 311,-176 300,-187C289,-198 274,-203 257,-203C240,-203 225,-198 214,-187C203,-176 197,-162 197,-144z"
        />
        <glyph
          unicode="&#x1E6F;"
          horizAdvX="424"
          d="M403,29C388,17 371,8 350,2C329,-4 306,-7 283,-7C224,-7 179,8 147,39C115,70 99,114 99,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C224,150 230,130 243,117C256,103 274,96 298,96C326,96 349,103 368,118M84,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="u"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270z"
        />
        <glyph
          unicode="&#xFA;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M389,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x16D;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M165,744l82,0C248,721 257,703 274,690C291,676 313,669 338,669C363,669 385,676 402,690C419,703 428,721 429,744l82,0C509,700 492,665 460,639C427,613 387,600 338,600C289,600 249,613 217,639C184,665 167,700 165,744z"
        />
        <glyph
          unicode="&#x1D4;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M529,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xFB;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M426,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x215;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M88,744l125,0l80,-137l-98,0M275,744l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#xFC;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M181,678C181,696 187,711 198,722C209,733 224,739 241,739C258,739 273,733 284,722C295,711 301,696 301,678C301,661 295,646 284,635C273,624 258,618 241,618C224,618 209,624 198,635C187,646 181,661 181,678M375,678C375,696 381,711 392,722C403,733 418,739 435,739C452,739 467,733 478,722C489,711 495,696 495,678C495,661 489,646 478,635C467,624 452,618 435,618C418,618 403,624 392,635C381,646 375,661 375,678z"
        />
        <glyph
          unicode="&#x1EE5;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M278,-144C278,-127 284,-112 295,-100C306,-89 321,-83 338,-83C355,-83 370,-89 381,-100C392,-112 398,-127 398,-144C398,-162 392,-176 381,-187C370,-198 355,-203 338,-203C321,-203 306,-198 295,-187C284,-176 278,-162 278,-144z"
        />
        <glyph
          unicode="&#xF9;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M140,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EE7;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M322,643C341,652 355,661 364,672C372,682 376,694 376,707C376,720 371,730 362,739C353,747 341,751 326,751C305,751 285,745 266,732l-27,55C252,797 268,805 285,810C302,815 320,818 339,818C371,818 397,809 418,791C439,772 449,748 449,718C449,664 420,624 363,597z"
        />
        <glyph
          unicode="&#x1B0;"
          horizAdvX="692"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M518,534l21,0C558,534 574,540 585,552C596,563 601,579 601,598C601,621 594,642 581,660l77,28C678,663 688,631 688,594C688,548 675,512 648,485C621,458 585,445 538,445l-20,0z"
        />
        <glyph
          unicode="&#x1EE9;"
          horizAdvX="692"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M518,534l21,0C558,534 574,540 585,552C596,563 601,579 601,598C601,621 594,642 581,660l77,28C678,663 688,631 688,594C688,548 675,512 648,485C621,458 585,445 538,445l-20,0M389,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EF1;"
          horizAdvX="692"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M518,534l21,0C558,534 574,540 585,552C596,563 601,579 601,598C601,621 594,642 581,660l77,28C678,663 688,631 688,594C688,548 675,512 648,485C621,458 585,445 538,445l-20,0M278,-144C278,-127 284,-112 295,-100C306,-89 321,-83 338,-83C355,-83 370,-89 381,-100C392,-112 398,-127 398,-144C398,-162 392,-176 381,-187C370,-198 355,-203 338,-203C321,-203 306,-198 295,-187C284,-176 278,-162 278,-144z"
        />
        <glyph
          unicode="&#x1EEB;"
          horizAdvX="692"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M518,534l21,0C558,534 574,540 585,552C596,563 601,579 601,598C601,621 594,642 581,660l77,28C678,663 688,631 688,594C688,548 675,512 648,485C621,458 585,445 538,445l-20,0M140,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EED;"
          horizAdvX="692"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M518,534l21,0C558,534 574,540 585,552C596,563 601,579 601,598C601,621 594,642 581,660l77,28C678,663 688,631 688,594C688,548 675,512 648,485C621,458 585,445 538,445l-20,0M322,643C341,652 355,661 364,672C372,682 376,694 376,707C376,720 371,730 362,739C353,747 341,751 326,751C305,751 285,745 266,732l-27,55C252,797 268,805 285,810C302,815 320,818 339,818C371,818 397,809 418,791C439,772 449,748 449,718C449,664 420,624 363,597z"
        />
        <glyph
          unicode="&#x1EEF;"
          horizAdvX="692"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M518,534l21,0C558,534 574,540 585,552C596,563 601,579 601,598C601,621 594,642 581,660l77,28C678,663 688,631 688,594C688,548 675,512 648,485C621,458 585,445 538,445l-20,0M321,647C310,656 302,662 295,667C288,671 281,673 274,673C260,673 249,668 240,657C231,646 226,632 225,613l-67,0C159,654 169,686 189,711C208,735 234,747 266,747C283,747 297,744 310,737C322,730 337,720 355,707C366,698 375,692 382,688C389,683 395,681 402,681C416,681 428,686 437,696C446,706 450,720 451,738l67,0C517,699 506,667 487,643C468,619 442,607 410,607C393,607 379,610 367,617C354,624 339,634 321,647z"
        />
        <glyph
          unicode="&#x171;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M276,744l125,0l-108,-137l-98,0M463,744l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#x217;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M338,751C387,751 427,738 460,712C492,686 509,651 511,607l-82,0C428,630 419,649 402,663C385,676 363,683 338,683C313,683 291,676 274,663C257,649 248,630 247,607l-82,0C167,651 184,686 217,712C249,738 289,751 338,751z"
        />
        <glyph
          unicode="&#x16B;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M165,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1E7B;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M165,696l346,0l0,-70l-346,0M186,828C186,845 192,859 203,870C214,881 228,886 244,886C261,886 275,881 286,870C297,859 302,845 302,828C302,811 297,798 286,787C275,776 261,770 244,770C228,770 214,776 203,787C192,798 186,811 186,828M375,828C375,845 381,859 392,870C403,881 417,886 433,886C449,886 463,880 474,869C485,858 491,844 491,828C491,811 485,798 474,787C463,776 449,770 433,770C417,770 403,776 392,787C381,798 375,811 375,828z"
        />
        <glyph
          unicode="&#x173;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M397,-121C397,-90 409,-61 433,-33C457,-6 494,18 544,37l55,-37C557,-19 527,-37 509,-54C490,-72 481,-91 481,-111C481,-126 486,-138 497,-147C508,-156 522,-160 540,-160C563,-160 584,-155 601,-144l21,-61C595,-220 563,-228 527,-228C486,-228 455,-218 432,-198C409,-179 397,-153 397,-121z"
        />
        <glyph
          unicode="&#x16F;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M221,707C221,739 232,766 255,789C277,812 304,823 337,823C370,823 398,812 421,789C443,766 454,739 454,707C454,675 443,648 421,626C398,604 370,593 337,593C304,593 277,604 255,626C232,648 221,675 221,707M401,707C401,726 395,741 383,754C370,766 355,772 337,772C319,772 304,766 292,754C280,741 274,726 274,707C274,689 280,674 292,662C304,650 319,644 337,644C356,644 371,650 383,662C395,674 401,689 401,707z"
        />
        <glyph
          unicode="&#x169;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M321,647C310,656 302,662 295,667C288,671 281,673 274,673C260,673 249,668 240,657C231,646 226,632 225,613l-67,0C159,654 169,686 189,711C208,735 234,747 266,747C283,747 297,744 310,737C322,730 337,720 355,707C366,698 375,692 382,688C389,683 395,681 402,681C416,681 428,686 437,696C446,706 450,720 451,738l67,0C517,699 506,667 487,643C468,619 442,607 410,607C393,607 379,610 367,617C354,624 339,634 321,647z"
        />
        <glyph
          unicode="&#x1E79;"
          horizAdvX="681"
          d="M599,534l0,-534l-119,0l0,68C460,44 435,26 405,13C375,0 343,-7 308,-7C237,-7 181,13 140,53C99,92 78,151 78,229l0,305l125,0l0,-288C203,198 214,162 236,139C257,115 288,103 328,103C373,103 408,117 435,145C461,172 474,212 474,264l0,270M319,642C308,649 300,654 293,657C286,660 280,662 273,662C245,662 230,646 229,614l-68,0C162,650 172,678 191,698C210,718 235,728 267,728C284,728 299,725 312,720C325,714 340,706 355,696C367,689 377,683 384,680C391,677 398,675 405,675C418,675 429,679 436,688C443,696 448,707 449,722l66,0C514,687 505,659 486,639C467,618 442,608 411,608C393,608 377,611 364,617C350,623 335,631 319,642M375,876l134,0l-152,-117l-96,0z"
        />
        <glyph
          unicode="v"
          horizAdvX="578"
          d="M582,534l-229,-534l-129,0l-229,534l130,0l166,-396l171,396z"
        />
        <glyph
          unicode="w"
          horizAdvX="919"
          d="M915,534l-197,-534l-120,0l-138,368l-140,-368l-120,0l-196,534l118,0l141,-394l147,394l105,0l144,-396l145,396z"
        />
        <glyph
          unicode="&#x1E83;"
          horizAdvX="919"
          d="M915,534l-197,-534l-120,0l-138,368l-140,-368l-120,0l-196,534l118,0l141,-394l147,394l105,0l144,-396l145,396M508,742l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x175;"
          horizAdvX="919"
          d="M915,534l-197,-534l-120,0l-138,368l-140,-368l-120,0l-196,534l118,0l141,-394l147,394l105,0l144,-396l145,396M545,605l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x1E85;"
          horizAdvX="919"
          d="M915,534l-197,-534l-120,0l-138,368l-140,-368l-120,0l-196,534l118,0l141,-394l147,394l105,0l144,-396l145,396M300,676C300,694 306,709 317,720C328,731 343,737 360,737C377,737 392,731 403,720C414,709 420,694 420,676C420,659 414,644 403,633C392,622 377,616 360,616C343,616 328,622 317,633C306,644 300,659 300,676M494,676C494,694 500,709 511,720C522,731 537,737 554,737C571,737 586,731 597,720C608,709 614,694 614,676C614,659 608,644 597,633C586,622 571,616 554,616C537,616 522,622 511,633C500,644 494,659 494,676z"
        />
        <glyph
          unicode="&#x1E81;"
          horizAdvX="919"
          d="M915,534l-197,-534l-120,0l-138,368l-140,-368l-120,0l-196,534l118,0l141,-394l147,394l105,0l144,-396l145,396M259,742l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="x"
          horizAdvX="573"
          d="M422,0l-137,185l-138,-185l-138,0l208,271l-199,263l139,0l131,-175l131,175l135,0l-200,-261l209,-273z"
        />
        <glyph
          unicode="y"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395z"
        />
        <glyph
          unicode="&#xFD;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M327,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x177;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M364,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#xFF;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M119,678C119,696 125,711 136,722C147,733 162,739 179,739C196,739 211,733 222,722C233,711 239,696 239,678C239,661 233,646 222,635C211,624 196,618 179,618C162,618 147,624 136,635C125,646 119,661 119,678M313,678C313,696 319,711 330,722C341,733 356,739 373,739C390,739 405,733 416,722C427,711 433,696 433,678C433,661 427,646 416,635C405,624 390,618 373,618C356,618 341,624 330,635C319,646 313,661 313,678z"
        />
        <glyph
          unicode="&#x1E8F;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M205,690C205,711 212,728 225,741C238,754 255,761 276,761C297,761 314,754 327,741C340,728 347,711 347,690C347,669 340,652 327,639C314,626 297,619 276,619C255,619 238,626 225,639C212,652 205,669 205,690z"
        />
        <glyph
          unicode="&#x1EF5;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M376,-144C376,-127 382,-112 393,-100C404,-89 419,-83 436,-83C453,-83 468,-89 479,-100C490,-112 496,-127 496,-144C496,-162 490,-176 479,-187C468,-198 453,-203 436,-203C419,-203 404,-198 393,-187C382,-176 376,-162 376,-144z"
        />
        <glyph
          unicode="&#x1EF3;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M78,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x1EF7;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M260,643C279,652 293,661 302,672C310,682 314,694 314,707C314,720 309,730 300,739C291,747 279,751 264,751C243,751 223,745 204,732l-27,55C190,797 206,805 223,810C240,815 258,818 277,818C309,818 335,809 356,791C377,772 387,748 387,718C387,664 358,624 301,597z"
        />
        <glyph
          unicode="&#x233;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M103,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x1EF9;"
          horizAdvX="578"
          d="M582,534l-250,-577C309,-101 280,-142 247,-165C214,-189 173,-201 126,-201C99,-201 73,-197 47,-188C21,-179 0,-167 -17,-152l50,92C45,-71 59,-80 76,-87C92,-94 108,-97 125,-97C147,-97 165,-91 180,-80C194,-69 207,-50 219,-23l9,21l-233,536l130,0l168,-395l169,395M259,647C248,656 240,662 233,667C226,671 219,673 212,673C198,673 187,668 178,657C169,646 164,632 163,613l-67,0C97,654 107,686 127,711C146,735 172,747 204,747C221,747 235,744 248,737C260,730 275,720 293,707C304,698 313,692 320,688C327,683 333,681 340,681C354,681 366,686 375,696C384,706 388,720 389,738l67,0C455,699 444,667 425,643C406,619 380,607 348,607C331,607 317,610 305,617C292,624 277,634 259,647z"
        />
        <glyph
          unicode="z"
          horizAdvX="532"
          d="M499,100l0,-100l-461,0l0,79l293,355l-287,0l0,100l447,0l0,-79l-294,-355z"
        />
        <glyph
          unicode="&#x17A;"
          horizAdvX="532"
          d="M499,100l0,-100l-461,0l0,79l293,355l-287,0l0,100l447,0l0,-79l-294,-355M319,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x17E;"
          horizAdvX="532"
          d="M499,100l0,-100l-461,0l0,79l293,355l-287,0l0,100l447,0l0,-79l-294,-355M459,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x17C;"
          horizAdvX="532"
          d="M499,100l0,-100l-461,0l0,79l293,355l-287,0l0,100l447,0l0,-79l-294,-355M197,690C197,711 204,728 217,741C230,754 247,761 268,761C289,761 306,754 319,741C332,728 339,711 339,690C339,669 332,652 319,639C306,626 289,619 268,619C247,619 230,626 217,639C204,652 197,669 197,690z"
        />
        <glyph
          unicode="&#x1E93;"
          horizAdvX="532"
          d="M499,100l0,-100l-461,0l0,79l293,355l-287,0l0,100l447,0l0,-79l-294,-355M213,-144C213,-127 219,-112 230,-100C241,-89 256,-83 273,-83C290,-83 305,-89 316,-100C327,-112 333,-127 333,-144C333,-162 327,-176 316,-187C305,-198 290,-203 273,-203C256,-203 241,-198 230,-187C219,-176 213,-162 213,-144z"
        />
        <glyph
          unicode="&#xFB01;"
          horizAdvX="738"
          d="M220,562l0,-32l147,0l0,-100l-143,0l0,-430l-125,0l0,430l-88,0l0,100l88,0l0,34C99,621 116,667 149,700C182,733 229,749 290,749C338,749 376,739 403,720l-35,-94C347,641 324,648 299,648C246,648 220,619 220,562M531,534l125,0l0,-534l-125,0M514,697C514,718 522,736 537,751C552,765 571,772 594,772C617,772 636,765 651,752C666,738 674,721 674,700C674,678 667,660 652,645C637,630 617,622 594,622C571,622 552,629 537,644C522,658 514,676 514,697z"
        />
        <glyph
          unicode="&#xFB02;"
          horizAdvX="658"
          d="M220,562l0,-32l147,0l0,-100l-143,0l0,-430l-125,0l0,430l-88,0l0,100l88,0l0,34C99,621 116,667 149,700C182,733 229,749 290,749C338,749 376,739 403,720l-35,-94C347,641 324,648 299,648C246,648 220,619 220,562M451,742l125,0l0,-742l-125,0z"
        />
        <glyph
          unicode="&#xAA;"
          horizAdvX="408"
          d="M349,611l0,-185l-81,0l0,36C259,450 245,440 226,433C207,426 185,422 160,422C119,422 88,431 66,449C44,466 33,489 33,518C33,547 45,570 68,587C91,604 126,612 174,612l88,0l0,3C262,660 235,683 180,683C161,683 143,680 124,674C105,668 88,660 75,650l-32,56C61,719 83,730 110,738C137,745 163,749 190,749C296,749 349,703 349,611M262,522l0,39l-76,0C139,561 116,548 116,521C116,508 122,498 133,491C144,484 160,480 180,480C220,480 247,494 262,522z"
        />
        <glyph
          unicode="&#xBA;"
          horizAdvX="422"
          d="M28,586C28,617 36,645 52,670C67,695 89,714 117,728C145,742 176,749 211,749C246,749 278,742 306,728C333,714 355,695 371,670C386,645 394,617 394,586C394,555 386,527 371,502C355,477 333,457 306,443C278,429 246,422 211,422C176,422 145,429 117,443C89,457 67,477 52,502C36,527 28,555 28,586M308,586C308,613 299,636 281,653C263,670 240,678 211,678C182,678 159,670 141,653C123,636 114,613 114,586C114,558 123,536 141,519C158,502 182,493 211,493C240,493 264,502 282,519C299,536 308,558 308,586z"
        />
        <glyph
          unicode="&#x410;"
          horizAdvX="777"
          d="M562,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M519,264l-132,306l-131,-306z"
        />
        <glyph
          unicode="&#x411;"
          horizAdvX="735"
          d="M99,700l545,0l0,-107l-417,0l0,-172l207,0C518,421 582,404 626,369C669,334 691,283 691,217C691,148 667,94 619,57C571,19 503,0 414,0l-315,0M407,100C457,100 495,110 521,129C547,148 560,176 560,213C560,285 509,321 407,321l-180,0l0,-221z"
        />
        <glyph
          unicode="&#x412;"
          horizAdvX="746"
          d="M696,194C696,133 673,85 628,51C583,17 517,0 431,0l-332,0l0,700l313,0C491,700 552,684 596,653C640,622 662,577 662,520C662,483 653,452 636,425C618,398 592,378 559,364C604,352 638,332 661,303C684,274 696,237 696,194M227,401l174,0C443,401 475,409 497,426C519,443 530,467 530,500C530,533 519,558 497,575C475,592 443,600 401,600l-174,0M565,204C565,272 519,306 427,306l-200,0l0,-206l200,0C519,100 565,135 565,204z"
        />
        <glyph
          unicode="&#x413;"
          horizAdvX="585"
          d="M573,590l-347,0l1,-590l-128,0l0,700l475,0z"
        />
        <glyph
          unicode="&#x403;"
          horizAdvX="585"
          d="M573,590l-347,0l1,-590l-128,0l0,700l475,0M379,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x490;"
          horizAdvX="588"
          d="M99,700l355,0l0,144l120,0l0,-254l-347,0l0,-590l-128,0z"
        />
        <glyph
          unicode="&#x414;"
          horizAdvX="816"
          d="M792,110l0,-259l-120,0l0,149l-537,0l0,-149l-121,0l1,259l30,0C89,112 120,145 138,208C155,271 166,361 170,478l8,222l513,0l0,-590M208,110l355,0l0,480l-274,0l-5,-124C281,375 273,300 262,240C251,179 233,136 208,110z"
        />
        <glyph
          unicode="&#x415;"
          horizAdvX="670"
          d="M623,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193z"
        />
        <glyph
          unicode="&#x400;"
          horizAdvX="670"
          d="M623,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M157,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x401;"
          horizAdvX="670"
          d="M623,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M198,828C198,846 204,861 215,872C226,883 241,889 258,889C275,889 290,883 301,872C312,861 318,846 318,828C318,811 312,796 301,785C290,774 275,768 258,768C241,768 226,774 215,785C204,796 198,811 198,828M392,828C392,846 398,861 409,872C420,883 435,889 452,889C469,889 484,883 495,872C506,861 512,846 512,828C512,811 506,796 495,785C484,774 469,768 452,768C435,768 420,774 409,785C398,796 392,811 392,828z"
        />
        <glyph
          unicode="&#x416;"
          horizAdvX="1052"
          d="M710,295l-120,0l0,-295l-127,0l0,295l-120,0l-177,-295l-150,0l220,365l-205,335l138,0l171,-293l123,0l0,293l127,0l0,-293l121,0l172,293l138,0l-204,-336l219,-364l-150,0z"
        />
        <glyph
          unicode="&#x417;"
          horizAdvX="649"
          d="M600,196C600,154 586,118 559,87C532,56 495,32 450,15C404,-2 354,-10 300,-10C253,-10 207,-3 160,10C113,23 69,42 29,68l42,95C102,142 136,127 173,116C210,105 247,99 284,99C337,99 381,109 416,130C451,151 468,179 468,215C468,246 455,269 430,285C405,301 370,309 325,309l-163,0l0,102l153,0C354,411 384,419 406,435C428,451 439,473 439,500C439,533 424,558 395,576C365,594 327,603 281,603C250,603 217,599 184,590C151,581 119,567 90,550l-41,105C85,674 123,689 164,699C204,708 244,713 284,713C336,713 384,705 428,689C471,673 506,650 532,621C558,591 571,556 571,516C571,481 562,451 543,425C524,399 499,379 466,365C508,352 541,330 565,301C588,271 600,236 600,196z"
        />
        <glyph
          unicode="&#x418;"
          horizAdvX="822"
          d="M99,700l128,0l0,-497l376,497l121,0l0,-700l-128,0l0,496l-376,-496l-121,0z"
        />
        <glyph
          unicode="&#x419;"
          horizAdvX="822"
          d="M99,700l128,0l0,-497l376,497l121,0l0,-700l-128,0l0,496l-376,-496l-121,0M228,892l91,0C320,867 328,848 343,835C358,822 381,815 410,815C439,815 461,822 478,835C494,848 502,867 503,892l90,0C592,845 577,810 546,785C515,760 469,747 410,747C350,747 305,759 275,784C244,809 229,845 228,892z"
        />
        <glyph
          unicode="&#x40D;"
          horizAdvX="822"
          d="M99,700l128,0l0,-497l376,497l121,0l0,-700l-128,0l0,496l-376,-496l-121,0M212,894l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x48A;"
          horizAdvX="856"
          d="M228,892l91,0C320,867 328,848 343,835C358,822 381,815 410,815C439,815 461,822 478,835C494,848 502,867 503,892l90,0C592,845 577,810 546,785C515,760 469,747 410,747C350,747 305,759 275,784C244,809 229,845 228,892M826,110l-75,-259l-103,0l33,149l-85,0l0,496l-376,-496l-121,0l0,700l128,0l0,-497l376,497l121,0l0,-590z"
        />
        <glyph
          unicode="&#x41A;"
          horizAdvX="722"
          d="M356,295l-130,0l0,-295l-128,0l0,700l128,0l0,-293l134,0l201,293l138,0l-239,-339l248,-361l-147,0z"
        />
        <glyph
          unicode="&#x40C;"
          horizAdvX="722"
          d="M356,295l-130,0l0,-295l-128,0l0,700l128,0l0,-293l134,0l201,293l138,0l-239,-339l248,-361l-147,0M418,894l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x41B;"
          horizAdvX="795"
          d="M696,700l0,-700l-128,0l0,590l-276,0l-6,-175C283,317 275,238 262,177C249,116 229,69 201,38C173,6 134,-10 85,-10C68,-10 44,-7 14,0l9,113C32,110 42,109 53,109C94,109 123,133 140,182C157,230 167,306 171,410l10,290z"
        />
        <glyph
          unicode="&#x41C;"
          horizAdvX="963"
          d="M741,0l-1,464l-230,-384l-58,0l-230,378l0,-458l-123,0l0,700l107,0l277,-463l274,463l106,0l2,-700z"
        />
        <glyph
          unicode="&#x41D;"
          horizAdvX="818"
          d="M720,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289z"
        />
        <glyph
          unicode="&#x41E;"
          horizAdvX="845"
          d="M45,350C45,418 61,480 94,535C127,590 172,633 229,664C286,695 351,710 422,710C493,710 558,695 615,664C672,633 717,590 750,535C783,480 799,419 799,350C799,281 783,220 750,165C717,110 672,68 615,37C558,6 493,-10 422,-10C351,-10 286,6 229,37C172,68 127,111 94,166C61,221 45,282 45,350M668,350C668,397 657,439 636,477C615,514 585,544 548,565C511,586 469,596 422,596C375,596 333,586 296,565C259,544 229,514 208,477C187,439 176,397 176,350C176,303 187,261 208,224C229,186 259,157 296,136C333,115 375,104 422,104C469,104 511,115 548,136C585,157 615,186 636,224C657,261 668,303 668,350z"
        />
        <glyph
          unicode="&#x41F;"
          horizAdvX="817"
          d="M718,700l0,-700l-128,0l0,590l-363,0l0,-590l-128,0l0,700z"
        />
        <glyph
          unicode="&#x420;"
          horizAdvX="719"
          d="M684,451C684,400 672,356 648,319C624,281 590,252 545,232C500,212 447,202 386,202l-158,0l0,-202l-130,0l0,700l288,0C447,700 500,690 545,670C590,650 624,621 648,584C672,547 684,502 684,451M553,451C553,496 538,530 509,554C480,578 437,590 380,590l-152,0l0,-278l152,0C437,312 480,324 509,348C538,372 553,406 553,451z"
        />
        <glyph
          unicode="&#x421;"
          horizAdvX="721"
          d="M46,350C46,419 62,480 95,535C128,590 173,633 230,664C287,695 350,710 421,710C478,710 531,700 578,680C625,660 665,631 698,593l-84,-79C563,569 501,596 427,596C379,596 336,586 298,565C260,544 230,514 209,477C188,440 177,397 177,350C177,303 188,260 209,223C230,186 260,157 298,136C336,115 379,104 427,104C501,104 563,132 614,187l84,-80C665,69 625,40 578,20C530,0 477,-10 420,-10C349,-10 286,6 229,37C172,68 127,110 95,165C62,220 46,281 46,350z"
        />
        <glyph
          unicode="&#x422;"
          horizAdvX="620"
          d="M606,590l-232,0l0,-590l-129,0l0,590l-231,0l0,110l592,0z"
        />
        <glyph
          unicode="&#x423;"
          horizAdvX="706"
          d="M699,700l-304,-565C368,85 337,48 300,23C263,-2 222,-15 179,-15C144,-15 110,-8 76,7l32,102C135,100 158,96 176,96C218,96 253,118 280,161l11,17l-279,522l139,0l207,-411l213,411z"
        />
        <glyph
          unicode="&#x40E;"
          horizAdvX="706"
          d="M699,700l-304,-565C368,85 337,48 300,23C263,-2 222,-15 179,-15C144,-15 110,-8 76,7l32,102C135,100 158,96 176,96C218,96 253,118 280,161l11,17l-279,522l139,0l207,-411l213,411M167,892l91,0C259,867 267,848 282,835C297,822 320,815 349,815C378,815 400,822 417,835C433,848 441,867 442,892l90,0C531,845 516,810 485,785C454,760 408,747 349,747C289,747 244,759 214,784C183,809 168,845 167,892z"
        />
        <glyph
          unicode="&#x424;"
          horizAdvX="937"
          d="M529,46l0,-74l-120,0l0,74C291,52 200,81 136,134C71,187 39,259 39,351C39,443 71,516 136,569C200,622 291,651 409,657l0,71l120,0l0,-71C647,651 738,622 802,569C866,516 898,443 898,351C898,259 866,186 802,133C738,80 647,51 529,46M773,352C773,413 752,461 711,495C669,529 608,549 529,554l0,-406C609,154 670,174 711,209C752,243 773,291 773,352M409,148l0,406C247,542 166,474 166,351C166,290 187,243 229,209C270,175 330,155 409,148z"
        />
        <glyph
          unicode="&#x425;"
          horizAdvX="692"
          d="M179,700l165,-234l165,234l152,0l-235,-336l254,-364l-154,0l-182,255l-181,-255l-150,0l253,359l-237,341z"
        />
        <glyph
          unicode="&#x427;"
          horizAdvX="742"
          d="M644,700l0,-700l-129,0l0,264C449,239 386,227 327,227C238,227 169,249 121,292C72,335 48,396 48,477l0,223l129,0l0,-209C177,442 191,404 220,378C249,352 290,339 343,339C404,339 461,351 515,376l0,324z"
        />
        <glyph
          unicode="&#x426;"
          horizAdvX="834"
          d="M813,110l0,-264l-120,0l0,154l-594,0l0,700l128,0l0,-590l353,0l0,590l129,0l0,-590z"
        />
        <glyph
          unicode="&#x428;"
          horizAdvX="1097"
          d="M998,700l0,-700l-899,0l0,700l128,0l0,-590l258,0l0,590l128,0l0,-590l257,0l0,590z"
        />
        <glyph
          unicode="&#x429;"
          horizAdvX="1126"
          d="M998,700l0,-700l-899,0l0,700l128,0l0,-590l258,0l0,590l128,0l0,-590l257,0l0,590M1099,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x40F;"
          horizAdvX="807"
          d="M709,0l-246,0l0,-154l-120,0l0,154l-244,0l0,700l128,0l0,-590l353,0l0,590l129,0z"
        />
        <glyph
          unicode="&#x42C;"
          horizAdvX="717"
          d="M682,239C682,162 657,103 608,62C559,21 489,0 400,0l-301,0l0,700l128,0l0,-237l194,0C505,463 570,444 615,407C660,369 682,313 682,239M552,237C552,322 499,364 394,364l-167,0l0,-264l167,0C445,100 484,112 511,135C538,158 552,192 552,237z"
        />
        <glyph
          unicode="&#x42A;"
          horizAdvX="836"
          d="M802,239C802,161 778,102 729,61C680,20 610,0 519,0l-300,0l0,590l-205,0l0,110l333,0l0,-237l194,0C625,463 690,444 735,407C780,369 802,313 802,239M671,237C671,322 619,364 514,364l-167,0l0,-264l167,0C565,100 604,112 631,135C658,158 671,192 671,237z"
        />
        <glyph
          unicode="&#x42B;"
          horizAdvX="1010"
          d="M682,239C682,162 657,103 608,62C559,21 489,0 400,0l-301,0l0,700l128,0l0,-237l194,0C505,463 570,444 615,407C660,369 682,313 682,239M552,237C552,322 499,364 394,364l-167,0l0,-264l167,0C445,100 484,112 511,135C538,158 552,192 552,237M783,700l128,0l0,-700l-128,0z"
        />
        <glyph
          unicode="&#x409;"
          horizAdvX="1186"
          d="M696,700l0,-700l-128,0l0,590l-276,0l-6,-175C283,317 275,238 262,177C249,116 229,69 201,38C173,6 134,-10 85,-10C68,-10 44,-7 14,0l9,113C32,110 42,109 53,109C94,109 123,133 140,182C157,230 167,306 171,410l10,290M1151,239C1151,162 1126,103 1077,62C1028,21 958,0 869,0l-301,0l0,700l128,0l0,-237l194,0C974,463 1039,444 1084,407C1129,369 1151,313 1151,239M1021,237C1021,322 968,364 863,364l-167,0l0,-264l167,0C914,100 953,112 980,135C1007,158 1021,192 1021,237z"
        />
        <glyph
          unicode="&#x40A;"
          horizAdvX="1176"
          d="M1144,223C1144,153 1121,98 1074,59C1027,20 963,0 881,0l-304,0l0,319l-350,0l0,-319l-128,0l0,700l128,0l0,-271l350,0l0,271l128,0l0,-267l192,0C975,433 1036,415 1079,378C1122,341 1144,290 1144,223M1012,218C1012,255 1000,284 975,304C950,323 914,333 868,333l-163,0l0,-233l163,0C913,100 949,110 974,131C999,152 1012,181 1012,218z"
        />
        <glyph
          unicode="&#x405;"
          horizAdvX="645"
          d="M44,74l45,101C118,152 153,134 195,120C236,105 278,98 320,98C372,98 411,106 437,123C462,140 475,162 475,189C475,209 468,226 454,239C439,252 421,262 399,269C377,276 347,285 309,294C256,307 213,319 180,332C147,345 118,365 95,392C71,419 59,455 59,501C59,540 70,575 91,607C112,638 143,663 186,682C228,701 280,710 341,710C384,710 426,705 467,694C508,683 544,668 574,648l-41,-101C502,565 470,579 437,588C404,597 371,602 340,602C289,602 251,593 226,576C201,559 188,536 188,507C188,487 195,471 210,458C224,445 242,435 264,428C286,421 316,412 354,403C406,391 449,379 482,366C515,353 544,333 568,306C591,279 603,243 603,198C603,159 593,124 572,93C551,62 519,37 476,18C433,-1 381,-10 320,-10C266,-10 214,-2 164,13C113,28 73,49 44,74z"
        />
        <glyph
          unicode="&#x404;"
          horizAdvX="711"
          d="M47,350C47,419 62,480 93,535C124,590 166,633 221,664C275,695 336,710 404,710C463,710 517,700 566,680C614,660 654,631 686,593l-82,-80C553,568 488,596 411,596C351,596 300,578 259,541C217,504 191,456 182,396l313,0l0,-103l-311,0C195,236 222,191 263,156C304,121 353,104 411,104C488,104 553,132 604,187l82,-80C654,69 614,40 566,20C517,0 463,-10 404,-10C336,-10 275,6 221,37C166,68 124,110 93,165C62,220 47,281 47,350z"
        />
        <glyph
          unicode="&#x42D;"
          horizAdvX="710"
          d="M663,350C663,281 648,220 617,165C586,110 544,68 490,37C435,6 374,-10 306,-10C247,-10 193,0 145,20C96,40 56,69 24,107l82,80C157,132 222,104 299,104C359,104 410,122 452,159C493,196 519,244 528,304l-313,0l0,103l311,0C515,464 489,509 448,544C407,579 357,596 299,596C222,596 157,568 106,513l-82,80C56,631 96,660 145,680C193,700 247,710 306,710C374,710 435,695 490,664C544,633 586,590 617,535C648,480 663,419 663,350z"
        />
        <glyph
          unicode="&#x406;"
          horizAdvX="326"
          d="M99,700l128,0l0,-700l-128,0z"
        />
        <glyph
          unicode="&#x407;"
          horizAdvX="319"
          d="M94,700l130,0l0,-700l-130,0M2,828C2,846 8,861 19,872C30,883 45,889 62,889C79,889 94,883 105,872C116,861 122,846 122,828C122,811 116,796 105,785C94,774 79,768 62,768C45,768 30,774 19,785C8,796 2,811 2,828M196,828C196,846 202,861 213,872C224,883 239,889 256,889C273,889 288,883 299,872C310,861 316,846 316,828C316,811 310,796 299,785C288,774 273,768 256,768C239,768 224,774 213,785C202,796 196,811 196,828z"
        />
        <glyph
          unicode="&#x408;"
          horizAdvX="554"
          d="M15,91l74,89C128,127 174,100 228,100C301,100 337,143 337,229l0,362l-249,0l0,109l379,0l0,-464C467,154 447,93 407,52C367,11 308,-10 231,-10C186,-10 145,-1 108,17C70,34 39,59 15,91z"
        />
        <glyph
          unicode="&#x40B;"
          horizAdvX="849"
          d="M806,182l0,-182l-128,0l0,165C678,212 665,249 639,274C612,299 576,311 529,311C484,311 433,298 374,273l0,-273l-129,0l0,590l-231,0l0,110l612,0l0,-110l-252,0l0,-200C440,413 501,424 558,424C635,424 696,404 740,363C784,322 806,262 806,182z"
        />
        <glyph
          unicode="&#x42E;"
          horizAdvX="1106"
          d="M1059,350C1059,281 1043,220 1012,165C981,110 938,68 883,37C828,6 766,-10 697,-10C634,-10 577,3 526,29C474,55 432,91 399,138C366,185 345,238 337,297l-110,0l0,-297l-128,0l0,700l128,0l0,-287l112,0C348,471 370,523 403,568C436,613 478,648 529,673C580,698 636,710 697,710C766,710 828,695 883,664C938,633 981,590 1012,535C1043,480 1059,419 1059,350M928,350C928,398 918,441 898,478C878,515 850,544 815,565C780,586 740,596 697,596C654,596 615,586 580,565C545,544 517,515 497,478C476,441 466,398 466,350C466,302 476,259 497,222C517,185 545,156 580,135C615,114 654,104 697,104C740,104 780,114 815,135C850,156 878,185 898,222C918,259 928,302 928,350z"
        />
        <glyph
          unicode="&#x42F;"
          horizAdvX="732"
          d="M633,700l0,-700l-128,0l0,195l-173,0l-14,0l-130,-195l-137,0l150,216C152,234 115,263 89,302C62,341 49,388 49,443C49,496 61,542 85,581C109,620 143,649 187,670C231,690 283,700 342,700M181,445C181,399 195,364 222,339C249,314 289,301 341,301l164,0l0,289l-159,0C293,590 252,578 224,553C195,528 181,492 181,445z"
        />
        <glyph
          unicode="&#x402;"
          horizAdvX="841"
          d="M560,431C637,431 697,412 742,374C786,336 808,282 808,211C808,139 786,85 741,49C696,12 638,-6 569,-6C538,-6 511,-3 486,2l14,106C516,103 534,101 554,101C589,101 619,110 643,128C667,146 679,173 679,208C679,245 666,273 640,293C613,313 578,323 534,323C486,323 433,311 376,287l0,-287l-129,0l0,590l-232,0l0,110l618,0l0,-110l-257,0l0,-190C442,421 503,431 560,431z"
        />
        <glyph
          unicode="&#x462;"
          horizAdvX="820"
          d="M786,235C786,160 762,103 714,62C665,21 596,0 507,0l-306,0l0,548l-183,0l0,91l183,0l0,103l128,0l0,-103l211,0l0,-91l-211,0l0,-90l199,0C611,458 675,439 720,401C764,363 786,308 786,235M654,231C654,316 603,358 502,358l-173,0l0,-258l173,0C552,100 590,111 616,133C641,155 654,188 654,231z"
        />
        <glyph
          unicode="&#x46A;"
          horizAdvX="921"
          d="M906,0l-134,0l-47,122C689,231 632,285 554,285l-34,0l0,-285l-120,0l0,285l-31,0C330,285 296,272 267,247C238,222 214,180 195,122l-46,-122l-134,0l63,152C107,229 141,286 182,322C223,357 271,376 327,378l-225,239l0,83l717,0l0,-80l-226,-242C649,377 698,358 739,323C780,287 815,230 844,152M246,603l215,-225l214,225z"
        />
        <glyph
          unicode="&#x472;"
          horizAdvX="845"
          d="M45,350C45,418 61,480 94,535C127,590 172,633 229,664C286,695 351,710 422,710C493,710 558,695 615,664C672,633 717,590 750,535C783,480 799,419 799,350C799,281 783,220 750,165C717,110 672,68 615,37C558,6 493,-10 422,-10C351,-10 286,6 229,37C172,68 127,111 94,166C61,221 45,282 45,350M668,350C668,397 657,439 636,477C615,514 585,544 548,565C511,586 469,596 422,596C375,596 333,586 296,565C259,544 229,514 208,477C187,439 176,397 176,350C176,303 187,261 208,224C229,186 259,157 296,136C333,115 375,104 422,104C469,104 511,115 548,136C585,157 615,186 636,224C657,261 668,303 668,350M88,398l675,0l0,-96l-675,0z"
        />
        <glyph
          unicode="&#x474;"
          horizAdvX="776"
          d="M11,700l141,0l210,-515l134,368C515,608 539,648 569,674C598,699 636,712 681,712C704,712 731,708 762,701l-13,-124C733,581 719,583 706,583C681,583 660,575 644,558C627,541 612,513 598,474l-172,-474l-129,0z"
        />
        <glyph
          unicode="&#x492;"
          horizAdvX="629"
          d="M257,590l0,-223l284,0l0,-90l-283,0l0,-277l-128,0l0,277l-105,0l0,90l105,0l0,333l475,0l-1,-110z"
        />
        <glyph
          unicode="&#x494;"
          horizAdvX="705"
          d="M400,397C451,397 496,387 536,366C576,345 607,316 630,277C653,238 664,191 664,138C664,83 652,33 628,-10C604,-54 571,-88 530,-112C488,-136 441,-148 389,-148C365,-148 342,-146 319,-141l22,104C354,-40 367,-41 380,-41C409,-41 436,-34 460,-19C483,-5 502,15 516,41C529,66 536,96 536,129C536,180 521,221 490,250C459,279 420,294 371,294C323,294 275,279 227,250l0,-250l-128,0l0,700l495,0l0,-110l-367,0l0,-231C288,384 345,397 400,397z"
        />
        <glyph
          unicode="&#x496;"
          horizAdvX="1087"
          d="M710,295l-120,0l0,-295l-127,0l0,295l-120,0l-177,-295l-150,0l220,365l-205,335l138,0l171,-293l123,0l0,293l127,0l0,-293l121,0l172,293l138,0l-204,-336l219,-364l-150,0M1074,110l0,-259l-120,0l0,149l-68,0l0,110z"
        />
        <glyph
          unicode="&#x498;"
          horizAdvX="649"
          d="M600,196C600,154 586,118 559,87C532,56 495,32 450,15C404,-2 354,-10 300,-10C253,-10 207,-3 160,10C113,23 69,42 29,68l42,95C102,142 136,127 173,116C210,105 247,99 284,99C337,99 381,109 416,130C451,151 468,179 468,215C468,246 455,269 430,285C405,301 370,309 325,309l-163,0l0,102l153,0C354,411 384,419 406,435C428,451 439,473 439,500C439,533 424,558 395,576C365,594 327,603 281,603C250,603 217,599 184,590C151,581 119,567 90,550l-41,105C85,674 123,689 164,699C204,708 244,713 284,713C336,713 384,705 428,689C471,673 506,650 532,621C558,591 571,556 571,516C571,481 562,451 543,425C524,399 499,379 466,365C508,352 541,330 565,301C588,271 600,236 600,196M366,-149l-121,0l0,176l121,0z"
        />
        <glyph
          unicode="&#x49A;"
          horizAdvX="762"
          d="M356,295l-130,0l0,-295l-128,0l0,700l128,0l0,-293l134,0l201,293l138,0l-239,-339l248,-361l-147,0M749,110l0,-259l-120,0l0,149l-68,0l0,110z"
        />
        <glyph
          unicode="&#x49C;"
          horizAdvX="756"
          d="M406,295l-37,0l0,-129l-86,0l0,129l-57,0l0,-295l-128,0l0,700l128,0l0,-293l57,0l0,130l86,0l0,-130l40,0l177,293l138,0l-212,-338l229,-362l-152,0z"
        />
        <glyph
          unicode="&#x49E;"
          horizAdvX="745"
          d="M378,295l-130,0l0,-295l-128,0l0,536l-89,0l0,82l89,0l0,82l128,0l0,-82l153,0l0,-82l-153,0l0,-129l134,0l201,293l138,0l-239,-339l248,-361l-147,0z"
        />
        <glyph
          unicode="&#x4A0;"
          horizAdvX="844"
          d="M477,295l-130,0l0,-295l-128,0l0,590l-205,0l0,110l333,0l0,-293l134,0l201,293l138,0l-239,-338l249,-362l-148,0z"
        />
        <glyph
          unicode="&#x4A2;"
          horizAdvX="849"
          d="M720,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289M825,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x4A4;"
          horizAdvX="1083"
          d="M720,700l0,-700l-130,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289M1067,590l-347,0l1,-590l-128,0l0,700l475,0z"
        />
        <glyph
          unicode="&#x4A6;"
          horizAdvX="1187"
          d="M888,396C939,396 984,386 1025,365C1065,344 1096,315 1119,276C1142,237 1153,191 1153,138C1153,83 1141,33 1117,-10C1093,-54 1060,-88 1019,-112C977,-136 930,-148 878,-148C854,-148 831,-146 808,-141l21,104C842,-40 855,-41 869,-41C914,-41 952,-25 981,6C1010,37 1024,78 1024,129C1024,180 1009,220 980,249C950,278 911,292 863,292C816,292 768,278 718,251l0,-251l-128,0l0,590l-363,0l0,-590l-128,0l0,700l619,0l0,-340C775,384 831,396 888,396z"
        />
        <glyph
          unicode="&#x524;"
          horizAdvX="848"
          d="M718,700l0,-700l-128,0l0,590l-363,0l0,-590l-128,0l0,700M822,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x4A8;"
          horizAdvX="976"
          d="M779,-19C717,-19 659,-8 606,14C555,-3 502,-12 447,-12C371,-12 303,4 243,36C183,68 136,113 102,172C68,230 51,297 51,374C51,444 66,509 95,568C124,627 165,676 218,714l103,-56C277,625 243,582 218,531C193,480 181,425 181,368C181,311 193,261 218,218C242,175 276,141 320,118C364,94 414,82 471,82C481,82 488,82 493,83C449,121 415,167 390,221C365,275 353,334 353,399C353,462 365,517 388,565C411,613 444,650 487,677C529,703 578,716 634,716C688,716 736,704 777,679C818,654 849,620 872,575C895,530 906,478 906,419C906,350 890,286 859,227C828,168 785,118 731,78C751,75 770,73 787,73C824,73 863,79 904,91l11,-92C869,-13 824,-19 779,-19M624,117C673,143 711,181 738,231C765,280 779,339 779,406C779,469 766,518 741,553C715,588 679,606 632,606C585,606 548,588 521,551C494,514 480,463 480,399C480,336 493,280 519,231C544,182 579,144 624,117z"
        />
        <glyph
          unicode="&#x4AA;"
          horizAdvX="721"
          d="M46,350C46,419 62,480 95,535C128,590 173,633 230,664C287,695 350,710 421,710C478,710 531,700 578,680C625,660 665,631 698,593l-84,-79C563,569 501,596 427,596C379,596 336,586 298,565C260,544 230,514 209,477C188,440 177,397 177,350C177,303 188,260 209,223C230,186 260,157 298,136C336,115 379,104 427,104C501,104 563,132 614,187l84,-80C665,69 625,40 578,20C530,0 477,-10 420,-10C349,-10 286,6 229,37C172,68 127,110 95,165C62,220 46,281 46,350M464,-149l-121,0l0,176l121,0z"
        />
        <glyph
          unicode="&#x4AC;"
          horizAdvX="620"
          d="M606,590l-232,0l0,-590l-129,0l0,590l-231,0l0,110l592,0M477,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x4AE;"
          horizAdvX="688"
          d="M409,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0z"
        />
        <glyph
          unicode="&#x4B0;"
          horizAdvX="661"
          d="M396,247l0,-247l-130,0l0,245l-275,455l139,0l205,-341l207,341l128,0M78,301l497,0l0,-95l-497,0z"
        />
        <glyph
          unicode="&#x4B2;"
          horizAdvX="725"
          d="M179,700l165,-234l165,234l152,0l-235,-336l254,-364l-154,0l-182,255l-181,-255l-150,0l253,359l-237,341M714,110l0,-259l-120,0l0,149l-68,0l0,110z"
        />
        <glyph
          unicode="&#x4B4;"
          horizAdvX="983"
          d="M962,109l0,-264l-120,0l0,154l-594,0l0,1l-1,0l0,590l-232,0l0,110l557,0l0,-110l-196,0l0,-481l353,0l0,590l129,0l0,-590z"
        />
        <glyph
          unicode="&#x4B6;"
          horizAdvX="773"
          d="M644,700l0,-700l-129,0l0,264C449,239 386,227 327,227C238,227 169,249 121,292C72,335 48,396 48,477l0,223l129,0l0,-209C177,442 191,404 220,378C249,352 290,339 343,339C404,339 461,351 515,376l0,324M747,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x4B8;"
          horizAdvX="742"
          d="M643,700l0,-700l-129,0l0,264C468,247 424,236 382,231l0,-133l-86,0l0,130C216,233 155,256 112,299C69,342 47,401 47,477l0,223l129,0l0,-209C176,449 186,416 207,391C227,366 257,350 296,343l0,127l86,0l0,-129C427,345 471,357 514,376l0,324z"
        />
        <glyph
          unicode="&#x4BA;"
          horizAdvX="742"
          d="M694,223l0,-223l-128,0l0,209C566,258 552,296 523,322C494,348 453,361 400,361C339,361 281,349 227,324l0,-324l-128,0l0,700l128,0l0,-264C293,461 356,473 415,473C504,473 573,452 622,409C670,366 694,304 694,223z"
        />
        <glyph
          unicode="&#x526;"
          horizAdvX="813"
          d="M694,223l0,-223l-128,0l0,209C566,258 552,296 523,322C494,348 453,361 400,361C339,361 281,349 227,324l0,-324l-128,0l0,700l128,0l0,-264C293,461 356,473 415,473C504,473 573,452 622,409C670,366 694,304 694,223M798,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x4BC;"
          horizAdvX="1004"
          d="M961,292l-586,0C383,253 399,220 422,192C445,163 473,141 506,126C539,111 574,103 611,103C646,103 680,110 715,124C749,138 780,159 809,186l95,-82C863,67 817,38 766,19C715,0 663,-10 610,-10C550,-10 494,2 441,26C388,49 343,84 308,130C273,175 251,229 242,292l-28,0C156,292 111,308 78,340C45,372 28,413 28,464C28,501 37,539 56,578l118,-21C156,529 147,502 147,475C147,452 154,433 169,418C184,403 204,395 231,395l10,0C248,459 269,515 304,563C339,610 382,647 435,672C488,697 544,710 605,710C670,710 730,696 785,667C840,638 884,596 917,542C950,488 966,425 966,353C966,332 964,312 961,292M372,395l460,0C827,436 813,471 792,502C770,533 743,556 711,573C678,590 643,598 605,598C567,598 532,590 499,573C466,556 438,533 416,502C393,471 379,436 372,395z"
        />
        <glyph
          unicode="&#x4BE;"
          horizAdvX="1004"
          d="M961,292l-586,0C383,253 399,220 422,192C445,163 473,141 506,126C539,111 574,103 611,103C646,103 680,110 715,124C749,138 780,159 809,186l95,-82C863,67 817,38 766,19C715,0 663,-10 610,-10C550,-10 494,2 441,26C388,49 343,84 308,130C273,175 251,229 242,292l-28,0C156,292 111,308 78,340C45,372 28,413 28,464C28,501 37,539 56,578l118,-21C156,529 147,502 147,475C147,452 154,433 169,418C184,403 204,395 231,395l10,0C248,459 269,515 304,563C339,610 382,647 435,672C488,697 544,710 605,710C670,710 730,696 785,667C840,638 884,596 917,542C950,488 966,425 966,353C966,332 964,312 961,292M372,395l460,0C827,436 813,471 792,502C770,533 743,556 711,573C678,590 643,598 605,598C567,598 532,590 499,573C466,556 438,533 416,502C393,471 379,436 372,395M673,-149l-121,0l0,176l121,0z"
        />
        <glyph
          unicode="&#x4C0;"
          horizAdvX="327"
          d="M98,700l130,0l0,-700l-130,0z"
        />
        <glyph
          unicode="&#x4C1;"
          horizAdvX="1052"
          d="M710,295l-120,0l0,-295l-127,0l0,295l-120,0l-177,-295l-150,0l220,365l-205,335l138,0l171,-293l123,0l0,293l127,0l0,-293l121,0l172,293l138,0l-204,-336l219,-364l-150,0M346,892l91,0C438,867 446,848 461,835C476,822 499,815 528,815C557,815 579,822 596,835C612,848 620,867 621,892l90,0C710,845 695,810 664,785C633,760 587,747 528,747C468,747 423,759 393,784C362,809 347,845 346,892z"
        />
        <glyph
          unicode="&#x4C3;"
          horizAdvX="729"
          d="M709,-23C709,-80 693,-124 660,-156C627,-188 583,-204 526,-204C483,-204 442,-194 402,-174l32,97C457,-89 479,-95 499,-95C520,-95 537,-88 550,-75C562,-62 568,-43 568,-19C568,18 548,65 508,122C467,179 417,237 357,295l-130,0l0,-295l-128,0l0,700l128,0l0,-293l133,0l201,293l138,0l-239,-338C542,277 604,204 646,143C688,82 709,26 709,-23z"
        />
        <glyph
          unicode="&#x4C7;"
          horizAdvX="815"
          d="M444,-77C467,-90 489,-97 511,-97C533,-97 551,-90 566,-77C581,-64 588,-46 588,-21l0,314l-361,0l0,-293l-128,0l0,700l128,0l0,-296l361,0l0,296l128,0l0,-724C716,-63 708,-95 691,-122C674,-149 652,-169 625,-182C597,-195 566,-202 533,-202C488,-202 447,-191 409,-169z"
        />
        <glyph
          unicode="&#x4C9;"
          horizAdvX="852"
          d="M823,110l-75,-259l-103,0l33,149l-88,0l0,300l-362,0l0,-300l-130,0l0,700l130,0l0,-289l362,0l0,289l130,0l0,-590z"
        />
        <glyph
          unicode="&#x4CB;"
          horizAdvX="737"
          d="M639,700l0,-700l-131,0l0,-149l-121,0l0,259l123,0l0,181C446,266 384,254 324,254C235,254 166,275 118,316C69,357 45,416 45,493l0,207l129,0l0,-193C174,462 188,427 217,402C246,377 286,365 339,365C396,365 453,377 510,402l0,298z"
        />
        <glyph
          unicode="&#x4CD;"
          horizAdvX="1008"
          d="M979,110l-75,-259l-103,0l33,149l-93,0l-1,464l-230,-384l-58,0l-230,378l0,-458l-123,0l0,700l107,0l277,-463l274,463l106,0l2,-590z"
        />
        <glyph
          unicode="&#x4D0;"
          horizAdvX="777"
          d="M562,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M519,264l-132,306l-131,-306M206,892l91,0C298,867 306,848 321,835C336,822 359,815 388,815C417,815 439,822 456,835C472,848 480,867 481,892l90,0C570,845 555,810 524,785C493,760 447,747 388,747C328,747 283,759 253,784C222,809 207,845 206,892z"
        />
        <glyph
          unicode="&#x4D2;"
          horizAdvX="777"
          d="M562,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M519,264l-132,306l-131,-306M231,828C231,846 237,861 248,872C259,883 274,889 291,889C308,889 323,883 334,872C345,861 351,846 351,828C351,811 345,796 334,785C323,774 308,768 291,768C274,768 259,774 248,785C237,796 231,811 231,828M425,828C425,846 431,861 442,872C453,883 468,889 485,889C502,889 517,883 528,872C539,861 545,846 545,828C545,811 539,796 528,785C517,774 502,768 485,768C468,768 453,774 442,785C431,796 425,811 425,828z"
        />
        <glyph
          unicode="&#x4D4;"
          horizAdvX="1069"
          d="M1022,109l0,-109l-525,0l0,162l-266,0l-92,-162l-134,0l406,700l597,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M497,264l0,327l-22,0l-186,-327z"
        />
        <glyph
          unicode="&#x4D6;"
          horizAdvX="670"
          d="M623,109l0,-109l-525,0l0,700l511,0l0,-109l-381,0l0,-182l338,0l0,-107l-338,0l0,-193M173,892l91,0C265,867 273,848 288,835C303,822 326,815 355,815C384,815 406,822 423,835C439,848 447,867 448,892l90,0C537,845 522,810 491,785C460,760 414,747 355,747C295,747 250,759 220,784C189,809 174,845 173,892z"
        />
        <glyph
          unicode="&#x4D8;"
          horizAdvX="822"
          d="M776,352C776,279 759,215 725,160C690,105 645,63 588,34C531,5 469,-10 403,-10C337,-10 276,5 221,34C165,63 120,104 87,158C54,212 37,275 37,347C37,368 38,386 41,402l600,0C634,441 618,476 594,506C570,535 541,558 507,574C472,589 435,597 396,597C361,597 327,590 292,576C257,562 225,541 195,514l-95,82C141,633 188,662 240,681C292,700 345,710 398,710C465,710 527,696 585,668C643,639 689,598 724,544C759,489 776,425 776,352M643,311l-473,0C175,269 188,232 210,201C231,170 259,145 293,128C327,111 364,102 403,102C443,102 480,111 515,128C549,145 578,170 601,201C624,232 638,269 643,311z"
        />
        <glyph
          unicode="&#x4DA;"
          horizAdvX="822"
          d="M776,352C776,279 759,215 725,160C690,105 645,63 588,34C531,5 469,-10 403,-10C337,-10 276,5 221,34C165,63 120,104 87,158C54,212 37,275 37,347C37,368 38,386 41,402l600,0C634,441 618,476 594,506C570,535 541,558 507,574C472,589 435,597 396,597C361,597 327,590 292,576C257,562 225,541 195,514l-95,82C141,633 188,662 240,681C292,700 345,710 398,710C465,710 527,696 585,668C643,639 689,598 724,544C759,489 776,425 776,352M643,311l-473,0C175,269 188,232 210,201C231,170 259,145 293,128C327,111 364,102 403,102C443,102 480,111 515,128C549,145 578,170 601,201C624,232 638,269 643,311M255,828C255,846 261,861 272,872C283,883 298,889 315,889C332,889 347,883 358,872C369,861 375,846 375,828C375,811 369,796 358,785C347,774 332,768 315,768C298,768 283,774 272,785C261,796 255,811 255,828M449,828C449,846 455,861 466,872C477,883 492,889 509,889C526,889 541,883 552,872C563,861 569,846 569,828C569,811 563,796 552,785C541,774 526,768 509,768C492,768 477,774 466,785C455,796 449,811 449,828z"
        />
        <glyph
          unicode="&#x4DC;"
          horizAdvX="1052"
          d="M710,295l-120,0l0,-295l-127,0l0,295l-120,0l-177,-295l-150,0l220,365l-205,335l138,0l171,-293l123,0l0,293l127,0l0,-293l121,0l172,293l138,0l-204,-336l219,-364l-150,0M371,828C371,846 377,861 388,872C399,883 414,889 431,889C448,889 463,883 474,872C485,861 491,846 491,828C491,811 485,796 474,785C463,774 448,768 431,768C414,768 399,774 388,785C377,796 371,811 371,828M565,828C565,846 571,861 582,872C593,883 608,889 625,889C642,889 657,883 668,872C679,861 685,846 685,828C685,811 679,796 668,785C657,774 642,768 625,768C608,768 593,774 582,785C571,796 565,811 565,828z"
        />
        <glyph
          unicode="&#x4DE;"
          horizAdvX="649"
          d="M600,196C600,154 586,118 559,87C532,56 495,32 450,15C404,-2 354,-10 300,-10C253,-10 207,-3 160,10C113,23 69,42 29,68l42,95C102,142 136,127 173,116C210,105 247,99 284,99C337,99 381,109 416,130C451,151 468,179 468,215C468,246 455,269 430,285C405,301 370,309 325,309l-163,0l0,102l153,0C354,411 384,419 406,435C428,451 439,473 439,500C439,533 424,558 395,576C365,594 327,603 281,603C250,603 217,599 184,590C151,581 119,567 90,550l-41,105C85,674 123,689 164,699C204,708 244,713 284,713C336,713 384,705 428,689C471,673 506,650 532,621C558,591 571,556 571,516C571,481 562,451 543,425C524,399 499,379 466,365C508,352 541,330 565,301C588,271 600,236 600,196M165,828C165,846 171,861 182,872C193,883 208,889 225,889C242,889 257,883 268,872C279,861 285,846 285,828C285,811 279,796 268,785C257,774 242,768 225,768C208,768 193,774 182,785C171,796 165,811 165,828M359,828C359,846 365,861 376,872C387,883 402,889 419,889C436,889 451,883 462,872C473,861 479,846 479,828C479,811 473,796 462,785C451,774 436,768 419,768C402,768 387,774 376,785C365,796 359,811 359,828z"
        />
        <glyph
          unicode="&#x4E0;"
          horizAdvX="631"
          d="M588,210C588,169 577,132 556,99C535,66 502,39 459,20C416,0 362,-10 299,-10C246,-10 195,-3 147,11C98,25 59,44 29,69l46,102C102,150 135,133 174,120C213,107 253,101 295,101C346,101 385,111 414,130C443,149 457,176 457,210C457,243 443,269 416,288C389,306 347,315 292,315l-66,0l0,87l175,191l-344,0l0,107l497,0l0,-87l-185,-202C440,404 495,382 532,347C569,311 588,265 588,210z"
        />
        <glyph
          unicode="&#x4E2;"
          horizAdvX="822"
          d="M99,700l128,0l0,-497l376,497l121,0l0,-700l-128,0l0,496l-376,-496l-121,0M237,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x4E4;"
          horizAdvX="822"
          d="M99,700l128,0l0,-497l376,497l121,0l0,-700l-128,0l0,496l-376,-496l-121,0M253,828C253,846 259,861 270,872C281,883 296,889 313,889C330,889 345,883 356,872C367,861 373,846 373,828C373,811 367,796 356,785C345,774 330,768 313,768C296,768 281,774 270,785C259,796 253,811 253,828M447,828C447,846 453,861 464,872C475,883 490,889 507,889C524,889 539,883 550,872C561,861 567,846 567,828C567,811 561,796 550,785C539,774 524,768 507,768C490,768 475,774 464,785C453,796 447,811 447,828z"
        />
        <glyph
          unicode="&#x4E6;"
          horizAdvX="845"
          d="M45,350C45,418 61,480 94,535C127,590 172,633 229,664C286,695 351,710 422,710C493,710 558,695 615,664C672,633 717,590 750,535C783,480 799,419 799,350C799,281 783,220 750,165C717,110 672,68 615,37C558,6 493,-10 422,-10C351,-10 286,6 229,37C172,68 127,111 94,166C61,221 45,282 45,350M668,350C668,397 657,439 636,477C615,514 585,544 548,565C511,586 469,596 422,596C375,596 333,586 296,565C259,544 229,514 208,477C187,439 176,397 176,350C176,303 187,261 208,224C229,186 259,157 296,136C333,115 375,104 422,104C469,104 511,115 548,136C585,157 615,186 636,224C657,261 668,303 668,350M265,828C265,846 271,861 282,872C293,883 308,889 325,889C342,889 357,883 368,872C379,861 385,846 385,828C385,811 379,796 368,785C357,774 342,768 325,768C308,768 293,774 282,785C271,796 265,811 265,828M459,828C459,846 465,861 476,872C487,883 502,889 519,889C536,889 551,883 562,872C573,861 579,846 579,828C579,811 573,796 562,785C551,774 536,768 519,768C502,768 487,774 476,785C465,796 459,811 459,828z"
        />
        <glyph
          unicode="&#x4E8;"
          horizAdvX="845"
          d="M45,350C45,418 61,480 94,535C127,590 172,633 229,664C286,695 351,710 422,710C493,710 558,695 615,664C672,633 717,590 750,535C783,480 799,419 799,350C799,281 783,220 750,165C717,110 672,68 615,37C558,6 493,-10 422,-10C351,-10 286,6 229,37C172,68 127,111 94,166C61,221 45,282 45,350M668,350C668,397 657,439 636,477C615,514 585,544 548,565C511,586 469,596 422,596C375,596 333,586 296,565C259,544 229,514 208,477C187,439 176,397 176,350C176,303 187,261 208,224C229,186 259,157 296,136C333,115 375,104 422,104C469,104 511,115 548,136C585,157 615,186 636,224C657,261 668,303 668,350M88,398l675,0l0,-96l-675,0z"
        />
        <glyph
          unicode="&#x4EA;"
          horizAdvX="845"
          d="M45,350C45,418 61,480 94,535C127,590 172,633 229,664C286,695 351,710 422,710C493,710 558,695 615,664C672,633 717,590 750,535C783,480 799,419 799,350C799,281 783,220 750,165C717,110 672,68 615,37C558,6 493,-10 422,-10C351,-10 286,6 229,37C172,68 127,111 94,166C61,221 45,282 45,350M668,350C668,397 657,439 636,477C615,514 585,544 548,565C511,586 469,596 422,596C375,596 333,586 296,565C259,544 229,514 208,477C187,439 176,397 176,350C176,303 187,261 208,224C229,186 259,157 296,136C333,115 375,104 422,104C469,104 511,115 548,136C585,157 615,186 636,224C657,261 668,303 668,350M88,398l675,0l0,-96l-675,0M265,828C265,846 271,861 282,872C293,883 308,889 325,889C342,889 357,883 368,872C379,861 385,846 385,828C385,811 379,796 368,785C357,774 342,768 325,768C308,768 293,774 282,785C271,796 265,811 265,828M459,828C459,846 465,861 476,872C487,883 502,889 519,889C536,889 551,883 562,872C573,861 579,846 579,828C579,811 573,796 562,785C551,774 536,768 519,768C502,768 487,774 476,785C465,796 459,811 459,828z"
        />
        <glyph
          unicode="&#x4EC;"
          horizAdvX="710"
          d="M663,350C663,281 648,220 617,165C586,110 544,68 490,37C435,6 374,-10 306,-10C247,-10 193,0 145,20C96,40 56,69 24,107l82,80C157,132 222,104 299,104C359,104 410,122 452,159C493,196 519,244 528,304l-313,0l0,103l311,0C515,464 489,509 448,544C407,579 357,596 299,596C222,596 157,568 106,513l-82,80C56,631 96,660 145,680C193,700 247,710 306,710C374,710 435,695 490,664C544,633 586,590 617,535C648,480 663,419 663,350M167,828C167,846 173,861 184,872C195,883 210,889 227,889C244,889 259,883 270,872C281,861 287,846 287,828C287,811 281,796 270,785C259,774 244,768 227,768C210,768 195,774 184,785C173,796 167,811 167,828M361,828C361,846 367,861 378,872C389,883 404,889 421,889C438,889 453,883 464,872C475,861 481,846 481,828C481,811 475,796 464,785C453,774 438,768 421,768C404,768 389,774 378,785C367,796 361,811 361,828z"
        />
        <glyph
          unicode="&#x4EE;"
          horizAdvX="706"
          d="M699,700l-304,-565C368,85 337,48 300,23C263,-2 222,-15 179,-15C144,-15 110,-8 76,7l32,102C135,100 158,96 176,96C218,96 253,118 280,161l11,17l-279,522l139,0l207,-411l213,411M176,863l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x4F0;"
          horizAdvX="706"
          d="M699,700l-304,-565C368,85 337,48 300,23C263,-2 222,-15 179,-15C144,-15 110,-8 76,7l32,102C135,100 158,96 176,96C218,96 253,118 280,161l11,17l-279,522l139,0l207,-411l213,411M192,828C192,846 198,861 209,872C220,883 235,889 252,889C269,889 284,883 295,872C306,861 312,846 312,828C312,811 306,796 295,785C284,774 269,768 252,768C235,768 220,774 209,785C198,796 192,811 192,828M386,828C386,846 392,861 403,872C414,883 429,889 446,889C463,889 478,883 489,872C500,861 506,846 506,828C506,811 500,796 489,785C478,774 463,768 446,768C429,768 414,774 403,785C392,796 386,811 386,828z"
        />
        <glyph
          unicode="&#x4F2;"
          horizAdvX="706"
          d="M699,700l-304,-565C368,85 337,48 300,23C263,-2 222,-15 179,-15C144,-15 110,-8 76,7l32,102C135,100 158,96 176,96C218,96 253,118 280,161l11,17l-279,522l139,0l207,-411l213,411M287,894l125,0l-108,-137l-98,0M474,894l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#x4F4;"
          horizAdvX="742"
          d="M644,700l0,-700l-129,0l0,264C449,239 386,227 327,227C238,227 169,249 121,292C72,335 48,396 48,477l0,223l129,0l0,-209C177,442 191,404 220,378C249,352 290,339 343,339C404,339 461,351 515,376l0,324M190,828C190,846 196,861 207,872C218,883 233,889 250,889C267,889 282,883 293,872C304,861 310,846 310,828C310,811 304,796 293,785C282,774 267,768 250,768C233,768 218,774 207,785C196,796 190,811 190,828M384,828C384,846 390,861 401,872C412,883 427,889 444,889C461,889 476,883 487,872C498,861 504,846 504,828C504,811 498,796 487,785C476,774 461,768 444,768C427,768 412,774 401,785C390,796 384,811 384,828z"
        />
        <glyph
          unicode="&#x4F6;"
          horizAdvX="585"
          d="M573,590l-347,0l1,-590l-128,0l0,700l475,0M331,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x4F8;"
          horizAdvX="1010"
          d="M682,239C682,162 657,103 608,62C559,21 489,0 400,0l-301,0l0,700l128,0l0,-237l194,0C505,463 570,444 615,407C660,369 682,313 682,239M552,237C552,322 499,364 394,364l-167,0l0,-264l167,0C445,100 484,112 511,135C538,158 552,192 552,237M783,700l128,0l0,-700l-128,0M351,828C351,846 357,861 368,872C379,883 394,889 411,889C428,889 443,883 454,872C465,861 471,846 471,828C471,811 465,796 454,785C443,774 428,768 411,768C394,768 379,774 368,785C357,796 351,811 351,828M545,828C545,846 551,861 562,872C573,883 588,889 605,889C622,889 637,883 648,872C659,861 665,846 665,828C665,811 659,796 648,785C637,774 622,768 605,768C588,768 573,774 562,785C551,796 545,811 545,828z"
        />
        <glyph
          unicode="&#x4FA;"
          horizAdvX="629"
          d="M258,590l0,-223l283,0l0,-90l-283,0l0,-174l92,0l0,-130C350,-82 333,-124 299,-155C265,-186 222,-202 171,-202C128,-202 85,-191 44,-168l34,88C100,-93 123,-100 147,-100C170,-100 188,-93 203,-78C218,-63 225,-42 225,-15l0,15l-96,0l0,277l-105,0l0,90l105,0l0,333l477,0l0,-110z"
        />
        <glyph
          unicode="&#x4FC;"
          horizAdvX="695"
          d="M425,364C487,289 533,232 564,193C594,153 619,114 639,76C658,37 668,1 668,-32C668,-85 651,-127 616,-158C581,-189 537,-204 484,-204C442,-204 401,-194 361,-174l31,91C413,-95 435,-101 457,-101C478,-101 495,-94 508,-81C521,-68 527,-49 527,-26C527,6 512,45 482,90C452,135 407,194 346,267l-187,-267l-150,0l261,358l-247,342l149,0l179,-247l176,247l141,0z"
        />
        <glyph
          unicode="&#x4FE;"
          horizAdvX="705"
          d="M688,0l-155,0l-182,255l-181,-255l-150,0l219,311l-149,0l0,91l153,0l-207,298l150,0l165,-234l165,234l151,0l-208,-298l150,0l0,-91l-139,0z"
        />
        <glyph
          unicode="&#x510;"
          horizAdvX="648"
          d="M620,68C579,42 536,23 489,10C442,-3 395,-10 348,-10C294,-10 244,-2 199,15C153,32 117,56 90,87C63,118 49,154 49,196C49,237 61,272 85,301C108,330 141,352 183,365C150,379 125,399 106,425C87,451 78,481 78,516C78,556 91,591 117,621C143,650 178,673 222,689C265,705 313,713 365,713C405,713 445,708 485,699C525,689 563,674 599,655l-40,-105C530,567 498,581 465,590C432,599 399,603 368,603C322,603 284,594 255,576C225,558 210,533 210,500C210,472 221,450 243,435C264,419 294,411 333,411l154,0l0,-102l-163,0C279,309 244,301 219,285C194,269 181,246 181,215C181,179 199,151 234,130C269,109 312,99 364,99C401,99 438,105 476,116C513,127 547,142 578,163z"
        />
        <glyph
          unicode="&#x512;"
          horizAdvX="795"
          d="M424,-77C447,-90 469,-97 491,-97C513,-97 531,-90 546,-77C561,-64 568,-46 568,-21l0,611l-276,0l-6,-175C283,317 275,238 262,177C249,116 228,69 200,38C172,6 133,-10 84,-10C68,-10 44,-7 13,0l9,113C32,110 42,109 52,109C93,109 122,133 139,182C156,230 166,306 170,410l11,290l515,0l0,-724C696,-63 688,-95 671,-122C654,-149 632,-169 605,-182C577,-195 546,-202 513,-202C468,-202 427,-191 389,-169z"
        />
        <glyph
          unicode="&#x51A;"
          horizAdvX="852"
          d="M834,-70C811,-97 784,-118 753,-132C722,-146 688,-153 651,-153C603,-153 559,-142 519,-120C479,-99 433,-61 382,-8C317,-3 259,16 208,49C157,81 117,123 88,176C59,228 45,286 45,350C45,418 61,480 94,535C127,590 172,633 229,664C286,695 351,710 422,710C493,710 558,695 615,664C672,633 717,590 750,535C783,480 799,419 799,350C799,295 788,244 766,197C744,150 713,110 674,77C635,44 589,20 537,5C556,-16 575,-31 594,-40C613,-49 633,-54 654,-54C700,-54 740,-35 774,2M422,104C469,104 511,115 548,136C585,157 615,186 636,224C657,261 668,303 668,350C668,397 657,439 636,477C615,514 585,544 548,565C511,586 469,596 422,596C375,596 333,586 296,565C259,544 229,514 208,477C187,439 176,397 176,350C176,303 187,261 208,224C229,186 259,157 296,136C333,115 375,104 422,104z"
        />
        <glyph
          unicode="&#x51C;"
          horizAdvX="1104"
          d="M1088,700l-230,-700l-136,0l-168,514l-171,-514l-136,0l-231,700l135,0l171,-525l175,525l120,0l171,-529l175,529z"
        />
        <glyph
          unicode="&#x48C;"
          horizAdvX="745"
          d="M710,235C710,160 686,102 638,61C589,20 520,0 431,0l-306,0l0,548l-97,0l0,91l97,0l0,103l128,0l0,-103l210,0l0,-91l-210,0l0,-90l200,0C536,458 600,439 644,401C688,363 710,308 710,235M578,231C578,316 527,358 426,358l-173,0l0,-258l173,0C476,100 514,111 540,133C565,155 578,188 578,231z"
        />
        <glyph
          unicode="&#x48E;"
          horizAdvX="734"
          d="M579,239l84,-109l-63,-51l-97,127C468,196 429,191 387,191l-158,0l0,-191l-130,0l0,700l288,0C479,700 552,678 605,633C658,588 684,525 684,446C684,400 675,360 657,325C639,290 613,261 579,239M428,304l-89,116l62,51l104,-136C537,360 553,397 553,446C553,493 538,528 509,553C480,578 437,590 381,590l-152,0l0,-289l152,0C398,301 413,302 428,304z"
        />
        <glyph
          unicode="&#x528;"
          horizAdvX="814"
          d="M-45,-77C-22,-90 0,-97 22,-97C44,-97 62,-90 77,-77C91,-64 98,-46 98,-21l0,721l129,0l0,-296l361,0l0,296l128,0l0,-700l-128,0l0,293l-361,0l0,-317C227,-63 219,-95 202,-122C185,-149 163,-169 136,-182C108,-195 77,-202 44,-202C-1,-202 -42,-191 -80,-169z"
        />
        <glyph
          unicode="&#x52E;"
          horizAdvX="826"
          d="M696,700l0,-700l-128,0l0,590l-276,0l-6,-175C283,317 275,238 262,177C249,116 229,69 201,38C173,6 134,-10 85,-10C68,-10 44,-7 14,0l9,113C32,110 42,109 53,109C94,109 123,133 140,182C157,230 167,306 171,410l10,290M800,110l0,-259l-120,0l0,149l-112,0l0,110z"
        />
        <glyph
          unicode="&#x430;"
          horizAdvX="597"
          d="M526,313l0,-313l-118,0l0,65C393,42 371,24 343,12C314,-1 280,-7 240,-7C200,-7 165,0 135,14C105,27 82,46 66,71C49,95 41,122 41,153C41,201 59,240 95,269C130,298 186,312 263,312l138,0l0,8C401,357 390,386 368,406C345,426 312,436 268,436C238,436 209,431 180,422C151,413 126,400 106,383l-49,91C85,495 119,512 158,523C197,534 239,540 283,540C362,540 422,521 464,484C505,446 526,389 526,313M401,167l0,62l-129,0C200,229 164,205 164,158C164,135 173,117 191,104C209,91 234,84 266,84C297,84 325,91 350,106C374,120 391,140 401,167z"
        />
        <glyph
          unicode="&#x431;"
          horizAdvX="667"
          d="M630,252C630,201 618,155 594,116C570,76 536,45 493,23C450,1 400,-10 345,-10C252,-10 181,21 130,82C79,143 54,232 54,347C54,454 77,539 123,602C169,665 239,706 334,726l248,53l18,-112l-225,-46C310,608 262,585 230,553C198,521 179,476 174,418C198,447 227,469 261,485C295,500 332,508 373,508C424,508 468,497 507,476C546,454 576,424 598,385C619,346 630,302 630,252M502,249C502,295 488,332 460,360C431,388 394,402 348,402C301,402 264,388 235,360C206,332 192,295 192,249C192,202 207,164 236,135C265,106 302,91 348,91C394,91 431,106 460,136C488,165 502,203 502,249z"
        />
        <glyph
          unicode="&#x432;"
          horizAdvX="619"
          d="M570,150C570,102 552,65 516,39C480,13 427,0 356,0l-272,0l0,534l261,0C410,534 461,522 498,498C535,474 553,440 553,396C553,369 546,345 532,325C517,305 497,289 471,278C537,258 570,215 570,150M203,311l131,0C399,311 431,333 431,378C431,421 399,443 334,443l-131,0M449,158C449,181 441,199 425,211C409,222 384,228 350,228l-147,0l0,-137l143,0C415,91 449,113 449,158z"
        />
        <glyph
          unicode="&#x433;"
          horizAdvX="501"
          d="M494,428l-285,0l0,-428l-125,0l0,534l410,0z"
        />
        <glyph
          unicode="&#x453;"
          horizAdvX="501"
          d="M494,428l-285,0l0,-428l-125,0l0,534l410,0M342,742l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x491;"
          horizAdvX="484"
          d="M84,534l275,0l0,109l117,0l0,-215l-267,0l0,-428l-125,0z"
        />
        <glyph
          unicode="&#x434;"
          horizAdvX="682"
          d="M654,106l0,-225l-117,0l0,119l-412,0l0,-119l-116,0l0,225l25,0C68,107 91,129 104,172C116,214 124,274 128,351l7,183l440,0l0,-428M187,106l263,0l0,322l-205,0l-3,-86C239,281 234,231 227,192C220,153 206,125 187,106z"
        />
        <glyph
          unicode="&#x435;"
          horizAdvX="637"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310z"
        />
        <glyph
          unicode="&#x450;"
          horizAdvX="637"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M113,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x451;"
          horizAdvX="637"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M154,678C154,696 160,711 171,722C182,733 197,739 214,739C231,739 246,733 257,722C268,711 274,696 274,678C274,661 268,646 257,635C246,624 231,618 214,618C197,618 182,624 171,635C160,646 154,661 154,678M348,678C348,696 354,711 365,722C376,733 391,739 408,739C425,739 440,733 451,722C462,711 468,696 468,678C468,661 462,646 451,635C440,624 425,618 408,618C391,618 376,624 365,635C354,646 348,661 348,678z"
        />
        <glyph
          unicode="&#x436;"
          horizAdvX="879"
          d="M591,214l-90,0l0,-214l-124,0l0,214l-90,0l-130,-214l-148,0l175,276l-162,258l133,0l130,-216l92,0l0,216l124,0l0,-216l91,0l131,216l133,0l-162,-258l176,-276l-149,0z"
        />
        <glyph
          unicode="&#x437;"
          horizAdvX="554"
          d="M508,157C508,124 497,96 475,71C453,46 423,26 384,12C345,-2 301,-9 252,-9C211,-9 170,-4 131,6C92,15 56,29 24,47l36,93C86,125 115,114 148,106C181,98 213,94 246,94C288,94 321,101 345,114C368,127 380,144 380,167C380,188 371,204 353,215C334,226 308,232 275,232l-104,0l0,84l94,0C296,316 321,321 339,332C356,343 365,358 365,377C365,396 354,412 333,423C312,434 282,440 245,440C192,440 135,427 76,401l-32,92C114,526 185,542 256,542C301,542 342,536 378,524C414,511 442,494 463,472C483,449 493,424 493,395C493,369 485,346 469,326C453,305 431,289 403,278C436,269 462,254 481,233C499,212 508,186 508,157z"
        />
        <glyph
          unicode="&#x438;"
          horizAdvX="702"
          d="M84,534l125,0l0,-352l295,352l114,0l0,-534l-125,0l0,352l-294,-352l-115,0z"
        />
        <glyph
          unicode="&#x439;"
          horizAdvX="702"
          d="M84,534l125,0l0,-352l295,352l114,0l0,-534l-125,0l0,352l-294,-352l-115,0M176,744l83,0C260,720 267,701 282,687C296,673 316,666 342,666C367,666 388,673 403,687C418,701 425,720 426,744l85,0C510,696 496,660 467,635C438,610 397,597 343,597C289,597 248,610 220,635C191,660 177,696 176,744z"
        />
        <glyph
          unicode="&#x45D;"
          horizAdvX="702"
          d="M84,534l125,0l0,-352l295,352l114,0l0,-534l-125,0l0,352l-294,-352l-115,0M145,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x48B;"
          horizAdvX="726"
          d="M170,744l82,0C253,721 262,703 279,690C296,676 318,669 343,669C368,669 390,676 407,690C424,703 433,721 434,744l82,0C514,700 497,665 465,639C432,613 392,600 343,600C294,600 254,613 222,639C189,665 172,700 170,744M701,106l-63,-216l-100,0l26,110l-71,0l0,352l-294,-352l-115,0l0,534l125,0l0,-352l295,352l114,0l0,-428z"
        />
        <glyph
          unicode="&#x43A;"
          horizAdvX="618"
          d="M306,214l-97,0l0,-214l-125,0l0,534l125,0l0,-216l100,0l158,216l134,0l-194,-257l207,-277l-147,0z"
        />
        <glyph
          unicode="&#x45C;"
          horizAdvX="618"
          d="M306,214l-97,0l0,-214l-125,0l0,534l125,0l0,-216l100,0l158,216l134,0l-194,-257l207,-277l-147,0M369,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x43B;"
          horizAdvX="652"
          d="M568,534l0,-534l-125,0l0,428l-200,0l-5,-106C235,251 228,192 219,145C209,97 193,59 170,32C147,4 115,-10 75,-10C56,-10 33,-6 6,1l6,105C21,104 29,103 36,103C61,103 80,113 93,134C106,154 114,178 118,207C121,235 124,275 127,328l9,206z"
        />
        <glyph
          unicode="&#x43C;"
          horizAdvX="806"
          d="M609,0l0,354l-180,-298l-54,0l-177,299l0,-355l-114,0l0,534l131,0l190,-334l199,334l117,0l1,-534z"
        />
        <glyph
          unicode="&#x43D;"
          horizAdvX="687"
          d="M84,534l125,0l0,-217l269,0l0,217l125,0l0,-534l-125,0l0,211l-269,0l0,-211l-125,0z"
        />
        <glyph
          unicode="&#x43E;"
          horizAdvX="646"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267z"
        />
        <glyph
          unicode="&#x43F;"
          horizAdvX="681"
          d="M596,534l0,-534l-124,0l0,428l-263,0l0,-428l-125,0l0,534z"
        />
        <glyph
          unicode="&#x440;"
          horizAdvX="691"
          d="M651,267C651,213 639,165 616,124C593,82 561,50 520,27C479,4 432,-7 380,-7C308,-7 251,17 209,65l0,-259l-125,0l0,728l119,0l0,-70C224,489 249,508 280,521C310,534 343,540 380,540C432,540 479,529 520,506C561,483 593,451 616,410C639,369 651,321 651,267M524,267C524,317 509,357 480,388C450,419 412,434 366,434C336,434 309,427 285,414C261,400 242,380 228,355C214,330 207,300 207,267C207,234 214,204 228,179C242,154 261,134 285,121C309,107 336,100 366,100C412,100 450,115 480,146C509,177 524,217 524,267z"
        />
        <glyph
          unicode="&#x441;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267z"
        />
        <glyph
          unicode="&#x442;"
          horizAdvX="514"
          d="M507,428l-187,0l0,-428l-125,0l0,428l-188,0l0,106l500,0z"
        />
        <glyph
          unicode="&#x443;"
          horizAdvX="591"
          d="M588,534l-250,-577C315,-101 286,-142 253,-165C220,-189 179,-201 132,-201C105,-201 79,-197 53,-188C27,-179 6,-167 -11,-152l50,92C51,-71 65,-80 82,-87C98,-94 114,-97 131,-97C153,-97 171,-91 186,-80C200,-69 213,-50 225,-23l9,21l-233,536l130,0l168,-395l169,395z"
        />
        <glyph
          unicode="&#x45E;"
          horizAdvX="591"
          d="M588,534l-250,-577C315,-101 286,-142 253,-165C220,-189 179,-201 132,-201C105,-201 79,-197 53,-188C27,-179 6,-167 -11,-152l50,92C51,-71 65,-80 82,-87C98,-94 114,-97 131,-97C153,-97 171,-91 186,-80C200,-69 213,-50 225,-23l9,21l-233,536l130,0l168,-395l169,395M115,744l83,0C199,720 206,701 221,687C235,673 255,666 281,666C306,666 327,673 342,687C357,701 364,720 365,744l85,0C449,696 435,660 406,635C377,610 336,597 282,597C228,597 187,610 159,635C130,660 116,696 115,744z"
        />
        <glyph
          unicode="&#x444;"
          horizAdvX="827"
          d="M472,-8l0,-186l-118,0l0,185C252,-2 174,25 119,72C64,119 37,185 37,268C37,351 64,415 119,462C174,508 252,534 354,541l0,201l118,0l0,-201C574,534 653,508 708,461C763,414 790,350 790,268C790,185 763,120 708,73C653,26 574,-1 472,-8M354,96l0,339C288,430 239,413 208,385C176,357 160,318 160,268C160,217 176,177 208,148C239,119 288,102 354,96M668,268C668,319 652,358 620,386C587,413 538,430 472,435l0,-339C603,108 668,165 668,268z"
        />
        <glyph
          unicode="&#x445;"
          horizAdvX="564"
          d="M418,0l-137,185l-138,-185l-138,0l208,271l-199,263l139,0l131,-175l131,175l135,0l-200,-261l209,-273z"
        />
        <glyph
          unicode="&#x447;"
          horizAdvX="621"
          d="M537,534l0,-534l-125,0l0,207C361,182 307,170 251,170C182,170 130,186 93,218C56,249 38,298 38,363l0,171l125,0l0,-163C163,337 173,312 193,295C213,278 241,270 278,270C325,270 369,280 412,300l0,234z"
        />
        <glyph
          unicode="&#x446;"
          horizAdvX="697"
          d="M670,106l0,-229l-117,0l0,123l-469,0l0,534l125,0l0,-428l255,0l0,428l125,0l0,-428z"
        />
        <glyph
          unicode="&#x448;"
          horizAdvX="955"
          d="M871,534l0,-534l-787,0l0,534l125,0l0,-428l207,0l0,428l125,0l0,-428l205,0l0,428z"
        />
        <glyph
          unicode="&#x449;"
          horizAdvX="955"
          d="M871,534l0,-534l-787,0l0,534l125,0l0,-428l207,0l0,428l125,0l0,-428l205,0l0,428M948,106l0,-216l-117,0l0,110l-89,0l0,106z"
        />
        <glyph
          unicode="&#x45F;"
          horizAdvX="653"
          d="M568,0l-182,0l0,-123l-118,0l0,123l-184,0l0,534l125,0l0,-428l235,0l0,428l124,0z"
        />
        <glyph
          unicode="&#x44C;"
          horizAdvX="589"
          d="M563,182C563,123 543,78 503,46C463,14 407,-2 334,-1l-250,1l0,534l125,0l0,-175l143,-1C420,358 472,343 509,312C545,281 563,238 563,182M436,184C436,214 427,236 408,250C389,264 361,271 323,272l-114,1l0,-181l114,-1C360,90 388,98 407,114C426,130 436,153 436,184z"
        />
        <glyph
          unicode="&#x44A;"
          horizAdvX="660"
          d="M634,183C634,125 614,80 574,48C534,16 478,0 406,0l-241,0l0,428l-158,0l0,106l283,0l0,-175l136,0C493,359 544,344 580,313C616,282 634,238 634,183M507,186C507,243 471,272 400,272l-110,0l0,-180l110,0C435,92 462,100 480,116C498,132 507,155 507,186z"
        />
        <glyph
          unicode="&#x44B;"
          horizAdvX="832"
          d="M554,182C554,123 534,78 495,46C455,14 399,-2 326,-1l-242,1l0,534l125,0l0,-175l135,-1C412,358 464,343 500,312C536,281 554,238 554,182M623,534l125,0l0,-534l-125,0M428,184C428,214 419,236 400,250C381,264 353,271 315,272l-106,1l0,-181l106,-1C352,90 380,98 399,114C418,130 428,153 428,184z"
        />
        <glyph
          unicode="&#x459;"
          horizAdvX="948"
          d="M568,534l0,-534l-125,0l0,428l-200,0l-5,-106C235,251 228,192 219,145C209,97 193,59 170,32C147,4 115,-10 75,-10C56,-10 33,-6 6,1l6,105C21,104 29,103 36,103C61,103 80,113 93,134C106,154 114,178 118,207C121,235 124,275 127,328l9,206M922,182C922,123 902,78 862,46C822,14 766,-2 693,-1l-250,1l0,534l125,0l0,-175l143,-1C779,358 831,343 868,312C904,281 922,238 922,182M795,184C795,214 786,236 767,250C748,264 720,271 682,272l-114,1l0,-181l114,-1C719,90 747,98 766,114C785,130 795,153 795,184z"
        />
        <glyph
          unicode="&#x45A;"
          horizAdvX="962"
          d="M939,174C939,119 919,77 880,46C841,15 786,0 717,0l-249,0l0,229l-259,0l0,-229l-125,0l0,534l125,0l0,-199l259,0l0,199l125,0l0,-199l142,0C800,335 851,321 886,293C921,265 939,225 939,174M811,167C811,190 802,208 785,221C768,233 742,239 709,239l-116,0l0,-149l116,0C742,90 767,97 785,111C802,124 811,143 811,167z"
        />
        <glyph
          unicode="&#x455;"
          horizAdvX="544"
          d="M37,53l48,95C109,132 138,119 172,110C205,100 238,95 271,95C346,95 383,115 383,154C383,173 374,186 355,193C336,200 305,207 263,214C219,221 183,228 156,237C128,246 104,261 84,283C63,304 53,334 53,373C53,424 74,464 117,495C159,525 216,540 288,540C325,540 361,536 398,528C435,519 465,508 488,494l-48,-95C395,426 344,439 287,439C250,439 223,434 204,423C185,412 175,397 175,379C175,359 185,345 206,337C226,328 257,320 300,313C343,306 378,299 405,290C432,281 456,267 476,246C495,225 505,196 505,158C505,108 483,68 440,38C397,8 338,-7 263,-7C220,-7 177,-1 136,10C95,21 62,36 37,53z"
        />
        <glyph
          unicode="&#x454;"
          horizAdvX="584"
          d="M38,267C38,320 51,367 76,409C101,451 136,484 181,508C226,531 276,543 332,543C381,543 425,535 464,519C503,503 537,480 564,450l-72,-70C450,422 398,443 337,443C292,443 253,431 221,407C188,382 167,350 158,309l247,0l0,-90l-246,0C169,180 190,149 222,126C254,103 292,91 337,91C398,91 450,112 492,155l72,-70C537,54 504,31 465,16C425,0 381,-8 332,-8C276,-8 226,4 181,28C136,51 101,84 76,126C51,167 38,214 38,267z"
        />
        <glyph
          unicode="&#x44D;"
          horizAdvX="581"
          d="M544,267C544,214 531,167 506,125C481,83 446,50 401,27C356,3 306,-9 250,-9C201,-9 157,-1 118,15C79,31 45,54 18,84l72,70C132,112 184,91 245,91C290,91 329,103 362,128C394,152 415,184 424,225l-247,0l0,90l246,0C413,354 392,386 360,409C328,432 290,443 245,443C184,443 132,422 90,379l-72,70C45,480 78,503 118,519C157,534 201,542 250,542C306,542 356,530 401,507C446,483 481,450 506,409C531,367 544,320 544,267z"
        />
        <glyph
          unicode="&#x456;"
          horizAdvX="294"
          d="M84,534l125,0l0,-534l-125,0M67,697C67,718 75,736 90,751C105,765 124,772 147,772C170,772 189,765 204,752C219,738 227,721 227,700C227,678 220,660 205,645C190,630 170,622 147,622C124,622 105,629 90,644C75,658 67,676 67,697z"
        />
        <glyph
          unicode="&#x457;"
          horizAdvX="289"
          d="M82,534l125,0l0,-534l-125,0M11,678C11,695 17,710 28,721C39,732 52,738 69,738C85,738 99,732 110,721C121,710 126,695 126,678C126,661 121,647 110,636C99,625 85,619 69,619C52,619 39,625 28,636C17,647 11,661 11,678M164,678C164,695 170,710 181,721C192,732 205,738 222,738C239,738 252,732 263,721C274,710 279,695 279,678C279,661 274,647 263,636C252,625 239,619 222,619C205,619 192,625 181,636C170,647 164,661 164,678z"
        />
        <glyph
          unicode="&#x458;"
          horizAdvX="295"
          d="M-95,-170l36,93C-40,-91 -16,-98 14,-98C37,-98 55,-91 68,-76C80,-62 86,-41 86,-13l0,547l125,0l0,-544C211,-69 195,-116 162,-150C129,-184 84,-201 25,-201C-28,-201 -68,-191 -95,-170M68,697C68,718 76,736 91,751C106,765 125,772 148,772C171,772 190,765 205,752C220,738 228,721 228,700C228,678 221,660 206,645C191,630 171,622 148,622C125,622 106,629 91,644C76,658 68,676 68,697z"
        />
        <glyph
          unicode="&#x45B;"
          horizAdvX="679"
          d="M610,306l0,-306l-125,0l0,290C485,337 474,372 452,396C430,419 399,431 358,431C312,431 276,417 249,390C222,362 209,322 209,270l0,-270l-125,0l0,600l-124,0l0,69l124,0l0,73l125,0l0,-73l225,0l0,-69l-225,0l0,-128C230,494 255,511 286,523C316,534 350,540 387,540C454,540 508,520 549,481C590,442 610,383 610,306z"
        />
        <glyph
          unicode="&#x44E;"
          horizAdvX="889"
          d="M850,267C850,214 838,167 814,126C790,84 757,51 715,28C673,5 625,-7 572,-7C525,-7 483,2 444,21C405,39 373,65 348,99C323,132 306,171 299,214l-90,0l0,-214l-125,0l0,534l125,0l0,-207l92,0C309,369 326,406 351,439C376,471 408,496 446,514C484,531 526,540 572,540C625,540 673,529 715,506C757,483 790,450 814,409C838,367 850,320 850,267M726,267C726,318 712,358 683,389C654,419 617,434 572,434C527,434 491,419 462,389C433,358 419,318 419,267C419,216 434,176 463,145C492,114 528,99 572,99C601,99 628,106 651,120C674,133 693,153 706,179C719,204 726,234 726,267z"
        />
        <glyph
          unicode="&#x44F;"
          horizAdvX="619"
          d="M535,534l0,-534l-112,0l0,161l-141,0l-109,-161l-134,0l126,178C126,191 97,212 77,240C56,267 46,301 46,342C46,403 68,451 111,484C154,517 214,534 290,534M173,338C173,278 212,248 290,248l133,0l0,181l-129,0C213,429 173,399 173,338z"
        />
        <glyph
          unicode="&#x452;"
          horizAdvX="680"
          d="M610,306l0,-316C610,-69 594,-116 562,-150C529,-184 483,-201 424,-201C371,-201 331,-191 304,-170l37,93C359,-91 383,-98 414,-98C437,-98 454,-91 467,-76C479,-62 485,-41 485,-13l0,303C485,337 474,372 452,396C430,419 399,431 358,431C312,431 276,417 249,390C222,362 209,322 209,270l0,-270l-125,0l0,595l-98,0l0,81l98,0l0,66l120,0l0,-66l232,0l0,-81l-232,0l0,-129C225,490 251,508 282,521C313,534 348,540 387,540C454,540 508,520 549,481C590,442 610,383 610,306z"
        />
        <glyph
          unicode="&#x463;"
          horizAdvX="630"
          d="M604,182C604,123 584,78 544,46C504,14 448,-2 375,-1l-250,1l0,477l-126,0l0,86l126,0l0,179l125,0l0,-179l194,0l0,-86l-194,0l0,-118l143,-1C461,358 513,343 550,312C586,281 604,238 604,182M477,184C477,214 468,236 449,250C430,264 402,271 364,272l-114,1l0,-181l114,-1C401,90 429,98 448,114C467,130 477,153 477,184z"
        />
        <glyph
          unicode="&#x46B;"
          horizAdvX="735"
          d="M724,0l-122,0l-32,85C557,129 540,161 521,180C502,199 477,208 447,208l-28,0l0,-208l-107,0l0,208l-24,0C259,208 234,198 214,179C194,160 177,128 164,85l-31,-85l-122,0l45,112C79,173 105,217 134,245C163,272 199,288 242,291l-163,167l0,76l573,0l0,-76l-164,-166C532,289 569,274 600,246C630,218 656,173 679,112M209,451l155,-159l2,0l157,159z"
        />
        <glyph
          unicode="&#x473;"
          horizAdvX="655"
          d="M617,267C617,214 605,167 580,126C555,84 520,51 476,28C432,5 382,-7 327,-7C272,-7 222,5 179,28C135,51 101,84 76,126C51,167 38,214 38,267C38,320 51,367 76,409C101,450 135,483 179,506C222,529 272,540 327,540C382,540 432,529 476,506C520,483 555,450 580,409C605,367 617,320 617,267M161,309l333,0C486,351 467,384 437,409C407,433 370,445 327,445C284,445 247,433 218,409C188,384 169,351 161,309M494,225l-333,0C169,184 188,151 218,126C248,101 284,89 327,89C370,89 407,101 437,126C467,150 486,183 494,225z"
        />
        <glyph
          unicode="&#x475;"
          horizAdvX="621"
          d="M541,545C561,545 586,541 615,534l-13,-121C587,417 574,419 564,419C549,419 536,414 527,403C517,392 506,371 495,341l-135,-341l-125,0l-233,534l137,0l159,-374l101,266C414,467 433,498 456,517C478,536 506,545 541,545z"
        />
        <glyph
          unicode="&#x493;"
          horizAdvX="532"
          d="M229,428l0,-160l215,0l0,-82l-215,0l0,-186l-125,0l0,186l-66,0l0,82l66,0l0,266l410,0l0,-106z"
        />
        <glyph
          unicode="&#x495;"
          horizAdvX="613"
          d="M349,290C396,290 438,281 475,262C512,243 542,216 563,181C584,145 594,103 594,55C594,6 583,-38 561,-77C539,-116 508,-146 468,-167C427,-189 381,-200 329,-200C300,-200 274,-197 249,-192l26,105C290,-90 306,-91 322,-91C367,-91 403,-78 428,-52C453,-27 466,7 466,50C466,95 453,130 426,153C399,176 366,187 325,187C285,187 246,177 209,158l0,-158l-125,0l0,534l401,0l0,-106l-276,0l0,-165C258,281 304,290 349,290z"
        />
        <glyph
          unicode="&#x497;"
          horizAdvX="909"
          d="M591,214l-90,0l0,-214l-124,0l0,214l-90,0l-130,-214l-148,0l175,276l-162,258l133,0l130,-216l92,0l0,216l124,0l0,-216l91,0l131,216l133,0l-162,-258l176,-276l-149,0M901,104l0,-215l-113,0l0,111l-67,0l0,104z"
        />
        <glyph
          unicode="&#x499;"
          horizAdvX="554"
          d="M317,-4l0,-106l-118,0l0,104C134,0 76,18 24,47l36,93C86,125 115,114 148,106C181,98 213,94 246,94C288,94 321,101 345,114C368,127 380,144 380,167C380,188 371,204 353,215C334,226 308,232 275,232l-104,0l0,84l94,0C296,316 321,321 339,332C356,343 365,358 365,377C365,396 354,412 333,423C312,434 282,440 245,440C192,440 135,427 76,401l-32,92C114,526 185,542 256,542C301,542 342,536 378,524C414,511 442,494 463,472C483,449 493,424 493,395C493,369 485,346 469,326C453,305 431,289 403,278C436,269 462,254 481,233C499,212 508,186 508,157C508,116 491,81 456,52C421,23 375,4 317,-4z"
        />
        <glyph
          unicode="&#x49B;"
          horizAdvX="618"
          d="M306,214l-97,0l0,-214l-125,0l0,534l125,0l0,-216l100,0l158,216l134,0l-194,-257l207,-277l-147,0M647,104l0,-215l-113,0l0,111l-67,0l0,104z"
        />
        <glyph
          unicode="&#x49D;"
          horizAdvX="634"
          d="M346,214l-21,0l0,-89l-79,0l0,89l-40,0l0,-214l-125,0l0,534l125,0l0,-216l40,0l0,91l79,0l0,-91l24,0l133,216l130,0l-165,-257l179,-277l-146,0z"
        />
        <glyph
          unicode="&#x49F;"
          horizAdvX="617"
          d="M305,214l-96,0l0,-214l-125,0l0,595l-89,0l0,83l89,0l0,64l125,0l0,-65l145,-1l0,-81l-145,0l0,-277l98,0l160,216l133,0l-194,-256l207,-278l-148,0z"
        />
        <glyph
          unicode="&#x4A1;"
          horizAdvX="698"
          d="M386,214l-97,0l0,-214l-125,0l0,430l-157,0l0,104l282,0l0,-216l100,0l158,216l134,0l-194,-257l207,-277l-147,0z"
        />
        <glyph
          unicode="&#x4A3;"
          horizAdvX="710"
          d="M84,534l125,0l0,-217l269,0l0,217l125,0l0,-534l-125,0l0,211l-269,0l0,-211l-125,0M684,106l0,-216l-117,0l0,110l-89,0l0,106z"
        />
        <glyph
          unicode="&#x4A5;"
          horizAdvX="895"
          d="M84,534l125,0l0,-217l269,0l0,217l125,0l0,-534l-125,0l0,211l-269,0l0,-211l-125,0M888,428l-285,0l0,-428l-125,0l0,534l410,0z"
        />
        <glyph
          unicode="&#x525;"
          horizAdvX="681"
          d="M596,534l0,-534l-124,0l0,428l-263,0l0,-428l-125,0l0,534M678,106l0,-216l-117,0l0,110l-89,0l0,106z"
        />
        <glyph
          unicode="&#x4A7;"
          horizAdvX="985"
          d="M724,290C771,290 813,281 850,262C887,243 916,216 937,181C958,146 968,104 968,55C968,6 957,-38 935,-77C913,-116 882,-146 842,-167C801,-189 755,-200 702,-200C673,-200 647,-197 622,-192l26,105C663,-90 679,-91 695,-91C741,-91 777,-78 802,-52C827,-27 840,7 840,50C840,95 827,130 800,153C773,176 739,187 698,187C660,187 622,178 585,159l0,-159l-125,0l0,428l-251,0l0,-428l-125,0l0,534l501,0l0,-270C631,281 677,290 724,290z"
        />
        <glyph
          unicode="&#x4A9;"
          horizAdvX="819"
          d="M651,-19C600,-19 551,-10 504,9C461,-4 417,-10 373,-10C307,-10 249,2 199,27C149,52 110,87 83,132C55,177 41,230 41,289C41,342 53,391 76,436C99,481 132,517 174,546l99,-51C239,467 213,435 194,399C175,363 166,325 166,284C166,222 186,173 227,136C268,99 323,81 392,81C362,110 339,144 322,183C305,221 297,262 297,306C297,354 307,396 326,433C345,469 373,497 408,517C443,537 485,547 532,547C577,547 617,538 651,519C685,500 711,474 730,440C749,406 758,367 758,322C758,273 747,227 725,184C703,141 672,105 632,74C647,73 658,72 665,72C694,72 725,76 757,84l6,-88C725,-14 688,-19 651,-19M415,308C415,264 424,225 443,191C462,157 488,130 521,109C559,128 588,156 609,191C630,226 640,268 640,315C640,355 630,387 610,410C590,433 563,445 530,445C495,445 467,433 446,408C425,383 415,350 415,308z"
        />
        <glyph
          unicode="&#x4AB;"
          horizAdvX="581"
          d="M38,267C38,320 50,367 75,408C100,449 134,482 178,505C221,528 271,540 327,540C380,540 426,529 466,508C505,487 535,456 555,416l-96,-56C444,385 425,403 402,416C379,428 353,434 326,434C279,434 241,419 210,389C179,358 164,318 164,267C164,216 179,176 210,146C240,115 279,100 326,100C353,100 379,106 402,119C425,131 444,149 459,174l96,-56C534,78 504,47 465,26C425,4 379,-7 327,-7C272,-7 222,5 178,28C134,51 100,84 75,126C50,167 38,214 38,267M374,-110l-118,0l0,135l118,0z"
        />
        <glyph
          unicode="&#x4AD;"
          horizAdvX="514"
          d="M507,428l-187,0l0,-428l-125,0l0,428l-188,0l0,106l500,0M401,106l0,-216l-117,0l0,110l-89,0l0,106z"
        />
        <glyph
          unicode="&#x4AF;"
          horizAdvX="593"
          d="M359,-3l0,-191l-126,0l0,189l-230,539l130,0l166,-396l171,396l120,0z"
        />
        <glyph
          unicode="&#x4B1;"
          horizAdvX="593"
          d="M359,-3l0,-191l-126,0l0,189l-230,539l130,0l166,-396l171,396l120,0M69,47l452,0l0,-82l-452,0z"
        />
        <glyph
          unicode="&#x4B3;"
          horizAdvX="564"
          d="M418,0l-137,185l-138,-185l-138,0l208,271l-199,263l139,0l131,-175l131,175l135,0l-200,-261l209,-273M597,104l0,-215l-113,0l0,111l-67,0l0,104z"
        />
        <glyph
          unicode="&#x4B5;"
          horizAdvX="786"
          d="M754,106l0,-216l-116,0l0,110l-442,0l0,428l-188,0l0,106l451,0l0,-106l-139,0l0,-322l236,0l0,428l124,0l0,-428z"
        />
        <glyph
          unicode="&#x4B7;"
          horizAdvX="650"
          d="M537,534l0,-534l-125,0l0,207C361,182 307,170 251,170C182,170 130,186 93,218C56,249 38,298 38,363l0,171l125,0l0,-163C163,337 173,312 193,295C213,278 241,270 278,270C325,270 369,280 412,300l0,234M618,106l0,-216l-117,0l0,110l-89,0l0,106z"
        />
        <glyph
          unicode="&#x4B9;"
          horizAdvX="621"
          d="M536,534l0,-534l-125,0l0,207C379,192 349,182 322,177l0,-100l-80,0l0,93C176,171 125,188 90,220C55,251 37,299 37,363l0,171l125,0l0,-163C162,315 189,282 243,273l0,95l79,0l0,-95C353,278 382,287 411,300l0,234z"
        />
        <glyph
          unicode="&#x4BB;"
          horizAdvX="679"
          d="M610,306l0,-306l-125,0l0,290C485,337 474,372 452,396C430,419 399,431 358,431C312,431 276,417 249,390C222,362 209,322 209,270l0,-270l-125,0l0,742l125,0l0,-270C230,494 255,511 286,523C316,534 350,540 387,540C454,540 508,520 549,481C590,442 610,383 610,306z"
        />
        <glyph
          unicode="&#x527;"
          horizAdvX="710"
          d="M689,106l0,-216l-116,0l0,110l-88,0l0,287C485,334 474,369 453,392C431,415 400,427 360,427C313,427 277,413 250,386C223,358 209,318 209,267l0,-267l-125,0l0,742l125,0l0,-287C230,482 257,503 289,518C320,533 356,540 395,540C461,540 513,521 552,482C591,443 610,386 610,309l0,-203z"
        />
        <glyph
          unicode="&#x4BD;"
          horizAdvX="793"
          d="M754,227l-431,0C330,188 350,157 382,134C414,111 454,99 502,99C562,99 612,118 651,157l69,-77C695,51 664,30 626,15C588,0 545,-7 498,-7C443,-7 394,3 351,23C308,42 273,70 246,105C219,140 203,181 197,227l-22,0C125,227 87,238 60,259C33,280 19,310 19,347C19,376 26,406 41,438l101,-19C130,398 124,379 124,362C124,346 130,333 142,324C153,315 170,310 191,310l6,0C204,355 220,395 246,430C271,465 304,492 344,511C384,530 429,540 478,540C531,540 579,529 622,506C664,483 697,450 721,409C745,367 757,319 757,264C757,251 756,239 754,227M322,310l312,0C629,349 612,380 584,404C555,428 520,440 478,440C437,440 402,428 374,405C345,381 328,349 322,310z"
        />
        <glyph
          unicode="&#x4BF;"
          horizAdvX="793"
          d="M754,227l-431,0C330,188 350,157 382,134C414,111 454,99 502,99C562,99 612,118 651,157l69,-77C695,51 664,30 626,15C588,0 545,-7 498,-7C443,-7 394,3 351,23C308,42 273,70 246,105C219,140 203,181 197,227l-22,0C125,227 87,238 60,259C33,280 19,310 19,347C19,376 26,406 41,438l101,-19C130,398 124,379 124,362C124,346 130,333 142,324C153,315 170,310 191,310l6,0C204,355 220,395 246,430C271,465 304,492 344,511C384,530 429,540 478,540C531,540 579,529 622,506C664,483 697,450 721,409C745,367 757,319 757,264C757,251 756,239 754,227M322,310l312,0C629,349 612,380 584,404C555,428 520,440 478,440C437,440 402,428 374,405C345,381 328,349 322,310M541,-110l-118,0l0,135l118,0z"
        />
        <glyph
          unicode="&#x4CF;"
          horizAdvX="294"
          d="M84,742l125,0l0,-742l-125,0z"
        />
        <glyph
          unicode="&#x4C2;"
          horizAdvX="879"
          d="M591,214l-90,0l0,-214l-124,0l0,214l-90,0l-130,-214l-148,0l175,276l-162,258l133,0l130,-216l92,0l0,216l124,0l0,-216l91,0l131,216l133,0l-162,-258l176,-276l-149,0M272,744l83,0C356,720 363,701 378,687C392,673 412,666 438,666C463,666 484,673 499,687C514,701 521,720 522,744l85,0C606,696 592,660 563,635C534,610 493,597 439,597C385,597 344,610 316,635C287,660 273,696 272,744z"
        />
        <glyph
          unicode="&#x4C4;"
          horizAdvX="618"
          d="M597,-29C597,-82 581,-124 548,-155C515,-186 472,-202 418,-202C378,-202 338,-192 297,-173l33,92C353,-93 376,-99 398,-99C420,-99 438,-92 451,-79C464,-66 471,-47 471,-24C471,8 458,45 432,87C405,129 369,171 324,214l-115,0l0,-214l-125,0l0,534l125,0l0,-216l100,0l158,216l134,0l-188,-248C536,167 597,62 597,-29z"
        />
        <glyph
          unicode="&#x4C6;"
          horizAdvX="676"
          d="M651,106l-63,-216l-100,0l26,110l-71,0l0,428l-200,0l-5,-106C235,251 228,192 219,145C209,97 193,59 170,32C147,4 115,-10 75,-10C56,-10 33,-6 6,1l6,105C21,104 29,103 36,103C61,103 80,113 93,134C106,154 114,178 118,207C121,235 124,275 127,328l9,206l432,0l0,-428z"
        />
        <glyph
          unicode="&#x4C8;"
          horizAdvX="692"
          d="M304,-169l35,92C358,-91 382,-98 409,-98C432,-98 451,-91 464,-76C477,-62 483,-41 483,-13l0,224l-274,0l0,-211l-125,0l0,534l125,0l0,-217l274,0l0,217l124,0l0,-550C607,-73 591,-118 560,-151C528,-184 483,-201 426,-201C402,-201 379,-198 358,-193C337,-188 319,-180 304,-169z"
        />
        <glyph
          unicode="&#x4CA;"
          horizAdvX="711"
          d="M686,106l-63,-216l-100,0l26,110l-71,0l0,211l-269,0l0,-211l-125,0l0,534l125,0l0,-217l269,0l0,217l125,0l0,-428z"
        />
        <glyph
          unicode="&#x4CC;"
          horizAdvX="617"
          d="M533,534l0,-534l-100,0l0,-110l-117,0l0,211l92,0l0,128C358,205 304,193 247,193C178,193 126,209 89,241C52,272 34,320 34,385l0,149l125,0l0,-141C159,326 198,292 275,292C318,292 363,302 408,323l0,211z"
        />
        <glyph
          unicode="&#x4CE;"
          horizAdvX="843"
          d="M817,106l-63,-216l-100,0l26,110l-71,0l0,354l-180,-298l-54,0l-177,299l0,-355l-114,0l0,534l131,0l190,-334l199,334l117,0l1,-428z"
        />
        <glyph
          unicode="&#x4D1;"
          horizAdvX="597"
          d="M526,313l0,-313l-118,0l0,65C393,42 371,24 343,12C314,-1 280,-7 240,-7C200,-7 165,0 135,14C105,27 82,46 66,71C49,95 41,122 41,153C41,201 59,240 95,269C130,298 186,312 263,312l138,0l0,8C401,357 390,386 368,406C345,426 312,436 268,436C238,436 209,431 180,422C151,413 126,400 106,383l-49,91C85,495 119,512 158,523C197,534 239,540 283,540C362,540 422,521 464,484C505,446 526,389 526,313M401,167l0,62l-129,0C200,229 164,205 164,158C164,135 173,117 191,104C209,91 234,84 266,84C297,84 325,91 350,106C374,120 391,140 401,167M127,744l83,0C211,720 218,701 233,687C247,673 267,666 293,666C318,666 339,673 354,687C369,701 376,720 377,744l85,0C461,696 447,660 418,635C389,610 348,597 294,597C240,597 199,610 171,635C142,660 128,696 127,744z"
        />
        <glyph
          unicode="&#x4D3;"
          horizAdvX="597"
          d="M526,313l0,-313l-118,0l0,65C393,42 371,24 343,12C314,-1 280,-7 240,-7C200,-7 165,0 135,14C105,27 82,46 66,71C49,95 41,122 41,153C41,201 59,240 95,269C130,298 186,312 263,312l138,0l0,8C401,357 390,386 368,406C345,426 312,436 268,436C238,436 209,431 180,422C151,413 126,400 106,383l-49,91C85,495 119,512 158,523C197,534 239,540 283,540C362,540 422,521 464,484C505,446 526,389 526,313M401,167l0,62l-129,0C200,229 164,205 164,158C164,135 173,117 191,104C209,91 234,84 266,84C297,84 325,91 350,106C374,120 391,140 401,167M137,678C137,696 143,711 154,722C165,733 180,739 197,739C214,739 229,733 240,722C251,711 257,696 257,678C257,661 251,646 240,635C229,624 214,618 197,618C180,618 165,624 154,635C143,646 137,661 137,678M331,678C331,696 337,711 348,722C359,733 374,739 391,739C408,739 423,733 434,722C445,711 451,696 451,678C451,661 445,646 434,635C423,624 408,618 391,618C374,618 359,624 348,635C337,646 331,661 331,678z"
        />
        <glyph
          unicode="&#x4D5;"
          horizAdvX="997"
          d="M949,229l-421,0C535,189 553,157 584,134C615,111 654,99 701,99C758,99 807,118 846,157l68,-77C889,51 858,30 822,15C785,0 744,-7 698,-7C649,-7 605,1 566,17C526,33 492,58 465,91C444,56 415,31 378,16C341,1 301,-7 257,-7C190,-7 138,8 99,39C60,69 41,109 41,158C41,203 59,240 95,269C131,297 187,311 263,311l138,0l0,9C401,357 390,386 368,406C345,426 312,436 269,436C238,436 209,431 180,422C151,413 126,400 106,383l-49,91C85,495 119,512 158,523C197,534 239,540 282,540C376,540 442,513 481,458C505,484 534,504 569,519C603,533 640,540 680,540C731,540 778,529 819,506C860,483 893,451 917,410C940,369 952,322 952,269C952,254 951,240 949,229M527,311l302,0C824,352 807,383 778,406C749,429 716,440 679,440C642,440 609,429 580,406C551,383 533,352 527,311M401,201l0,28l-129,0C200,229 164,206 164,160C164,136 173,117 192,104C210,91 235,84 268,84C309,84 342,95 366,116C389,137 401,166 401,201z"
        />
        <glyph
          unicode="&#x4D7;"
          horizAdvX="637"
          d="M582,227l-419,0C170,188 190,157 221,134C252,111 290,99 336,99C395,99 443,118 481,157l67,-77C524,51 494,30 457,15C420,0 379,-7 333,-7C274,-7 223,5 178,28C133,51 99,84 75,126C50,167 38,214 38,267C38,319 50,366 74,408C97,449 130,482 172,505C214,528 261,540 314,540C366,540 413,529 454,506C495,483 527,450 550,409C573,367 584,319 584,264C584,255 583,243 582,227M162,310l303,0C460,349 443,380 416,404C389,428 355,440 314,440C274,440 240,428 213,405C185,381 168,349 162,310M144,744l83,0C228,720 235,701 250,687C264,673 284,666 310,666C335,666 356,673 371,687C386,701 393,720 394,744l85,0C478,696 464,660 435,635C406,610 365,597 311,597C257,597 216,610 188,635C159,660 145,696 144,744z"
        />
        <glyph
          unicode="&#x4D9;"
          horizAdvX="636"
          d="M599,267C599,215 587,168 564,127C540,85 507,52 465,29C423,6 376,-6 323,-6C271,-6 225,6 184,29C143,52 111,84 88,126C65,167 53,215 53,270C53,279 54,291 55,307l419,0C467,346 448,378 417,401C386,424 347,435 301,435C242,435 194,416 156,377l-67,77C113,483 143,504 180,519C217,534 258,541 304,541C363,541 414,529 459,506C504,483 538,450 563,409C587,367 599,320 599,267M475,224l-303,0C177,185 194,154 221,130C248,106 282,94 323,94C363,94 397,106 425,130C452,153 469,185 475,224z"
        />
        <glyph
          unicode="&#x4DB;"
          horizAdvX="636"
          d="M599,267C599,215 587,168 564,127C540,85 507,52 465,29C423,6 376,-6 323,-6C271,-6 225,6 184,29C143,52 111,84 88,126C65,167 53,215 53,270C53,279 54,291 55,307l419,0C467,346 448,378 417,401C386,424 347,435 301,435C242,435 194,416 156,377l-67,77C113,483 143,504 180,519C217,534 258,541 304,541C363,541 414,529 459,506C504,483 538,450 563,409C587,367 599,320 599,267M475,224l-303,0C177,185 194,154 221,130C248,106 282,94 323,94C363,94 397,106 425,130C452,153 469,185 475,224M169,678C169,696 175,711 186,722C197,733 212,739 229,739C246,739 261,733 272,722C283,711 289,696 289,678C289,661 283,646 272,635C261,624 246,618 229,618C212,618 197,624 186,635C175,646 169,661 169,678M363,678C363,696 369,711 380,722C391,733 406,739 423,739C440,739 455,733 466,722C477,711 483,696 483,678C483,661 477,646 466,635C455,624 440,618 423,618C406,618 391,624 380,635C369,646 363,661 363,678z"
        />
        <glyph
          unicode="&#x4DD;"
          horizAdvX="879"
          d="M591,214l-90,0l0,-214l-124,0l0,214l-90,0l-130,-214l-148,0l175,276l-162,258l133,0l130,-216l92,0l0,216l124,0l0,-216l91,0l131,216l133,0l-162,-258l176,-276l-149,0M282,678C282,696 288,711 299,722C310,733 325,739 342,739C359,739 374,733 385,722C396,711 402,696 402,678C402,661 396,646 385,635C374,624 359,618 342,618C325,618 310,624 299,635C288,646 282,661 282,678M476,678C476,696 482,711 493,722C504,733 519,739 536,739C553,739 568,733 579,722C590,711 596,696 596,678C596,661 590,646 579,635C568,624 553,618 536,618C519,618 504,624 493,635C482,646 476,661 476,678z"
        />
        <glyph
          unicode="&#x4DF;"
          horizAdvX="554"
          d="M508,157C508,124 497,96 475,71C453,46 423,26 384,12C345,-2 301,-9 252,-9C211,-9 170,-4 131,6C92,15 56,29 24,47l36,93C86,125 115,114 148,106C181,98 213,94 246,94C288,94 321,101 345,114C368,127 380,144 380,167C380,188 371,204 353,215C334,226 308,232 275,232l-104,0l0,84l94,0C296,316 321,321 339,332C356,343 365,358 365,377C365,396 354,412 333,423C312,434 282,440 245,440C192,440 135,427 76,401l-32,92C114,526 185,542 256,542C301,542 342,536 378,524C414,511 442,494 463,472C483,449 493,424 493,395C493,369 485,346 469,326C453,305 431,289 403,278C436,269 462,254 481,233C499,212 508,186 508,157M113,678C113,696 119,711 130,722C141,733 156,739 173,739C190,739 205,733 216,722C227,711 233,696 233,678C233,661 227,646 216,635C205,624 190,618 173,618C156,618 141,624 130,635C119,646 113,661 113,678M307,678C307,696 313,711 324,722C335,733 350,739 367,739C384,739 399,733 410,722C421,711 427,696 427,678C427,661 421,646 410,635C399,624 384,618 367,618C350,618 335,624 324,635C313,646 307,661 307,678z"
        />
        <glyph
          unicode="&#x4E1;"
          horizAdvX="541"
          d="M512,25C512,-17 502,-55 482,-89C461,-124 431,-151 390,-171C349,-191 298,-201 238,-201C187,-201 139,-194 94,-179C48,-164 10,-144 -19,-119l46,103C52,-39 82,-56 119,-69C155,-82 193,-89 233,-89C281,-89 318,-79 344,-59C370,-39 383,-11 383,25C383,60 371,88 346,108C321,127 282,137 231,137l-65,0l0,87l165,202l-323,0l0,108l472,0l0,-88l-174,-213C373,226 424,204 459,167C494,130 512,82 512,25z"
        />
        <glyph
          unicode="&#x4E3;"
          horizAdvX="702"
          d="M84,534l125,0l0,-352l295,352l114,0l0,-534l-125,0l0,352l-294,-352l-115,0M170,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x4E5;"
          horizAdvX="702"
          d="M84,534l125,0l0,-352l295,352l114,0l0,-534l-125,0l0,352l-294,-352l-115,0M186,678C186,696 192,711 203,722C214,733 229,739 246,739C263,739 278,733 289,722C300,711 306,696 306,678C306,661 300,646 289,635C278,624 263,618 246,618C229,618 214,624 203,635C192,646 186,661 186,678M380,678C380,696 386,711 397,722C408,733 423,739 440,739C457,739 472,733 483,722C494,711 500,696 500,678C500,661 494,646 483,635C472,624 457,618 440,618C423,618 408,624 397,635C386,646 380,661 380,678z"
        />
        <glyph
          unicode="&#x4E7;"
          horizAdvX="646"
          d="M38,267C38,320 50,367 75,408C99,449 133,482 176,505C219,528 268,540 322,540C377,540 426,528 469,505C512,482 546,449 571,408C595,367 607,320 607,267C607,214 595,167 571,126C546,84 512,51 469,28C426,5 377,-7 322,-7C268,-7 219,5 176,28C133,51 99,84 75,126C50,167 38,214 38,267M481,267C481,317 466,357 436,388C406,419 368,434 322,434C276,434 238,419 209,388C179,357 164,317 164,267C164,217 179,177 209,146C238,115 276,100 322,100C368,100 406,115 436,146C466,177 481,217 481,267M164,678C164,696 170,711 181,722C192,733 207,739 224,739C241,739 256,733 267,722C278,711 284,696 284,678C284,661 278,646 267,635C256,624 241,618 224,618C207,618 192,624 181,635C170,646 164,661 164,678M358,678C358,696 364,711 375,722C386,733 401,739 418,739C435,739 450,733 461,722C472,711 478,696 478,678C478,661 472,646 461,635C450,624 435,618 418,618C401,618 386,624 375,635C364,646 358,661 358,678z"
        />
        <glyph
          unicode="&#x4E9;"
          horizAdvX="655"
          d="M617,267C617,214 605,167 580,126C555,84 520,51 476,28C432,5 382,-7 327,-7C272,-7 222,5 179,28C135,51 101,84 76,126C51,167 38,214 38,267C38,320 51,367 76,409C101,450 135,483 179,506C222,529 272,540 327,540C382,540 432,529 476,506C520,483 555,450 580,409C605,367 617,320 617,267M161,309l333,0C486,351 467,384 437,409C407,433 370,445 327,445C284,445 247,433 218,409C188,384 169,351 161,309M494,225l-333,0C169,184 188,151 218,126C248,101 284,89 327,89C370,89 407,101 437,126C467,150 486,183 494,225z"
        />
        <glyph
          unicode="&#x4EB;"
          horizAdvX="655"
          d="M617,267C617,214 605,167 580,126C555,84 520,51 476,28C432,5 382,-7 327,-7C272,-7 222,5 179,28C135,51 101,84 76,126C51,167 38,214 38,267C38,320 51,367 76,409C101,450 135,483 179,506C222,529 272,540 327,540C382,540 432,529 476,506C520,483 555,450 580,409C605,367 617,320 617,267M161,309l333,0C486,351 467,384 437,409C407,433 370,445 327,445C284,445 247,433 218,409C188,384 169,351 161,309M494,225l-333,0C169,184 188,151 218,126C248,101 284,89 327,89C370,89 407,101 437,126C467,150 486,183 494,225M171,678C171,696 177,711 188,722C199,733 214,739 231,739C248,739 263,733 274,722C285,711 291,696 291,678C291,661 285,646 274,635C263,624 248,618 231,618C214,618 199,624 188,635C177,646 171,661 171,678M365,678C365,696 371,711 382,722C393,733 408,739 425,739C442,739 457,733 468,722C479,711 485,696 485,678C485,661 479,646 468,635C457,624 442,618 425,618C408,618 393,624 382,635C371,646 365,661 365,678z"
        />
        <glyph
          unicode="&#x4ED;"
          horizAdvX="581"
          d="M544,267C544,214 531,167 506,125C481,83 446,50 401,27C356,3 306,-9 250,-9C201,-9 157,-1 118,15C79,31 45,54 18,84l72,70C132,112 184,91 245,91C290,91 329,103 362,128C394,152 415,184 424,225l-247,0l0,90l246,0C413,354 392,386 360,409C328,432 290,443 245,443C184,443 132,422 90,379l-72,70C45,480 78,503 118,519C157,534 201,542 250,542C306,542 356,530 401,507C446,483 481,450 506,409C531,367 544,320 544,267M112,678C112,696 118,711 129,722C140,733 155,739 172,739C189,739 204,733 215,722C226,711 232,696 232,678C232,661 226,646 215,635C204,624 189,618 172,618C155,618 140,624 129,635C118,646 112,661 112,678M306,678C306,696 312,711 323,722C334,733 349,739 366,739C383,739 398,733 409,722C420,711 426,696 426,678C426,661 420,646 409,635C398,624 383,618 366,618C349,618 334,624 323,635C312,646 306,661 306,678z"
        />
        <glyph
          unicode="&#x4EF;"
          horizAdvX="591"
          d="M588,534l-250,-577C315,-101 286,-142 253,-165C220,-189 179,-201 132,-201C105,-201 79,-197 53,-188C27,-179 6,-167 -11,-152l50,92C51,-71 65,-80 82,-87C98,-94 114,-97 131,-97C153,-97 171,-91 186,-80C200,-69 213,-50 225,-23l9,21l-233,536l130,0l168,-395l169,395M109,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x4F1;"
          horizAdvX="591"
          d="M588,534l-250,-577C315,-101 286,-142 253,-165C220,-189 179,-201 132,-201C105,-201 79,-197 53,-188C27,-179 6,-167 -11,-152l50,92C51,-71 65,-80 82,-87C98,-94 114,-97 131,-97C153,-97 171,-91 186,-80C200,-69 213,-50 225,-23l9,21l-233,536l130,0l168,-395l169,395M125,678C125,696 131,711 142,722C153,733 168,739 185,739C202,739 217,733 228,722C239,711 245,696 245,678C245,661 239,646 228,635C217,624 202,618 185,618C168,618 153,624 142,635C131,646 125,661 125,678M319,678C319,696 325,711 336,722C347,733 362,739 379,739C396,739 411,733 422,722C433,711 439,696 439,678C439,661 433,646 422,635C411,624 396,618 379,618C362,618 347,624 336,635C325,646 319,661 319,678z"
        />
        <glyph
          unicode="&#x4F3;"
          horizAdvX="591"
          d="M588,534l-250,-577C315,-101 286,-142 253,-165C220,-189 179,-201 132,-201C105,-201 79,-197 53,-188C27,-179 6,-167 -11,-152l50,92C51,-71 65,-80 82,-87C98,-94 114,-97 131,-97C153,-97 171,-91 186,-80C200,-69 213,-50 225,-23l9,21l-233,536l130,0l168,-395l169,395M220,744l125,0l-108,-137l-98,0M407,744l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#x4F5;"
          horizAdvX="621"
          d="M537,534l0,-534l-125,0l0,207C361,182 307,170 251,170C182,170 130,186 93,218C56,249 38,298 38,363l0,171l125,0l0,-163C163,337 173,312 193,295C213,278 241,270 278,270C325,270 369,280 412,300l0,234M127,678C127,696 133,711 144,722C155,733 170,739 187,739C204,739 219,733 230,722C241,711 247,696 247,678C247,661 241,646 230,635C219,624 204,618 187,618C170,618 155,624 144,635C133,646 127,661 127,678M321,678C321,696 327,711 338,722C349,733 364,739 381,739C398,739 413,733 424,722C435,711 441,696 441,678C441,661 435,646 424,635C413,624 398,618 381,618C364,618 349,624 338,635C327,646 321,661 321,678z"
        />
        <glyph
          unicode="&#x4F7;"
          horizAdvX="501"
          d="M494,428l-285,0l0,-428l-125,0l0,534l410,0M290,106l0,-216l-117,0l0,110l-89,0l0,106z"
        />
        <glyph
          unicode="&#x4F9;"
          horizAdvX="832"
          d="M554,182C554,123 534,78 495,46C455,14 399,-2 326,-1l-242,1l0,534l125,0l0,-175l135,-1C412,358 464,343 500,312C536,281 554,238 554,182M623,534l125,0l0,-534l-125,0M428,184C428,214 419,236 400,250C381,264 353,271 315,272l-106,1l0,-181l106,-1C352,90 380,98 399,114C418,130 428,153 428,184M269,678C269,696 275,711 286,722C297,733 312,739 329,739C346,739 361,733 372,722C383,711 389,696 389,678C389,661 383,646 372,635C361,624 346,618 329,618C312,618 297,624 286,635C275,646 269,661 269,678M463,678C463,696 469,711 480,722C491,733 506,739 523,739C540,739 555,733 566,722C577,711 583,696 583,678C583,661 577,646 566,635C555,624 540,618 523,618C506,618 491,624 480,635C469,646 463,661 463,678z"
        />
        <glyph
          unicode="&#x4FB;"
          horizAdvX="506"
          d="M237,428l0,-133l200,0l0,-82l-200,0l0,-113l80,0l0,-120C317,-76 301,-120 269,-152C237,-185 193,-201 136,-201C113,-201 90,-198 69,-192C48,-187 29,-179 14,-168l33,88C68,-95 92,-102 119,-102C143,-102 161,-94 174,-79C186,-64 192,-42 192,-13l0,13l-80,0l0,213l-85,0l0,82l85,0l0,239l371,0l0,-106z"
        />
        <glyph
          unicode="&#x4FD;"
          horizAdvX="565"
          d="M556,-44C556,-92 539,-130 506,-159C473,-188 431,-202 380,-202C339,-202 298,-192 257,-171l34,93C313,-92 336,-99 360,-99C381,-99 398,-93 411,-80C424,-68 431,-53 431,-34C431,-11 420,17 399,50C378,82 341,128 289,188l-143,-188l-139,0l207,272l-199,262l140,0l131,-173l130,173l136,0l-193,-254C409,222 447,176 474,141C500,106 520,74 535,44C549,14 556,-15 556,-44z"
        />
        <glyph
          unicode="&#x4FF;"
          horizAdvX="587"
          d="M425,0l-133,183l-139,-183l-138,0l172,225l-106,0l0,83l111,0l-167,226l142,0l129,-175l132,175l133,0l-171,-226l117,0l0,-83l-104,0l167,-225z"
        />
        <glyph
          unicode="&#x511;"
          horizAdvX="553"
          d="M529,47C497,29 461,15 422,6C383,-4 342,-9 301,-9C252,-9 208,-2 169,12C130,26 100,46 78,71C56,96 45,124 45,157C45,186 54,212 73,233C91,254 117,269 150,278C122,289 100,305 84,326C68,346 60,369 60,395C60,424 70,449 91,472C111,494 139,511 175,524C211,536 252,542 297,542C368,542 439,526 509,493l-32,-92C418,427 362,440 308,440C271,440 241,434 220,423C199,412 188,396 188,377C188,358 197,343 215,332C232,321 257,316 288,316l94,0l0,-84l-104,0C245,232 219,226 201,215C182,204 173,188 173,167C173,144 185,127 209,114C232,101 265,94 307,94C340,94 372,98 405,106C438,114 467,125 493,140z"
        />
        <glyph
          unicode="&#x513;"
          horizAdvX="653"
          d="M568,534l0,-548C568,-71 552,-117 521,-150C489,-184 444,-201 385,-201C362,-201 340,-198 319,-193C298,-188 280,-180 265,-169l35,92C318,-91 341,-98 368,-98C392,-98 411,-91 424,-76C437,-62 443,-41 443,-13l0,441l-200,0l-5,-106C235,251 228,192 219,145C209,97 193,59 170,32C147,4 115,-10 75,-10C56,-10 33,-6 6,1l6,105C21,104 29,103 36,103C61,103 80,113 93,134C106,155 114,180 118,209C122,238 125,277 127,328l9,206z"
        />
        <glyph
          unicode="&#x51B;"
          horizAdvX="691"
          d="M606,534l0,-728l-125,0l0,259C439,17 382,-7 310,-7C258,-7 212,4 171,27C130,50 98,82 75,123C52,164 40,212 40,267C40,321 52,369 75,410C98,451 130,483 171,506C212,529 258,540 310,540C347,540 380,534 411,521C441,508 466,489 487,464l0,70M483,267C483,317 468,357 438,388C408,419 370,434 324,434C278,434 240,419 211,388C181,357 166,317 166,267C166,217 181,177 211,146C240,115 278,100 324,100C370,100 408,115 438,146C468,177 483,217 483,267z"
        />
        <glyph
          unicode="&#x51D;"
          horizAdvX="952"
          d="M945,534l-201,-534l-129,0l-138,372l-140,-372l-129,0l-202,534l128,0l141,-388l147,388l115,0l143,-389l146,389z"
        />
        <glyph
          unicode="&#x48D;"
          horizAdvX="622"
          d="M597,176C597,118 577,74 537,44C497,13 440,-2 367,-1l-249,1l0,394l-99,0l0,85l99,0l0,55l125,0l0,-55l194,0l0,-85l-194,0l0,-51l143,-1C455,341 508,327 544,300C579,272 597,231 597,176M470,177C470,206 461,227 443,238C425,249 396,255 357,256l-114,1l0,-167l114,0C432,89 470,118 470,177z"
        />
        <glyph
          unicode="&#x48F;"
          horizAdvX="702"
          d="M576,64l62,-79l-61,-48l-66,82C475,2 436,-7 395,-7C311,-7 249,24 210,86l0,-280l-126,0l0,728l120,0l0,-95C243,506 306,540 395,540C443,540 487,529 527,506C566,483 597,451 620,410C643,368 654,320 654,266C654,225 647,187 634,153C620,118 601,89 576,64M436,112l-68,85l64,50l67,-85C520,190 530,225 530,266C530,317 515,357 485,388C454,419 416,434 369,434C322,434 284,419 254,388C223,357 208,317 208,266C208,215 223,175 254,145C284,114 322,99 369,99C394,99 417,103 436,112z"
        />
        <glyph
          unicode="&#x529;"
          horizAdvX="688"
          d="M604,534l0,-534l-125,0l0,213l-269,0l0,-229C210,-73 194,-118 163,-151C131,-184 87,-201 30,-201C5,-201 -18,-198 -39,-193C-60,-188 -78,-180 -93,-169l35,92C-40,-91 -17,-98 11,-98C34,-98 53,-91 66,-76C79,-62 85,-41 85,-13l0,547l125,0l0,-214l269,0l0,214z"
        />
        <glyph
          unicode="&#x52F;"
          horizAdvX="675"
          d="M568,534l0,-534l-125,0l0,428l-200,0l-5,-106C235,251 228,192 219,145C209,97 193,59 170,32C147,4 115,-10 75,-10C56,-10 33,-6 6,1l6,105C21,104 29,103 36,103C61,103 80,113 93,134C106,154 114,178 118,207C121,235 124,275 127,328l9,206M649,106l0,-216l-117,0l0,110l-89,0l0,106z"
        />
        <glyph
          unicode="&#x394;"
          horizAdvX="749"
          d="M754,0l-759,0l315,700l128,0M173,110l399,0l-199,462z"
        />
        <glyph
          unicode="&#x3A9;"
          horizAdvX="894"
          d="M9,110l173,0C147,141 120,179 101,222C82,265 72,313 72,365C72,432 88,491 121,544C154,596 199,637 256,666C313,695 376,710 447,710C518,710 582,695 639,666C696,637 740,596 773,544C806,491 822,432 822,365C822,313 812,265 793,222C774,179 747,141 712,110l173,0l0,-110l-356,0l0,102C582,129 622,164 650,209C678,254 692,303 692,356C692,403 682,445 661,482C640,518 611,546 574,566C537,586 494,596 447,596C400,596 357,586 320,566C283,546 254,518 233,482C212,445 202,403 202,356C202,302 216,253 244,208C272,163 312,128 365,102l0,-102l-356,0z"
        />
        <glyph
          unicode="&#x3BC;"
          horizAdvX="686"
          d="M604,534l0,-534l-117,0l0,71C470,44 447,25 420,12C393,-1 364,-7 334,-7C279,-7 237,9 207,40l0,-234l-125,0l0,728l125,0l0,-288C207,198 218,162 240,139C261,115 292,103 333,103C378,103 413,117 440,145C466,172 479,212 479,264l0,270z"
        />
        <glyph
          unicode="&#x3C0;"
          horizAdvX="726"
          d="M716,435l-137,0l0,-435l-120,0l0,435l-120,0l-49,-435l-120,0l49,435l-32,0C126,435 96,410 96,360C96,349 99,337 104,324C109,310 115,297 123,285l-93,-29C17,273 6,292 -1,315C-8,338 -12,359 -12,380C-12,409 -4,436 12,459C27,482 49,501 77,514C104,527 136,534 172,534l544,0z"
        />
        <glyph
          unicode="&#x212B;"
          horizAdvX="749"
          d="M548,162l-350,0l-69,-162l-134,0l315,700l128,0l316,-700l-136,0M505,264l-132,306l-131,-306M257,873C257,905 268,932 291,955C313,978 340,989 373,989C406,989 434,978 457,955C479,932 490,905 490,873C490,841 479,814 457,792C434,770 406,759 373,759C340,759 313,770 291,792C268,814 257,841 257,873M437,873C437,892 431,907 419,920C406,932 391,938 373,938C355,938 340,932 328,920C316,907 310,892 310,873C310,855 316,840 328,828C340,816 355,810 373,810C392,810 407,816 419,828C431,840 437,855 437,873z"
        />
        <glyph
          unicode="&#x212A;"
          horizAdvX="729"
          d="M335,293l-111,-114l0,-179l-130,0l0,700l130,0l0,-359l346,359l146,0l-294,-313l312,-387l-152,0z"
        />
        <glyph
          unicode="0"
          horizAdvX="673"
          d="M44,350C44,425 57,489 82,544C107,598 142,639 186,668C230,696 280,710 336,710C392,710 442,696 487,668C531,639 566,598 591,544C616,489 629,425 629,350C629,275 616,211 591,157C566,102 531,61 487,33C442,4 392,-10 336,-10C280,-10 230,4 186,33C142,61 107,102 82,157C57,211 44,275 44,350M498,350C498,432 484,494 455,535C426,576 386,597 336,597C287,597 248,576 219,535C190,494 175,432 175,350C175,268 190,206 219,165C248,124 287,103 336,103C386,103 426,124 455,165C484,206 498,268 498,350z"
        />
        <glyph
          unicode="1"
          horizAdvX="381"
          d="M286,700l0,-700l-130,0l0,591l-148,0l0,109z"
        />
        <glyph
          unicode="2"
          horizAdvX="582"
          d="M551,110l0,-110l-516,0l0,87l278,264C344,381 366,407 377,430C388,452 393,474 393,496C393,529 382,554 360,571C338,588 306,597 263,597C192,597 137,573 99,524l-91,70C35,631 72,659 119,680C165,700 217,710 274,710C350,710 411,692 456,656C501,620 524,571 524,509C524,471 516,435 500,402C484,369 453,331 408,288l-187,-178z"
        />
        <glyph
          unicode="3"
          horizAdvX="582"
          d="M541,210C541,169 531,132 510,99C489,66 458,39 417,20C375,0 324,-10 264,-10C214,-10 166,-3 120,12C73,26 34,46 1,71l56,101C82,151 113,134 150,122C186,109 223,103 262,103C308,103 344,113 371,132C397,151 410,176 410,209C410,242 398,267 373,286C348,304 309,313 258,313l-64,0l0,89l159,189l-318,0l0,109l477,0l0,-87l-170,-202C407,403 456,381 490,345C524,309 541,264 541,210z"
        />
        <glyph
          unicode="4"
          horizAdvX="679"
          d="M668,159l-124,0l0,-159l-126,0l0,159l-384,0l0,90l345,451l139,0l-325,-431l229,0l0,141l122,0l0,-141l124,0z"
        />
        <glyph
          unicode="5"
          horizAdvX="584"
          d="M553,215C553,173 543,135 522,101C501,67 470,40 429,20C388,0 337,-10 276,-10C226,-10 178,-3 131,12C84,26 45,46 13,71l55,101C94,151 125,134 162,122C198,109 235,103 274,103C320,103 356,113 383,132C409,151 422,177 422,210C422,246 408,273 380,292C351,310 303,319 234,319l-169,0l36,381l411,0l0,-109l-301,0l-15,-162l67,0C362,429 435,410 482,372C529,333 553,281 553,215z"
        />
        <glyph
          unicode="6"
          horizAdvX="627"
          d="M603,217C603,172 592,132 569,98C546,63 516,37 477,18C438,-1 395,-10 347,-10C251,-10 177,20 124,80C71,140 44,226 44,338C44,417 58,484 87,540C116,595 156,638 208,667C259,696 319,710 387,710C423,710 457,706 489,699C520,691 548,680 571,665l-48,-98C488,590 444,601 390,601C323,601 270,580 232,539C194,498 175,438 175,360l0,-1C196,383 222,401 254,414C286,426 322,432 362,432C408,432 449,423 486,406C523,388 551,363 572,330C593,297 603,260 603,217M477,211C477,248 464,277 439,298C414,319 380,330 337,330C295,330 261,319 235,296C208,273 195,245 195,210C195,176 208,148 234,126C259,103 295,92 340,92C381,92 414,103 439,125C464,146 477,175 477,211z"
        />
        <glyph
          unicode="7"
          horizAdvX="608"
          d="M572,700l0,-87l-272,-613l-139,0l263,590l-275,0l0,-119l-121,0l0,229z"
        />
        <glyph
          unicode="8"
          horizAdvX="652"
          d="M612,201C612,158 600,121 577,89C553,57 519,33 476,16C433,-1 382,-10 325,-10C268,-10 218,-1 175,16C132,33 98,57 75,89C52,121 40,158 40,201C40,239 50,272 69,301C88,329 116,351 152,368C124,383 103,404 88,429C73,454 65,482 65,515C65,554 76,589 98,618C119,647 150,670 189,686C228,702 274,710 325,710C377,710 423,702 462,686C501,670 532,647 554,618C576,589 587,554 587,515C587,482 579,454 564,429C549,404 527,384 498,368C535,351 563,329 583,301C602,272 612,239 612,201M193,509C193,479 205,455 229,438C252,420 284,411 325,411C366,411 399,420 423,438C447,455 459,479 459,509C459,540 447,565 423,582C398,599 366,608 325,608C284,608 252,599 229,582C205,565 193,540 193,509M481,205C481,240 467,267 439,287C411,307 373,317 325,317C277,317 239,307 212,287C185,267 171,240 171,205C171,170 185,142 212,122C239,102 277,92 325,92C373,92 411,102 439,122C467,142 481,170 481,205z"
        />
        <glyph
          unicode="9"
          horizAdvX="627"
          d="M583,362C583,283 569,216 540,161C511,105 471,63 420,34C368,5 308,-10 240,-10C204,-10 170,-6 139,2C107,9 79,20 56,35l48,98C139,110 183,99 237,99C304,99 357,120 395,161C433,202 452,262 452,340l0,1C431,317 405,299 373,287C341,274 305,268 265,268C219,268 178,277 141,295C104,312 76,337 55,370C34,403 24,440 24,483C24,528 35,568 58,603C81,637 111,663 150,682C189,701 232,710 280,710C376,710 451,680 504,620C557,560 583,474 583,362M432,490C432,524 419,552 394,575C368,597 332,608 287,608C246,608 213,597 188,576C163,554 150,525 150,489C150,452 163,423 188,402C213,381 247,370 290,370C332,370 366,381 393,404C419,427 432,455 432,490z"
        />
        <glyph
          unicode="&#x2080;"
          horizAdvX="430"
          d="M24,108C24,174 42,226 77,265C112,303 158,322 215,322C272,322 319,303 354,265C389,226 406,174 406,108C406,42 389,-10 354,-49C319,-88 272,-107 215,-107C158,-107 112,-88 77,-49C42,-10 24,42 24,108M317,108C317,153 308,188 289,212C270,235 246,247 215,247C184,247 160,235 141,212C122,188 113,153 113,108C113,63 122,28 141,4C160,-20 184,-32 215,-32C246,-32 270,-20 289,4C308,28 317,63 317,108z"
        />
        <glyph
          unicode="&#x2081;"
          horizAdvX="430"
          d="M374,-29l0,-71l-297,0l0,71l110,0l0,273l-102,0l0,71l193,0l0,-344z"
        />
        <glyph
          unicode="&#x2082;"
          horizAdvX="430"
          d="M384,-29l0,-71l-340,0l0,56l179,156C244,131 258,146 266,158C273,169 277,181 277,193C277,209 270,222 256,232C241,242 220,247 191,247C168,247 148,243 130,236C112,229 98,218 87,203l-63,45C41,271 65,290 94,303C123,316 158,322 198,322C251,322 292,311 322,288C352,265 367,236 367,199C367,178 362,158 352,139C341,120 321,98 290,71l-114,-100z"
        />
        <glyph
          unicode="&#x2083;"
          horizAdvX="430"
          d="M389,25C389,1 382,-21 368,-41C353,-61 332,-77 304,-89C276,-101 242,-107 201,-107C165,-107 131,-102 98,-93C65,-84 40,-73 22,-58l35,67C74,-4 96,-14 121,-21C146,-28 173,-32 201,-32C233,-32 257,-27 274,-17C291,-7 299,7 299,25C299,43 291,57 276,67C261,77 237,82 206,82l-52,0l0,58l100,104l-216,0l0,71l329,0l0,-56l-110,-113C300,141 332,128 355,107C378,85 389,58 389,25z"
        />
        <glyph
          unicode="&#x2084;"
          horizAdvX="430"
          d="M407,-8l-74,0l0,-92l-88,0l0,92l-228,0l0,58l195,265l99,0l-186,-252l128,0l0,77l80,0l0,-77l74,0z"
        />
        <glyph
          unicode="&#x2085;"
          horizAdvX="430"
          d="M387,26C387,-12 371,-44 338,-69C305,-94 259,-107 199,-107C163,-107 129,-102 97,-93C64,-84 39,-73 21,-58l35,66C73,-4 95,-14 120,-21C145,-28 172,-32 199,-32C231,-32 255,-27 272,-17C289,-7 297,7 297,25C297,44 288,58 271,67C254,76 224,81 181,81l-123,0l25,234l275,0l0,-71l-201,0l-10,-92l48,0C323,152 387,110 387,26z"
        />
        <glyph
          unicode="&#x2086;"
          horizAdvX="430"
          d="M403,30C403,-11 387,-44 355,-69C323,-94 282,-107 233,-107C169,-107 119,-89 84,-53C49,-17 31,33 31,98C31,167 52,221 95,262C138,302 195,322 267,322C289,322 311,320 332,315C353,310 371,304 386,296l-32,-68C327,241 298,248 266,248C221,248 186,236 160,212C133,187 120,155 120,114C133,127 150,138 172,146C193,154 218,158 245,158C290,158 328,146 358,123C388,99 403,68 403,30M314,26C314,46 306,62 291,75C276,87 254,93 227,93C200,93 178,87 161,74C144,61 135,45 135,26C135,8 143,-7 159,-20C175,-33 198,-40 229,-40C255,-40 276,-34 291,-22C306,-10 314,6 314,26z"
        />
        <glyph
          unicode="&#x2087;"
          horizAdvX="430"
          d="M403,315l0,-56l-187,-359l-99,0l178,344l-183,0l0,-69l-80,0l0,140z"
        />
        <glyph
          unicode="&#x2088;"
          horizAdvX="430"
          d="M406,17C406,-20 389,-50 354,-73C319,-96 273,-107 214,-107C156,-107 110,-96 76,-73C41,-50 24,-20 24,17C24,40 30,60 43,77C55,94 72,107 95,117C57,136 38,166 38,205C38,240 54,268 87,290C119,311 161,322 214,322C267,322 310,311 343,289C376,267 392,239 392,204C392,165 373,136 335,117C382,96 406,63 406,17M126,203C126,186 134,173 150,163C166,152 187,147 214,147C242,147 264,152 280,163C296,173 304,186 304,202C304,219 296,232 280,242C264,251 242,256 214,256C187,256 165,251 150,242C134,232 126,219 126,203M316,21C316,40 307,55 289,67C271,78 246,84 214,84C183,84 159,78 141,67C123,55 114,40 114,21C114,2 123,-13 141,-24C158,-35 183,-41 214,-41C246,-41 271,-35 289,-24C307,-13 316,2 316,21z"
        />
        <glyph
          unicode="&#x2089;"
          horizAdvX="430"
          d="M399,117C399,48 378,-6 335,-46C292,-87 235,-107 163,-107C141,-107 120,-105 99,-100C78,-95 59,-89 44,-81l32,68C103,-26 132,-33 164,-33C209,-33 244,-21 271,4C297,28 310,60 310,101C297,88 280,77 259,69C237,61 212,57 185,57C140,57 102,69 72,93C42,116 27,147 27,185C27,226 43,259 75,284C107,309 148,322 197,322C261,322 311,304 346,268C381,232 399,182 399,117M295,189C295,207 287,223 271,236C254,249 231,255 200,255C173,255 152,249 137,237C122,225 114,209 114,189C114,169 122,153 138,141C153,128 175,122 202,122C230,122 253,128 270,141C287,154 295,170 295,189z"
        />
        <glyph
          unicode="&#x2070;"
          horizAdvX="430"
          d="M24,535C24,601 42,653 77,692C112,730 158,749 215,749C272,749 319,730 354,692C389,653 406,601 406,535C406,469 389,417 354,378C319,339 272,320 215,320C158,320 112,339 77,378C42,417 24,469 24,535M317,535C317,580 308,615 289,639C270,662 246,674 215,674C184,674 160,662 141,639C122,615 113,580 113,535C113,490 122,455 141,431C160,407 184,395 215,395C246,395 270,407 289,431C308,455 317,490 317,535z"
        />
        <glyph
          unicode="&#xB9;"
          horizAdvX="430"
          d="M374,398l0,-71l-297,0l0,71l110,0l0,273l-102,0l0,71l193,0l0,-344z"
        />
        <glyph
          unicode="&#xB2;"
          horizAdvX="430"
          d="M384,398l0,-71l-340,0l0,56l179,156C244,558 258,573 266,585C273,596 277,608 277,620C277,636 270,649 256,659C241,669 220,674 191,674C168,674 148,670 130,663C112,656 98,645 87,630l-63,45C41,698 65,717 94,730C123,743 158,749 198,749C251,749 292,738 322,715C352,692 367,663 367,626C367,605 362,585 352,566C341,547 321,525 290,498l-114,-100z"
        />
        <glyph
          unicode="&#xB3;"
          horizAdvX="430"
          d="M389,452C389,428 382,406 368,386C353,366 332,350 304,338C276,326 242,320 201,320C165,320 131,325 98,334C65,343 40,354 22,369l35,67C74,423 96,413 121,406C146,399 173,395 201,395C233,395 257,400 274,410C291,420 299,434 299,452C299,470 291,484 276,494C261,504 237,509 206,509l-52,0l0,58l100,104l-216,0l0,71l329,0l0,-56l-110,-113C300,568 332,555 355,534C378,512 389,485 389,452z"
        />
        <glyph
          unicode="&#x2074;"
          horizAdvX="430"
          d="M407,419l-74,0l0,-92l-88,0l0,92l-228,0l0,58l195,265l99,0l-186,-252l128,0l0,77l80,0l0,-77l74,0z"
        />
        <glyph
          unicode="&#x2075;"
          horizAdvX="430"
          d="M387,453C387,415 371,383 338,358C305,333 259,320 199,320C163,320 129,325 97,334C64,343 39,354 21,369l35,66C73,423 95,413 120,406C145,399 172,395 199,395C231,395 255,400 272,410C289,420 297,434 297,452C297,471 288,485 271,494C254,503 224,508 181,508l-123,0l25,234l275,0l0,-71l-201,0l-10,-92l48,0C323,579 387,537 387,453z"
        />
        <glyph
          unicode="&#x2076;"
          horizAdvX="430"
          d="M403,457C403,416 387,383 355,358C323,333 282,320 233,320C169,320 119,338 84,374C49,410 31,460 31,525C31,594 52,648 95,689C138,729 195,749 267,749C289,749 311,747 332,742C353,737 371,731 386,723l-32,-68C327,668 298,675 266,675C221,675 186,663 160,639C133,614 120,582 120,541C133,554 150,565 172,573C193,581 218,585 245,585C290,585 328,573 358,550C388,526 403,495 403,457M314,453C314,473 306,489 291,502C276,514 254,520 227,520C200,520 178,514 161,501C144,488 135,472 135,453C135,435 143,420 159,407C175,394 198,387 229,387C255,387 276,393 291,405C306,417 314,433 314,453z"
        />
        <glyph
          unicode="&#x2077;"
          horizAdvX="430"
          d="M403,742l0,-56l-187,-359l-99,0l178,344l-183,0l0,-69l-80,0l0,140z"
        />
        <glyph
          unicode="&#x2078;"
          horizAdvX="430"
          d="M406,444C406,407 389,377 354,354C319,331 273,320 214,320C156,320 110,331 76,354C41,377 24,407 24,444C24,467 30,487 43,504C55,521 72,534 95,544C57,563 38,593 38,632C38,667 54,695 87,717C119,738 161,749 214,749C267,749 310,738 343,716C376,694 392,666 392,631C392,592 373,563 335,544C382,523 406,490 406,444M126,630C126,613 134,600 150,590C166,579 187,574 214,574C242,574 264,579 280,590C296,600 304,613 304,629C304,646 296,659 280,669C264,678 242,683 214,683C187,683 165,678 150,669C134,659 126,646 126,630M316,448C316,467 307,482 289,494C271,505 246,511 214,511C183,511 159,505 141,494C123,482 114,467 114,448C114,429 123,414 141,403C158,392 183,386 214,386C246,386 271,392 289,403C307,414 316,429 316,448z"
        />
        <glyph
          unicode="&#x2079;"
          horizAdvX="430"
          d="M399,544C399,475 378,421 335,381C292,340 235,320 163,320C141,320 120,322 99,327C78,332 59,338 44,346l32,68C103,401 132,394 164,394C209,394 244,406 271,431C297,455 310,487 310,528C297,515 280,504 259,496C237,488 212,484 185,484C140,484 102,496 72,520C42,543 27,574 27,612C27,653 43,686 75,711C107,736 148,749 197,749C261,749 311,731 346,695C381,659 399,609 399,544M295,616C295,634 287,650 271,663C254,676 231,682 200,682C173,682 152,676 137,664C122,652 114,636 114,616C114,596 122,580 138,568C153,555 175,549 202,549C230,549 253,555 270,568C287,581 295,597 295,616z"
        />
        <glyph
          unicode="&#x2044;"
          horizAdvX="179"
          d="M280,700l97,0l-478,-700l-97,0z"
        />
        <glyph
          unicode="&#xBD;"
          horizAdvX="1039"
          d="M374,356l0,-71l-297,0l0,71l110,0l0,273l-102,0l0,71l193,0l0,-344M710,700l97,0l-478,-700l-97,0M993,71l0,-71l-340,0l0,56l179,156C853,231 867,246 875,258C882,269 886,281 886,293C886,309 879,322 865,332C850,342 829,347 800,347C777,347 757,343 739,336C721,329 707,318 696,303l-63,45C650,371 674,390 703,403C732,416 767,422 807,422C860,422 901,411 931,388C961,365 976,336 976,299C976,278 971,258 961,239C950,220 930,198 899,171l-114,-100z"
        />
        <glyph
          unicode="&#x2153;"
          horizAdvX="1039"
          d="M374,356l0,-71l-297,0l0,71l110,0l0,273l-102,0l0,71l193,0l0,-344M710,700l97,0l-478,-700l-97,0M998,125C998,101 991,79 977,59C962,39 941,23 913,11C885,-1 851,-7 810,-7C774,-7 740,-2 707,7C674,16 649,27 631,42l35,67C683,96 705,86 730,79C755,72 782,68 810,68C842,68 866,73 883,83C900,93 908,107 908,125C908,143 900,157 885,167C870,177 846,182 815,182l-52,0l0,58l100,104l-216,0l0,71l329,0l0,-56l-110,-113C909,241 941,228 964,207C987,185 998,158 998,125z"
        />
        <glyph
          unicode="&#x2154;"
          horizAdvX="1039"
          d="M384,356l0,-71l-340,0l0,56l179,156C244,516 258,531 266,543C273,554 277,566 277,578C277,594 270,607 256,617C241,627 220,632 191,632C168,632 148,628 130,621C112,614 98,603 87,588l-63,45C41,656 65,675 94,688C123,701 158,707 198,707C251,707 292,696 322,673C352,650 367,621 367,584C367,563 362,543 352,524C341,505 321,483 290,456l-114,-100M710,700l97,0l-478,-700l-97,0M998,125C998,101 991,79 977,59C962,39 941,23 913,11C885,-1 851,-7 810,-7C774,-7 740,-2 707,7C674,16 649,27 631,42l35,67C683,96 705,86 730,79C755,72 782,68 810,68C842,68 866,73 883,83C900,93 908,107 908,125C908,143 900,157 885,167C870,177 846,182 815,182l-52,0l0,58l100,104l-216,0l0,71l329,0l0,-56l-110,-113C909,241 941,228 964,207C987,185 998,158 998,125z"
        />
        <glyph
          unicode="&#xBC;"
          horizAdvX="1039"
          d="M374,356l0,-71l-297,0l0,71l110,0l0,273l-102,0l0,71l193,0l0,-344M710,700l97,0l-478,-700l-97,0M1016,92l-74,0l0,-92l-88,0l0,92l-228,0l0,58l195,265l99,0l-186,-252l128,0l0,77l80,0l0,-77l74,0z"
        />
        <glyph
          unicode="&#xBE;"
          horizAdvX="1039"
          d="M389,410C389,386 382,364 368,344C353,324 332,308 304,296C276,284 242,278 201,278C165,278 131,283 98,292C65,301 40,312 22,327l35,67C74,381 96,371 121,364C146,357 173,353 201,353C233,353 257,358 274,368C291,378 299,392 299,410C299,428 291,442 276,452C261,462 237,467 206,467l-52,0l0,58l100,104l-216,0l0,71l329,0l0,-56l-110,-113C300,526 332,513 355,492C378,470 389,443 389,410M710,700l97,0l-478,-700l-97,0M1016,92l-74,0l0,-92l-88,0l0,92l-228,0l0,58l195,265l99,0l-186,-252l128,0l0,77l80,0l0,-77l74,0z"
        />
        <glyph
          unicode="&#x215B;"
          horizAdvX="1039"
          d="M374,356l0,-71l-297,0l0,71l110,0l0,273l-102,0l0,71l193,0l0,-344M710,700l97,0l-478,-700l-97,0M1015,117C1015,80 998,50 963,27C928,4 882,-7 823,-7C765,-7 719,4 685,27C650,50 633,80 633,117C633,140 639,160 652,177C664,194 681,207 704,217C666,236 647,266 647,305C647,340 663,368 696,390C728,411 770,422 823,422C876,422 919,411 952,389C985,367 1001,339 1001,304C1001,265 982,236 944,217C991,196 1015,163 1015,117M735,303C735,286 743,273 759,263C775,252 796,247 823,247C851,247 873,252 889,263C905,273 913,286 913,302C913,319 905,332 889,342C873,351 851,356 823,356C796,356 774,351 759,342C743,332 735,319 735,303M925,121C925,140 916,155 898,167C880,178 855,184 823,184C792,184 768,178 750,167C732,155 723,140 723,121C723,102 732,87 750,76C767,65 792,59 823,59C855,59 880,65 898,76C916,87 925,102 925,121z"
        />
        <glyph
          unicode="&#x215C;"
          horizAdvX="1039"
          d="M389,410C389,386 382,364 368,344C353,324 332,308 304,296C276,284 242,278 201,278C165,278 131,283 98,292C65,301 40,312 22,327l35,67C74,381 96,371 121,364C146,357 173,353 201,353C233,353 257,358 274,368C291,378 299,392 299,410C299,428 291,442 276,452C261,462 237,467 206,467l-52,0l0,58l100,104l-216,0l0,71l329,0l0,-56l-110,-113C300,526 332,513 355,492C378,470 389,443 389,410M710,700l97,0l-478,-700l-97,0M1015,117C1015,80 998,50 963,27C928,4 882,-7 823,-7C765,-7 719,4 685,27C650,50 633,80 633,117C633,140 639,160 652,177C664,194 681,207 704,217C666,236 647,266 647,305C647,340 663,368 696,390C728,411 770,422 823,422C876,422 919,411 952,389C985,367 1001,339 1001,304C1001,265 982,236 944,217C991,196 1015,163 1015,117M735,303C735,286 743,273 759,263C775,252 796,247 823,247C851,247 873,252 889,263C905,273 913,286 913,302C913,319 905,332 889,342C873,351 851,356 823,356C796,356 774,351 759,342C743,332 735,319 735,303M925,121C925,140 916,155 898,167C880,178 855,184 823,184C792,184 768,178 750,167C732,155 723,140 723,121C723,102 732,87 750,76C767,65 792,59 823,59C855,59 880,65 898,76C916,87 925,102 925,121z"
        />
        <glyph
          unicode="&#x215D;"
          horizAdvX="1039"
          d="M387,411C387,373 371,341 338,316C305,291 259,278 199,278C163,278 129,283 97,292C64,301 39,312 21,327l35,66C73,381 95,371 120,364C145,357 172,353 199,353C231,353 255,358 272,368C289,378 297,392 297,410C297,429 288,443 271,452C254,461 224,466 181,466l-123,0l25,234l275,0l0,-71l-201,0l-10,-92l48,0C323,537 387,495 387,411M710,700l97,0l-478,-700l-97,0M1015,117C1015,80 998,50 963,27C928,4 882,-7 823,-7C765,-7 719,4 685,27C650,50 633,80 633,117C633,140 639,160 652,177C664,194 681,207 704,217C666,236 647,266 647,305C647,340 663,368 696,390C728,411 770,422 823,422C876,422 919,411 952,389C985,367 1001,339 1001,304C1001,265 982,236 944,217C991,196 1015,163 1015,117M735,303C735,286 743,273 759,263C775,252 796,247 823,247C851,247 873,252 889,263C905,273 913,286 913,302C913,319 905,332 889,342C873,351 851,356 823,356C796,356 774,351 759,342C743,332 735,319 735,303M925,121C925,140 916,155 898,167C880,178 855,184 823,184C792,184 768,178 750,167C732,155 723,140 723,121C723,102 732,87 750,76C767,65 792,59 823,59C855,59 880,65 898,76C916,87 925,102 925,121z"
        />
        <glyph
          unicode="&#x215E;"
          horizAdvX="1039"
          d="M403,700l0,-56l-187,-359l-99,0l178,344l-183,0l0,-69l-80,0l0,140M710,700l97,0l-478,-700l-97,0M1015,117C1015,80 998,50 963,27C928,4 882,-7 823,-7C765,-7 719,4 685,27C650,50 633,80 633,117C633,140 639,160 652,177C664,194 681,207 704,217C666,236 647,266 647,305C647,340 663,368 696,390C728,411 770,422 823,422C876,422 919,411 952,389C985,367 1001,339 1001,304C1001,265 982,236 944,217C991,196 1015,163 1015,117M735,303C735,286 743,273 759,263C775,252 796,247 823,247C851,247 873,252 889,263C905,273 913,286 913,302C913,319 905,332 889,342C873,351 851,356 823,356C796,356 774,351 759,342C743,332 735,319 735,303M925,121C925,140 916,155 898,167C880,178 855,184 823,184C792,184 768,178 750,167C732,155 723,140 723,121C723,102 732,87 750,76C767,65 792,59 823,59C855,59 880,65 898,76C916,87 925,102 925,121z"
        />
        <glyph
          unicode="*"
          horizAdvX="416"
          d="M286,550l114,-63l-39,-68l-116,68l1,-129l-76,0l1,128l-115,-67l-40,68l115,63l-115,63l40,69l115,-68l-1,128l76,0l-1,-129l116,69l39,-69z"
        />
        <glyph
          unicode="\"
          horizAdvX="371"
          d="M290,-100l-330,942l112,0l330,-942z"
        />
        <glyph
          unicode="&#xB7;"
          horizAdvX="284"
          d="M60,279C60,303 68,323 84,339C99,354 119,362 142,362C166,362 186,354 201,339C216,324 224,304 224,279C224,254 216,233 201,218C186,203 166,195 142,195C119,195 99,203 84,218C68,233 60,254 60,279z"
        />
        <glyph
          unicode="&#x2022;"
          horizAdvX="335"
          d="M60,278C60,309 71,334 92,355C113,376 138,386 167,386C196,386 222,376 243,355C264,334 275,309 275,278C275,247 264,221 243,200C222,179 196,169 167,169C138,169 113,179 92,200C71,221 60,247 60,278z"
        />
        <glyph
          unicode=":"
          horizAdvX="244"
          d="M40,457C40,482 48,502 64,517C79,532 99,540 122,540C145,540 165,532 181,517C196,502 204,482 204,457C204,433 196,413 180,398C164,382 145,374 122,374C99,374 80,382 64,398C48,413 40,433 40,457M40,76C40,101 48,121 64,136C79,151 99,159 122,159C145,159 165,151 181,136C196,121 204,101 204,76C204,52 196,32 180,17C164,1 145,-7 122,-7C99,-7 80,1 64,17C48,32 40,52 40,76z"
        />
        <glyph
          unicode=","
          horizAdvX="244"
          d="M204,76C204,65 203,54 200,43C197,31 190,13 181,-10l-51,-141l-81,0l40,153C74,8 63,18 55,31C46,44 42,59 42,76C42,101 50,121 65,136C80,151 100,159 123,159C146,159 166,151 181,136C196,121 204,101 204,76z"
        />
        <glyph
          unicode="&#x2026;"
          horizAdvX="743"
          d="M40,76C40,101 48,121 64,136C79,151 99,159 122,159C145,159 165,151 181,136C196,121 204,101 204,76C204,52 196,32 180,17C164,1 145,-7 122,-7C99,-7 80,1 64,17C48,32 40,52 40,76M289,76C289,101 297,121 313,136C328,151 348,159 371,159C394,159 414,151 430,136C445,121 453,101 453,76C453,52 445,32 429,17C413,1 394,-7 371,-7C348,-7 329,1 313,17C297,32 289,52 289,76M539,76C539,101 547,121 563,136C578,151 598,159 621,159C644,159 664,151 680,136C695,121 703,101 703,76C703,52 695,32 679,17C663,1 644,-7 621,-7C598,-7 579,1 563,17C547,32 539,52 539,76z"
        />
        <glyph
          unicode="!"
          horizAdvX="278"
          d="M66,700l148,0l-25,-468l-99,0M59,71C59,93 67,111 82,126C97,141 117,148 140,148C163,148 182,141 197,126C212,111 219,93 219,71C219,49 212,31 197,16C182,1 163,-7 140,-7C117,-7 97,1 82,16C67,31 59,49 59,71z"
        />
        <glyph
          unicode="&#xA1;"
          horizAdvX="278"
          d="M219,462C219,441 212,423 197,408C182,393 163,386 140,386C117,386 97,393 82,408C67,423 59,441 59,462C59,484 67,503 82,518C97,533 117,540 140,540C163,540 182,533 197,518C212,503 219,484 219,462M214,-154l-148,0l24,456l99,0z"
        />
        <glyph
          unicode="#"
          horizAdvX="711"
          d="M520,261l135,0l0,-91l-146,0l-21,-170l-91,0l21,170l-169,0l-21,-170l-91,0l21,170l-135,0l0,91l146,0l23,178l-135,0l0,91l146,0l21,170l91,0l-21,-170l168,0l21,170l91,0l-21,-170l134,0l1,-91l-146,0M429,261l22,178l-168,0l-23,-178z"
        />
        <glyph
          unicode="."
          horizAdvX="244"
          d="M40,76C40,101 48,121 64,136C79,151 99,159 122,159C145,159 165,151 181,136C196,121 204,101 204,76C204,52 196,32 180,17C164,1 145,-7 122,-7C99,-7 80,1 64,17C48,32 40,52 40,76z"
        />
        <glyph
          unicode="?"
          horizAdvX="581"
          d="M318,399C342,420 360,439 372,455C383,470 389,488 389,508C389,536 378,559 355,576C332,593 302,602 264,602C192,602 138,576 101,525l-97,63C33,627 70,658 115,679C160,700 214,710 275,710C350,710 409,694 454,662C498,629 520,585 520,528C520,501 516,477 507,456C498,435 487,418 474,404C461,389 445,373 424,354C399,331 380,311 368,294C355,277 349,256 349,232l-127,0C222,268 230,298 247,322C263,345 287,371 318,399M206,71C206,93 214,111 229,126C244,141 263,148 286,148C309,148 328,141 343,126C358,111 365,93 365,71C365,49 358,31 343,16C328,1 309,-7 286,-7C263,-7 244,1 229,16C214,31 206,49 206,71z"
        />
        <glyph
          unicode="&#xBF;"
          horizAdvX="581"
          d="M215,462C215,484 223,503 238,518C253,533 272,540 295,540C318,540 338,533 353,518C368,503 375,484 375,462C375,441 368,423 353,408C338,393 318,386 295,386C272,386 253,393 238,408C223,423 215,441 215,462M61,12C61,39 66,62 75,83C84,104 94,121 107,135C120,149 136,165 157,183C183,206 202,226 214,243C226,259 232,279 232,302l127,0C359,277 355,255 346,236C337,217 326,200 314,187C301,173 285,157 264,139C239,118 221,99 210,84C198,69 192,51 192,31C192,6 203,-15 226,-31C249,-48 279,-56 317,-56C388,-56 442,-30 480,21l96,-63C548,-81 511,-111 466,-132C420,-153 367,-164 306,-164C232,-164 173,-148 128,-117C83,-86 61,-43 61,12z"
        />
        <glyph
          unicode='"'
          horizAdvX="413"
          d="M56,700l108,0l-9,-277l-91,0M249,700l108,0l-9,-277l-91,0z"
        />
        <glyph unicode="'" horizAdvX="220" d="M56,700l108,0l-9,-277l-91,0z" />
        <glyph
          unicode=";"
          horizAdvX="244"
          d="M40,457C40,482 48,502 64,517C79,532 99,540 122,540C145,540 165,532 181,517C196,502 204,482 204,457C204,433 196,413 180,398C164,382 145,374 122,374C99,374 80,382 64,398C48,413 40,433 40,457M204,76C204,65 203,54 200,43C197,31 190,13 181,-10l-51,-141l-81,0l40,153C74,8 63,18 55,31C46,44 42,59 42,76C42,101 50,121 65,136C80,151 100,159 123,159C146,159 166,151 181,136C196,121 204,101 204,76z"
        />
        <glyph
          unicode="/"
          horizAdvX="371"
          d="M300,842l112,0l-330,-942l-112,0z"
        />
        <glyph unicode="_" horizAdvX="500" d="M0,0l500,0l0,-76l-500,0z" />
        <glyph
          unicode="{"
          horizAdvX="370"
          d="M199,274C220,267 235,256 244,241C253,225 258,202 258,172l0,-190C258,-43 264,-61 277,-74C289,-87 307,-94 332,-94l21,0l0,-100l-50,0C247,-194 205,-180 176,-152C147,-125 133,-84 133,-31l0,206C133,207 118,223 88,223l-33,0l0,102l33,0C118,325 133,341 133,373l0,206C133,632 147,673 176,701C205,728 247,742 303,742l50,0l0,-100l-21,0C307,642 289,636 277,623C264,610 258,591 258,566l0,-190C258,346 253,323 244,308C235,292 220,281 199,274z"
        />
        <glyph
          unicode="}"
          horizAdvX="370"
          d="M316,325l0,-102l-34,0C268,223 257,219 250,211C242,203 238,191 238,175l0,-206C238,-84 224,-125 195,-152C166,-180 123,-194 67,-194l-50,0l0,100l21,0C63,-94 82,-87 95,-74C107,-61 113,-43 113,-18l0,190C113,203 118,226 127,241C136,256 150,267 171,274C150,281 136,292 127,307C118,322 113,345 113,376l0,190C113,591 107,610 95,623C82,636 63,642 38,642l-21,0l0,100l50,0C123,742 166,728 195,701C224,673 238,632 238,579l0,-206C238,357 242,345 250,337C257,329 268,325 282,325z"
        />
        <glyph
          unicode="["
          horizAdvX="350"
          d="M94,742l239,0l0,-100l-114,0l0,-736l114,0l0,-100l-239,0z"
        />
        <glyph
          unicode="]"
          horizAdvX="350"
          d="M17,-194l0,100l113,0l0,736l-113,0l0,100l238,0l0,-936z"
        />
        <glyph
          unicode="("
          horizAdvX="347"
          d="M84,274C84,365 95,451 116,532C137,612 167,682 206,742l119,0C284,670 255,596 236,520C217,443 207,361 207,274C207,187 217,105 236,29C255,-48 284,-122 325,-194l-119,0C167,-133 137,-63 116,17C95,97 84,183 84,274z"
        />
        <glyph
          unicode=")"
          horizAdvX="348"
          d="M22,-194C63,-122 93,-48 112,29C131,106 141,187 141,274C141,361 131,442 112,519C93,596 63,670 22,742l119,0C180,683 211,613 232,533C253,452 263,366 263,274C263,182 253,96 232,17C211,-63 180,-133 141,-194z"
        />
        <glyph
          unicode="&#x2014;"
          horizAdvX="1000"
          d="M0,319l1000,0l0,-82l-1000,0z"
        />
        <glyph
          unicode="&#x2013;"
          horizAdvX="500"
          d="M0,319l500,0l0,-82l-500,0z"
        />
        <glyph
          unicode="&#x2012;"
          horizAdvX="700"
          d="M45,396l610,0l0,-82l-610,0z"
        />
        <glyph
          unicode="&#x2015;"
          horizAdvX="1000"
          d="M0,319l1000,0l0,-82l-1000,0z"
        />
        <glyph unicode="-" horizAdvX="385" d="M55,330l275,0l0,-104l-275,0z" />
        <glyph
          unicode="&#x2010;"
          horizAdvX="385"
          d="M55,330l275,0l0,-104l-275,0z"
        />
        <glyph
          unicode="&#xAD;"
          horizAdvX="385"
          d="M55,330l275,0l0,-104l-275,0z"
        />
        <glyph
          unicode="&#xAB;"
          horizAdvX="535"
          d="M42,267l142,194l113,0l-139,-194l139,-193l-113,0M250,267l142,194l113,0l-139,-194l139,-193l-113,0z"
        />
        <glyph
          unicode="&#xBB;"
          horizAdvX="535"
          d="M30,74l139,193l-139,194l113,0l142,-194l-142,-193M238,74l139,193l-139,194l113,0l142,-194l-142,-193z"
        />
        <glyph
          unicode="&#x2039;"
          horizAdvX="327"
          d="M42,267l142,194l113,0l-139,-194l139,-193l-113,0z"
        />
        <glyph
          unicode="&#x203A;"
          horizAdvX="327"
          d="M30,74l139,193l-139,194l113,0l142,-194l-142,-193z"
        />
        <glyph
          unicode="&#x201E;"
          horizAdvX="456"
          d="M204,76C204,65 203,54 200,43C197,31 190,13 181,-10l-51,-141l-81,0l40,153C74,8 63,18 55,31C46,44 42,59 42,76C42,101 50,121 65,136C80,151 100,159 123,159C146,159 166,151 181,136C196,121 204,101 204,76M416,76C416,65 415,54 412,43C409,31 402,13 393,-10l-51,-141l-81,0l40,153C286,8 275,18 267,31C258,44 254,59 254,76C254,101 262,121 277,136C292,151 312,159 335,159C358,159 378,151 393,136C408,121 416,101 416,76z"
        />
        <glyph
          unicode="&#x201C;"
          horizAdvX="456"
          d="M202,514C202,490 194,470 179,455C164,440 145,432 122,432C98,432 78,440 63,455C48,470 40,490 40,514C40,526 42,538 45,549C48,560 53,577 62,600l53,142l80,0l-40,-153C170,583 181,574 190,561C198,548 202,532 202,514M414,514C414,490 406,470 391,455C376,440 357,432 334,432C310,432 290,440 275,455C260,470 252,490 252,514C252,526 254,538 257,549C260,560 265,577 274,600l53,142l80,0l-40,-153C382,583 393,574 402,561C410,548 414,532 414,514z"
        />
        <glyph
          unicode="&#x201D;"
          horizAdvX="456"
          d="M204,667C204,655 203,643 200,632C197,621 191,604 182,581l-52,-142l-81,0l40,154C74,598 63,608 55,621C46,634 42,649 42,667C42,691 50,711 65,726C80,741 100,749 123,749C146,749 166,741 181,726C196,711 204,691 204,667M416,667C416,655 415,643 412,632C409,621 403,604 394,581l-52,-142l-81,0l40,154C286,598 275,608 267,621C258,634 254,649 254,667C254,691 262,711 277,726C292,741 312,749 335,749C358,749 378,741 393,726C408,711 416,691 416,667z"
        />
        <glyph
          unicode="&#x2018;"
          horizAdvX="244"
          d="M202,514C202,490 194,470 179,455C164,440 145,432 122,432C98,432 78,440 63,455C48,470 40,490 40,514C40,526 42,538 45,549C48,560 53,577 62,600l53,142l80,0l-40,-153C170,583 181,574 190,561C198,548 202,532 202,514z"
        />
        <glyph
          unicode="&#x2019;"
          horizAdvX="244"
          d="M204,667C204,655 203,643 200,632C197,621 191,604 182,581l-52,-142l-81,0l40,154C74,598 63,608 55,621C46,634 42,649 42,667C42,691 50,711 65,726C80,741 100,749 123,749C146,749 166,741 181,726C196,711 204,691 204,667z"
        />
        <glyph
          unicode="&#x201A;"
          horizAdvX="244"
          d="M204,76C204,65 203,54 200,43C197,31 190,13 181,-10l-51,-141l-81,0l40,153C74,8 63,18 55,31C46,44 42,59 42,76C42,101 50,121 65,136C80,151 100,159 123,159C146,159 166,151 181,136C196,121 204,101 204,76z"
        />
        <glyph
          unicode="&#x27E8;"
          horizAdvX="344"
          d="M29,274l165,468l114,0l-157,-468l157,-468l-114,0z"
        />
        <glyph
          unicode="&#x27E9;"
          horizAdvX="344"
          d="M315,274l-165,-468l-114,0l157,468l-157,468l114,0z"
        />
        <glyph unicode="&#x2007;" horizAdvX="700" />
        <glyph unicode="&#x200A;" horizAdvX="100" />
        <glyph unicode="&#x2008;" horizAdvX="244" />
        <glyph unicode="&#xA0;" horizAdvX="276" />
        <glyph unicode="&#x2009;" horizAdvX="138" />
        <glyph unicode="&#x200B;" horizAdvX="0" />
        <glyph
          unicode="&#x20B5;"
          horizAdvX="727"
          d="M612,187l84,-80C667,72 631,45 589,26C547,6 500,-6 449,-9l0,-111l-82,0l0,113C305,0 250,20 201,53C152,85 113,127 86,179C58,230 44,287 44,350C44,413 58,470 86,522C113,573 152,615 201,648C250,680 305,700 367,707l0,113l82,0l0,-111C500,706 547,694 589,675C631,655 667,628 696,593l-84,-79C567,563 513,590 449,595l0,-490C513,110 567,138 612,187M367,110l0,480C310,579 263,551 228,508C193,464 175,411 175,350C175,289 193,236 228,193C263,149 310,121 367,110z"
        />
        <glyph
          unicode="&#xA2;"
          horizAdvX="581"
          d="M459,174l96,-56C536,82 510,54 477,33C443,12 404,-1 359,-6l0,-114l-81,0l0,116C231,3 189,18 153,43C116,68 88,99 68,138C48,177 38,220 38,267C38,314 48,357 68,396C88,435 116,466 153,491C189,515 231,530 278,537l0,117l81,0l0,-115C404,535 443,523 477,502C511,481 537,452 555,416l-96,-56C435,399 402,422 359,431l0,-328C402,112 435,135 459,174M278,106l0,322C243,418 216,399 195,371C174,343 164,308 164,267C164,226 174,191 195,163C216,134 243,115 278,106z"
        />
        <glyph
          unicode="&#x20A1;"
          horizAdvX="727"
          d="M612,187l84,-80C664,70 625,41 578,21C531,1 479,-9 422,-10l-28,-110l-76,0l29,116C320,1 295,7 274,15l-34,-135l-76,0l43,168C156,80 117,122 88,175C59,227 44,285 44,350C44,414 58,472 87,524C115,576 154,618 204,651C254,683 311,702 374,708l29,112l76,0l-28,-111C477,707 502,703 526,697l31,123l76,0l-38,-149C634,652 668,626 696,593l-84,-79C597,530 581,543 565,554l-114,-449C514,111 567,138 612,187M376,108l122,478C476,593 452,596 425,596l-3,0l-118,-465C327,120 351,112 376,108M240,179l103,405C292,568 251,539 221,498C190,456 175,407 175,350C175,316 181,285 192,256C203,227 219,201 240,179z"
        />
        <glyph
          unicode="&#xA4;"
          horizAdvX="700"
          d="M570,170l101,-100l-71,-75l-106,105C451,73 403,59 350,59C297,59 249,73 206,101l-107,-106l-71,75l102,102C99,217 83,267 83,320C83,373 98,421 128,466l-100,99l71,75l103,-102C246,567 295,582 350,582C403,582 453,568 498,539l102,101l71,-75l-99,-98C603,422 618,373 618,320C618,266 602,216 570,170M519,320C519,349 511,376 496,401C481,426 460,445 434,460C408,474 380,481 350,481C320,481 292,474 267,460C241,445 221,426 206,401C191,376 183,349 183,320C183,291 191,265 206,240C221,215 241,196 267,182C292,167 320,160 350,160C380,160 408,167 434,182C460,196 481,215 496,240C511,265 519,291 519,320z"
        />
        <glyph
          unicode="$"
          horizAdvX="629"
          d="M362,-8l0,-112l-82,0l0,111C231,-6 184,3 140,18C96,33 61,51 35,74l45,101C105,155 135,138 170,125C205,111 242,102 280,99l0,200C233,310 193,323 162,337C130,350 103,370 82,397C61,423 50,458 50,501C50,555 70,601 109,639C148,677 205,700 280,707l0,113l82,0l0,-111C399,707 436,701 472,690C507,679 538,665 565,648l-41,-101C473,576 419,594 362,601l0,-202C410,388 450,375 482,362C513,349 540,329 562,303C583,276 594,241 594,198C594,144 574,98 535,61C495,23 437,0 362,-8M280,420l0,178C246,593 221,582 204,566C187,550 179,530 179,507C179,485 188,467 205,454C222,441 247,429 280,420M466,189C466,212 457,230 439,244C421,257 395,269 362,278l0,-177C397,106 424,117 441,132C458,147 466,166 466,189z"
        />
        <glyph
          unicode="&#x20AB;"
          horizAdvX="687"
          d="M687,602l-82,0l0,-602l-120,0l0,69C464,44 439,25 409,12C378,-1 345,-7 308,-7C257,-7 211,4 170,27C129,50 96,82 73,124C50,165 38,213 38,267C38,321 50,369 73,410C96,451 129,483 170,506C211,529 257,540 308,540C343,540 376,534 405,522C434,510 459,492 480,468l0,134l-197,0l0,69l197,0l0,71l125,0l0,-71l82,0M482,267C482,300 475,330 461,355C447,380 428,400 404,414C380,427 353,434 323,434C293,434 266,427 242,414C218,400 199,380 185,355C171,330 164,300 164,267C164,234 171,204 185,179C199,154 218,134 242,121C266,107 293,100 323,100C353,100 380,107 404,121C428,134 447,154 461,179C475,204 482,234 482,267M59,-68l546,0l0,-69l-546,0z"
        />
        <glyph
          unicode="&#x20AC;"
          horizAdvX="813"
          d="M698,187l83,-80C749,69 709,40 662,20C614,0 561,-10 504,-10C446,-10 392,1 343,22C293,43 251,73 217,112C182,151 158,195 144,246l-114,0l0,70l101,0C130,323 130,335 130,350C130,365 130,377 131,384l-101,0l0,70l114,0C158,505 182,549 217,588C251,627 293,657 343,678C392,699 446,710 504,710C561,710 614,700 662,680C709,660 749,631 781,593l-83,-79C647,569 585,596 511,596C458,596 411,583 371,558C330,533 300,498 281,454l287,0l0,-70l-306,0C261,369 260,358 260,350C260,342 261,331 262,316l306,0l0,-70l-287,0C300,202 330,167 371,142C411,117 458,104 511,104C585,104 647,132 698,187z"
        />
        <glyph
          unicode="&#x192;"
          horizAdvX="464"
          d="M296,561l-6,-44l147,0l-12,-100l-143,0l-54,-433C221,-73 199,-118 164,-151C128,-184 82,-201 25,-201C0,-201 -22,-198 -42,-193C-63,-188 -80,-180 -93,-169l44,92C-31,-91 -8,-98 19,-98C42,-98 61,-91 76,-76C91,-62 100,-41 103,-13l54,430l-87,0l12,100l87,0l6,47C182,621 203,666 240,699C277,732 324,749 383,749C406,749 427,746 448,741C468,736 484,728 497,717l-45,-92C434,639 412,646 387,646C333,646 303,618 296,561z"
        />
        <glyph
          unicode="&#x20A3;"
          horizAdvX="673"
          d="M260,591l0,-213l338,0l0,-110l-338,0l0,-94l220,0l0,-70l-220,0l0,-104l-130,0l0,104l-100,0l0,70l100,0l0,526l511,0l0,-109z"
        />
        <glyph
          unicode="&#x20B2;"
          horizAdvX="772"
          d="M572,358l123,0l0,-279C662,53 625,33 582,18C539,3 495,-6 449,-9l0,-111l-82,0l0,113C304,0 249,20 200,53C151,86 112,128 85,179C58,230 44,287 44,350C44,413 58,470 85,521C112,572 151,614 200,647C249,679 304,699 367,707l0,113l82,0l0,-111C502,706 550,696 593,677C636,658 673,631 704,596l-82,-80C573,565 516,591 449,595l0,-490C493,107 534,119 572,140M367,110l0,480C309,578 263,550 228,507C193,464 175,411 175,350C175,289 193,237 228,194C263,151 309,123 367,110z"
        />
        <glyph
          unicode="&#x20B4;"
          horizAdvX="825"
          d="M742,456l0,-69l-665,0l0,69l437,0C539,470 552,487 552,507C552,536 540,559 515,576C490,593 452,602 401,602C332,602 266,587 202,557l-30,102C206,676 243,689 282,698C321,707 359,711 397,711C456,711 506,702 549,685C592,667 624,642 647,611C670,579 681,542 681,501C681,484 679,469 675,456M77,318l665,0l0,-69l-428,0C282,232 266,212 266,189C266,162 279,140 304,123C329,106 368,98 420,98C464,98 507,104 550,116C592,128 627,144 656,164l36,-101C653,40 611,22 564,9C517,-4 470,-11 423,-11C363,-11 312,-2 269,15C226,32 194,57 171,88C148,119 137,156 137,198C137,215 140,232 147,249l-70,0z"
        />
        <glyph
          unicode="&#x20AD;"
          horizAdvX="697"
          d="M440,309l262,-309l-152,0l-263,309l-29,0l0,-309l-128,0l0,309l-100,0l0,82l100,0l0,309l128,0l0,-309l29,0l263,309l152,0l-262,-309l186,0l0,-82z"
        />
        <glyph
          unicode="&#x20A4;"
          horizAdvX="657"
          d="M265,109l361,0l0,-109l-596,0l0,109l105,0l0,137l-105,0l0,70l105,0l0,68l-105,0l0,70l105,0C139,533 168,595 223,641C277,687 351,710 446,710C525,710 591,694 643,663l-41,-106C561,583 507,596 439,596C385,596 344,584 315,560C286,536 269,501 266,454l252,0l0,-70l-253,0l0,-68l253,0l0,-70l-253,0z"
        />
        <glyph
          unicode="&#x20BA;"
          horizAdvX="731"
          d="M278,0l-143,0l0,254l-105,-56l0,74l105,55l0,69l-105,-55l0,73l105,56l0,230l128,0l0,-163l255,135l0,-74l-255,-134l0,-69l255,134l0,-73l-255,-135l0,-212l43,0C391,109 455,130 498,173C541,216 562,276 562,355l129,0C692,243 657,156 587,94C516,31 413,0 278,0z"
        />
        <glyph
          unicode="&#x20BC;"
          horizAdvX="914"
          d="M819,324l0,-324l-120,0l0,322C699,403 682,467 648,513C614,559 564,586 498,594l0,-474l-81,0l0,474C351,586 301,559 267,513C232,466 215,403 215,322l0,-322l-121,0l0,324C94,397 108,461 135,517C162,573 199,618 248,651C297,684 353,703 417,708l0,112l81,0l0,-112C562,702 618,683 667,650C715,617 752,573 779,517C806,461 819,397 819,324z"
        />
        <glyph
          unicode="&#x20A6;"
          horizAdvX="891"
          d="M756,384l0,-68l105,0l0,-70l-105,0l0,-246l-107,0l-385,473l0,-473l-129,0l0,246l-105,0l0,70l105,0l0,68l-105,0l0,70l105,0l0,246l107,0l385,-474l0,474l129,0l0,-246l105,0l0,-70z"
        />
        <glyph
          unicode="&#x20A7;"
          horizAdvX="1670"
          d="M680,451C680,400 668,356 644,319C620,281 586,252 541,232C496,212 443,202 382,202l-158,0l0,-202l-130,0l0,700l288,0C443,700 496,690 541,670C586,650 620,621 644,584C668,547 680,502 680,451M549,451C549,496 534,530 505,554C476,578 433,590 376,590l-152,0l0,-278l152,0C433,312 476,324 505,348C534,372 549,406 549,451M1134,29C1119,17 1102,8 1081,2C1060,-4 1037,-7 1014,-7C955,-7 910,8 878,39C846,70 830,114 830,173l0,257l-88,0l0,100l88,0l0,122l125,0l0,-122l143,0l0,-100l-143,0l0,-254C955,150 961,130 974,117C987,103 1005,96 1029,96C1057,96 1080,103 1099,118M1176,53l48,95C1248,132 1277,119 1311,110C1344,100 1377,95 1410,95C1485,95 1522,115 1522,154C1522,173 1513,186 1494,193C1475,200 1444,207 1402,214C1358,221 1322,228 1295,237C1267,246 1243,261 1223,283C1202,304 1192,334 1192,373C1192,424 1213,464 1256,495C1298,525 1355,540 1427,540C1464,540 1500,536 1537,528C1574,519 1604,508 1627,494l-48,-95C1534,426 1483,439 1426,439C1389,439 1362,434 1343,423C1324,412 1314,397 1314,379C1314,359 1324,345 1345,337C1365,328 1396,320 1439,313C1482,306 1517,299 1544,290C1571,281 1595,267 1615,246C1634,225 1644,196 1644,158C1644,108 1622,68 1579,38C1536,8 1477,-7 1402,-7C1359,-7 1316,-1 1275,10C1234,21 1201,36 1176,53z"
        />
        <glyph
          unicode="&#x20B1;"
          horizAdvX="855"
          d="M825,486l-107,0C719,471 720,460 720,451C720,443 719,432 718,417l107,0l0,-69l-125,0C680,302 646,266 598,241C550,215 491,202 422,202l-157,0l0,-202l-130,0l0,348l-105,0l0,69l105,0l0,69l-105,0l0,69l105,0l0,145l287,0C491,700 550,687 598,662C645,637 679,601 700,555l125,0M265,555l279,0C515,578 472,590 417,590l-152,0M586,486l-321,0l0,-69l321,0C588,428 589,440 589,451C589,464 588,475 586,486M545,348l-280,0l0,-36l152,0C472,312 515,324 545,348z"
        />
        <glyph
          unicode="&#x20BD;"
          horizAdvX="762"
          d="M260,231l0,-57l220,0l0,-70l-220,0l0,-104l-130,0l0,104l-100,0l0,70l100,0l0,526l288,0C511,700 584,680 637,640C689,600 715,542 715,465C715,389 689,331 637,291C584,251 511,231 418,231M417,590l-157,0l0,-254l157,0C470,336 512,347 541,370C570,392 584,423 584,464C584,505 570,536 541,558C512,579 471,590 417,590z"
        />
        <glyph
          unicode="&#x20B9;"
          horizAdvX="722"
          d="M672,630l-157,0C536,611 552,588 563,561l109,0l0,-70l-94,0C579,486 579,480 579,471C579,418 566,374 539,338C512,301 473,274 424,256l168,-256l-140,0l-153,234l-17,0l-188,0l0,110l180,0C333,344 377,356 406,380C434,404 448,436 448,477l0,14l-413,0l0,70l392,0C413,584 393,601 366,613C339,625 305,631 265,630l-230,0l0,70l637,0z"
        />
        <glyph
          unicode="&#xA3;"
          horizAdvX="657"
          d="M265,109l361,0l0,-109l-596,0l0,109l105,0l0,200l-105,0l0,82l105,0l0,46C135,492 147,541 172,582C196,623 231,654 278,677C325,699 381,710 446,710C525,710 591,694 643,663l-41,-106C561,583 507,596 439,596C382,596 339,583 310,556C280,529 265,489 265,438l0,-47l253,0l0,-82l-253,0z"
        />
        <glyph
          unicode="&#x20B8;"
          horizAdvX="725"
          d="M70,700l593,0l0,-71l-593,0M302,491l-232,0l0,71l593,0l0,-71l-232,0l0,-491l-129,0z"
        />
        <glyph
          unicode="&#x20AE;"
          horizAdvX="692"
          d="M411,590l0,-147l151,74l0,-74l-151,-74l0,-69l151,74l0,-73l-151,-74l0,-227l-130,0l0,163l-152,-74l0,73l152,74l0,69l-152,-74l0,73l152,75l0,211l-232,0l0,110l594,0l0,-110z"
        />
        <glyph
          unicode="&#x20A9;"
          horizAdvX="1219"
          d="M1066,384l-24,-68l147,0l0,-70l-171,0l-85,-246l-139,0l-87,246l-194,0l-88,-246l-139,0l-85,246l-171,0l0,70l147,0l-23,68l-124,0l0,70l100,0l-85,246l134,0l84,-246l201,0l87,246l124,0l85,-246l201,0l86,246l128,0l-85,-246l99,0l0,-70M587,454l46,0l-23,65M311,316l104,0l24,68l-152,0M682,316l-24,68l-96,0l-24,-68M937,384l-153,0l24,-68l105,0M390,246l-55,0l27,-79M888,246l-56,0l28,-81z"
        />
        <glyph
          unicode="&#xA5;"
          horizAdvX="721"
          d="M459,312l166,0l0,-70l-199,0l0,-68l199,0l0,-70l-199,0l0,-104l-130,0l0,104l-199,0l0,70l199,0l0,68l-199,0l0,70l165,0l-271,388l138,0l235,-336l235,336l131,0z"
        />
        <glyph
          unicode="&#x2219;"
          horizAdvX="304"
          d="M70,353C70,377 78,397 94,413C109,428 129,436 152,436C176,436 196,428 211,413C226,398 234,378 234,353C234,328 226,307 211,292C196,277 176,269 152,269C129,269 109,277 94,292C78,307 70,328 70,353z"
        />
        <glyph
          unicode="&#x2052;"
          horizAdvX="565"
          d="M65,630C65,650 72,667 86,680C100,693 117,700 137,700C157,700 174,693 188,680C201,667 208,650 208,630C208,611 201,594 187,581C173,567 156,560 137,560C117,560 100,567 86,581C72,594 65,611 65,630M363,700l94,0l-255,-700l-94,0M357,70C357,90 364,107 378,120C391,133 408,140 428,140C448,140 465,133 479,120C492,107 499,90 499,70C499,50 492,33 479,20C465,7 448,0 428,0C408,0 391,7 378,20C364,33 357,50 357,70z"
        />
        <glyph
          unicode="&#x2215;"
          horizAdvX="371"
          d="M300,842l112,0l-330,-942l-112,0z"
        />
        <glyph
          unicode="+"
          horizAdvX="590"
          d="M526,300l-177,0l0,-175l-107,0l0,175l-177,0l0,100l177,0l0,175l107,0l0,-175l177,0z"
        />
        <glyph
          unicode="&#x2212;"
          horizAdvX="590"
          d="M65,400l461,0l0,-100l-461,0z"
        />
        <glyph
          unicode="&#xD7;"
          horizAdvX="590"
          d="M366,350l127,-128l-69,-73l-129,130l-129,-130l-67,73l126,128l-126,128l67,73l129,-130l129,130l69,-73z"
        />
        <glyph
          unicode="&#xF7;"
          horizAdvX="590"
          d="M226,553C226,574 233,591 246,604C259,617 276,624 295,624C315,624 332,617 345,604C358,591 365,574 365,553C365,532 358,515 345,502C332,488 315,481 295,481C276,481 259,488 246,502C233,515 226,532 226,553M65,400l461,0l0,-100l-461,0M226,148C226,169 233,186 246,199C259,212 276,219 295,219C315,219 332,212 345,199C358,186 365,169 365,148C365,127 358,110 345,97C332,83 315,76 295,76C276,76 259,83 246,97C233,110 226,127 226,148z"
        />
        <glyph
          unicode="="
          horizAdvX="590"
          d="M65,521l461,0l0,-100l-461,0M65,279l461,0l0,-100l-461,0z"
        />
        <glyph
          unicode="&#x2260;"
          horizAdvX="590"
          d="M385,421l-76,-142l217,0l0,-100l-270,0l-61,-115l-103,0l61,115l-88,0l0,100l142,0l75,142l-217,0l0,100l271,0l61,115l102,0l-61,-115l88,0l0,-100z"
        />
        <glyph
          unicode="&gt;"
          horizAdvX="590"
          d="M526,404l0,-108l-461,-179l0,104l345,129l-345,129l0,104z"
        />
        <glyph
          unicode="&lt;"
          horizAdvX="590"
          d="M526,479l-345,-129l345,-129l0,-104l-461,179l0,108l461,179z"
        />
        <glyph
          unicode="&#x2265;"
          horizAdvX="590"
          d="M526,458l0,-105l-461,-170l0,103l327,119l-327,120l0,103M65,100l461,0l0,-100l-461,0z"
        />
        <glyph
          unicode="&#x2264;"
          horizAdvX="590"
          d="M526,525l-327,-120l327,-119l0,-103l-461,170l0,105l461,170M65,100l461,0l0,-100l-461,0z"
        />
        <glyph
          unicode="&#xB1;"
          horizAdvX="590"
          d="M526,464l0,-99l-177,0l0,-168l-107,0l0,168l-177,0l0,99l177,0l0,169l107,0l0,-169M65,100l461,0l0,-100l-461,0z"
        />
        <glyph
          unicode="&#x2248;"
          horizAdvX="590"
          d="M273,432C254,446 240,456 231,461C221,466 211,469 200,469C181,469 165,462 154,447C142,432 135,411 134,385l-80,0C55,443 69,487 94,518C119,548 153,563 194,563C218,563 239,558 258,549C276,539 296,525 319,508C335,495 348,486 359,480C369,474 379,471 390,471C410,471 426,479 438,494C449,509 456,529 457,555l79,0C535,497 522,453 497,423C472,392 438,377 396,377C372,377 351,382 333,392C314,401 294,415 273,432M273,191C256,204 242,214 232,220C222,226 211,229 200,229C181,229 165,221 154,206C142,191 135,170 134,145l-80,0C55,203 69,247 94,277C119,307 153,322 194,322C218,322 239,317 258,308C276,298 296,284 319,267C335,254 348,245 359,239C369,233 379,230 390,230C410,230 426,238 438,253C449,268 456,289 457,315l79,0C535,256 522,212 497,182C472,151 438,136 396,136C372,136 351,141 334,150C316,159 296,173 273,191z"
        />
        <glyph
          unicode="~"
          horizAdvX="590"
          d="M273,313C254,327 240,337 231,342C221,347 211,350 200,350C181,350 165,342 154,327C142,312 135,291 134,266l-80,0C55,324 69,368 94,399C119,429 153,444 194,444C219,444 240,439 259,430C277,420 297,406 319,388C335,375 348,366 359,360C369,354 379,351 390,351C410,351 426,359 438,374C449,389 456,410 457,436l79,0C535,377 522,333 497,303C472,273 438,258 396,258C372,258 351,263 333,273C314,282 294,296 273,313z"
        />
        <glyph
          unicode="&#xAC;"
          horizAdvX="590"
          d="M526,124l-107,0l0,177l-354,0l0,100l461,0z"
        />
        <glyph
          unicode="&#x2205;"
          horizAdvX="590"
          d="M479,471C508,432 523,388 523,338C523,297 513,260 493,225C472,190 445,163 410,143C375,122 337,112 296,112C242,112 194,130 152,165l-85,-79l-35,39l84,78C86,244 71,289 71,338C71,379 81,416 102,451C122,485 149,512 184,532C218,552 255,562 296,562C351,562 400,544 443,509l81,76l35,-39M178,261l203,191C356,470 328,479 296,479C271,479 247,473 226,460C204,447 187,430 175,409C162,387 156,363 156,338C156,311 163,285 178,261M417,413l-203,-190C239,205 266,196 296,196C322,196 346,202 368,215C390,228 407,245 420,267C433,289 439,313 439,338C439,366 432,391 417,413z"
        />
        <glyph
          unicode="&#x221E;"
          horizAdvX="927"
          d="M889,351C889,312 880,277 862,246C844,215 820,190 789,173C758,155 723,146 684,146C632,146 589,157 556,179C522,200 491,230 464,269C437,230 406,200 373,179C340,157 297,146 244,146C205,146 170,155 139,173C108,190 83,215 65,246C47,277 38,311 38,350C38,389 47,424 65,455C83,486 108,510 139,528C170,545 205,554 244,554C297,554 340,543 373,522C406,500 437,470 464,431C491,470 522,500 556,522C589,543 632,554 684,554C723,554 758,545 789,528C820,510 844,486 862,455C880,424 889,390 889,351M412,350C389,386 365,414 342,433C318,452 288,461 253,461C220,461 192,451 171,430C149,409 138,382 138,350C138,318 149,292 171,271C192,250 220,239 253,239C288,239 318,249 342,268C365,287 389,314 412,350M789,351C789,382 778,409 756,430C734,451 707,461 674,461C639,461 609,452 586,433C562,414 539,386 516,350C539,314 562,287 586,268C609,249 639,239 674,239C707,239 734,250 756,271C778,292 789,319 789,351z"
        />
        <glyph
          unicode="&#x222B;"
          horizAdvX="416"
          d="M-33,-169l34,92C19,-91 42,-98 71,-98C94,-98 113,-91 126,-76C139,-62 145,-41 145,-13l0,580C145,624 161,669 193,702C225,735 270,752 327,752C351,752 374,749 395,744C416,739 434,731 449,721l-35,-93C394,643 371,650 345,650C321,650 303,643 290,628C277,613 270,592 270,564l0,-580C270,-73 254,-118 223,-151C191,-184 146,-201 88,-201C64,-201 42,-198 21,-193C0,-188 -18,-180 -33,-169z"
        />
        <glyph
          unicode="&#x2126;"
          horizAdvX="894"
          d="M9,110l173,0C147,141 120,179 101,222C82,265 72,313 72,365C72,432 88,491 121,544C154,596 199,637 256,666C313,695 376,710 447,710C518,710 582,695 639,666C696,637 740,596 773,544C806,491 822,432 822,365C822,313 812,265 793,222C774,179 747,141 712,110l173,0l0,-110l-356,0l0,102C582,129 622,164 650,209C678,254 692,303 692,356C692,403 682,445 661,482C640,518 611,546 574,566C537,586 494,596 447,596C400,596 357,586 320,566C283,546 254,518 233,482C212,445 202,403 202,356C202,302 216,253 244,208C272,163 312,128 365,102l0,-102l-356,0z"
        />
        <glyph
          unicode="&#x2206;"
          horizAdvX="749"
          d="M754,0l-759,0l315,700l128,0M173,110l399,0l-199,462z"
        />
        <glyph
          unicode="&#x220F;"
          horizAdvX="810"
          d="M94,700l622,0l0,-894l-130,0l0,778l-362,0l0,-778l-130,0z"
        />
        <glyph
          unicode="&#x2211;"
          horizAdvX="663"
          d="M638,-84l0,-110l-597,0l0,87l318,361l-305,359l0,87l577,0l0,-110l-402,0l252,-293l0,-87l-266,-294z"
        />
        <glyph
          unicode="&#x221A;"
          horizAdvX="811"
          d="M816,742l-314,-936l-129,0l-177,495l-131,0l0,100l223,0l149,-437l262,778z"
        />
        <glyph
          unicode="&#xB5;"
          horizAdvX="686"
          d="M604,534l0,-534l-117,0l0,71C470,44 447,25 420,12C393,-1 364,-7 334,-7C279,-7 237,9 207,40l0,-234l-125,0l0,728l125,0l0,-288C207,198 218,162 240,139C261,115 292,103 333,103C378,103 413,117 440,145C466,172 479,212 479,264l0,270z"
        />
        <glyph
          unicode="&#x2202;"
          horizAdvX="700"
          d="M637,343C637,270 625,208 600,155C575,102 539,61 492,33C445,4 390,-10 325,-10C276,-10 231,-1 192,18C152,37 121,63 98,98C75,133 63,173 63,218C63,263 74,302 97,336C119,370 150,396 189,415C228,434 271,443 319,443C360,443 397,436 430,421C462,406 488,384 509,356l0,15C509,452 488,515 447,560C405,605 346,627 271,627C218,627 171,620 128,606l-18,105C159,726 214,734 273,734C389,734 479,700 542,632C605,563 637,467 637,343M484,216C484,252 470,282 443,306C415,329 380,341 337,341C292,341 257,330 230,307C203,284 190,254 190,216C190,179 203,149 230,126C256,103 290,92 333,92C363,92 390,98 413,109C436,120 453,136 466,155C478,174 484,194 484,216z"
        />
        <glyph
          unicode="%"
          horizAdvX="860"
          d="M33,515C33,572 48,619 79,654C110,689 150,707 200,707C250,707 291,690 322,655C353,620 368,573 368,515C368,457 353,411 322,376C291,341 250,323 200,323C150,323 110,341 79,376C48,411 33,458 33,515M620,700l98,0l-478,-700l-98,0M286,515C286,554 279,584 264,605C249,626 227,637 200,637C174,637 153,626 138,605C122,583 114,553 114,515C114,477 122,447 138,426C153,404 174,393 200,393C227,393 249,404 264,425C279,446 286,476 286,515M492,185C492,242 507,289 538,324C569,359 609,377 659,377C709,377 750,359 781,324C812,289 827,242 827,185C827,128 812,81 781,46C750,11 709,-7 659,-7C609,-7 569,11 538,46C507,81 492,128 492,185M745,185C745,223 737,253 722,275C707,296 686,307 659,307C632,307 611,296 596,275C581,254 573,224 573,185C573,146 581,116 596,95C611,74 632,63 659,63C686,63 707,74 722,96C737,117 745,147 745,185z"
        />
        <glyph
          unicode="&#x2030;"
          horizAdvX="1233"
          d="M33,515C33,572 48,619 79,654C110,689 150,707 200,707C250,707 291,690 322,655C353,620 368,573 368,515C368,457 353,411 322,376C291,341 250,323 200,323C150,323 110,341 79,376C48,411 33,458 33,515M620,700l98,0l-478,-700l-98,0M286,515C286,554 279,584 264,605C249,626 227,637 200,637C174,637 153,626 138,605C122,583 114,553 114,515C114,477 122,447 138,426C153,404 174,393 200,393C227,393 249,404 264,425C279,446 286,476 286,515M492,185C492,242 507,289 538,324C569,359 609,377 659,377C709,377 750,359 781,324C812,289 827,242 827,185C827,128 812,81 781,46C750,11 709,-7 659,-7C609,-7 569,11 538,46C507,81 492,128 492,185M865,185C865,243 881,290 912,325C943,360 983,377 1033,377C1083,377 1123,359 1154,324C1185,289 1200,242 1200,185C1200,128 1185,81 1154,46C1123,11 1083,-7 1033,-7C983,-7 943,11 912,46C881,81 865,127 865,185M745,185C745,223 737,253 722,275C707,296 686,307 659,307C632,307 611,296 596,275C581,254 573,224 573,185C573,146 581,116 596,95C611,74 632,63 659,63C686,63 707,74 722,96C737,117 745,147 745,185M1119,185C1119,223 1111,253 1096,275C1080,296 1059,307 1033,307C1006,307 985,296 970,275C955,254 947,224 947,185C947,146 955,116 970,95C985,74 1006,63 1033,63C1059,63 1080,74 1096,96C1111,117 1119,147 1119,185z"
        />
        <glyph
          unicode="&#x2191;"
          horizAdvX="600"
          d="M514,262l-165,103l0,-319l-98,0l0,320l-165,-104l0,102l213,144l215,-144z"
        />
        <glyph
          unicode="&#x2197;"
          horizAdvX="600"
          d="M446,111l-44,191l-226,-226l-70,74l225,224l-187,44l68,72l252,-51l51,-255z"
        />
        <glyph
          unicode="&#x2192;"
          horizAdvX="600"
          d="M515,275l-144,-217l-110,0l106,171l-308,0l0,91l308,0l-106,170l110,0z"
        />
        <glyph
          unicode="&#x2198;"
          horizAdvX="600"
          d="M514,374l-49,-255l-253,-51l-69,72l187,45l-223,223l70,74l226,-226l43,190z"
        />
        <glyph
          unicode="&#x2193;"
          horizAdvX="600"
          d="M522,288l0,-102l-213,-145l-215,145l0,102l165,-103l0,318l98,0l0,-319z"
        />
        <glyph
          unicode="&#x2199;"
          horizAdvX="600"
          d="M283,190l187,-44l-68,-72l-252,51l-51,255l69,73l44,-191l226,226l70,-74z"
        />
        <glyph
          unicode="&#x2190;"
          horizAdvX="600"
          d="M541,229l-307,0l105,-170l-110,0l-144,216l144,216l110,0l-106,-171l308,0z"
        />
        <glyph
          unicode="&#x2196;"
          horizAdvX="600"
          d="M437,82l-226,226l-43,-190l-68,72l49,255l253,51l69,-72l-187,-45l223,-223z"
        />
        <glyph
          unicode="&#x2194;"
          horizAdvX="778"
          d="M728,275l-143,-216l-110,0l105,170l-382,0l105,-170l-110,0l-143,216l143,216l110,0l-106,-171l384,0l-106,171l110,0z"
        />
        <glyph
          unicode="&#x2195;"
          horizAdvX="600"
          d="M349,523l0,-352l163,104l0,-102l-212,-146l-215,146l0,102l166,-104l0,353l-165,-104l0,102l213,145l215,-145l0,-102z"
        />
        <glyph
          unicode="&#x25C6;"
          horizAdvX="600"
          d="M300,540l274,-275l-274,-274l-275,274z"
        />
        <glyph
          unicode="&#x25C7;"
          horizAdvX="600"
          d="M300,540l274,-275l-274,-274l-275,274M300,91l179,174l-179,174l-180,-174z"
        />
        <glyph
          unicode="&#x25CA;"
          horizAdvX="498"
          d="M38,330l212,317l211,-317l-212,-320M376,330l-126,184l-128,-184l127,-185z"
        />
        <glyph
          unicode="&#x25A0;"
          horizAdvX="600"
          d="M106,459l388,0l0,-388l-388,0z"
        />
        <glyph
          unicode="&#x25A1;"
          horizAdvX="600"
          d="M106,459l388,0l0,-388l-388,0M427,142l0,246l-254,0l0,-246z"
        />
        <glyph
          unicode="&#x25B2;"
          horizAdvX="600"
          d="M300,478l213,-426l-426,0z"
        />
        <glyph
          unicode="&#x25B6;"
          horizAdvX="600"
          d="M541,266l-426,-213l0,426z"
        />
        <glyph
          unicode="&#x25BC;"
          horizAdvX="600"
          d="M513,478l-213,-426l-213,426z"
        />
        <glyph unicode="&#x25C0;" horizAdvX="601" d="M58,265l426,213l0,-426z" />
        <glyph
          unicode="&#x25B3;"
          horizAdvX="600"
          d="M300,478l213,-426l-426,0M425,110l-125,240l-125,-240z"
        />
        <glyph
          unicode="&#x25B7;"
          horizAdvX="600"
          d="M541,266l-426,-213l0,426M170,145l249,121l-249,121z"
        />
        <glyph
          unicode="&#x25BD;"
          horizAdvX="600"
          d="M513,478l-213,-426l-213,426M300,180l125,240l-250,0z"
        />
        <glyph
          unicode="&#x25C1;"
          horizAdvX="600"
          d="M58,265l426,213l0,-426M429,386l-249,-121l249,-121z"
        />
        <glyph
          unicode="@"
          horizAdvX="1034"
          d="M991,271C991,184 974,117 940,68C906,19 859,-6 799,-6C767,-6 740,2 718,17C696,32 681,53 672,81C632,23 572,-6 492,-6C447,-6 406,5 369,27C332,48 304,78 283,117C262,156 252,200 252,249C252,298 262,341 283,380C304,418 332,448 369,470C406,491 447,502 492,502C527,502 559,496 588,484C616,472 640,454 659,431l0,65l111,0l0,-340C770,132 775,115 785,104C794,93 807,88 822,88C849,88 870,104 885,135C899,166 906,210 906,269C906,340 890,402 858,456C825,510 780,552 722,582C664,612 597,627 522,627C446,627 379,611 320,580C261,548 216,504 184,447C152,390 136,326 136,254C136,181 152,116 184,59C215,2 260,-42 318,-73C376,-105 443,-121 518,-121C551,-121 584,-117 618,-110C651,-103 683,-92 712,-77l27,-80C710,-172 675,-184 636,-192C596,-200 557,-204 518,-204C425,-204 342,-184 271,-145C199,-106 143,-51 104,18C64,87 44,166 44,254C44,341 64,419 104,488C144,557 200,611 273,650C345,689 428,709 522,709C613,709 694,690 765,653C836,616 892,564 932,497C971,430 991,355 991,271M661,249C661,297 647,335 620,363C592,391 556,405 513,405C469,405 433,391 406,363C379,334 365,296 365,249C365,201 379,163 406,134C433,105 469,91 513,91C556,91 592,105 620,134C647,162 661,200 661,249z"
        />
        <glyph
          unicode="&amp;"
          horizAdvX="706"
          d="M619,-11l-88,85C466,18 387,-10 296,-10C247,-10 204,-2 165,14C126,30 96,52 74,81C52,109 41,141 41,177C41,220 54,259 79,292C104,325 146,357 203,389C175,417 155,443 143,468C131,492 125,517 125,543C125,592 144,632 181,663C218,693 267,708 328,708C385,708 430,694 464,667C498,639 515,601 515,554C515,518 504,486 482,458C459,430 423,402 372,373l151,-144C540,262 553,301 562,347l101,-33C650,252 629,199 600,154l88,-84M239,545C239,529 243,514 252,500C261,485 277,466 302,441C342,463 370,482 386,499C401,515 409,533 409,552C409,573 402,589 388,601C373,613 353,619 328,619C301,619 279,612 263,599C247,585 239,567 239,545M457,145l-185,177C232,300 204,279 188,259C171,238 163,215 163,190C163,161 176,137 202,119C228,101 262,92 305,92C363,92 414,110 457,145z"
        />
        <glyph
          unicode="&#xB6;"
          horizAdvX="666"
          d="M227,371C165,372 115,390 76,424C37,457 17,502 17,557C17,614 38,659 80,692C121,725 177,742 247,742l325,0l0,-842l-103,0l0,748l-139,0l0,-748l-103,0z"
        />
        <glyph
          unicode="&#xA7;"
          horizAdvX="511"
          d="M410,170C442,145 458,109 458,63C458,30 449,0 431,-25C413,-51 387,-71 354,-85C320,-100 281,-107 237,-107C197,-107 157,-101 117,-89C77,-78 46,-63 24,-46l37,93C84,30 111,16 144,5C176,-6 208,-11 240,-11C273,-11 300,-5 319,8C338,20 347,37 347,60C347,83 337,99 317,110C296,120 265,130 222,139C182,148 149,158 124,168C99,178 77,194 59,215C41,236 32,265 32,301C32,329 38,354 50,376C62,398 79,416 101,430C70,456 54,491 54,536C54,587 75,629 118,660C161,691 219,707 294,707C327,707 363,703 400,694C437,685 465,673 486,658l-37,-91C402,596 348,611 286,611C246,611 216,605 196,593C175,580 165,563 165,540C165,525 170,513 181,504C191,495 204,488 219,483C234,478 256,471 285,464C325,455 358,445 384,434C410,423 432,407 451,385C470,362 479,332 479,294C479,269 473,245 461,224C449,203 432,185 410,170M269,225C289,220 302,217 309,215C328,222 343,232 354,245C365,258 370,274 370,292C370,309 365,322 354,333C343,343 329,351 312,357C295,363 272,369 242,376C222,381 209,384 202,386C183,379 169,369 158,356C147,343 141,327 141,309C141,292 147,279 158,269C169,258 182,250 199,244C216,238 239,232 269,225z"
        />
        <glyph
          unicode="&#xA9;"
          horizAdvX="794"
          d="M44,350C44,415 60,474 91,528C122,582 164,625 218,656C272,687 332,703 398,703C464,703 524,688 578,657C631,626 673,584 704,531C735,477 750,417 750,352C750,287 734,227 703,173C672,118 629,75 575,44C521,13 461,-3 396,-3C331,-3 271,13 218,44C164,75 122,118 91,172C60,226 44,285 44,350M692,352C692,407 679,458 654,503C629,548 594,583 549,609C504,635 454,648 398,648C342,648 291,635 246,609C201,582 165,546 140,501C114,456 101,405 101,350C101,295 114,244 140,199C165,154 201,118 246,92C291,65 341,52 396,52C451,52 502,65 547,92C592,119 627,155 653,201C679,246 692,297 692,352M196,350C196,389 205,425 223,456C241,487 266,511 298,528C330,545 366,554 407,554C444,554 478,546 507,531C536,515 558,494 574,467l-73,-48C480,452 449,469 406,469C373,469 346,458 325,437C303,415 292,386 292,350C292,314 303,285 325,264C346,242 373,231 406,231C449,231 480,248 501,281l73,-46C558,207 536,185 507,170C478,154 444,146 407,146C366,146 330,155 298,172C266,189 241,214 223,245C205,276 196,311 196,350z"
        />
        <glyph
          unicode="&#xAE;"
          horizAdvX="794"
          d="M750,352C750,287 734,227 703,173C672,118 629,75 575,44C521,13 461,-3 396,-3C331,-3 271,13 218,44C164,75 122,118 91,172C60,226 44,285 44,350C44,415 60,474 91,528C122,582 164,625 218,656C272,687 332,703 398,703C464,703 524,688 578,657C631,626 673,584 704,531C735,477 750,417 750,352M692,352C692,407 679,458 654,503C629,548 594,583 549,609C504,635 454,648 398,648C342,648 291,635 246,609C201,582 165,546 140,501C114,456 101,405 101,350C101,295 114,244 140,199C165,154 201,118 246,92C291,65 341,52 396,52C451,52 502,65 547,92C592,119 627,155 653,201C679,246 692,297 692,352M500,288l88,-135l-86,0l-78,120l-11,0l-78,0l0,-120l-86,0l0,394l164,0C464,547 505,535 535,510C564,485 579,452 579,410C579,381 572,356 559,335C545,314 525,299 500,288M408,340C436,340 458,346 473,359C488,371 496,388 496,410C496,432 488,449 473,461C458,473 436,479 408,479l-74,0l0,-139z"
        />
        <glyph
          unicode="&#x2122;"
          horizAdvX="1037"
          d="M4,700l384,0l0,-73l-146,0l0,-342l-92,0l0,342l-146,0M856,285l-1,262l-140,-216l-42,0l-141,211l0,-257l-87,0l0,415l74,0l176,-266l173,266l74,0l1,-415z"
        />
        <glyph
          unicode="&#xB0;"
          horizAdvX="419"
          d="M39,540C39,571 47,599 62,625C77,650 97,671 123,686C149,701 178,708 209,708C240,708 269,701 295,686C321,671 342,650 357,625C372,599 379,571 379,540C379,509 372,481 357,456C342,431 321,411 295,396C269,381 240,373 209,373C178,373 149,381 123,396C97,411 77,431 62,456C47,481 39,509 39,540M309,540C309,569 300,594 281,613C262,632 238,642 209,642C180,642 156,632 137,613C118,594 108,569 108,540C108,511 118,487 137,468C156,449 180,439 209,439C238,439 262,449 281,468C300,487 309,511 309,540z"
        />
        <glyph unicode="|" horizAdvX="303" d="M94,742l115,0l0,-936l-115,0z" />
        <glyph
          unicode="&#xA6;"
          horizAdvX="303"
          d="M94,742l115,0l0,-350l-115,0M94,156l115,0l0,-350l-115,0z"
        />
        <glyph
          unicode="&#x2113;"
          horizAdvX="470"
          d="M96,162l-53,-36l-20,54l83,60l45,264C163,571 185,621 216,655C247,689 283,706 325,706C359,706 386,695 406,674C426,652 436,621 436,581C436,470 353,349 186,218C182,197 180,179 180,163C180,136 186,115 197,102C208,89 226,82 251,82C276,82 301,90 324,105C347,120 369,140 392,167l38,-46C373,38 305,-4 226,-4C179,-4 146,10 126,39C105,68 95,109 96,162M368,579C368,598 364,612 355,623C346,633 335,638 322,638C303,638 286,628 271,608C256,587 244,556 237,513l-35,-199C251,355 291,399 322,447C353,495 368,539 368,579z"
        />
        <glyph
          unicode="&#x2020;"
          horizAdvX="575"
          d="M224,364l-194,0l0,107l194,0l0,229l125,0l0,-229l196,0l0,-107l-196,0l0,-558l-125,0z"
        />
        <glyph
          unicode="&#x2021;"
          horizAdvX="575"
          d="M349,365l0,-224l196,0l0,-106l-196,0l0,-229l-125,0l0,229l-194,0l0,106l194,0l0,224l-194,0l0,106l194,0l0,229l125,0l0,-229l196,0l0,-106z"
        />
        <glyph
          unicode="&#x212E;"
          horizAdvX="872"
          d="M44,349C44,413 62,472 97,527C132,581 180,624 240,656C300,687 365,703 436,703C507,703 573,687 633,656C693,624 741,581 776,527C811,472 828,413 828,349l0,-8l-635,0C189,341 187,340 187,337l0,-192C187,139 190,131 196,122C223,89 257,63 300,45C343,27 388,18 437,18C486,18 533,28 577,49C621,70 658,98 689,134l57,0C710,92 665,59 610,34C555,9 497,-3 436,-3C365,-3 300,13 240,45C180,76 132,119 97,173C62,227 44,286 44,349M680,360C684,360 686,362 686,366l0,193C686,568 682,576 675,582C644,613 608,638 567,656C526,673 482,682 437,682C392,682 348,673 307,655C266,636 229,611 197,579C190,571 187,563 187,555l0,-189C187,362 189,360 193,360z"
        />
        <glyph
          unicode="&#x2116;"
          horizAdvX="1210"
          d="M798,544C798,575 806,603 822,628C837,653 859,672 887,686C915,700 946,707 981,707C1016,707 1048,700 1076,686C1103,672 1125,653 1141,628C1156,603 1164,575 1164,544C1164,513 1156,485 1141,460C1125,435 1103,416 1076,402C1048,388 1016,381 981,381C946,381 915,388 887,402C859,416 837,435 822,460C806,485 798,513 798,544M587,700l129,0l0,-700l-107,0l-386,474l0,-474l-129,0l0,700l107,0l386,-474M884,544C884,517 893,495 911,478C929,461 952,452 981,452C1010,452 1033,461 1051,478C1069,495 1078,517 1078,544C1078,572 1069,595 1052,612C1034,629 1010,637 981,637C952,637 928,629 911,612C893,595 884,572 884,544M818,308l327,0l0,-80l-327,0z"
        />
        <glyph
          unicode="^"
          horizAdvX="591"
          d="M427,142l-131,317l-132,-317l-98,0l179,416l102,0l178,-416z"
        />
        <glyph
          unicode="&#x2032;"
          horizAdvX="220"
          d="M56,700l108,0l-9,-277l-91,0z"
        />
        <glyph
          unicode="&#x2033;"
          horizAdvX="413"
          d="M56,700l108,0l-9,-277l-91,0M250,700l107,0l-9,-277l-90,0z"
        />
        <glyph
          unicode="&#x308;"
          horizAdvX="0"
          d="M-457,678C-457,696 -451,711 -440,722C-429,733 -414,739 -397,739C-380,739 -365,733 -354,722C-343,711 -337,696 -337,678C-337,661 -343,646 -354,635C-365,624 -380,618 -397,618C-414,618 -429,624 -440,635C-451,646 -457,661 -457,678M-263,678C-263,696 -257,711 -246,722C-235,733 -220,739 -203,739C-186,739 -171,733 -160,722C-149,711 -143,696 -143,678C-143,661 -149,646 -160,635C-171,624 -186,618 -203,618C-220,618 -235,624 -246,635C-257,646 -263,661 -263,678z"
        />
        <glyph
          unicode="&#x307;"
          horizAdvX="0"
          d="M-371,690C-371,711 -364,728 -351,741C-338,754 -321,761 -300,761C-279,761 -262,754 -249,741C-236,728 -229,711 -229,690C-229,669 -236,652 -249,639C-262,626 -279,619 -300,619C-321,619 -338,626 -351,639C-364,652 -371,669 -371,690z"
        />
        <glyph
          unicode="&#x300;"
          horizAdvX="0"
          d="M-498,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x301;"
          horizAdvX="0"
          d="M-249,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x30B;"
          horizAdvX="0"
          d="M-362,744l125,0l-108,-137l-98,0M-175,744l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#x302;"
          horizAdvX="0"
          d="M-212,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#x30C;"
          horizAdvX="0"
          d="M-109,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#x306;"
          horizAdvX="0"
          d="M-473,744l82,0C-390,721 -381,703 -364,690C-347,676 -325,669 -300,669C-275,669 -253,676 -236,690C-219,703 -210,721 -209,744l82,0C-129,700 -146,665 -178,639C-211,613 -251,600 -300,600C-349,600 -389,613 -421,639C-454,665 -471,700 -473,744z"
        />
        <glyph
          unicode="&#x30A;"
          horizAdvX="0"
          d="M-417,707C-417,739 -406,766 -383,789C-361,812 -334,823 -301,823C-268,823 -240,812 -217,789C-195,766 -184,739 -184,707C-184,675 -195,648 -217,626C-240,604 -268,593 -301,593C-334,593 -361,604 -383,626C-406,648 -417,675 -417,707M-237,707C-237,726 -243,741 -255,754C-268,766 -283,772 -301,772C-319,772 -334,766 -346,754C-358,741 -364,726 -364,707C-364,689 -358,674 -346,662C-334,650 -319,644 -301,644C-282,644 -267,650 -255,662C-243,674 -237,689 -237,707z"
        />
        <glyph
          unicode="&#x303;"
          horizAdvX="0"
          d="M-317,647C-328,656 -336,662 -343,667C-350,671 -357,673 -364,673C-378,673 -389,668 -398,657C-407,646 -412,632 -413,613l-67,0C-479,654 -469,686 -449,711C-430,735 -404,747 -372,747C-355,747 -341,744 -328,737C-316,730 -301,720 -283,707C-272,698 -263,692 -256,688C-249,683 -243,681 -236,681C-222,681 -210,686 -201,696C-192,706 -188,720 -187,738l67,0C-121,699 -132,667 -151,643C-170,619 -196,607 -228,607C-245,607 -259,610 -271,617C-284,624 -299,634 -317,647z"
        />
        <glyph
          unicode="&#x304;"
          horizAdvX="0"
          d="M-473,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x309;"
          horizAdvX="0"
          d="M-316,643C-297,652 -283,661 -274,672C-266,682 -262,694 -262,707C-262,720 -267,730 -276,739C-285,747 -297,751 -312,751C-333,751 -353,745 -372,732l-27,55C-386,797 -370,805 -353,810C-336,815 -318,818 -299,818C-267,818 -241,809 -220,791C-199,772 -189,748 -189,718C-189,664 -218,624 -275,597z"
        />
        <glyph
          unicode="&#x30F;"
          horizAdvX="0"
          d="M-550,744l125,0l80,-137l-98,0M-363,744l125,0l81,-137l-98,0z"
        />
        <glyph
          unicode="&#x311;"
          horizAdvX="0"
          d="M-300,751C-251,751 -211,738 -178,712C-146,686 -129,651 -127,607l-82,0C-210,630 -219,649 -236,663C-253,676 -275,683 -300,683C-325,683 -347,676 -364,663C-381,649 -390,630 -391,607l-82,0C-471,651 -454,686 -421,712C-389,738 -349,751 -300,751z"
        />
        <glyph
          unicode="&#x312;"
          horizAdvX="0"
          d="M-233,653C-233,637 -239,624 -250,615C-262,605 -279,600 -300,600C-321,600 -338,605 -349,615C-361,625 -367,638 -367,653C-367,669 -361,688 -349,710l41,92l69,0l-32,-101C-246,694 -233,678 -233,653z"
        />
        <glyph
          unicode="&#x31B;"
          horizAdvX="0"
          d="M-320,534l21,0C-280,534 -264,540 -253,552C-242,563 -237,579 -237,598C-237,621 -244,642 -257,660l77,28C-160,663 -150,631 -150,594C-150,548 -163,512 -190,485C-217,458 -253,445 -300,445l-20,0z"
        />
        <glyph
          unicode="&#x323;"
          horizAdvX="0"
          d="M-360,-144C-360,-127 -354,-112 -343,-100C-332,-89 -317,-83 -300,-83C-283,-83 -268,-89 -257,-100C-246,-112 -240,-127 -240,-144C-240,-162 -246,-176 -257,-187C-268,-198 -283,-203 -300,-203C-317,-203 -332,-198 -343,-187C-354,-176 -360,-162 -360,-144z"
        />
        <glyph
          unicode="&#x324;"
          horizAdvX="0"
          d="M-448,-141C-448,-124 -442,-110 -431,-99C-420,-88 -407,-83 -391,-83C-375,-83 -361,-89 -349,-100C-338,-111 -332,-125 -332,-141C-332,-158 -338,-171 -349,-182C-361,-193 -375,-199 -391,-199C-407,-199 -420,-193 -431,-182C-442,-171 -448,-158 -448,-141M-267,-141C-267,-124 -261,-110 -250,-99C-239,-88 -225,-83 -209,-83C-193,-83 -179,-88 -168,-99C-157,-110 -151,-124 -151,-141C-151,-158 -157,-171 -168,-182C-179,-193 -193,-199 -209,-199C-225,-199 -239,-193 -250,-182C-261,-171 -267,-158 -267,-141z"
        />
        <glyph
          unicode="&#x326;"
          horizAdvX="0"
          d="M-240,-136C-240,-155 -245,-174 -256,-195l-32,-70l-61,0l24,81C-348,-177 -360,-161 -360,-136C-360,-120 -354,-107 -343,-97C-332,-88 -318,-83 -300,-83C-282,-83 -267,-88 -256,-98C-245,-108 -240,-121 -240,-136z"
        />
        <glyph
          unicode="&#x327;"
          horizAdvX="0"
          d="M-421,-207l23,59C-377,-159 -355,-164 -330,-164C-295,-164 -277,-152 -277,-128C-277,-118 -281,-110 -290,-104C-299,-98 -312,-95 -330,-95l-31,0l27,102l68,0l-14,-56C-251,-52 -230,-61 -215,-76C-200,-91 -193,-110 -193,-133C-193,-162 -205,-185 -230,-202C-255,-219 -288,-228 -329,-228C-365,-228 -396,-221 -421,-207z"
        />
        <glyph
          unicode="&#x328;"
          horizAdvX="0"
          d="M-501,-121C-501,-90 -489,-61 -465,-33C-441,-6 -404,18 -354,37l55,-37C-341,-19 -371,-37 -389,-54C-408,-72 -417,-91 -417,-111C-417,-126 -412,-138 -401,-147C-390,-156 -376,-160 -358,-160C-335,-160 -314,-155 -297,-144l21,-61C-303,-220 -335,-228 -371,-228C-412,-228 -443,-218 -466,-198C-489,-179 -501,-153 -501,-121z"
        />
        <glyph
          unicode="&#x32E;"
          horizAdvX="0"
          d="M-473,-77l78,0C-395,-98 -386,-115 -368,-128C-350,-141 -327,-148 -300,-148C-273,-148 -250,-141 -232,-128C-214,-115 -205,-98 -205,-77l78,0C-129,-118 -146,-150 -178,-175C-211,-200 -251,-213 -300,-213C-349,-213 -389,-200 -421,-175C-454,-150 -471,-118 -473,-77z"
        />
        <glyph
          unicode="&#x331;"
          horizAdvX="0"
          d="M-473,-104l346,0l0,-74l-346,0z"
        />
        <glyph
          unicode="&#x335;"
          horizAdvX="0"
          d="M-502,478l404,0l0,-82l-404,0z"
        />
        <glyph
          unicode="&#x336;"
          horizAdvX="0"
          d="M-861,484l819,0l0,-96l-819,0z"
        />
        <glyph
          unicode="&#x337;"
          horizAdvX="0"
          d="M-408,313l294,223l51,-71l-293,-222z"
        />
        <glyph
          unicode="&#x338;"
          horizAdvX="0"
          d="M-127,602l70,0l-440,-672l-70,0z"
        />
        <glyph
          unicode="&#x2BC;"
          horizAdvX="180"
          d="M50,700l83,0l-19,-261l-67,0z"
        />
        <glyph
          unicode="&#x2BB;"
          horizAdvX="280"
          d="M217,658C217,634 209,614 194,599C179,584 160,576 137,576C113,576 93,584 78,599C63,614 55,634 55,658C55,670 57,682 60,693C63,704 68,721 77,744l53,142l80,0l-40,-153C185,727 196,718 205,705C213,692 217,676 217,658z"
        />
        <glyph
          unicode="&#x2BA;"
          horizAdvX="413"
          d="M56,700l108,0l-9,-277l-91,0M249,700l108,0l-9,-277l-91,0z"
        />
        <glyph
          unicode="&#x2C9;"
          horizAdvX="600"
          d="M127,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x2CB;"
          horizAdvX="600"
          d="M102,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x2B9;"
          horizAdvX="220"
          d="M56,700l108,0l-9,-277l-91,0z"
        />
        <glyph
          unicode="&#x2BF;"
          horizAdvX="600"
          d="M180,707C180,739 191,766 214,789C237,812 265,823 299,823l0,-53C281,770 266,764 254,752C242,740 236,725 236,707C236,690 242,675 254,664C266,652 281,646 299,646l0,-53C265,593 237,604 214,626C191,648 180,675 180,707z"
        />
        <glyph
          unicode="&#x2BE;"
          horizAdvX="600"
          d="M299,646C318,646 333,652 345,664C357,675 363,690 363,707C363,725 357,740 345,752C332,764 317,770 299,770l0,53C334,823 362,812 385,790C407,767 418,740 418,707C418,675 407,648 385,626C362,604 334,593 299,593z"
        />
        <glyph
          unicode="&#x2CA;"
          horizAdvX="600"
          d="M351,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x2CC;"
          horizAdvX="180"
          d="M47,-30l86,0l0,-194l-86,0z"
        />
        <glyph
          unicode="&#x2C8;"
          horizAdvX="178"
          d="M47,700l84,0l0,-193l-84,0z"
        />
        <glyph
          unicode="&#xB4;"
          horizAdvX="600"
          d="M351,744l147,0l-174,-137l-108,0z"
        />
        <glyph
          unicode="&#x2D8;"
          horizAdvX="600"
          d="M127,744l82,0C210,721 219,703 236,690C253,676 275,669 300,669C325,669 347,676 364,690C381,703 390,721 391,744l82,0C471,700 454,665 422,639C389,613 349,600 300,600C251,600 211,613 179,639C146,665 129,700 127,744z"
        />
        <glyph
          unicode="&#x2C7;"
          horizAdvX="600"
          d="M491,744l-134,-137l-114,0l-134,137l103,0l88,-74l88,74z"
        />
        <glyph
          unicode="&#xB8;"
          horizAdvX="600"
          d="M179,-207l23,59C223,-159 245,-164 270,-164C305,-164 323,-152 323,-128C323,-118 319,-110 310,-104C301,-98 288,-95 270,-95l-31,0l27,102l68,0l-14,-56C349,-52 370,-61 385,-76C400,-91 407,-110 407,-133C407,-162 395,-185 370,-202C345,-219 312,-228 271,-228C235,-228 204,-221 179,-207z"
        />
        <glyph
          unicode="&#x2C6;"
          horizAdvX="600"
          d="M388,607l-88,74l-88,-74l-103,0l134,137l114,0l134,-137z"
        />
        <glyph
          unicode="&#xA8;"
          horizAdvX="600"
          d="M143,678C143,696 149,711 160,722C171,733 186,739 203,739C220,739 235,733 246,722C257,711 263,696 263,678C263,661 257,646 246,635C235,624 220,618 203,618C186,618 171,624 160,635C149,646 143,661 143,678M337,678C337,696 343,711 354,722C365,733 380,739 397,739C414,739 429,733 440,722C451,711 457,696 457,678C457,661 451,646 440,635C429,624 414,618 397,618C380,618 365,624 354,635C343,646 337,661 337,678z"
        />
        <glyph
          unicode="&#x2D9;"
          horizAdvX="600"
          d="M229,690C229,711 236,728 249,741C262,754 279,761 300,761C321,761 338,754 351,741C364,728 371,711 371,690C371,669 364,652 351,639C338,626 321,619 300,619C279,619 262,626 249,639C236,652 229,669 229,690z"
        />
        <glyph
          unicode="`"
          horizAdvX="600"
          d="M102,744l147,0l135,-137l-108,0z"
        />
        <glyph
          unicode="&#x2DD;"
          horizAdvX="600"
          d="M238,744l125,0l-108,-137l-98,0M425,744l125,0l-107,-137l-98,0z"
        />
        <glyph
          unicode="&#xAF;"
          horizAdvX="600"
          d="M127,713l346,0l0,-75l-346,0z"
        />
        <glyph
          unicode="&#x2DB;"
          horizAdvX="600"
          d="M99,-121C99,-90 111,-61 135,-33C159,-6 196,18 246,37l55,-37C259,-19 229,-37 211,-54C192,-72 183,-91 183,-111C183,-126 188,-138 199,-147C210,-156 224,-160 242,-160C265,-160 286,-155 303,-144l21,-61C297,-220 265,-228 229,-228C188,-228 157,-218 134,-198C111,-179 99,-153 99,-121z"
        />
        <glyph
          unicode="&#x2DA;"
          horizAdvX="600"
          d="M183,707C183,739 194,766 217,789C239,812 266,823 299,823C332,823 360,812 383,789C405,766 416,739 416,707C416,675 405,648 383,626C360,604 332,593 299,593C266,593 239,604 217,626C194,648 183,675 183,707M363,707C363,726 357,741 345,754C332,766 317,772 299,772C281,772 266,766 254,754C242,741 236,726 236,707C236,689 242,674 254,662C266,650 281,644 299,644C318,644 333,650 345,662C357,674 363,689 363,707z"
        />
        <glyph
          unicode="&#x2DC;"
          horizAdvX="600"
          d="M283,647C272,656 264,662 257,667C250,671 243,673 236,673C222,673 211,668 202,657C193,646 188,632 187,613l-67,0C121,654 131,686 151,711C170,735 196,747 228,747C245,747 259,744 272,737C284,730 299,720 317,707C328,698 337,692 344,688C351,683 357,681 364,681C378,681 390,686 399,696C408,706 412,720 413,738l67,0C479,699 468,667 449,643C430,619 404,607 372,607C355,607 341,610 329,617C316,624 301,634 283,647z"
        />
      </font>

      <g className="st1">
        <rect
          x="-37.6"
          y="41.74"
          className="st2"
          width="125.31"
          height="68.72"
        />
        <rect
          x="-37.6"
          y="41.74"
          className="st2"
          width="125.31"
          height="68.72"
        />
      </g>
      <g>
        <path
          className="st7"
          d="M54.33,28.24c-1.22-0.65-2.18-1.55-2.87-2.71c-0.69-1.16-1.03-2.47-1.03-3.94s0.34-2.77,1.03-3.93
		c0.69-1.15,1.64-2.05,2.85-2.7c1.22-0.65,2.6-0.97,4.16-0.97c1.47,0,2.75,0.3,3.86,0.89c1.1,0.59,1.93,1.45,2.49,2.56L62.16,19
		c-0.43-0.69-0.96-1.2-1.6-1.55c-0.64-0.34-1.34-0.52-2.1-0.52c-1.3,0-2.38,0.42-3.23,1.27c-0.85,0.84-1.28,1.97-1.28,3.38
		c0,1.41,0.42,2.54,1.27,3.38c0.84,0.85,1.93,1.27,3.24,1.27c0.76,0,1.46-0.17,2.1-0.52c0.64-0.34,1.17-0.86,1.6-1.55l2.67,1.56
		c-0.58,1.11-1.42,1.97-2.52,2.57c-1.1,0.6-2.38,0.91-3.83,0.91C56.94,29.22,55.56,28.89,54.33,28.24"
        />
        <path
          className="st7"
          d="M82.23,14.15v14.87h-3.31v-1.89c-0.56,0.67-1.25,1.18-2.09,1.55c-0.84,0.36-1.74,0.54-2.7,0.54
		c-1.99,0-3.55-0.55-4.69-1.66c-1.14-1.1-1.71-2.74-1.71-4.91v-8.49h3.48v8.02c0,1.34,0.3,2.33,0.9,2.99
		c0.6,0.66,1.46,0.99,2.58,0.99c1.24,0,2.23-0.39,2.96-1.16c0.73-0.77,1.1-1.88,1.1-3.33v-7.52H82.23z"
        />
        <path
          className="st7"
          d="M87.85,28.74c-1.15-0.32-2.07-0.71-2.76-1.2l1.34-2.64c0.67,0.45,1.47,0.8,2.41,1.07
		c0.94,0.27,1.86,0.4,2.77,0.4c2.08,0,3.12-0.55,3.12-1.64c0-0.52-0.26-0.88-0.79-1.09c-0.53-0.2-1.38-0.4-2.55-0.58
		c-1.22-0.19-2.22-0.4-2.99-0.64c-0.77-0.24-1.44-0.66-2-1.27c-0.57-0.6-0.85-1.44-0.85-2.52c0-1.41,0.59-2.54,1.77-3.38
		c1.18-0.84,2.77-1.27,4.77-1.27c1.02,0,2.04,0.12,3.06,0.35c1.02,0.23,1.86,0.54,2.51,0.93l-1.34,2.64
		c-1.26-0.74-2.68-1.11-4.26-1.11c-1.02,0-1.8,0.15-2.32,0.46c-0.53,0.31-0.79,0.71-0.79,1.21c0,0.56,0.28,0.95,0.85,1.18
		c0.57,0.23,1.44,0.45,2.63,0.65c1.19,0.19,2.16,0.4,2.92,0.64c0.76,0.24,1.41,0.65,1.96,1.23c0.55,0.58,0.82,1.39,0.82,2.45
		c0,1.39-0.6,2.51-1.81,3.34c-1.21,0.84-2.85,1.25-4.93,1.25C90.18,29.22,89,29.06,87.85,28.74"
        />
        <path
          className="st7"
          d="M114.08,15.63c1.13,1.1,1.7,2.72,1.7,4.87v8.52h-3.48v-8.07c0-1.3-0.31-2.28-0.92-2.94
		c-0.61-0.66-1.49-0.99-2.62-0.99c-1.28,0-2.29,0.39-3.03,1.16c-0.74,0.77-1.11,1.88-1.11,3.33v7.52h-3.48V8.36h3.48v7.52
		c0.57-0.61,1.29-1.08,2.13-1.41c0.84-0.32,1.79-0.49,2.83-0.49C111.44,13.99,112.95,14.53,114.08,15.63"
        />
        <path
          className="st7"
          d="M133.87,14.15l-6.96,16.06c-0.65,1.61-1.44,2.75-2.37,3.41c-0.93,0.66-2.05,0.99-3.37,0.99
		c-0.74,0-1.48-0.12-2.2-0.36c-0.72-0.24-1.32-0.58-1.78-1l1.39-2.56c0.33,0.32,0.73,0.57,1.18,0.75c0.45,0.19,0.91,0.28,1.38,0.28
		c0.61,0,1.12-0.16,1.52-0.47c0.4-0.32,0.77-0.84,1.1-1.59l0.25-0.58l-6.49-14.92h3.62l4.68,11l4.7-11H133.87z"
        />
        <path
          className="st7"
          d="M148.14,14.93c1.14,0.63,2.04,1.52,2.69,2.67c0.65,1.15,0.97,2.48,0.97,3.98c0,1.5-0.33,2.84-0.97,4
		c-0.65,1.16-1.55,2.06-2.69,2.69s-2.44,0.95-3.88,0.95c-2,0-3.59-0.67-4.76-2v7.21h-3.48V14.15h3.31v1.95
		c0.58-0.71,1.29-1.23,2.13-1.59c0.84-0.35,1.78-0.53,2.8-0.53C145.7,13.99,147,14.3,148.14,14.93 M147.03,24.96
		c0.83-0.85,1.24-1.98,1.24-3.37s-0.41-2.51-1.24-3.37c-0.83-0.85-1.88-1.28-3.16-1.28c-0.83,0-1.59,0.19-2.25,0.57
		c-0.67,0.38-1.2,0.92-1.59,1.63c-0.39,0.71-0.58,1.52-0.58,2.45s0.19,1.74,0.58,2.45c0.39,0.71,0.92,1.25,1.59,1.63
		c0.67,0.38,1.42,0.57,2.25,0.57C145.15,26.24,146.2,25.81,147.03,24.96"
        />
        <path
          className="st7"
          d="M157.73,28.24c-1.21-0.65-2.15-1.55-2.83-2.71c-0.68-1.16-1.02-2.47-1.02-3.94s0.34-2.77,1.02-3.93
		c0.68-1.15,1.62-2.05,2.83-2.7c1.21-0.65,2.56-0.97,4.07-0.97c1.52,0,2.89,0.33,4.09,0.97c1.21,0.65,2.15,1.55,2.83,2.7
		c0.68,1.15,1.02,2.46,1.02,3.93s-0.34,2.78-1.02,3.94c-0.68,1.16-1.62,2.07-2.83,2.71c-1.21,0.65-2.57,0.97-4.09,0.97
		C160.29,29.22,158.94,28.89,157.73,28.24 M164.97,24.96c0.84-0.85,1.25-1.98,1.25-3.37s-0.42-2.51-1.25-3.37
		c-0.84-0.85-1.89-1.28-3.17-1.28c-1.28,0-2.33,0.43-3.16,1.28c-0.83,0.85-1.24,1.98-1.24,3.37s0.41,2.51,1.24,3.37
		c0.83,0.85,1.88,1.28,3.16,1.28C163.08,26.24,164.13,25.81,164.97,24.96"
        />
        <path
          className="st7"
          d="M174.13,28.74c-1.15-0.32-2.07-0.71-2.76-1.2l1.34-2.64c0.67,0.45,1.47,0.8,2.41,1.07
		c0.94,0.27,1.86,0.4,2.77,0.4c2.08,0,3.12-0.55,3.12-1.64c0-0.52-0.26-0.88-0.79-1.09c-0.53-0.2-1.38-0.4-2.55-0.58
		c-1.23-0.19-2.22-0.4-2.99-0.64c-0.77-0.24-1.44-0.66-2-1.27c-0.57-0.6-0.85-1.44-0.85-2.52c0-1.41,0.59-2.54,1.77-3.38
		c1.18-0.84,2.77-1.27,4.77-1.27c1.02,0,2.04,0.12,3.06,0.35c1.02,0.23,1.86,0.54,2.51,0.93l-1.34,2.64
		c-1.26-0.74-2.68-1.11-4.26-1.11c-1.02,0-1.8,0.15-2.32,0.46c-0.53,0.31-0.79,0.71-0.79,1.21c0,0.56,0.28,0.95,0.85,1.18
		c0.57,0.23,1.44,0.45,2.63,0.65c1.19,0.19,2.16,0.4,2.92,0.64c0.76,0.24,1.41,0.65,1.96,1.23c0.55,0.58,0.82,1.39,0.82,2.45
		c0,1.39-0.6,2.51-1.81,3.34c-1.21,0.84-2.85,1.25-4.93,1.25C176.46,29.22,175.28,29.06,174.13,28.74"
        />
        <path
          className="st7"
          d="M196.34,28.21c-0.41,0.33-0.9,0.58-1.49,0.75c-0.58,0.17-1.2,0.25-1.85,0.25c-1.63,0-2.9-0.43-3.79-1.28
		c-0.89-0.85-1.34-2.1-1.34-3.73v-7.15h-2.45v-2.78h2.45v-3.4h3.48v3.4h3.98v2.78h-3.98v7.07c0,0.72,0.18,1.28,0.53,1.66
		c0.35,0.38,0.86,0.57,1.53,0.57c0.78,0,1.43-0.2,1.95-0.61L196.34,28.21z"
        />
        <path
          id="Logo_1_"
          className="st0"
          d="M35.04,20.99c-0.28-0.32-0.56-0.63-0.85-0.93c-0.21,0.74-0.45,1.48-0.71,2.21
		c-0.02,0.04-0.03,0.09-0.05,0.13c-0.01,0.03-0.02,0.04,0.01,0.07c0.02,0.02,0.03,0.04,0.05,0.06c0.06,0.07,0.13,0.15,0.19,0.22
		c0.13,0.15,0.25,0.3,0.37,0.45c0.23,0.29,0.46,0.59,0.68,0.89c0.41,0.58,0.8,1.17,1.14,1.79c0.3,0.55,0.56,1.12,0.78,1.71
		c0.21,0.57,0.37,1.16,0.49,1.75c0.11,0.58,0.18,1.16,0.2,1.75c0.02,0.57-0.01,1.15-0.08,1.72c-0.07,0.56-0.19,1.12-0.35,1.66
		c-0.16,0.54-0.37,1.07-0.62,1.57c-0.25,0.51-0.55,0.99-0.88,1.45c-0.34,0.46-0.72,0.9-1.14,1.29c-0.47,0.45-0.99,0.85-1.53,1.22
		c-0.58,0.39-1.19,0.74-1.81,1.05c-0.63,0.31-1.27,0.57-1.94,0.8c-0.68,0.23-1.38,0.42-2.08,0.57c-0.69,0.15-1.38,0.25-2.07,0.33
		c-0.74,0.08-1.49,0.12-2.24,0.12c-0.73,0-1.46-0.03-2.18-0.1c-0.72-0.07-1.43-0.17-2.14-0.3c-0.7-0.13-1.39-0.3-2.07-0.51
		c-0.71-0.21-1.4-0.47-2.07-0.77c-0.67-0.3-1.32-0.64-1.95-1.01c-0.64-0.38-1.25-0.8-1.84-1.25C9.73,38.48,9.17,38,8.64,37.5
		c-0.55-0.52-1.06-1.07-1.55-1.65c-0.48-0.57-0.93-1.17-1.34-1.78c-0.42-0.62-0.81-1.27-1.16-1.93c-0.35-0.67-0.67-1.35-0.95-2.05
		c-0.28-0.69-0.51-1.4-0.72-2.11c-0.21-0.74-0.38-1.49-0.5-2.25c-0.12-0.75-0.2-1.51-0.23-2.27c-0.03-0.75-0.03-1.5,0.02-2.24
		c0.05-0.75,0.14-1.5,0.27-2.23c0.13-0.75,0.31-1.5,0.52-2.24c0.18-0.61,0.38-1.21,0.62-1.8c0.21-0.55,0.45-1.08,0.71-1.61
		c0.26-0.53,0.54-1.04,0.84-1.54c0.3-0.5,0.63-0.99,0.97-1.47C6.5,9.83,6.86,9.37,7.25,8.92c0.38-0.44,0.78-0.87,1.2-1.28
		c0.42-0.41,0.86-0.8,1.32-1.17c0.46-0.37,0.93-0.73,1.42-1.06c0.49-0.33,1-0.64,1.52-0.93c0.53-0.29,1.07-0.55,1.62-0.79
		c0.56-0.24,1.13-0.46,1.71-0.64c0.59-0.19,1.19-0.35,1.79-0.47c0.43-0.09,0.86-0.17,1.3-0.23c0.47-0.07,0.95-0.11,1.43-0.14
		c0.51-0.03,1.01-0.03,1.52,0c0.52,0.03,1.05,0.09,1.56,0.18c0.53,0.1,1.06,0.23,1.57,0.41c0.53,0.18,1.04,0.41,1.53,0.68
		c0.51,0.29,1,0.62,1.45,1c0.48,0.4,0.91,0.85,1.31,1.33c0.37,0.46,0.7,0.95,0.99,1.47c0.28,0.5,0.51,1.03,0.7,1.57
		c0.19,0.54,0.33,1.09,0.44,1.65c0.11,0.56,0.17,1.13,0.21,1.71c0.03,0.58,0.03,1.16,0,1.75c-0.03,0.58-0.09,1.17-0.17,1.75
		c-0.08,0.58-0.19,1.16-0.31,1.73c-0.12,0.57-0.27,1.13-0.43,1.69c-0.16,0.55-0.33,1.09-0.51,1.62c-0.18,0.52-0.37,1.03-0.57,1.54
		c-0.25,0.61-0.55,1.2-0.9,1.76c-0.34,0.55-0.73,1.06-1.17,1.54c-0.41,0.46-0.87,0.88-1.36,1.25c-0.46,0.35-0.96,0.65-1.48,0.89
		c-0.65,0.29-1.35,0.48-2.06,0.51c-0.67,0.03-1.34-0.09-1.97-0.33c-0.66-0.25-1.27-0.63-1.81-1.09c-0.55-0.47-1.02-1.04-1.36-1.68
		c-0.32-0.6-0.52-1.26-0.61-1.93c-0.09-0.65-0.08-1.31,0.03-1.96c0.11-0.63,0.31-1.25,0.61-1.81c0.32-0.61,0.74-1.18,1.24-1.65
		c0.5-0.46,1.08-0.83,1.72-1.08c0.57-0.22,1.17-0.35,1.78-0.39c0.57-0.03,1.14,0.01,1.7,0.1c0.62,0.11,1.23,0.28,1.81,0.5
		c0.65,0.24,1.28,0.55,1.89,0.89c0.62,0.35,1.21,0.74,1.78,1.16c0.11-0.39,0.22-0.78,0.32-1.17c0.05-0.19,0.09-0.39,0.14-0.59
		c0.02-0.1,0.04-0.2,0.07-0.31c0.01-0.05,0.02-0.09,0.03-0.14c0.01-0.02,0.02-0.05,0-0.07c-0.01-0.02-0.05-0.04-0.07-0.05
		c-0.66-0.44-1.35-0.85-2.07-1.2c-0.69-0.34-1.4-0.63-2.13-0.85c-0.74-0.23-1.51-0.38-2.29-0.45c-0.72-0.06-1.46-0.04-2.17,0.08
		c-0.76,0.13-1.51,0.36-2.2,0.71c-0.65,0.32-1.25,0.73-1.78,1.22c-0.5,0.46-0.94,0.99-1.31,1.56c-0.37,0.58-0.67,1.21-0.89,1.86
		c-0.25,0.74-0.4,1.51-0.44,2.29c-0.04,0.8,0.03,1.61,0.22,2.39c0.17,0.73,0.45,1.43,0.82,2.08c0.34,0.6,0.77,1.16,1.26,1.65
		c0.53,0.54,1.15,1,1.8,1.38c0.61,0.35,1.26,0.63,1.94,0.8c0.66,0.17,1.34,0.24,2.02,0.21c0.7-0.03,1.39-0.16,2.05-0.38
		c0.66-0.22,1.3-0.52,1.89-0.89c0.63-0.38,1.21-0.84,1.74-1.34c0.53-0.5,1.02-1.04,1.46-1.62c0.44-0.57,0.83-1.18,1.17-1.82
		c0.36-0.67,0.64-1.37,0.9-2.07c0.28-0.75,0.54-1.51,0.76-2.27c0.22-0.72,0.41-1.45,0.57-2.19c0.15-0.7,0.28-1.4,0.37-2.11
		c0.09-0.67,0.14-1.34,0.17-2.01c0.02-0.7,0.01-1.39-0.05-2.09c-0.06-0.67-0.15-1.33-0.3-1.98c-0.14-0.63-0.32-1.24-0.54-1.84
		c-0.22-0.58-0.48-1.15-0.79-1.69c-0.3-0.54-0.65-1.06-1.04-1.54c-0.42-0.53-0.88-1.03-1.38-1.48c-0.52-0.47-1.09-0.9-1.69-1.27
		c-0.61-0.37-1.26-0.69-1.93-0.95c-0.7-0.27-1.42-0.47-2.15-0.61c-0.74-0.14-1.5-0.23-2.26-0.26c-0.77-0.03-1.54,0-2.3,0.07
		C18.79,0.16,18,0.3,17.22,0.47c-0.8,0.18-1.58,0.41-2.36,0.68c-0.69,0.24-1.38,0.52-2.04,0.83c-0.67,0.31-1.32,0.66-1.95,1.04
		C10.24,3.4,9.63,3.82,9.04,4.26c-0.63,0.47-1.23,0.98-1.8,1.52C6.68,6.3,6.14,6.85,5.64,7.43c-0.5,0.57-0.97,1.17-1.41,1.8
		c-0.45,0.64-0.86,1.29-1.24,1.97c-0.38,0.68-0.73,1.38-1.04,2.09c-0.31,0.7-0.58,1.42-0.82,2.15c-0.23,0.72-0.43,1.46-0.6,2.2
		c-0.16,0.75-0.29,1.51-0.38,2.27c-0.09,0.8-0.14,1.61-0.15,2.42c-0.01,0.82,0.03,1.64,0.11,2.46c0.08,0.8,0.21,1.59,0.37,2.38
		c0.17,0.8,0.39,1.59,0.64,2.36c0.25,0.75,0.53,1.48,0.85,2.2c0.33,0.73,0.69,1.45,1.09,2.14c0.39,0.68,0.81,1.33,1.27,1.96
		c0.46,0.64,0.95,1.25,1.47,1.84c0.5,0.56,1.02,1.1,1.57,1.61c0.54,0.5,1.11,0.98,1.7,1.43c0.58,0.44,1.18,0.85,1.81,1.23
		c0.67,0.41,1.35,0.78,2.06,1.11c0.73,0.34,1.47,0.63,2.24,0.88c0.74,0.24,1.5,0.44,2.27,0.61c0.78,0.17,1.56,0.29,2.35,0.38
		c0.81,0.09,1.63,0.14,2.45,0.15c0.82,0.01,1.64-0.03,2.45-0.1c0.8-0.08,1.6-0.19,2.39-0.35c0.76-0.15,1.52-0.35,2.26-0.59
		c0.69-0.22,1.38-0.49,2.04-0.79c0.65-0.3,1.29-0.63,1.9-1.01c0.6-0.37,1.17-0.77,1.72-1.21c0.56-0.45,1.08-0.95,1.55-1.49
		c0.46-0.53,0.88-1.11,1.24-1.71c0.38-0.63,0.7-1.3,0.95-1.99c0.26-0.7,0.45-1.43,0.58-2.17c0.14-0.79,0.19-1.6,0.18-2.41
		c-0.02-0.82-0.11-1.63-0.28-2.43c-0.15-0.75-0.37-1.48-0.64-2.19c-0.27-0.71-0.6-1.4-0.98-2.05c-0.37-0.64-0.77-1.26-1.21-1.86
		C35.98,22.12,35.52,21.55,35.04,20.99z"
        />
      </g>
      <text transform="matrix(1 0 0 1 49.4355 29.0076)" className="st0 st8 st9">
        cushypost{props.name ? ` x ${props.name}` : ""}
      </text>
    </svg>
  );
};

export default Logo;
