import _ from "lodash";
import md5 from "md5";
import config from "../../api/config";

import {
  COUNTRY_LIST,
  PLACE_SEARCH,
  REAUTHENTICATE_SUCCESS,
  AUTHENTICATE_INIT,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
  CHANGE_PASSWORD_INIT,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  REGISTRATION_FAILURE,
  LOGOUT,
  CALENDAR_HOLIDAYS,
  SHIPMENT_RATE,
  SUBSCRIBE,
  USERSTORE_PUT,
  USERSTORE_GET,
  USERSTORE_DELETE,
  USERSTORE_CLEAR,
  CHANGE_PREFERENCES,
  SELECT_COUNTRY,
  CHANGE_CITY,
  CHANGE_POSTCODE,
  CHANGE_PACKAGE_MEASURE,
  SELECT_NAME,
  SELECT_CONTENT,
  SELECT_PACKAGE_TYPE,
  CHANGE_GOODS_VALUE,
  SELECT_COLLECTION_DATE,
  VALIDATED_ADDRESS,
  QUOTATION_APPROVE,
  TOGGLE_STEP,
  RESET_WIZARD,
  UPDATE_USER,
  INVOICES_GET,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const clean = (state) => {
  return state && Object.keys(state).length === 0 ? null : state;
};

export const authenticateReducer = (state = initialState.user, action) => {
  const response = action.payload;
  switch (action.type) {
    case AUTHENTICATE_SUCCESS:
      config.load(response.headers);
      return response.data.response.data;
    case CHANGE_PASSWORD_SUCCESS:
      config.load(response.headers);
      window.location.href = "/";
      return response.data.response.data;
    case REAUTHENTICATE_SUCCESS:
      return response.data.response.data;
    case LOGOUT:
      config.unload();
      return initialState.user;
    case UPDATE_USER:
      const clone = _.cloneDeep(state);
      const value = action.payload.value;

      switch (action.payload.field) {
        case "name": {
          clone[action.payload.field] = value;
          break;
        }
        case "address": {
          clone.region.address = value;
          break;
        }
        case "postalcode": {
          clone.region.postalcode = value;
          break;
        }
        case "city": {
          clone.region.city = value;
          break;
        }
        case "province": {
          clone.region.province = value;
          break;
        }
        case "vat_code": {
          clone[action.payload.field] = value;
          break;
        }
        case "pec": {
          clone.contacts.legal.pec = value;
          break;
        }
        case "sdi_code": {
          clone[action.payload.field] = value;
          break;
        }
        case "bank": {
          clone.bank = value;
          break;
        }
        case "bank_account_owner": {
          clone.bank_account_owner = value;
          break;
        }
        case "bank_account": {
          clone.bank_account = value;
          break;
        }
        case "split_payment": {
          clone.split_payment = value;
          break;
        }
        default: {
          break;
        }
      }

      return clone;

    default:
      return clean(state);
  }
};

export const passwordRecoveryReducer = (
  state = initialState.error_invalid_recovery_link,
  action
) => {
  switch (action.type) {
    case CHANGE_PASSWORD_INIT:
    case CHANGE_PASSWORD_SUCCESS:
      return false;

    case CHANGE_PASSWORD_FAILURE:
      return true;

    default:
      return state;
  }
};

export const authenticationResultReducer = (
  state = initialState.error_authentication_failure,
  action
) => {
  switch (action.type) {
    case AUTHENTICATE_INIT:
    case AUTHENTICATE_SUCCESS:
      return false;

    case AUTHENTICATE_FAILURE:
      return true;

    default:
      return state;
  }
};

export const registrationReducer = (
  state = initialState.error_invalid_credential,
  action
) => {
  switch (action.type) {
    case REGISTRATION_FAILURE:
      return true;

    default:
      return clean(state);
  }
};

export const countryListReducer = (
  state = initialState.country_list,
  action
) => {
  switch (action.type) {
    case COUNTRY_LIST:
      return action.payload;
    case LOGOUT:
      return initialState.country_list;
    default:
      return clean(state);
  }
};

export const placeSearchReducer = (state = initialState.places, action) => {
  const clone = _.cloneDeep(state);
  switch (action.type) {
    case PLACE_SEARCH:
      clone[action.party] = action.payload;
      return clone;
    case SELECT_COUNTRY:
      clone[action.payload.party] = [];
      return clone;
    case LOGOUT:
      return initialState.places;
    default:
      return state;
  }
};

export const holidaysReducer = (
  state = initialState.calendar_holidays,
  action
) => {
  switch (action.type) {
    case CALENDAR_HOLIDAYS:
      return action.payload;
    default:
      return clean(state);
  }
};

export const shipmentRateReducer = (
  state = initialState.quotations,
  action
) => {
  switch (action.type) {
    case SHIPMENT_RATE:
      const best_price = action.payload.best_price || {};
      const best_time = action.payload.best_time || {};
      best_price.id = best_price.id && best_price.id["$oid"];
      best_time.id = best_time.id && best_time.id["$oid"];
      return action.payload.list.map((q) => ({
        ...q,
        best_price: best_price.id === q.id["$oid"],
        best_time: best_time.id === q.id["$oid"],
      }));

    case CHANGE_GOODS_VALUE:
      return action.payload.insurance === "none" ? state : null;

    case CHANGE_CITY:
    case CHANGE_POSTCODE:
      const { value } = action.payload;
      return value.country === "IT" ? null : state;

    case CHANGE_PACKAGE_MEASURE:
    case SELECT_COUNTRY:
    case SELECT_PACKAGE_TYPE:
    case QUOTATION_APPROVE:
    case LOGOUT:
      return initialState.quotations;

    case SELECT_CONTENT:
      const { variant } = action.payload;
      return variant === "Expert" ? initialState.quotations : state;

    case SELECT_NAME:
    case VALIDATED_ADDRESS:
    case SELECT_COLLECTION_DATE:
      return state;

    case TOGGLE_STEP:
      return state;
    /*
    case TOGGLE_STEP:
      const { step } = action.payload;
      return step === 1 ? null : state;
    */

    case RESET_WIZARD:
      return null;

    default:
      return clean(state);
  }
};

export const subscribeReducer = (state = initialState.subscribe, action) => {
  switch (action.type) {
    case SUBSCRIBE:
      return action.payload;
    case LOGOUT:
      return initialState.subscribe;
    default:
      return clean(state);
  }
};

export const userStoreReducer = (state = initialState.user_store, action) => {
  const clone = _.cloneDeep(state);
  const { category, data } = action.payload ? action.payload : {};
  switch (action.type) {
    case USERSTORE_GET:
      clone[category] = data;
      return clone;

    case USERSTORE_PUT:
      switch (category) {
        case "address":
          data.hash = md5(data.name);
          break;
        case "package":
          data.hash = md5(data.content);
          break;
        default:
          break;
      }
      clone[category] = clone[category].filter(
        (value) => value.hash !== data.hash
      );
      clone[category].push(data);
      return clone;

    case USERSTORE_DELETE:
      clone[category] = clone[category].filter(
        (value) => value.hash !== data.hash
      );
      return clone;

    case USERSTORE_CLEAR:
      clone[category] = [];
      return clone;

    case CHANGE_PREFERENCES:
      clone.preferences = action.payload.preferences;
      return clone;

    case LOGOUT:
      return initialState.user_store;
    default:
      return clean(state);
  }
};

export const invoicesReducer = (state = initialState.invoices, action) => {
  switch (action.type) {
    case INVOICES_GET:
      return action.payload.data;
    default:
      return clean(state);
  }
};
