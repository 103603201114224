import moment from "moment";
import { PACKAGE_TYPE_PALLET } from "../../components/Forms/PackageTypeInput";
import configuration from "../../configs/config.json";

const {
  validation,
  calculator: { eu_countries },
} = configuration;

const zero = {
  equals: (input) => input === 0 || input === "0",
};

/**
 * Helper to convert between different types
 */
const convert = {
  convertibleToNumber: (text) => text || zero.equals(text),
  toNumber: (text) =>
    convert.convertibleToNumber(text)
      ? parseFloat(text.toString().replace(",", "."))
      : NaN,
};

/**
 * Comparison helper
 */
const compare = {
  sum_lte: (length_in, height_in, width_in, max) => {
    if (length_in && height_in && width_in) {
      const length = convert.toNumber(length_in);
      const height = convert.toNumber(height_in);
      const width = convert.toNumber(width_in);
      return length + height + width <= max;
    }
    return false;
  },
  between: (input, min, max) => {
    if (convert.convertibleToNumber(input)) {
      const value = convert.toNumber(input);
      return min <= value && value <= max;
    }
    return false;
  },
};

/**
 * https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s09.html
 * Words separated by single space
 */
export const nameValidator = /^(?=.{1,50}$)\S*(\s+\S+)*/i; // 50 chars

/**
 * Any string
 */
export const contactValidator = /^.{0,22}$/i; // 22 chars

/**
 * https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
 * https://en.wikipedia.org/wiki/Telephone_numbering_plan
 */
export const phoneValidator = /^(00)?[0-9]{4,15}$/; // 16 chars

/**
 *
 */
export const nationalPhoneValidator = {
  phone_number_prefixes: [
    "3",
    "004191",
    "010",
    "011",
    "0121",
    "0122",
    "0123",
    "0124",
    "0125",
    "0131",
    "0141",
    "0142",
    "0143",
    "0144",
    "015",
    "0161",
    "0163",
    "0165",
    "0166",
    "0171",
    "0172",
    "0173",
    "0174",
    "0175",
    "0182",
    "0183",
    "0184",
    "0185",
    "0187",
    "019",
    "02",
    "030",
    "031",
    "0321",
    "0322",
    "0323",
    "0324",
    "0331",
    "0332",
    "0341",
    "0342",
    "0343",
    "0344",
    "0345",
    "0346",
    "035",
    "0362",
    "0363",
    "0364",
    "0365",
    "0371",
    "0372",
    "0373",
    "0374",
    "0375",
    "0376",
    "0377",
    "0381",
    "0382",
    "0383",
    "0384",
    "0385",
    "0386",
    "039",
    "040",
    "041",
    "0421",
    "0422",
    "0423",
    "0424",
    "0425",
    "0426",
    "0427",
    "0428",
    "0429",
    "0431",
    "0432",
    "0433",
    "0434",
    "0435",
    "0436",
    "0437",
    "0438",
    "0439",
    "0442",
    "0444",
    "0445",
    "045",
    "0461",
    "0462",
    "0463",
    "0464",
    "0465",
    "0471",
    "0472",
    "0473",
    "0474",
    "0481",
    "049",
    "050",
    "051",
    "0521",
    "0522",
    "0523",
    "0524",
    "0525",
    "0532",
    "0533",
    "0534",
    "0535",
    "0536",
    "0541",
    "0542",
    "0543",
    "0544",
    "0545",
    "0546",
    "0547",
    "055",
    "0564",
    "0565",
    "0566",
    "0571",
    "0572",
    "0573",
    "0574",
    "0575",
    "0577",
    "0578",
    "0583",
    "0584",
    "0585",
    "0586",
    "0587",
    "0588",
    "059",
    "06",
    "070",
    "071",
    "0721",
    "0722",
    "0731",
    "0732",
    "0733",
    "0734",
    "0735",
    "0736",
    "0737",
    "0742",
    "0743",
    "0744",
    "0746",
    "075",
    "0761",
    "0763",
    "0765",
    "0766",
    "0771",
    "0773",
    "0774",
    "0775",
    "0776",
    "0781",
    "0782",
    "0783",
    "0784",
    "0785",
    "0789",
    "079",
    "080",
    "081",
    "0823",
    "0824",
    "0825",
    "0827",
    "0828",
    "0831",
    "0832",
    "0833",
    "0835",
    "0836",
    "085",
    "0861",
    "0862",
    "0863",
    "0864",
    "0865",
    "0871",
    "0872",
    "0873",
    "0874",
    "0875",
    "0881",
    "0882",
    "0883",
    "0884",
    "0885",
    "089",
    "090",
    "091",
    "0921",
    "0922",
    "0923",
    "0924",
    "0925",
    "0931",
    "0932",
    "0933",
    "0934",
    "0935",
    "0941",
    "0942",
    "095",
    "0961",
    "0962",
    "0963",
    "0964",
    "0965",
    "0966",
    "0967",
    "0968",
    "0971",
    "0972",
    "0973",
    "0974",
    "0975",
    "0976",
    "0981",
    "0982",
    "0983",
    "0984",
    "0985",
    "099",
  ],
  test: (input) =>
    nationalPhoneValidator.phone_number_prefixes.filter((prefix) =>
      input.startsWith(prefix)
    ).length > 0,
};

/**
 * Validate email format
 */
export const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Begins with minimun 3 non space chars
 */
export const contentValidator = /^\S{1,}(.\S.)+/;

/**
 * Range validator
 */
export const goodsValueValidator = {
  min: 0,
  max: validation.goods.value.max,
  test: (input) =>
    compare.between(input, goodsValueValidator.min, goodsValueValidator.max),
};

/**
 * Range validator
 */
export const goodsValueValidatorEasyMode = {
  min: validation.goods.value.min,
  max: validation.goods.value.max,
  test: (input, from, to) =>
    !eu_countries.includes(from.country) || !eu_countries.includes(to.country)
      ? compare.between(
          input,
          goodsValueValidatorEasyMode.min,
          goodsValueValidatorEasyMode.max
        )
      : compare.between(
          input,
          goodsValueValidator.min,
          goodsValueValidator.max
        ),
};

/**
 * Range validator
 */
export const weightValidator = {
  min: validation.weight.min,
  max: validation.weight.max,
  test: (type, input) =>
    type === PACKAGE_TYPE_PALLET ||
    compare.between(input, weightValidator.min, weightValidator.max),
};

/**
 * Range validator
 */
export const measureValidator = {
  min: validation.measure.min,
  max: validation.measure.max,
  test: (type, input_in) => {
    const input = convert.toNumber(input_in);
    return (
      input_in === "" ||
      type === PACKAGE_TYPE_PALLET ||
      compare.between(input, measureValidator.min, measureValidator.max)
    );
  },
};

/**
 * Upper bound validator
 */
export const measuresValidator = {
  max: validation.measures.sum.max,
  max_height: validation.measures.height.max,
  test: (type, length_in, height_in, width_in) => {
    const length = convert.toNumber(length_in);
    const height = convert.toNumber(height_in);
    const width = convert.toNumber(width_in);
    return (
      length_in === "" ||
      height_in === "" ||
      width_in === "" ||
      (type !== PACKAGE_TYPE_PALLET &&
        length + height + width <= measuresValidator.max) ||
      (type === PACKAGE_TYPE_PALLET && height <= measuresValidator.max_height)
    );
  },
};

/**
 * Address validator
 */
export const validityValidator = {
  test: (validity) => {
    return validity && validity.component !== "postalcode" && validity.valid;
  },
};

/**
 * From/To validator
 */
export const partyValidator = {
  test: (party) => {
    const { name, phone, email, contact, validity } = party;
    return (
      validityValidator.test(validity) &&
      nameValidator.test(name) &&
      phoneValidator.test(phone) &&
      emailValidator.test(email) &&
      contactValidator.test(contact)
    );
  },
  which: (party) => {
    const { name, phone, email, contact, validity } = party;
    const list = [];
    if (!validityValidator.test(validity)) list.push({ validity });
    if (!nameValidator.test(name)) list.push({ name });
    if (!phoneValidator.test(phone)) list.push({ phone });
    if (!emailValidator.test(email)) list.push({ email });
    if (!contactValidator.test(contact)) list.push({ contact });
    return list;
  },
};

/**
 * Address validator
 */
export const validityValidatorEasyMode = {
  test: (validity) => {
    return validity && validity.valid;
  },
};

/**
 * From/To validator
 */
export const partyValidatorEasyMode = {
  test: (party) => {
    const { name, validity } = party;
    return validityValidatorEasyMode.test(validity) && nameValidator.test(name);
  },
  which: (party) => {
    const { name, validity } = party;
    const list = [];
    if (!validityValidatorEasyMode.test(validity)) list.push({ validity });
    if (!nameValidator.test(name)) list.push({ name });
    return list;
  },
};

/**
 * Package validator
 */
export const packageValidator = {
  test: (_package) => {
    const { type, weight, height, width, length, content } = _package;
    return (
      weightValidator.test(type, weight) &&
      measureValidator.test(type, height) &&
      measureValidator.test(type, width) &&
      measureValidator.test(type, length) &&
      measuresValidator.test(type, length, height, width) &&
      contentValidator.test(content)
    );
  },
  which: (_package) => {
    const { type, weight, height, width, length, content } = _package;
    const list = [];
    if (!weightValidator.test(type, weight)) list.push({ type, weight });
    if (!measureValidator.test(type, height)) list.push({ type, height });
    if (!measureValidator.test(type, width)) list.push({ type, width });
    if (!measureValidator.test(type, length)) list.push({ type, length });
    if (!measuresValidator.test(type, length, height, width))
      list.push({ type, length, height, width });
    if (!contentValidator.test(content)) list.push({ content });
    return list;
  },
};

/**
 * Shipping validator
 */
export const shippingValidator = {
  test: (shipping) => {
    const { packages } = shipping;
    for (let i = 0; i < packages.length; i++) {
      if (!packageValidator.test(packages[i])) {
        return false;
      }
    }
    return true;
  },
  which: (shipping) => {
    let list = [];
    const { packages } = shipping;
    for (let i = 0; i < packages.length; i++) {
      list = [...list, packageValidator.which(packages[i])];
    }
    return list;
  },
};

/**
 * Shipping validator
 */
export const shippingValidatorEasyMode = {
  test: (shipping, services, from, to) => {
    const { packages } = shipping;
    for (let i = 0; i < packages.length; i++) {
      if (!packageValidator.test(packages[i])) {
        return false;
      }
    }
    return goodsValueValidatorEasyMode.test(services.insurance.value, from, to);
  },
  which: (shipping, services, from, to) => {
    let list = [];
    const { packages } = shipping;
    for (let i = 0; i < packages.length; i++) {
      list = [...list, packageValidator.which(packages[i])];
    }
    if (!goodsValueValidatorEasyMode.test(services.insurance.value, from, to)) {
      list.push({
        insurance: services.insurance,
        from_country: from.country,
        to_country: to.country,
      });
    }
    return list;
  },
};

export const collectionDateOffset = (since, to) =>
  (since.getTime() - to.getTime()) / 1000 / 60;

export const collectionDateValidator = {
  test: (date) =>
    date &&
    collectionDateOffset(moment(date).toDate(), new Date()) >
      validation.forewarning,
};

/**
 * Services validator
 */
export const servicesValidator = {
  test: (services) => {
    const { insurance, collection } = services;
    return (
      goodsValueValidator.test(insurance.value) &&
      collectionDateValidator.test(collection.date)
    );
  },
  which: (services) => {
    const { insurance, collection } = services;
    const list = [];
    if (!goodsValueValidator.test(insurance.value)) list.push({ insurance });
    if (!collectionDateValidator.test(collection.date))
      list.push({ collection });
    return list;
  },
};

/**
 * Order validator
 */
export const orderValidator = {
  test: (order) => {
    const { from, to, services, shipping } = order;
    return (
      partyValidator.test(from) &&
      partyValidator.test(to) &&
      servicesValidator.test(services) &&
      shippingValidatorEasyMode.test(shipping, services, from, to)
    );
  },
  which: (order) => {
    const { from, to, services, shipping } = order;
    let list = [];
    list = [...list, partyValidator.which(from)];
    list = [...list, partyValidator.which(to)];
    list = [...list, servicesValidator.which(services)];
    list = [
      ...list,
      shippingValidatorEasyMode.which(shipping, services, from, to),
    ];
    return list;
  },
};

/**
 * Order validator
 */
export const orderValidatorEasyMode = {
  test: (order) => {
    const { from, to, services, shipping } = order;
    return (
      partyValidatorEasyMode.test(from) &&
      partyValidatorEasyMode.test(to) &&
      servicesValidator.test(services) &&
      shippingValidator.test(shipping)
    );
  },
  which: (order) => {
    const { from, to, services, shipping } = order;
    let list = [];
    list = [...list, partyValidatorEasyMode.which(from)];
    list = [...list, partyValidatorEasyMode.which(to)];
    list = [...list, servicesValidator.which(services)];
    list = [...list, shippingValidator.which(shipping)];
    return list;
  },
};
