import { resetWizard } from "./wizard.actions";
import { setVariant } from "./variant.actions";
import { renewCollectionDate } from "./sagas";
import { initialState } from "../reducers/app.initial.state.js";

export const SET_COLLECTION_DATE = "SET_COLLECTION_DATE";
export const SET_INSURANCE = "SET_INSURANCE";
export const SET_PRODUCT = "SET_PRODUCT";

export const ADDRESS_SEARCH_STARTED = "ADDRESS_SEARCH_STARTED";
export const VALIDATED_ADDRESS = "VALIDATED_ADDRESS";
export const FORCE_ADDRESS = "FORCE_ADDRESS";

export const CHANGE_PREFERENCES = "CHANGE_PREFERENCES";

export const CHANGE_NAME = "CHANGE_NAME";
export const CHANGE_POSTCODE = "CHANGE_POSTCODE";
export const CHANGE_CITY = "CHANGE_CITY";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PHONE = "CHANGE_PHONE";
export const CHANGE_CONTACT = "CHANGE_CONTACT";
export const CHANGE_CONTENT = "CHANGE_CONTENT";
export const CHANGE_PACKAGE_MEASURE = "CHANGE_PACKAGE_MEASURE";
export const CHANGE_GOODS_VALUE = "CHANGE_GOODS_VALUE";

export const SELECT_COUNTRY = "SELECT_COUNTRY";
export const SELECT_PLACE = "SELECT_PLACE";
export const SELECT_NAME = "SELECT_NAME";
export const SELECT_CONTENT = "SELECT_CONTENT";
export const SELECT_PACKAGE_TYPE = "SELECT_PACKAGE_TYPE";
export const SELECT_PACKAGE_DESCRIPTION = "SELECT_PACKAGE_DESCRIPTION";
export const SELECT_PACKAGE_ADD = "SELECT_PACKAGE_ADD";
export const SELECT_PACKAGE_REMOVE = "SELECT_PACKAGE_REMOVE";
export const SELECT_COLLECTION_DATE = "SELECT_COLLECTION_DATE";

export const ADD_PACKAGE = "ADD_PACKAGE";
export const REMOVE_PACKAGE = "REMOVE_PACKAGE";

export const ERROR_PACKAGE_VALIDATION = "ERROR_PACKAGE_VALIDATION";
export const SHIPMENT_ORDER_SUBMIT = "SHIPMENT_ORDER_SUBMIT";
export const SHIPMENT_ORDER_SUBMIT_EASYMODE = "SHIPMENT_ORDER_SUBMIT_EASYMODE";
export const SHIPMENT_ORDER_COMPLETE_EASYMODE =
  "SHIPMENT_ORDER_SUBMIT_COMPLETE_EASYMODE";

export const PARTY_EDIT = "PARTY_EDIT";
export const SHIPPING_EDIT = "SHIPPING_EDIT";
export const SERVICES_EDIT = "SERVICES_EDIT";

export const CHOOSE_QUOTATION = "CHOOSE_QUOTATION";

export const selectCountry = (party, country, variant) => async (dispatch) => {
  dispatch({
    type: SELECT_COUNTRY,
    payload: {
      party,
      country,
      variant,
    },
  });
  dispatch(renewCollectionDate());
};

export const validateAddress =
  (party, address, variant) => async (dispatch) => {
    dispatch({
      type: VALIDATED_ADDRESS,
      payload: { party, address, variant },
    });
  };

export const forceAddress = (party, address) => async (dispatch) => {
  dispatch({
    type: FORCE_ADDRESS,
    payload: { party, address },
  });
};

export const changePostcode = (party, value, postcode) => async (dispatch) => {
  dispatch({
    type: CHANGE_POSTCODE,
    payload: { party, value, postcode, validity: { component: "postalcode" } },
  });
};

export const changeCity = (party, value, city) => async (dispatch) => {
  dispatch({
    type: CHANGE_CITY,
    payload: { party, value, city, validity: { component: "city" } },
  });
};

export const changePhone = (party, phone, country) => async (dispatch) => {
  dispatch({
    type: CHANGE_PHONE,
    payload: { party, phone, country },
  });
};

export const changeEmail = (party, email) => async (dispatch) => {
  dispatch({
    type: CHANGE_EMAIL,
    payload: { party, email },
  });
};

export const changeContact = (party, contact) => async (dispatch) => {
  dispatch({
    type: CHANGE_CONTACT,
    payload: { party, contact },
  });
};

export const selectName = (party, value, variant) => async (dispatch) => {
  dispatch({
    type: SELECT_NAME,
    payload: { party, value, variant },
  });
};

export const changeName = (party, name) => async (dispatch) => {
  dispatch({
    type: CHANGE_NAME,
    payload: { party, name },
  });
};

export const changeParty = (party, data, variant) => async (dispatch) => {
  dispatch(changeName(party, data.name));
  dispatch(changePhone(party, data.phone, data.country));
  dispatch(changeEmail(party, data.email));
  dispatch(changeContact(party, data.contact));
};

export const selectContent = (index, value, variant) => async (dispatch) => {
  dispatch({
    type: SELECT_CONTENT,
    payload: { index, value, variant },
  });
};

export const changePackageContent = (index, content) => async (dispatch) => {
  dispatch({
    type: CHANGE_CONTENT,
    payload: { index, content },
  });
};

export const selectPackageType = (index, type) => async (dispatch) => {
  dispatch({
    type: SELECT_PACKAGE_TYPE,
    payload: { index, type },
  });
};

export const changePackageMeasure =
  (party, index, value) => async (dispatch) => {
    dispatch({
      type: CHANGE_PACKAGE_MEASURE,
      payload: { party, index, value },
    });
  };

export const usePreferences = (preferences) => async (dispatch) => {
  dispatch({
    type: CHANGE_PREFERENCES,
    payload: { preferences },
  });
};

export const usePackage = (index, data) => async (dispatch) => {
  dispatch(selectPackageType(index, data.type));
  dispatch(changePackageMeasure("weight", index, data.weight));
  dispatch(changePackageMeasure("width", index, data.width));
  dispatch(changePackageMeasure("length", index, data.length));
  dispatch(changePackageMeasure("height", index, data.height));
  if (data.category === "package") {
    dispatch(changePackageContent(index, data.content));
  }
};

export const changePackage = (data, index, variant) => async (dispatch) => {
  if (variant === "Expert") {
    dispatch(selectPackageType(index, data.type));
    dispatch(changePackageMeasure("weight", index, data.weight));
    dispatch(changePackageMeasure("width", index, data.width));
    dispatch(changePackageMeasure("length", index, data.length));
    dispatch(changePackageMeasure("height", index, data.height));
  }
  dispatch(changePackageContent(index, data.content));
};

export const addPackage = () => async (dispatch) => {
  dispatch({
    type: ADD_PACKAGE,
    payload: {
      package: {
        ...initialState.order.shipping.packages[0],
        content: "content",
      },
    },
  });
};

export const removePackage = (index) => async (dispatch) => {
  dispatch({
    type: REMOVE_PACKAGE,
    payload: { index },
  });
};

export const selectGoodsValue = (value, insurance) => async (dispatch) => {
  dispatch({
    type: CHANGE_GOODS_VALUE,
    payload: { value, insurance },
  });
};

export const selectCollectionDate = (date, hours) => async (dispatch) => {
  dispatch({
    type: SELECT_COLLECTION_DATE,
    payload: { date, hours },
  });
};

export const changeServices = (data) => async (dispatch) => {
  const { collection, insurance } = data;
  dispatch(selectGoodsValue(insurance.value, insurance.algorithm));
  dispatch(selectCollectionDate(collection.date, collection.hours));
};

export const setDefaultValues = (order, reset) => async (dispatch) => {
  if (reset) {
    dispatch(resetWizard());
  }
  if (reset || !order.from.name) {
    dispatch(changeName("from", "from"));
  }
  if (reset || !order.to.name) {
    dispatch(changeName("to", "to"));
  }
  order.shipping.packages.forEach((pack, index) => {
    if (reset || !pack.content) {
      dispatch(changePackageContent(index, "content"));
    }
    dispatch(selectGoodsValue(index, "none"));
  });
};

export const shipmentOrderSubmit = () => async (dispatch) => {
  dispatch({
    type: SHIPMENT_ORDER_SUBMIT,
  });
};

export const shipmentOrderSubmitEasyMode =
  (insurance = "none") =>
  async (dispatch) => {
    dispatch({
      type: SHIPMENT_ORDER_SUBMIT_EASYMODE,
      payload: { insurance },
    });
  };

export const shipmentOrderCompleteEasyMode = () => async (dispatch) => {
  dispatch({
    type: SHIPMENT_ORDER_COMPLETE_EASYMODE,
  });
};

export const shipmentEdit = (shipment) => async (dispatch) => {
  dispatch(setVariant("Expert"));
  dispatch({
    type: PARTY_EDIT,
    payload: { party: "from", data: shipment.from },
  });
  dispatch({
    type: PARTY_EDIT,
    payload: { party: "to", data: shipment.to },
  });
  dispatch({
    type: SHIPPING_EDIT,
    payload: { shipping: shipment.shipping },
  });
  dispatch({
    type: SERVICES_EDIT,
    payload: { services: shipment.services },
  });
};

export const chooseQuotation = (quotation_id) => async (dispatch) => {
  dispatch({
    type: CHOOSE_QUOTATION,
    payload: { quotation_id },
  });
};
