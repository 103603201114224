/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.5.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import { BrowserRouter } from "react-router-dom";
import ErrorProtector from "./components/ErrorProtector";
import configuration from "../src/configs/config.json";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga";
import CheckCache from "../src/components/CheckCache";
import UserAutosense from "../src/components/UserAutosense";
// App Routes
import Routes from "./Routes";

// Vendor dependencies
import "./Vendor";
// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";

const { gateway, hj } = configuration;

class App extends Component {
  componentDidMount() {
    if (hj) {
      hotjar.initialize(hj.id, hj.sv);
      ReactGA.initialize("UA-169850184-1", { redactEmail: true });
    }
  }

  render() {
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename =
      process.env.NODE_ENV === "development" ? "/" : PUBLIC_URL || "/";

    return (
      <>
        <CheckCache />
        <UserAutosense />
        <ErrorProtector>
          <StripeProvider apiKey={gateway.stripe.key}>
            <BrowserRouter basename={basename}>
              <Elements>
                <Routes store={this.props.store} />
              </Elements>
            </BrowserRouter>
          </StripeProvider>
        </ErrorProtector>
      </>
    );
  }
}

export default App;
