import axios from "axios";
import retry, {action} from "./helpers";
import configuration from "../configs/config.json";

const {http} = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/security/v1`,
  headers: http.headers,
});

export default {
  registration: async (name, username, password) =>
    await retry(client.post, action("/start_sign_in", http.debug), {
      ...http.defaults,
      name,
      username,
      password,
    }),
};
