import axios from "axios";
import retry, { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/cart`,
  headers: http.headers,
});

export default {
  shipment_add: async (id) =>
    await retry(client.post, action("/item", http.debug), {
      ...http.defaults,
      type: "shipment",
      id,
    }),

  shipment_remove: async (id) =>
    await retry(
      client.delete,
      action("/item", http.debug),
      {
        ...http.defaults,
        id,
      },
      true
    ),

  shipment_add_all: async () =>
    await retry(client.post, action("/all", http.debug), http.defaults),

  shipment_remove_all: async () =>
    await retry(client.delete, action("/all", http.debug), http.defaults, true),

  summary_get: async () =>
    await retry(
      client.get,
      action("/summary", http.debug),
      http.defaults,
      true
    ),

  buy: async (params) =>
    await retry(client.post, action("/buy", http.debug), {
      ...http.defaults,
      ...params,
    }),

  confirm: async (params) =>
    await retry(client.post, action("/confirm", http.debug), {
      ...http.defaults,
      ...params,
    }),

  dismiss: async () =>
    await retry(client.post, action("/dismiss", http.debug), {
      ...http.defaults,
    }),
};
