import axios from "axios";
import retry, { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/geonames`,
  headers: http.headers
});

export default {
  country_list: async params =>
    await retry(client.post, action("/country_list", http.debug), {
      ...http.defaults,
      ...params
    }),
  place_search: async params =>
    await retry(client.post, action("/place_search", http.debug), {
      ...http.defaults,
      ...params
    })
};
