import { useClearCache } from 'react-clear-cache';



export default function CheckCache() {
    
    /// Controllo se la versione è aggiornata alla piu recente, altrimenti aggiorno la cache

    const { isLatestVersion, emptyCacheStorage } = useClearCache();
    if (!isLatestVersion) {
        emptyCacheStorage();
        window.location.reload()
    }
    else (console.log('versione aggiornata'))

    return null
}