import configuration from "../../configs/config.json";

const { order, tables } = configuration;
const { pagination } = tables;

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export const initialState = {
  loading: {
    authenticating: 0,
    user_store: {
      address: {
        from: { loading: false },
        to: { loading: false },
      },
      package: { loading: false },
      packaging: { loading: false },
    },
    places: {
      from: { searching: false },
      to: { searching: false },
    },
    password_recovery_sending: false,
    password_changing: false,
  },

  params: {
    limit: pagination.size,
    skip: 0,
    sort: { "services.collection.date": -1 },
  },
  sorts: {
    pickup: -1,
    pickup_for_drafts: -1,
  },

  user: null,

  error: null,
  country_list: null,
  places: {
    from: [],
    to: [],
  },
  calendar_holidays: [],

  errors: {},

  wizard: {
    activeStep: 1,
  },

  variant: "Easy",

  order: {
    quotation: null,
    rated: false,
    from: {
      name: "",
      country: "IT",
      postalcode: "",
      city: "",
      province: "",
      address: "",
      phone: "",
      email: "",
      contact: "",
    },
    to: {
      name: "",
      country: "IT",
      postalcode: "",
      city: "",
      province: "",
      address: "",
      phone: "",
      email: "",
      contact: "",
    },
    services: {
      collection: {
        date: null,
        hours: [10, 14],
      },
      insurance: {
        value: 0,
        currency: "EUR",
        algorithm: "none",
      },
      cash_on_delivery: {
        value: 0,
        currency: "EUR",
      },
    },
    shipping: {
      total_weight: 0,
      goods_desc: order.goods_desc,
      product: "All",
      special_instructions: order.special_instructions,
      packages: [
        {
          type: "Parcel",
          height: "",
          width: "",
          length: "",
          weight: "",
          content: "",
        },
      ],
    },
  },

  quotations: [],

  shipments: {},

  cart: {
    all: false,
    items: [],
    amounts: {
      net: 0,
      discount: 0,
      tax: 0,
      gross: 0,
    },
  },

  payment: null,

  error_authentication_failure: false,
  error_invalid_credential: false,
  error_invalid_recovery_link: false,

  user_store: {
    address: [],
    package: [],
    preferences: {
      hash: "",
      category: "packaging",
    },
  },

  invoices: {},
};
