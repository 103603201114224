import React, { Component } from "react";

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer-container">
        <span>
          &copy; {year} - Hive Digital Labs -{" "}
          <a
            href="https://www.iubenda.com/privacy-policy/34981116"
            className="iubenda-nostyle no-brand iubenda-embed iub-legal-only"
            title="Privacy Policy "
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <script type="text/javascript">
            {`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}
          </script>{" "}
          -{" "}
          <a
            href="https://www.iubenda.com/privacy-policy/34981116/cookie-policy"
            className="iubenda-nostyle no-brand iubenda-embed"
            title="Cookie Policy "
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
          <script type="text/javascript">
            {`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}
          </script>
        </span>
      </footer>
    );
  }
}

export default Footer;
