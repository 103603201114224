import moment from "moment";

const DateHelper = {
  /**
   *
   */
  ConvertToCollectionDateAndHours: (now, value) => {
    const hours = now.getHours();
    const collectionDate = value.toDate();
    const duration = moment.duration(value.diff(now));
    const offset = duration.asHours();
    const collectionHours = [10, 14];
    if (offset < 0) {
      if (hours < 8) {
        collectionDate.setHours(10);
      } else if (hours < 12) {
        collectionHours.shift();
        collectionDate.setHours(14);
      } else {
        return { state: { expired: true } };
      }
    } else {
      collectionDate.setHours(10);
    }
    collectionDate.setMinutes(0);
    return { collection: { collectionDate, collectionHours } };
  },
  /**
   *
   */
  ValidCollectionDateForImport: (holidays, currentDate, selectedDate) => {
    if (!holidays || holidays.length === 0) {
      return false;
    }
    const currentMoment = moment(currentDate);
    const weekday = currentMoment.day();
    const isSelected = currentDate === selectedDate;
    const isSaturdayOrSunday = [0, 6].includes(weekday); // TODO: depends on locale!!!
    const day = currentMoment.format("YYYY-MM-DD"); // calendar days are timezone based
    const isHoliday = holidays.map((holiday) => holiday.date).includes(day);
    return !isSaturdayOrSunday && !isSelected && !isHoliday;
  },
  /**
   *
   */
  ValidCollectionDate: (
    now,
    holidays,
    currentDate,
    selectedDate,
    quotation
  ) => {
    if (!holidays || holidays.length === 0) {
      return false;
    }
    const currentMoment = moment(currentDate);
    const weekday = currentMoment.day();
    const hours = !!quotation && quotation.label === "TNT" ? 12 : 0;
    const currentDateWithHours = moment(currentDate).add(hours, "hour");
    const isFuture = moment(now).isBefore(currentDateWithHours);
    const isSelected = currentDate === selectedDate;
    const isSaturdayOrSunday = [0, 6].includes(weekday); // TODO: depends on locale!!!
    const day = currentMoment.format("YYYY-MM-DD"); // calendar days are timezone based
    const isHoliday = holidays.map((holiday) => holiday.date).includes(day);
    return isFuture && !isSaturdayOrSunday && !isSelected && !isHoliday;
  },
  NextValidCollectionDate: (now, holidays, daysOffset, quotation) => {
    if (!holidays || holidays.length === 0) {
      const date = moment(now).add(3, "day");
      return date.toDate();
    }
    const selectedDate = moment(now).add(-1, "day");
    let currentDate = moment(now);
    do {
      daysOffset--;
      do {
        currentDate = currentDate.add(1, "day");
      } while (
        !DateHelper.ValidCollectionDate(
          now,
          holidays,
          currentDate.toDate(),
          selectedDate.toDate(),
          quotation
        )
      );
    } while (daysOffset > 0);
    return currentDate.toDate();
  },
  ToUnixTimestamp: (date) => Math.round(date.getTime() / 1000),
};

export default DateHelper;
