import _ from "lodash";

import {
  LOGOUT,
  SHIPMENT_SEARCH,
  CHOOSE_QUOTATION,
  SHIPMENT_CHANGE_STATUS_STARTED,
  SHIPMENT_CHANGE_STATUS_ERROR,
  SHIPMENT_CHANGE_STATUS,
  SHIPMENT_DELETE_STARTED,
  SHIPMENT_DELETE,
  SHIPMENT_DELETE_ERROR,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const shipmentsReducer = (state = initialState.shipments, action) => {
  const clone = _.cloneDeep(state);
  switch (action.type) {
    case LOGOUT:
    case CHOOSE_QUOTATION:
      return initialState.shipments;

    case SHIPMENT_SEARCH:
      clone[action.party] = action.payload;
      return clone;

    case SHIPMENT_CHANGE_STATUS_STARTED:
    case SHIPMENT_DELETE_STARTED:
      clone.temporary = _.cloneDeep(
        clone[action.party].items[action.payload.shipment_id]
      );
      delete clone[action.party].items[action.payload.shipment_id];
      clone[action.party].total--;
      return clone;

    case SHIPMENT_CHANGE_STATUS_ERROR:
    case SHIPMENT_DELETE_ERROR:
      clone[action.party].items[action.payload.shipment_id] = _.cloneDeep(
        clone.temporary
      );
      clone[action.party].total++;
      delete clone.temporary;
      return clone;

    case SHIPMENT_CHANGE_STATUS:
    case SHIPMENT_DELETE:
      delete clone.temporary;
      return clone;

    default:
      return state;
  }
};

export default shipmentsReducer;
