import axios from "axios";
import retry, { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/security/v1`,
  headers: http.headers
});

export default {
  subscribe: async params =>
    await retry(client.post, action("/sign_in", http.debug), {
      ...http.defaults,
      ...params
    })
};
