export const SET_VARIANT = "SET_VARIANT";

export const setVariant = (variant) => async (dispatch) => {
  dispatch({
    type: SET_VARIANT,
    payload: {
      variant,
    },
  });
};
