import React from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
// import Offsidebar from "./Offsidebar";
import Footer from "./Footer";
import UserHelper from "../../helpers/UserHelper";

const Base = (props) => {
  const demo = UserHelper.is_demo(props.user);
  const className = demo ? "section-container ml-0" : "section-container";

  return (
    <div className="wrapper">
      <Header />

      {!demo && <Sidebar />}

      {/*<Offsidebar />*/}

      <section className={className}>{props.children}</section>

      <Footer />
    </div>
  );
};

export default Base;
