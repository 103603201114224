import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/reducers";
import middlewares from "./middlewares/middlewares";
import { composeWithDevTools } from "redux-devtools-extension";
import * as Sentry from "@sentry/react";
import { updateTheme } from "./middlewares/themes.middleware.js";
import mySaga from "./sagas/saga";
import { persistedState, saveState } from "./persisted.store.js";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export default function configureStore() {
  const store = createStore(
    reducers,
    persistedState, // second argument overrides the initial state
    composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer)
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState(store.getState());
  });

  middlewares[5].run(mySaga);

  // Update the initial theme
  updateTheme(store.getState());

  return store;
}
