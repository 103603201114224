import axios from "axios";
import retry, { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/shipment`,
  headers: http.headers,
});

export default {
  rate: async (params) => {
    return await retry(client.post, action("/rate", http.debug), {
      ...http.defaults,
      ...params,
    });
  },
  search: async (params) => {
    return await retry(client.post, action("/search", http.debug), {
      ...http.defaults,
      ...params,
    });
  },
  status_put: async (shipment_id, status) => {
    return await retry(client.put, action("/status", http.debug), {
      ...http.defaults,
      shipment_id,
      status,
    });
  },
  label: async (shipment_id, contractor = null) => {
    return await retry(
      client.get,
      action("/label", http.debug),
      contractor
        ? { ...http.defaults, shipment_id, contractor }
        : { ...http.defaults, shipment_id },
      true
    );
  },
  draft_delete: async (shipment_id) => {
    return await retry(
      client.delete,
      action("/draft", http.debug),
      { ...http.defaults, shipment_id },
      true
    );
  },
  import: async (shipment) => {
    return await retry(client.post, action("/import", http.debug), {
      ...http.defaults,
      ...shipment,
    });
  },
  track: async ({ id, tracking_number }) => {
    return await retry(client.put, action("/track", http.debug), {
      ...http.defaults,
      id,
      tracking_number,
    });
  },
  tracking_data: async ({ id, tracking_number, reference }) => {
    return await retry(client.post, action("/tracking_data", http.debug), {
      ...http.defaults,
      id,
      tracking_number,
      reference,
    });
  },
  label_data: async ({ id, base64content }) => {
    return await retry(client.post, action("/label", http.debug), {
      ...http.defaults,
      id,
      base64content,
    });
  },
  party_data: async ({ id, party, value }) => {
    return await retry(client.post, action("/party_data", http.debug), {
      ...http.defaults,
      id,
      party,
      value,
    });
  },
  courier_resubmit: async ({ id }) => {
    return await retry(client.post, action("/courier_resubmit", http.debug), {
      ...http.defaults,
      id,
    });
  },
  pickup_resubmit: async ({ id, timestamp }) => {
    return await retry(client.post, action("/pickup_resubmit", http.debug), {
      ...http.defaults,
      id,
      timestamp,
    });
  },
};
