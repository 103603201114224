import _ from "lodash";

import { TOGGLE_STEP, RESET_WIZARD } from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const wizardReducer = (state = initialState.wizard, action) => {
  const clone = _.cloneDeep(state);
  switch (action.type) {
    case TOGGLE_STEP:
      const { step } = action.payload;
      clone.activeStep = step;
      return clone;

    case RESET_WIZARD:
      clone.activeStep = 1;
      return clone;

    default:
      return state;
  }
};

export default wizardReducer;
