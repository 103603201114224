import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './i18n';
import configureStore from './store/store';
import * as Sentry from '@sentry/browser';
import configuration from './configs/config.json';

// Check protocol and redirect to HTTPS
const { protocol } = window.location;
if (protocol === 'http:' && process.env.NODE_ENV !== 'development') {
  window.location = window.location.href.replace('http://', 'https://');
}

const { logging } = configuration;

Sentry.init({
  dsn: logging.sentry.dsn,
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App store={store} />
  </Provider>,
  document.getElementById('app')
);
