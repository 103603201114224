import React, { Component } from "react";
import { resetState } from "../store/persisted.store";
import Logger from "../api/logger";
import configuration from "../configs/config.json";

class ErrorProtector extends Component {
  state = { error: false };

  async componentDidCatch(error, info) {
    this.setState({ error, info });
    console.error(error, info);
    if (configuration.http.debug) {
      throw error;
    } else {
      try {
        const response = await Logger.error("Unhandled error", error, info);
        console.log(response);
      } catch (e) {
        console.error(e);
      }
      resetState();
      window.location.reload();
    }
  }

  render() {
    if (this.state.error) {
      return (
        <img
          className="block-center rounded"
          src="/img/cushypost-logo-color.svg"
          height="500px"
          alt="Logo"
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorProtector;
