import { put, takeLatest, call } from "redux-saga/effects";
import Shipment from "../../api/shipment";
import * as actions from "../actions/actions";

const {
  SHIPMENT_CHANGE_STATUS_AND_REFRESH_STARTED,
  SHIPMENT_SEARCH,
  SHIPMENT_SEARCH_ERROR,
  checker,
} = actions;

function* changeStatusAndRefresh({ section, id, status, params, sorts }) {
  let response = yield call(Shipment.status_put, id, status);
  let [success, message] = checker(response);
  if (success) {
    response = yield call(Shipment.search, {
      ...params,
      inspect: section === "inspection",
    });
    [success, message] = checker(response);
    if (success) {
      yield put({
        type: SHIPMENT_SEARCH,
        party: section,
        params,
        sorts,
        payload: response.data.response.data,
      });
    } else {
      yield put({
        type: SHIPMENT_SEARCH_ERROR,
      });
      return false;
    }
  } else {
    yield put(message);
  }
  return success;
}

function* mySaga() {
  yield takeLatest(
    SHIPMENT_CHANGE_STATUS_AND_REFRESH_STARTED,
    changeStatusAndRefresh
  );
}

export default mySaga;
