import {
  GET_CART_SUMMARY,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  ADD_ALL_TO_CART,
  REMOVE_ALL_FROM_CART,
  LOGOUT,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const clean = (state) => {
  return state && Object.keys(state).length === 0 ? null : state;
};

const cartReducer = (state = initialState.cart, action) => {
  switch (action.type) {
    case GET_CART_SUMMARY:
    case ADD_TO_CART:
    case REMOVE_FROM_CART:
    case ADD_ALL_TO_CART:
    case REMOVE_ALL_FROM_CART:
      return action.payload;

    case LOGOUT:
      return initialState.cart;

    default:
      return clean(state);
  }
};

export default cartReducer;
