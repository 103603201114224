import { SHIPMENT_SEARCH } from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const sortsReducer = (state = initialState.sorts, action) => {
  switch (action.type) {
    case SHIPMENT_SEARCH:
      return action.sorts;

    default:
      return state;
  }
};

export default sortsReducer;
