import axios from "axios";
import { serializeError } from "serialize-error";
import { readState } from "../store/persisted.store";
import { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/logger`,
  headers: http.headers,
});

export default {
  error: async (message, error, info) =>
    await client.post(action("/error", http.debug), {
      ...http.defaults,
      message,
      error: serializeError(error),
      info: serializeError(info),
      store: readState(),
    }),
};
