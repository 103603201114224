import axios from "axios";
import retry, { action, catcher } from "./helpers";
import config from "./config";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/security`,
  headers: http.headers,
});

export default {
  authenticate: async (email, password) =>
    await catcher(client.post, action("/login", http.debug), {
      ...http.defaults,
      username: email,
      password,
    }),

  autosense: async () =>
    await retry(client.post, action("/autosense", http.debug), http.defaults),

  refresh_token: async () =>
    await client.post(
      action("/refresh_token", http.debug),
      http.defaults,
      config.get(true)
    ),

  reset_password: async (email) =>
    await catcher(
      client.post,
      action("/reset_password", http.debug),
      { ...http.defaults, email },
      config.get(true)
    ),

  change_password: async (token, password) =>
    await catcher(
      client.post,
      action("/change_password", http.debug),
      { ...http.defaults, token, password },
      config.get(true)
    ),

  update_profile: async (
    id,
    name,
    address,
    postalcode,
    city,
    province,
    vat_code,
    pec,
    sdi_code,
    bank,
    bank_account_owner,
    bank_account,
    split_payment
  ) =>
    await retry(client.patch, action("/profile", http.debug), {
      ...http.defaults,
      id,
      name,
      address,
      postalcode,
      city,
      province,
      vat_code,
      pec,
      sdi_code,
      bank,
      bank_account_owner,
      bank_account,
      split_payment,
    }),

  sign_up: async ({
    name,
    email,
    password,
    address,
    postalcode,
    city,
    province,
    vat_code,
    pec,
    sdi_code,
    split_payment,
    terms,
  }) =>
    await retry(client.post, action("/sign_up", http.debug), {
      ...http.defaults,
      name,
      email,
      password,
      address,
      postalcode,
      city,
      province,
      vat_code,
      pec,
      sdi_code,
      split_payment,
      terms,
    }),
};
