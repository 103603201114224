import React, { Component } from "react";
import { FormGroup, Label, ButtonGroup, Button } from "reactstrap";
import { withNamespaces } from "react-i18next";

export const PACKAGE_TYPE_ENVELOPE = "Envelope";
export const PACKAGE_TYPE_PARCEL = "Parcel";
export const PACKAGE_TYPE_PALLET = "Pallet";
export const PACKAGE_TYPES = [
  PACKAGE_TYPE_ENVELOPE,
  PACKAGE_TYPE_PARCEL,
  PACKAGE_TYPE_PALLET,
];
export const DEFAULT_PACKAGE_TYPE = PACKAGE_TYPE_PARCEL;

class PackageTypeInput extends Component {
  handleClick = ({ target }) => {
    const { value } = target;
    const { onChange } = this.props;
    if (onChange) onChange(value);
  };

  render() {
    const { muted, i18n } = this.props;
    let { value, size } = this.props;
    if (!size) size = "lg";
    if (!value) value = DEFAULT_PACKAGE_TYPE;
    return (
      <FormGroup>
        <Label className={muted ? "text-muted" : ""}>
          {i18n.t("calculator.TYPE")}
        </Label>
        <div>
          <ButtonGroup className="d-flex">
            {PACKAGE_TYPES.map((type) => (
              <Button
                key={type}
                size={size}
                onClick={this.handleClick}
                value={type}
                active={value === type}
              >
                {i18n.t(`calculator.TYPE_${type.toUpperCase()}`)}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </FormGroup>
    );
  }
}

export default withNamespaces("translations")(PackageTypeInput);
