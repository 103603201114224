import axios from "axios";
import retry, { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/billing`,
  headers: http.headers,
});

export default {
  invoices_get: async () => {
    return await retry(
      client.get,
      action("/invoices", http.debug),
      {
        ...http.defaults,
        start: "2021-01-01",
      },
      true
    );
  },
  invoice_upload: async (mode, provider_id, content, date) => {
    return await retry(client.post, action("/invoice", http.debug), {
      ...http.defaults,
      mode,
      provider_id,
      content,
      date,
    });
  },
  couriers_get: async () => {
    return await retry(
      client.get,
      action("/couriers", http.debug),
      http.defaults,
      true
    );
  },
};
