import moment from "moment";
import {
  selectName,
  changeName,
  selectCollectionDate,
  validateAddress,
  changeContact,
  changePhone,
  changeEmail,
} from "./order.actions";
import {
  check,
  CALENDAR_HOLIDAYS_STARTED,
  CALENDAR_HOLIDAYS,
  CALENDAR_HOLIDAYS_ERROR,
} from "./api.actions";
import Calendar from "../../api/calendar";
import DateHelper from "../../helpers/DateHelper";

export const selectAddress = (party, address) => async (dispatch) => {
  switch (address.type) {
    case "record": {
      dispatch(selectName(party, address, "Expert"));
      break;
    }
    case "geodb":
    case "algolia":
    case "place": {
      dispatch(validateAddress(party, address, "Expert"));
      dispatch(changeContact(party, ""));
      dispatch(changePhone(party, ""));
      dispatch(changeEmail(party, ""));
      dispatch(changeName(party, party));
      break;
    }
    default: {
      break;
    }
  }
  if (party === "from") {
    dispatch(renewCollectionDate());
  }
};

export const renewCollectionDate = () => async (dispatch, getState) => {
  const { order } = getState();
  const { from } = order;
  const { country, location, validity } = from;
  if (country && validity && validity.valid) {
    const now = new Date();
    const year = now.getFullYear();
    const timestamp = DateHelper.ToUnixTimestamp(now);
    dispatch({ type: CALENDAR_HOLIDAYS_STARTED });
    const params = {
      year,
      country,
      timestamp,
    };
    if (location) {
      const { lat, lng } = location;
      params.lat = lat;
      params.lng = lng;
    }
    const promise = await Calendar.holidays(params);
    if (check(dispatch, promise)) {
      const calendar_holidays = promise.data.response.data;
      dispatch({
        type: CALENDAR_HOLIDAYS,
        payload: calendar_holidays,
      });
      const nextValidDate = DateHelper.NextValidCollectionDate(
        now,
        calendar_holidays,
        3
      );
      const { collection } = DateHelper.ConvertToCollectionDateAndHours(
        now,
        moment(nextValidDate)
      );
      if (collection) {
        dispatch(
          selectCollectionDate(
            collection.collectionDate,
            collection.collectionHours
          )
        );
      }
    } else {
      dispatch({
        type: CALENDAR_HOLIDAYS_ERROR,
      });
    }
  }
};
