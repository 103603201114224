import _ from "lodash";

import {
  AUTHENTICATE_STARTED,
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
  REAUTHENTICATE_STARTED,
  REAUTHENTICATE_SUCCESS,
  REAUTHENTICATE_FAILURE,
  RESET_PASSWORD_STARTED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CALENDAR_HOLIDAYS_STARTED,
  CALENDAR_HOLIDAYS,
  CALENDAR_HOLIDAYS_ERROR,
  COUNTRY_LIST_STARTED,
  COUNTRY_LIST,
  COUNTRY_LIST_ERROR,
  PLACE_SEARCH_STARTED,
  PLACE_SEARCH,
  PLACE_SEARCH_ERROR,
  USERSTORE_PUT_STARTED,
  USERSTORE_PUT,
  USERSTORE_PUT_ERROR,
  USERSTORE_GET_STARTED,
  USERSTORE_GET,
  USERSTORE_GET_ERROR,
  USERSTORE_DELETE_STARTED,
  USERSTORE_DELETE,
  USERSTORE_DELETE_ERROR,
  SHIPMENT_RATE_STARTED,
  SHIPMENT_RATE,
  SHIPMENT_RATE_ERROR,
  SHIPMENT_SEARCH_STARTED,
  SHIPMENT_SEARCH,
  SHIPMENT_SEARCH_ERROR,
  QUOTATION_APPROVE_STARTED,
  QUOTATION_APPROVE,
  QUOTATION_APPROVE_ERROR,
  LOGOUT,
  ADD_TO_CART_STARTED,
  ADD_TO_CART,
  ADD_TO_CART_ERROR,
  ADD_ALL_TO_CART_STARTED,
  ADD_ALL_TO_CART,
  ADD_ALL_TO_CART_ERROR,
  REMOVE_ALL_FROM_CART_STARTED,
  REMOVE_ALL_FROM_CART,
  REMOVE_ALL_FROM_CART_ERROR,
  REMOVE_FROM_CART_STARTED,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_ERROR,
  GET_CART_SUMMARY_STARTED,
  GET_CART_SUMMARY,
  GET_CART_SUMMARY_ERROR,
  BUY_CART_STARTED,
  BUY_CART,
  BUY_CART_FAILED,
  CONFIRM_PAYMENT,
  CONFIRM_PAYMENT_STARTED,
  CONFIRM_PAYMENT_ERROR,
  SHIPMENT_CHANGE_STATUS_STARTED,
  SHIPMENT_CHANGE_STATUS,
  SHIPMENT_CHANGE_STATUS_ERROR,
  SHIPMENT_DELETE_STARTED,
  SHIPMENT_DELETE,
  SHIPMENT_DELETE_ERROR,
  INVOICES_GET,
  INVOICES_GET_STARTED,
  INVOICES_GET_ERROR,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const loadingReducer = (state = initialState.loading, action) => {
  const clone = _.cloneDeep(state);
  const { party, payload } = action;
  const { category } = payload === Object(payload) ? payload : {};
  let store = category && clone.user_store[category];
  if (store && party) store = store[party];
  switch (action.type) {
    case LOGOUT:
      return initialState.loading;

    case AUTHENTICATE_STARTED:
    case REAUTHENTICATE_STARTED:
      clone.authenticating = new Date().getTime();
      return clone;
    case AUTHENTICATE_SUCCESS:
    case AUTHENTICATE_FAILURE:
    case REAUTHENTICATE_SUCCESS:
    case REAUTHENTICATE_FAILURE:
      clone.authenticating = 0;
      return clone;

    case RESET_PASSWORD_STARTED:
      clone.password_recovery_sending = true;
      return clone;
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAILURE:
      clone.password_recovery_sending = false;
      return clone;

    case CHANGE_PASSWORD_STARTED:
      clone.password_changing = true;
      return clone;
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAILURE:
      clone.password_changing = false;
      return clone;

    case COUNTRY_LIST_STARTED:
      clone.countries_listing = true;
      return clone;
    case COUNTRY_LIST:
    case COUNTRY_LIST_ERROR:
      clone.countries_listing = false;
      return clone;

    case PLACE_SEARCH_STARTED:
      clone.places[party].searching = true;
      return clone;
    case PLACE_SEARCH:
    case PLACE_SEARCH_ERROR:
      clone.places[party].searching = false;
      return clone;

    case CALENDAR_HOLIDAYS_STARTED:
      clone.calendar_holidays = true;
      return clone;
    case CALENDAR_HOLIDAYS:
    case CALENDAR_HOLIDAYS_ERROR:
      clone.calendar_holidays = false;
      return clone;

    case USERSTORE_GET_STARTED:
    case USERSTORE_PUT_STARTED:
    case USERSTORE_DELETE_STARTED:
      store.loading = true;
      return clone;

    case USERSTORE_GET:
    case USERSTORE_GET_ERROR:
    case USERSTORE_PUT:
    case USERSTORE_PUT_ERROR:
    case USERSTORE_DELETE:
    case USERSTORE_DELETE_ERROR:
      store.loading = false;
      return clone;

    case SHIPMENT_RATE_STARTED:
      clone.shipment_rating = true;
      return clone;
    case SHIPMENT_RATE:
    case SHIPMENT_RATE_ERROR:
      clone.shipment_rating = false;
      return clone;

    case SHIPMENT_SEARCH_STARTED:
      clone.shipment_searching = true;
      return clone;
    case SHIPMENT_SEARCH:
    case SHIPMENT_SEARCH_ERROR:
      clone.shipment_searching = false;
      return clone;

    case QUOTATION_APPROVE_STARTED:
      clone.quotation_approving = true;
      return clone;
    case QUOTATION_APPROVE:
    case QUOTATION_APPROVE_ERROR:
      clone.quotation_approving = false;
      return clone;

    case SHIPMENT_CHANGE_STATUS_STARTED:
      clone.shipment_changing_status = true;
      return clone;
    case SHIPMENT_CHANGE_STATUS:
    case SHIPMENT_CHANGE_STATUS_ERROR:
      clone.shipment_changing_status = false;
      return clone;

    case GET_CART_SUMMARY_STARTED:
      clone.getting_cart_summary = true;
      return clone;
    case GET_CART_SUMMARY:
    case GET_CART_SUMMARY_ERROR:
      clone.getting_cart_summary = false;
      return clone;

    case ADD_TO_CART_STARTED:
      clone.adding_to_cart = true;
      return clone;
    case ADD_TO_CART:
    case ADD_TO_CART_ERROR:
      clone.adding_to_cart = false;
      return clone;

    case REMOVE_FROM_CART_STARTED:
      clone.removing_from_cart = true;
      return clone;
    case REMOVE_FROM_CART:
    case REMOVE_FROM_CART_ERROR:
      clone.removing_from_cart = false;
      return clone;

    case ADD_ALL_TO_CART_STARTED:
      clone.adding_all_to_cart = true;
      return clone;
    case ADD_ALL_TO_CART:
    case ADD_ALL_TO_CART_ERROR:
      clone.adding_all_to_cart = false;
      return clone;

    case REMOVE_ALL_FROM_CART_STARTED:
      clone.removing_all_from_cart = true;
      return clone;
    case REMOVE_ALL_FROM_CART:
    case REMOVE_ALL_FROM_CART_ERROR:
      clone.removing_all_from_cart = false;
      return clone;

    case BUY_CART_STARTED:
      clone.buying_cart = true;
      return clone;
    case BUY_CART:
    case BUY_CART_FAILED:
      clone.buying_cart = false;
      return clone;

    case CONFIRM_PAYMENT_STARTED:
      clone.confirming_payment = true;
      return clone;
    case CONFIRM_PAYMENT:
    case CONFIRM_PAYMENT_ERROR:
      clone.confirming_payment = false;
      return clone;

    case SHIPMENT_DELETE_STARTED:
      clone.deleting_shipment = action.payload;
      return clone;
    case SHIPMENT_DELETE:
    case SHIPMENT_DELETE_ERROR:
      clone.deleting_shipment = false;
      return clone;

    case INVOICES_GET_STARTED:
      clone.getting_invoices = true;
      return clone;
    case INVOICES_GET:
    case INVOICES_GET_ERROR:
      clone.getting_invoices = false;
      return clone;

    default:
      return state;
  }
};

export default loadingReducer;
