import _ from "lodash";
import md5 from "md5";

import {
  SELECT_COUNTRY,
  VALIDATED_ADDRESS,
  FORCE_ADDRESS,
  CHANGE_CITY,
  CHANGE_PHONE,
  CHANGE_EMAIL,
  CHANGE_CONTACT,
  CHANGE_POSTCODE,
  SELECT_NAME,
  CHANGE_NAME,
  CHANGE_CONTENT,
  SELECT_CONTENT,
  CHANGE_PACKAGE_MEASURE,
  SELECT_PACKAGE_TYPE,
  CHANGE_GOODS_VALUE,
  SHIPMENT_ORDER_SUBMIT_EASYMODE,
  SELECT_COLLECTION_DATE,
  ADD_PACKAGE,
  REMOVE_PACKAGE,
  LOGOUT,
  QUOTATION_APPROVE,
  CHOOSE_QUOTATION,
  PARTY_EDIT,
  SHIPPING_EDIT,
  SERVICES_EDIT,
  TOGGLE_STEP,
  RESET_WIZARD,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const locationReducer = (state, action, party) => {
  if (action.type === LOGOUT) {
    return initialState.order[party];
  }
  if (action.type === TOGGLE_STEP) {
    // const { step } = action.payload;
    // return step === 1 ? initialState.order[party] : state;
    return state;
  }
  if (action.type === RESET_WIZARD) {
    return initialState.order[party];
  }
  if (action.payload && party === action.payload.party) {
    let clone = _.cloneDeep(state);
    const {
      name,
      country,
      city,
      postcode,
      address,
      phone,
      email,
      contact,
      value,
      data,
      validity,
      variant,
    } = action.payload;
    switch (action.type) {
      case CHANGE_NAME:
        clone.name = name;
        clone.hash = md5(name);
        return clone;

      case CHANGE_POSTCODE:
        if (postcode && validity.component === "postalcode") {
          clone.validity = { valid: true };
        }
        clone.postalcode = postcode;
        return clone;

      case CHANGE_CITY:
        clone.city = city;
        return clone;

      case CHANGE_PHONE:
        clone.phone = phone;
        return clone;
      case CHANGE_EMAIL:
        clone.email = email;
        return clone;
      case CHANGE_CONTACT:
        clone.contact = contact;
        return clone;

      case SELECT_NAME:
        delete value.id;
        delete value._id;
        delete value.user;
        return value;

      case SELECT_COUNTRY:
        clone.country = country;
        clone.postalcode = "";
        clone.city = "";
        clone.province = "";
        clone.address = "";
        clone.location = null;
        clone.validity = { valid: variant === "Easy" && country !== "IT" };
        return clone;

      case FORCE_ADDRESS:
        return address;

      case VALIDATED_ADDRESS:
        console.log(JSON.stringify(address));
        if (address.hash) {
          clone.hash = address.hash;
        } else {
          delete clone.hash;
        }
        if (address.locality) {
          clone.locality = address.locality.long_name;
          clone.city = address.locality.long_name;
        } else {
          delete clone.locality;
          delete clone.city;
        }
        if (address.administrative_area_level_1) {
          clone.administrative_area_level_1 =
            address.administrative_area_level_1.short_name;
          if (["US", "CA"].includes(address.country.short_name)) {
            clone.state = address.administrative_area_level_1.long_name;
          }
        } else {
          delete clone.administrative_area_level_1;
        }
        if (address.administrative_area_level_2) {
          clone.administrative_area_level_2 =
            address.administrative_area_level_2.short_name;
          clone.province = address.administrative_area_level_2.short_name;
        } else {
          delete clone.administrative_area_level_2;
          delete clone.province;
        }
        if (address.administrative_area_level_3) {
          clone.administrative_area_level_3 =
            address.administrative_area_level_3.short_name;
          clone.city = address.locality
            ? address.locality.long_name
            : address.administrative_area_level_3
            ? address.administrative_area_level_3.long_name
            : "";
        } else {
          delete clone.administrative_area_level_3;
          if (!address.locality) {
            delete clone.city;
          }
        }
        if (variant === "Expert" || address.country !== "IT") {
          clone.postalcode = address.postal_code
            ? address.postal_code.long_name
            : "";
        }
        clone.address = address.street_address;
        clone.location = address.location;
        clone.validity = address.validity;
        clone.type = address.type || "unknown";
        return clone;

      case PARTY_EDIT:
        const { region } = data;
        const { coordinates } = data.location;
        clone = {
          ..._.cloneDeep(data),
          ..._.cloneDeep(region),
        };
        delete clone.region;
        delete clone.created;
        delete clone.updated;
        if (coordinates && coordinates.length > 1) {
          clone.location = { lat: coordinates[1], lng: coordinates[0] };
        }
        return clone;

      default:
        return state;
    }
  }
  return state;
};

export const senderReducer = (state = initialState.order.from, action) => {
  return locationReducer(state, action, "from");
};

export const receiverReducer = (state = initialState.order.to, action) => {
  return locationReducer(state, action, "to");
};

export const shippingReducer = (
  state = initialState.order.shipping,
  action
) => {
  let clone = _.cloneDeep(state);
  const { payload } = action;
  const { index, variant } = payload ? payload : {};
  switch (action.type) {
    case CHANGE_CONTENT:
      if (clone.packages[index]) {
        clone.packages[index].content = payload.content;
        clone.packages[index].hash = md5(payload.content);
        clone.goods_desc = clone.packages.map((p) => p.content).join(", ");
      }
      return clone;

    case SELECT_CONTENT:
      const pack = payload.value;
      if (variant === "Expert") {
        delete pack.id;
        delete pack._id;
        delete pack.user;
        clone.packages[index] = pack;
        clone.total_weight = clone.packages.reduce(
          (sum, pack) => parseFloat(sum) + parseFloat(pack.weight),
          0
        );
      } else {
        clone.packages[index].content = pack.content;
      }
      clone.goods_desc = clone.packages.map((p) => p.content).join(", ");
      return clone;

    case SELECT_PACKAGE_TYPE:
      clone.packages[index].type = payload.type;
      return clone;

    case CHANGE_PACKAGE_MEASURE:
      const { party, value } = payload;
      clone.packages[index][party] = value;
      if (party === "weight") {
        clone.total_weight = clone.packages.reduce(
          (sum, pack) => parseFloat(sum) + parseFloat(pack.weight),
          0
        );
      }
      clone.packages[index][party] = value;
      return clone;

    case SHIPPING_EDIT:
      return _.cloneDeep(payload.shipping);

    case ADD_PACKAGE:
      clone.packages.push(payload.package);
      clone.total_weight = clone.packages.reduce(
        (sum, pack) => parseFloat(sum) + parseFloat(pack.weight),
        0
      );
      return clone;

    case REMOVE_PACKAGE:
      clone.packages.splice(payload.index, 1);
      clone.total_weight = clone.packages.reduce(
        (sum, pack) => parseFloat(sum) + parseFloat(pack.weight),
        0
      );
      return clone;

    /*
    case TOGGLE_STEP:
      const { step } = action.payload;
      return step === 1 ? initialState.order.shipping : state;
    */

    case RESET_WIZARD:
      return initialState.order.shipping;

    default:
      return state;
  }
};

export const servicesReducer = (
  state = initialState.order.services,
  action
) => {
  let clone = _.cloneDeep(state);
  const { payload } = action;
  switch (action.type) {
    case CHANGE_GOODS_VALUE:
      clone.insurance.value = payload.value;
      clone.insurance.algorithm = payload.insurance;
      return clone;

    case SHIPMENT_ORDER_SUBMIT_EASYMODE:
      clone.insurance.algorithm = payload.insurance;
      return clone;

    case SELECT_COLLECTION_DATE:
      if (payload.date && Array.isArray(payload.date.length)) {
        payload.date = null;
      }
      clone.collection = payload;
      return clone;

    case SERVICES_EDIT:
      clone = _.cloneDeep(payload.services);
      delete clone.collection.date;
      delete clone.collection.timestamp;
      return clone;

    /*
    case TOGGLE_STEP:
      const { step } = action.payload;
      return step === 1 ? initialState.order.services : state;
    */

    case RESET_WIZARD:
      return initialState.order.services;

    default:
      return state;
  }
};

export const quotationReducer = (
  state = initialState.order.quotation,
  action
) => {
  switch (action.type) {
    /*
    case TOGGLE_STEP:
      const { step } = action.payload;
      return step === 1 ? null : state;
    */
    case RESET_WIZARD:
      return null;

    case CHOOSE_QUOTATION:
      const { quotation_id } = action.payload;
      return quotation_id;

    case CHANGE_GOODS_VALUE:
      return action.payload.insurance === "none" ? state : null;

    case CHANGE_CITY:
    case CHANGE_POSTCODE:
      const { value } = action.payload;
      return value && value.country === "IT" ? null : state;

    case CHANGE_PACKAGE_MEASURE:
    case SELECT_COUNTRY:
    case SELECT_PACKAGE_TYPE:
    case QUOTATION_APPROVE:
      return null;

    case VALIDATED_ADDRESS:
    case SELECT_NAME:
      return state;

    case SELECT_CONTENT:
      const { variant } = action.payload;
      return variant === "Expert" ? null : state;

    default:
      return state;
  }
};
