import { BUY_CART, CONFIRM_PAYMENT, LOGOUT } from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const paymentReducer = (state = initialState.payment, action) => {
  switch (action.type) {
    case CONFIRM_PAYMENT:
    case LOGOUT:
      return initialState.payment;

    case BUY_CART:
      return {
        session_id: action.payload.id
      };

    default:
      return state;
  }
};

export default paymentReducer;
