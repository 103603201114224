import axios from "axios";
import retry, { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/user`,
  headers: http.headers,
});

export default {
  store_put: async (category, value) =>
    await retry(client.put, action("/store", http.debug), {
      ...http.defaults,
      category,
      value,
    }),

  store_get: async (
    category,
    search,
    filter = {},
    most_frequent = false,
    contractor = null
  ) =>
    await retry(
      client.get,
      action("/store", http.debug),
      contractor
        ? {
            ...http.defaults,
            category,
            search,
            sort: JSON.stringify({
              [category === "package" ? "content" : "name"]: 1,
            }),
            most_frequent,
            filter: JSON.stringify(filter),
            contractor,
          }
        : {
            ...http.defaults,
            category,
            search,
            sort: JSON.stringify({
              [category === "package" ? "content" : "name"]: 1,
            }),
            most_frequent,
            filter: JSON.stringify(filter),
          },
      true
    ),

  store_delete: async (category, value) =>
    await retry(
      client.delete,
      action("/store", http.debug),
      {
        ...http.defaults,
        category,
        value,
      },
      true
    ),

  store_reset: async (category) =>
    await retry(
      client.delete,
      action("/store", http.debug),
      {
        ...http.defaults,
        category,
      },
      true
    ),
};
