import axios from "axios";
import retry, { action } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: `${http.baseUrl}/quotation`,
  headers: http.headers,
});

export default {
  approve: async (quotation_id, status, order) => {
    return await retry(client.post, action("/approve", http.debug), {
      ...http.defaults,
      quotation_id,
      as: status,
      order,
    });
  },
};
