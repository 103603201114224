import thunk from "redux-thunk";
import settings from "./settings.middleware.js";
import themes from "./themes.middleware.js";
import logger from "./logger.middleware.js";
import validationMiddleware from "./validation.middleware.js";
import createSagaMiddleware from "redux-saga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export default [
  thunk,
  settings,
  themes,
  logger,
  validationMiddleware,
  sagaMiddleware,
];
