import { SET_VARIANT } from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const variantReducer = (state = initialState.variant, action) => {
  switch (action.type) {
    case SET_VARIANT:
      return action.payload.variant;

    default:
      return state;
  }
};

export default variantReducer;
