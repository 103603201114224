import _ from "lodash";
import configuration from "../../configs/config.json";

import {
  validationError,
  validationSuccess,
  userStorePut,
  shipmentRate,
  changeParty,
  changePackage,
  changeServices,
  selectGoodsValue,
  toggleStep,
  changePostcode,
  logError,
  CHANGE_EMAIL,
  CHANGE_NAME,
  SELECT_NAME,
  CHANGE_PHONE,
  CHANGE_CONTACT,
  CHANGE_CONTENT,
  SELECT_CONTENT,
  CHANGE_GOODS_VALUE,
  CHANGE_PACKAGE_MEASURE,
  SHIPMENT_ORDER_SUBMIT,
  SHIPMENT_ORDER_SUBMIT_EASYMODE,
  SHIPMENT_ORDER_COMPLETE_EASYMODE,
  SELECT_COLLECTION_DATE,
} from "../actions/actions";

import {
  contactValidator,
  contentValidator,
  emailValidator,
  goodsValueValidator,
  goodsValueValidatorEasyMode,
  measureValidator,
  measuresValidator,
  nameValidator,
  phoneValidator,
  weightValidator,
  orderValidator,
  partyValidator,
  shippingValidator,
  shippingValidatorEasyMode,
  servicesValidator,
  nationalPhoneValidator,
  collectionDateValidator,
  orderValidatorEasyMode,
  partyValidatorEasyMode,
} from "./validators";

const {
  calculator: { eu_countries },
} = configuration;

export const INVALID_NAME = "INVALID_NAME";
export const INVALID_EMAIL = "INVALID_EMAIL";
export const INVALID_PHONE = "INVALID_PHONE";
export const INVALID_CONTACT = "INVALID_CONTACT";
export const INVALID_CONTENT = "INVALID_CONTENT";
export const VALUE_OUT_OF_RANGE = "VALUE_OUT_OF_RANGE";
export const MEASURES_OUT_OF_RANGE = "MEASURES_OUT_OF_RANGE";
export const DATEPICKER_CHOOSE = "DATEPICKER_CHOOSE";

const validationMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  const state = getState();
  const { party, index, value, variant, insurance } = action.payload || {};
  switch (action.type) {
    case CHANGE_NAME:
      const { name } = action.payload;
      if (nameValidator.test(name)) {
        dispatch(validationSuccess("order", party, "name"));
      } else {
        dispatch(
          validationError("order", party, "name", { value: INVALID_NAME })
        );
      }
      next(action);
      break;

    case CHANGE_PHONE:
      const { phone, country } = action.payload;
      const national = ["IT", "VA", "SM"].includes(country);
      if (
        phoneValidator.test(phone) &&
        (national ? nationalPhoneValidator.test(phone) : true)
      ) {
        dispatch(validationSuccess("order", party, "phone"));
      } else {
        dispatch(
          validationError("order", party, "phone", { value: INVALID_PHONE })
        );
      }
      next(action);
      break;

    case CHANGE_EMAIL:
      const { email } = action.payload;
      if (emailValidator.test(email)) {
        dispatch(validationSuccess("order", party, "email"));
      } else {
        dispatch(
          validationError("order", party, "email", { value: INVALID_EMAIL })
        );
      }
      next(action);
      break;

    case CHANGE_CONTACT:
      const { contact } = action.payload;
      if (contactValidator.test(contact)) {
        dispatch(validationSuccess("order", party, "contact"));
      } else {
        dispatch(
          validationError("order", party, "contact", { value: INVALID_CONTACT })
        );
      }
      next(action);
      break;

    case CHANGE_CONTENT:
      const { content } = action.payload;
      if (contentValidator.test(content)) {
        dispatch(validationSuccess("order", "packages", "content", index));
      } else {
        dispatch(
          validationError("order", "packages", "content", {
            index,
            value: INVALID_CONTENT,
            min: 4,
          })
        );
      }
      next(action);
      break;

    case CHANGE_GOODS_VALUE:
      if (
        !eu_countries.includes(state.order.from.country) ||
        !eu_countries.includes(state.order.to.country)
      ) {
        if (
          goodsValueValidatorEasyMode.test(
            value,
            state.order.from,
            state.order.to
          )
        ) {
          dispatch(validationSuccess("order", "services", "insurance"));
        } else {
          dispatch(
            validationError("order", "services", "insurance", {
              index,
              value: VALUE_OUT_OF_RANGE,
              min: goodsValueValidatorEasyMode.max,
              max: goodsValueValidatorEasyMode.min,
            })
          );
        }
      } else {
        if (goodsValueValidator.test(value)) {
          dispatch(validationSuccess("order", "services", "insurance"));
        } else {
          dispatch(
            validationError("order", "services", "insurance", {
              index,
              value: VALUE_OUT_OF_RANGE,
              min: goodsValueValidator.max,
              max: goodsValueValidator.min,
            })
          );
        }
      }
      next(action);
      break;

    case CHANGE_PACKAGE_MEASURE:
      const p = { ...state.order.shipping.packages[index] };
      switch (party) {
        case "weight":
          if (weightValidator.test(p.type, value)) {
            dispatch(validationSuccess("order", "packages", party, index));
          } else {
            dispatch(
              validationError("order", "packages", party, {
                value: VALUE_OUT_OF_RANGE,
                index,
                min: weightValidator.min,
                max: weightValidator.max,
              })
            );
          }
          break;
        default:
          if (measureValidator.test(p.type, value)) {
            p[party] = value;
            if (measuresValidator.test(p.type, p.length, p.height, p.width)) {
              dispatch(validationSuccess("order", "packages", party, index));
              if (party !== "width") {
                dispatch(
                  validationSuccess("order", "packages", "width", index)
                );
              }
            } else {
              dispatch(
                validationError("order", "packages", "width", {
                  value: MEASURES_OUT_OF_RANGE,
                  index,
                  max: measuresValidator.max,
                })
              );
            }
          } else {
            dispatch(
              validationError("order", "packages", party, {
                value: VALUE_OUT_OF_RANGE,
                index,
                min: measureValidator.min,
                max: measureValidator.max,
              })
            );
          }
          break;
      }
      next(action);
      break;

    case SELECT_COLLECTION_DATE:
      if (collectionDateValidator.test(action.payload.date)) {
        dispatch(validationSuccess("order", "services", "collection"));
      } else {
        dispatch(
          validationError("order", "services", "collection", {
            value: DATEPICKER_CHOOSE,
          })
        );
      }
      next(action);
      break;

    case SELECT_NAME:
      dispatch(changeParty(party, value, variant));
      next(action);
      break;

    case SELECT_CONTENT:
      dispatch(changePackage(value, index, variant));
      next(action);
      break;

    case SHIPMENT_ORDER_SUBMIT:
      if (orderValidator.test(state.order)) {
        dispatch(validationSuccess("order"));
        dispatch(userStorePut("from", "address", state.order.from));
        dispatch(userStorePut("to", "address", state.order.to));
        state.order.shipping.packages.forEach((pack) => {
          dispatch(userStorePut(null, "package", state.order.shipping.pack));
        });
        const clone = _.cloneDeep(state.order);
        clone.shipping.product = "All";
        clone.services.insurance.algorithm = "all";
        dispatch(shipmentRate(clone));
        dispatch(toggleStep(2));
      } else {
        if (partyValidator.test(state.order.from)) {
          dispatch(userStorePut("from", "address", state.order.from));
        } else {
          dispatch(changeParty("from", state.order.from, "Expert"));
          dispatch(
            logError("validation", {
              errors: partyValidator.which(state.order.from),
            })
          );
        }
        if (partyValidator.test(state.order.to)) {
          dispatch(userStorePut("to", "address", state.order.to));
        } else {
          dispatch(changeParty("to", state.order.to, "Expert"));
          dispatch(
            logError("validation", {
              errors: partyValidator.which(state.order.to),
            })
          );
        }
        if (shippingValidator.test(state.order.shipping)) {
          state.order.shipping.packages.forEach((pack) => {
            dispatch(userStorePut(null, "package", pack));
          });
        } else {
          state.order.shipping.packages.forEach((pack, index) => {
            dispatch(changePackage(pack, index, "Expert"));
          });
          dispatch(
            logError("validation", {
              errors: shippingValidator.which(state.order.shipping),
            })
          );
        }
        if (!servicesValidator.test(state.order.services)) {
          dispatch(changeServices(state.order.services));
          dispatch(
            logError("validation", {
              errors: servicesValidator.which(state.order.services),
            })
          );
        }
      }
      next(action);
      break;

    case SHIPMENT_ORDER_SUBMIT_EASYMODE:
      if (orderValidatorEasyMode.test(state.order)) {
        dispatch(validationSuccess("order"));
        const clone = _.cloneDeep(state.order);
        clone.shipping.product = "All";
        clone.services.insurance.algorithm = insurance;
        dispatch(shipmentRate(clone));
        dispatch(toggleStep(2));
      } else {
        if (!partyValidatorEasyMode.test(state.order.from)) {
          dispatch(changePostcode("from", state.order.from.postalcode));
          dispatch(
            logError("validation", {
              errors: partyValidatorEasyMode.which(state.order.from),
            })
          );
        }
        if (!partyValidatorEasyMode.test(state.order.to)) {
          dispatch(changePostcode("to", state.order.to.postalcode));
          dispatch(
            logError("validation", {
              errors: partyValidatorEasyMode.which(state.order.to),
            })
          );
        }
        if (
          !shippingValidatorEasyMode.test(
            state.order.shipping,
            state.order.services,
            state.order.from,
            state.order.to
          )
        ) {
          state.order.shipping.packages.forEach((pack, index) => {
            dispatch(changePackage(pack, index, "Easy"));
          });
          dispatch(
            logError("validation", {
              errors: shippingValidatorEasyMode.which(
                state.order.shipping,
                state.order.services,
                state.order.from,
                state.order.to
              ),
            })
          );
        }
        if (!servicesValidator.test(state.order.services)) {
          dispatch(changeServices(state.order.services));
          dispatch(
            logError("validation", {
              errors: servicesValidator.which(state.order.services),
            })
          );
        }
      }
      next(action);
      break;

    case SHIPMENT_ORDER_COMPLETE_EASYMODE:
      if (state.order.to.phone === "") {
        state.order.to.phone = state.order.from.phone;
      }
      if (state.order.to.email === "") {
        state.order.to.email = state.order.from.email;
      }
      if (orderValidator.test(state.order)) {
        dispatch(validationSuccess("order"));
        dispatch(userStorePut("from", "address", state.order.from));
        dispatch(userStorePut("to", "address", state.order.to));
        state.order.shipping.packages.forEach((pack) => {
          dispatch(userStorePut(null, "package", pack));
        });
        dispatch(toggleStep(4));
      } else {
        if (partyValidator.test(state.order.from)) {
          dispatch(userStorePut("from", "address", state.order.from));
        } else {
          dispatch(changeParty("from", state.order.from, "Easy"));
          dispatch(
            logError("validation", {
              errors: partyValidator.which(state.order.from),
            })
          );
        }
        if (partyValidator.test(state.order.to)) {
          dispatch(userStorePut("to", "address", state.order.to));
        } else {
          dispatch(changeParty("to", state.order.to, "Easy"));
          dispatch(
            logError("validation", {
              errors: partyValidator.which(state.order.to),
            })
          );
        }
        if (
          shippingValidatorEasyMode.test(
            state.order.shipping,
            state.order.services,
            state.order.from,
            state.order.to
          )
        ) {
          state.order.shipping.packages.forEach((pack) => {
            dispatch(userStorePut(null, "package", pack));
          });
        } else {
          state.order.shipping.packages.forEach((pack, index) => {
            dispatch(changePackage(pack, index, "Easy"));
          });
          dispatch(
            selectGoodsValue(
              state.order.services.insurance.value,
              state.order.services.insurance.algorithm
            )
          );
          dispatch(
            logError("validation", {
              errors: shippingValidatorEasyMode.which(
                state.order.shipping,
                state.order.services,
                state.order.from,
                state.order.to
              ),
            })
          );
        }
        if (!servicesValidator.test(state.order.services)) {
          dispatch(changeServices(state.order.services));
          dispatch(
            logError("validation", {
              errors: servicesValidator.which(state.order.services),
            })
          );
        }
      }
      next(action);
      break;

    default:
      return next(action);
  }
};

export default validationMiddleware;
